import React, { useState } from 'react'

const ExcelJS = require("exceljs")

export const CobranzasGerenciaXLS = ({ data, mes, año }) => {

  const [enableBtn, setEnableBtn] = useState(true)

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(`Cobranzas de ${mes} - ${año}`);
    sheet.properties.defaultRowHeight = 20;
    sheet.properties.defaultColWidth = 25;

    sheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0041FF' }
    };

    sheet.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFF' }
    };
    sheet.columns = [
      { header: 'Zona', key: 'Sector', width: 25 },
      { header: 'Documento', key: 'Documento', width: 20 },
      { header: 'Cliente', key: 'NombreCliente', width: 60 },
      { header: 'Código Vendedor', key: 'CodigoVendedor', width: 25 },
      { header: 'Vendedor', key: 'NombreVendedor', width: 20 },
      { header: 'Fecha Emisión', key: 'FechaEmision', width: 25 },
      { header: 'Fecha Vencimiento', key: 'FechaVencimiento', width: 30 },
      { header: 'Días de Vencido', key: 'DiasVencido', width: 30 },
      { header: 'Días de Credito', key: 'DiasCredito', width: 30 },
      { header: 'Total Facturado', key: 'TotalFact', width: 25 },
      { header: 'Total Pendiente', key: 'TotalPend', width: 25 },
      { header: 'Estatus', key: 'Estatus', width: 15 },
    ];

    data.forEach(documento => {
      const rowData = {
        Sector: documento.Sector,
        Documento: documento.documento,
        NombreCliente: documento.nombrecli,
        CodigoVendedor: documento.CodVendedor,
        NombreVendedor: documento.Nombre,
        FechaEmision: documento.FechaDocumento,
        FechaVencimiento: documento.FechaVencimiento,
        DiasVencido: documento.DiasVencidos,
        DiasCredito: documento.DiasCredito,
        TotalFact: documento.TotalFact,
        TotalPend: documento.TotalPend,
        Estatus: documento.Estatus,
      };
      sheet.addRow(rowData);
    });

    for (let i = 1; i <= sheet.columns.length; i++) {
      sheet.getColumn(i).alignment = { vertical: 'middle', horizontal: 'center' }
    }

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Cobranzas de ${mes} - ${año}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
      setEnableBtn(true)
    });
  }

  return (
    <>
      <div className='d-md-block d-none'>
        {
          (enableBtn) ?
            <button onClick={exportToExcel} className='btn btn-info btn-sm mb-2 w-100'>Generar Reporte XLSX</button>
            :
            <button className='btn btn-info btn-sm mb-2 w-100 disabled' disabled>Generar Reporte XLSX</button>
        }
      </div>
    </>
  )
}