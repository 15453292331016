import React from 'react'

import AuthUser from '../../components/AuthUser';
import { getClientesDesatendidosByVendedor, getClientesXLSX, getClientesDeudoresXLSX } from '../../api/requestClientes';

import { Dropdown, DropdownButton } from 'react-bootstrap'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

export const ClientesSupervisorXLS = ({ vendedor, fileName }) => {

  const { user } = AuthUser();

  const exportToExcelGeneral = async () => {

    const clientes = await getClientesXLSX(vendedor)

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(clientes);
    const wb = { Sheets: { 'clientes': ws }, SheetNames: ['clientes'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `General de Clientes - ${vendedor}` + fileExtension);
  }

  const exportToExcelDesatendidos = async () => {

    const clientesDesatendidos = await getClientesDesatendidosByVendedor(vendedor, 'XLSX')

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(clientesDesatendidos);
    const wb = { Sheets: { 'CLIENTES DESATENDIDOS': ws }, SheetNames: ['CLIENTES DESATENDIDOS'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Clientes desatendidos ${vendedor}` + fileExtension);
  }

  const exportToExcelDeudores = async () => {

    const clientesDeudores = await getClientesDeudoresXLSX(vendedor)

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx';

    const ws = XLSX.utils.json_to_sheet(clientesDeudores);
    const wb = { Sheets: { 'CLIENTES DEUDORES': ws }, SheetNames: ['CLIENTES DEUDORES'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Clientes deudores ${vendedor}` + fileExtension);
  }

  return (
    <>
      <div className='d-md-block d-none'>
        <DropdownButton
          key={'end'}
          id={`dropdown-button-drop-${'end'}`}
          drop={'end'}
          variant="primary"
          title={` Exportar a XLSX `}
        >
          <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={exportToExcelGeneral}>General de Clientes</Dropdown.Item>
          {/* <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={exportToExcelDesatendidos}>No atendidos</Dropdown.Item> */}
          <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={exportToExcelDeudores}>Clientes con deudas</Dropdown.Item>
        </DropdownButton>
      </div>
    </>
  )
}
