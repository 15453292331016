import React, { useState, useEffect } from 'react'

import AuthUser from '../../components/AuthUser';

import { getTopClientes, getParetos } from '../../api/requestSupervisor';

const ExcelJS = require("exceljs")

export const TopClientesXLS = ({ generate }) => {

  const { user } = AuthUser();

  const [enableBtn, setEnableBtn] = useState(true)

  const exportToExcel = async () => {
    let resTopClientes
    if (generate === 'paretos') {
      resTopClientes = await getParetos(user.CodVendedor, 'xls')
    } else {
      resTopClientes = await getTopClientes(user.CodVendedor, 'xls')
    }
    const topDeClientes = await resTopClientes
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(`Top Clientes (${generate})`);
    sheet.properties.defaultRowHeight = 20;
    sheet.properties.defaultColWidth = 25;
    
    sheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0041FF' }
    };

    sheet.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFF' }
    };
    sheet.columns = [
      { header: 'Zona', key: 'Sector', width: 25 },
      { header: 'Código Cliente', key: 'CodigoCliente', width: 25 },
      { header: 'Cliente', key: 'NombreCliente', width: 75 },
      { header: 'Código Vendedor', key: 'CodigoVendedor', width: 25 },
      { header: 'Vendedor', key: 'NombreVendedor', width: 25 },
      { header: 'Máximo Facturado', key: 'Maximo_Facturado', width: 25 },
      { header: 'Mes Máximo Facturado', key: 'Mes', width: 32 },
      { header: 'Facturado Mes en Curso', key: 'Facturado', width: 34 },
    ];

    topDeClientes.forEach(cliente => {
      const rowData = {
        Sector: cliente.Sector,
        CodigoCliente: cliente.CodigoCliente,
        NombreCliente: cliente.NombreCliente,
        CodigoVendedor: cliente.CodigoVendedor,
        NombreVendedor: cliente.NombreVendedor,
        Maximo_Facturado: cliente.Maximo_Facturado,
        Mes: cliente.Mes,
        Facturado: cliente.Facturado
      };
      sheet.addRow(rowData);
    });

    for (let i = 1; i <= sheet.columns.length; i++) {
      if (i !== 3) {
        sheet.getColumn(i).alignment = { vertical: 'middle', horizontal: 'center' }
      }
    }

    sheet.getCell("C1").alignment = { vertical: 'middle', horizontal: 'center' }

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Top Clientes (${generate}).xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
      setEnableBtn(true)
    });
  }

  return (
    <>
      <div className='d-md-block d-none'>
        {
          (enableBtn) ?
            <button onClick={exportToExcel} className='btn btn-info btn-sm mb-2 w-100'>Generar Reporte de Clientes XLSX</button>
            :
            <button className='btn btn-info btn-sm mb-2 w-100 disabled' disabled>Generar Reporte de Clientes XLSX</button>
        }
      </div>
    </>
  )
}
