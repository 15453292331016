import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import AuthUser from '../../../components/AuthUser';
import { ConsultaDocVencidos } from './ConsultaDocVencidos';
import { DetalleMasterCobranzas } from './DetalleMasterCobranzas';

import { getListaMasterCobranzas, getListaMasterCobranzasZona, getCobranzaVendedor } from '../../../api/requestCobranzas';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import { Dropdown, DropdownButton, Collapse, Modal, Button, Col, Row } from 'react-bootstrap'
import { RiCloseCircleFill } from "react-icons/ri";

import belmenyLogo from '../../../assets/img/logoBG.webp';
import userIcon from '../../../assets/img/user-icon-resize.webp';

export const MasterCobranzas = () => {

  useEffect(() => {
    if (user.CodSupervisor != null) {
      setUsername(user.CodSupervisor)
    }
    else if (user.CodGerente != null) {
      setUsername(user.CodGerente)
    }
    loadMaster()
  }, []);

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [username, setUsername] = useState('')
  const [lista, setLista] = useState([]);
  const [totalFacturado, setTotalFacturado] = useState(0);
  const [totalPendiente, setTotalPendiente] = useState(0);
  const [totalPagado, setTotalPagado] = useState(0);
  const [montoFac, setMontoFac] = useState(0);
  const [montoPen, setMontoPen] = useState(0);
  const [montoPag, setMontoPag] = useState(0);
  const [zona, setZona] = useState('');
  const [chartZonaData, setChartZonaData] = useState({});
  const [options, setOptions] = useState({
    indexAxis: 'y',
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ventas vs Cobranzas',
      },
    },
  });
  const [btnShow, setBtnShow] = useState(false);

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [showZona, setShowZona] = useState(false);

  const now = new Date();
  const firstDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth(), 1)).slice(1, 11);
  const lastDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth() + 1, 0)).slice(1, 11);

  const loadMaster = async () => {
    const res = await getListaMasterCobranzas()
    setLista(res)

    let montoFacturado = 0
    let montoPendiente = 0
    let montoPagado = 0

    for (let i = 0; i < res.length; i++) {
      montoFacturado += parseInt(res[i].Facturado)
      montoPendiente += parseInt(res[i].Pendiente)
      montoPagado += parseInt(res[i].Pagado)
    }
    setTotalFacturado(montoFacturado)
    setTotalPendiente(montoPendiente)
    setTotalPagado(montoPagado)

    setLoading(false)
  }

  const handleFilter = async (e, zona) => {
    e.preventDefault();

    let montoFacturado = 0
    let montoPendiente = 0
    let montoPagado = 0

    switch (zona) {
      case 'Andina':
        setLoading(true)
        const resAndina = await getListaMasterCobranzasZona('Andina')
        setZona('Andina')
        setLista(resAndina)

        for (let i = 0; i < resAndina.length; i++) {
          montoFacturado += parseInt(resAndina[i].Facturado)
          montoPendiente += parseInt(resAndina[i].Pendiente)
          montoPagado += parseInt(resAndina[i].Pagado)
        }
        setMontoFac(montoFacturado)
        setMontoPen(montoPendiente)
        setMontoPag(montoPagado)
        setChartZonaData({
          labels: resAndina.map((Andina) => Andina.Nombre),
          datasets: [
            {
              data: resAndina.map((Andina) => Andina.Facturado),
              label: `Facturado`,
              backgroundColor: '#2980b9',
              stack: 'Stack 0',
            },
            {
              data: resAndina.map((Andina) => Andina.Pendiente),
              label: `Pendiente`,
              backgroundColor: '#e74c3c',
              stack: 'Stack 1',
            },
            {
              data: resAndina.map((Andina) => Andina.Pagado),
              label: `Pagado`,
              backgroundColor: '#2ecc71',
              stack: 'Stack 2',
            },
          ]
        })
        setBtnShow(true)
        setLoading(false)
        break;

      case 'Centro':
        setLoading(true)
        const resCentro = await getListaMasterCobranzasZona('Centro')
        setZona('Centro')
        setLista(resCentro)
        for (let i = 0; i < resCentro.length; i++) {
          montoFacturado += parseInt(resCentro[i].Facturado)
          montoPendiente += parseInt(resCentro[i].Pendiente)
          montoPagado += parseInt(resCentro[i].Pagado)
        }
        setMontoFac(montoFacturado)
        setMontoPen(montoPendiente)
        setMontoPag(montoPagado)
        setChartZonaData({
          labels: resCentro.map((Centro) => Centro.Nombre),
          datasets: [
            {
              data: resCentro.map((Centro) => Centro.Facturado),
              label: `Facturado`,
              backgroundColor: '#2980b9',
              stack: 'Stack 0',
            },
            {
              data: resCentro.map((Centro) => Centro.Pendiente),
              label: `Pendiente`,
              backgroundColor: '#e74c3c',
              stack: 'Stack 1',
            },
            {
              data: resCentro.map((Centro) => Centro.Pagado),
              label: `Pagado`,
              backgroundColor: '#2ecc71',
              stack: 'Stack 2',
            },
          ]
        })
        setBtnShow(true)
        setLoading(false)
        break;

      case 'Falcon':
        setLoading(true)
        const resFalcon = await getListaMasterCobranzasZona('Falcon')
        setZona('Falcon')
        setLista(resFalcon)
        for (let i = 0; i < resFalcon.length; i++) {
          montoFacturado += parseInt(resFalcon[i].Facturado)
          montoPendiente += parseInt(resFalcon[i].Pendiente)
          montoPagado += parseInt(resFalcon[i].Pagado)
        }
        setMontoFac(montoFacturado)
        setMontoPen(montoPendiente)
        setMontoPag(montoPagado)
        setChartZonaData({
          labels: resFalcon.map((Falcon) => Falcon.Nombre),
          datasets: [
            {
              data: resFalcon.map((Falcon) => Falcon.Facturado),
              label: `Facturado`,
              backgroundColor: '#2980b9',
              stack: 'Stack 0',
            },
            {
              data: resFalcon.map((Falcon) => Falcon.Pendiente),
              label: `Pendiente`,
              backgroundColor: '#e74c3c',
              stack: 'Stack 1',
            },
            {
              data: resFalcon.map((Falcon) => Falcon.Pagado),
              label: `Pagado`,
              backgroundColor: '#2ecc71',
              stack: 'Stack 2',
            },
          ]
        })
        setBtnShow(true)
        setLoading(false)
        break;

      case 'Gran Caracas':
        setLoading(true)
        const resCaracas = await getListaMasterCobranzasZona('Gran Caracas')
        setZona('Gran Caracas')
        setLista(resCaracas)
        for (let i = 0; i < resCaracas.length; i++) {
          montoFacturado += parseInt(resCaracas[i].Facturado)
          montoPendiente += parseInt(resCaracas[i].Pendiente)
          montoPagado += parseInt(resCaracas[i].Pagado)
        }
        setMontoFac(montoFacturado)
        setMontoPen(montoPendiente)
        setMontoPag(montoPagado)
        setChartZonaData({
          labels: resCaracas.map((Caracas) => Caracas.Nombre),
          datasets: [
            {
              data: resCaracas.map((Caracas) => Caracas.Facturado),
              label: `Facturado`,
              backgroundColor: '#2980b9',
              stack: 'Stack 0',
            },
            {
              data: resCaracas.map((Caracas) => Caracas.Pendiente),
              label: `Pendiente`,
              backgroundColor: '#e74c3c',
              stack: 'Stack 1',
            },
            {
              data: resCaracas.map((Caracas) => Caracas.Pagado),
              label: `Pagado`,
              backgroundColor: '#2ecc71',
              stack: 'Stack 2',
            },
          ]
        })
        setBtnShow(true)
        setLoading(false)
        break;

      case 'Llanos':
        setLoading(true)
        const resLlanos = await getListaMasterCobranzasZona('Llanos')
        setZona('Llanos')
        setLista(resLlanos)
        for (let i = 0; i < resLlanos.length; i++) {
          montoFacturado += parseInt(resLlanos[i].Facturado)
          montoPendiente += parseInt(resLlanos[i].Pendiente)
          montoPagado += parseInt(resLlanos[i].Pagado)
        }
        setMontoFac(montoFacturado)
        setMontoPen(montoPendiente)
        setMontoPag(montoPagado)
        setChartZonaData({
          labels: resLlanos.map((Llanos) => Llanos.Nombre),
          datasets: [
            {
              data: resLlanos.map((Llanos) => Llanos.Facturado),
              label: `Facturado`,
              backgroundColor: '#2980b9',
              stack: 'Stack 0',
            },
            {
              data: resLlanos.map((Llanos) => Llanos.Pendiente),
              label: `Pendiente`,
              backgroundColor: '#e74c3c',
              stack: 'Stack 1',
            },
            {
              data: resLlanos.map((Llanos) => Llanos.Pagado),
              label: `Pagado`,
              backgroundColor: '#2ecc71',
              stack: 'Stack 2',
            },
          ]
        })
        setBtnShow(true)
        setLoading(false)
        break;

      case 'Oriente':
        setLoading(true)
        const resOriente = await getListaMasterCobranzasZona('Oriente')
        setZona('Oriente')
        setLista(resOriente)
        for (let i = 0; i < resOriente.length; i++) {
          montoFacturado += parseInt(resOriente[i].Facturado)
          montoPendiente += parseInt(resOriente[i].Pendiente)
          montoPagado += parseInt(resOriente[i].Pagado)
        }
        setMontoFac(montoFacturado)
        setMontoPen(montoPendiente)
        setMontoPag(montoPagado)
        setChartZonaData({
          labels: resOriente.map((Oriente) => Oriente.Nombre),
          datasets: [
            {
              data: resOriente.map((Oriente) => Oriente.Facturado),
              label: `Facturado`,
              backgroundColor: '#2980b9',
              stack: 'Stack 0',
            },
            {
              data: resOriente.map((Oriente) => Oriente.Pendiente),
              label: `Pendiente`,
              backgroundColor: '#e74c3c',
              stack: 'Stack 1',
            },
            {
              data: resOriente.map((Oriente) => Oriente.Pagado),
              label: `Pagado`,
              backgroundColor: '#2ecc71',
              stack: 'Stack 2',
            },
          ]
        })
        setBtnShow(true)
        setLoading(false)
        break;

      case 'Tachira':
        setLoading(true)
        const resTachira = await getListaMasterCobranzasZona('Tachira')
        setZona('Tachira')
        setLista(resTachira)
        for (let i = 0; i < resTachira.length; i++) {
          montoFacturado += parseInt(resTachira[i].Facturado)
          montoPendiente += parseInt(resTachira[i].Pendiente)
          montoPagado += parseInt(resTachira[i].Pagado)
        }
        setMontoFac(montoFacturado)
        setMontoPen(montoPendiente)
        setMontoPag(montoPagado)
        setChartZonaData({
          labels: resTachira.map((Tachira) => Tachira.Nombre),
          datasets: [
            {
              data: resTachira.map((Tachira) => Tachira.Facturado),
              label: `Facturado`,
              backgroundColor: '#2980b9',
              stack: 'Stack 0',
            },
            {
              data: resTachira.map((Tachira) => Tachira.Pendiente),
              label: `Pendiente`,
              backgroundColor: '#e74c3c',
              stack: 'Stack 1',
            },
            {
              data: resTachira.map((Tachira) => Tachira.Pagado),
              label: `Pagado`,
              backgroundColor: '#2ecc71',
              stack: 'Stack 2',
            },
          ]
        })
        setBtnShow(true)
        setLoading(false)
        break;

      case 'Zulia':
        setLoading(true)
        const resZulia = await getListaMasterCobranzasZona('Zulia')
        setZona('Zulia')
        setLista(resZulia)
        for (let i = 0; i < resZulia.length; i++) {
          montoFacturado += parseInt(resZulia[i].Facturado)
          montoPendiente += parseInt(resZulia[i].Pendiente)
          montoPagado += parseInt(resZulia[i].Pagado)
        }
        setMontoFac(montoFacturado)
        setMontoPen(montoPendiente)
        setMontoPag(montoPagado)
        setChartZonaData({
          labels: resZulia.map((Zulia) => Zulia.Nombre),
          datasets: [
            {
              data: resZulia.map((Zulia) => Zulia.Facturado),
              label: `Facturado`,
              backgroundColor: '#2980b9',
              stack: 'Stack 0',
            },
            {
              data: resZulia.map((Zulia) => Zulia.Pendiente),
              label: `Pendiente`,
              backgroundColor: '#e74c3c',
              stack: 'Stack 1',
            },
            {
              data: resZulia.map((Zulia) => Zulia.Pagado),
              label: `Pagado`,
              backgroundColor: '#2ecc71',
              stack: 'Stack 2',
            },
          ]
        })
        setBtnShow(true)
        setLoading(false)
        break;

      case 'Limpiar':
        setLoading(true)
        loadMaster()
        setBtnShow(false)
        setLoading(false)
        break;

      default:
        setLoading(true)
        loadMaster()
        setBtnShow(false)
        setLoading(false)
        break;
    }
  }

  function handleShowZona() {
    setFullscreen(true);
    setShowZona(true);
  }

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  <span className="loader-cobranzas"></span>
                </div>
              </> :
              <>
                {/* Div para movil */}
                <div className="d-sm-block d-md-none">
                  <div className="container-fluid rounded">

                    <div className="consultas-title px-1 mt-5 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Ventas vs Pagado</h4>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mb-2">
                          <DropdownButton
                            key={'bottom'}
                            id={`dropdown-button-drop-${'bottom'}`}
                            drop={'bottom'}
                            variant="primary"
                            title={` Zona `}
                          >
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Andina')}>Andina</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Centro')}>Centro</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Falcon')}>Falcon</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Gran Caracas')}>Gran Caracas</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Llanos')}>Llanos</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Oriente')}>Oriente</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Tachira')}>Tachira</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Zulia')}>Zulia</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="1" className='text-decoration-none bg-danger text-light' onClick={e => handleFilter(e, 'Limpiar')}>Limpiar Filtro</Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {
                        (btnShow && zona !== '') ?
                          <>
                            <div className="col">
                              <div className="mb-2">
                                <button className="btn btn-primary" onClick={(e) => handleShowZona()}>Ver Reporte de Ventas vs Pagado de la zona {zona}</button>
                              </div>
                            </div>
                          </> :
                          <>
                          </>
                      }
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="float-start">
                          <ConsultaDocVencidos />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="division bg-belmeny my-3"></div>

                  <h3 className="text-center text-belmeny">Vendedores</h3>

                  <div className="container-fluid">
                    {lista.map((item) => (
                      <>
                        <div className="card my-3 drop-shadow border-belmeny">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-10">
                                <h5 className="card-title text-belmeny">{item.Nombre.toUpperCase()}</h5>
                              </div>
                              <div className="col-2">
                                <DetalleMasterCobranzas vendedor={item.CodigoVendedor} totalFacturado={totalFacturado} totalPendiente={totalPendiente} totalPagado={totalPagado} />
                              </div>
                            </div>
                            <h6 className="card-subtitle mb-2 text-muted fst-italic">{item.CodigoVendedor}</h6>
                            <h6>Facturado: <span className="fw-bold text-belmeny">${item.Facturado}</span></h6>
                            <h6 className='my-1'>Pendiente: <span className='fw-bold text-danger'>${item.Pendiente}</span></h6>
                            <h6>Pagado: <span className='fw-bold text-success'>${item.Pagado}</span></h6>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>

                {/* Div para web */}
                <div className="d-none d-md-block">
                  <div className="container-fluid rounded">
                    <div className="row">
                      <div className="col">
                        <div className="text-belmeny">
                          <h2 className='fs-1'><strong>Bienvenido</strong></h2>
                          <h3>{user.Nombre}</h3>
                          <h5><i>{username}</i></h5>
                        </div>
                      </div>
                      <div className="col">
                        <img src={userIcon} alt="Logo Belmeny Group" className='float-end me-5 drop-shadow' />
                      </div>
                    </div>

                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Ventas vs Pagado</h4>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="mb-2">
                          <DropdownButton
                            key={'bottom'}
                            id={`dropdown-button-drop-${'bottom'}`}
                            drop={'bottom'}
                            variant="primary"
                            title={` Zona `}
                          >
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Andina')}>Andina</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Centro')}>Centro</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Falcon')}>Falcon</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Gran Caracas')}>Gran Caracas</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Llanos')}>Llanos</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Oriente')}>Oriente</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Tachira')}>Tachira</Dropdown.Item>
                            <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, 'Zulia')}>Zulia</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="1" className='text-decoration-none bg-danger text-light' onClick={e => handleFilter(e, 'Limpiar')}>Limpiar Filtro</Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </div>
                      {
                        (btnShow && zona !== '') ?
                          <>
                            <div className="col">
                              <div className="mb-2">
                                <button className="btn btn-primary" onClick={(e) => handleShowZona()}>Ver Reporte de Ventas vs Pagado de la zona: {zona}</button>
                              </div>
                            </div>
                          </> :
                          <>
                          </>
                      }
                      <div className="col">
                        <ConsultaDocVencidos />
                      </div>
                    </div>
                  </div>

                  <div className="division bg-belmeny my-3"></div>

                  <h3 className="text-center text-belmeny">Vendedores</h3>

                  <div className="container-fluid">
                    <table className="table table-responsive table-striped text-center table-bordered table-hover border-belmeny rounded">
                      <thead className='bg-belmeny text-light'>
                        <tr>
                          <th className='py-2'>Vendedor</th>
                          <th className='py-2'>Nombre</th>
                          <th className='py-2'>Facturado</th>
                          <th className='py-2'>Pendiente</th>
                          <th className='py-2'>Pagado</th>
                          <th className='py-2'>Opciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lista.map((item) => (
                          <>
                            <tr>
                              <td>{item.CodigoVendedor}</td>
                              <td className='w-25'>{item.Nombre.toUpperCase()}</td>
                              <td className='fw-bold text-belmeny'>${item.Facturado}</td>
                              <td className='fw-bold text-danger'>${item.Pendiente}</td>
                              <td className='fw-bold text-success'>${item.Pagado}</td>
                              <td>
                                <DetalleMasterCobranzas vendedor={item.CodigoVendedor} totalFacturado={totalFacturado} totalPendiente={totalPendiente} totalPagado={totalPagado} />
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>

                </div>
              </>
          }
        </div>
      </Layout>

      
    </>
  )
}
