import React, { useState, useEffect } from 'react'

import AuthUser from '../../components/AuthUser'

import exhibidorPizarra from '../../assets/img/ingco-pizarra.jpeg'
import exhibidor1Cuerpo from '../../assets/img/ingco-1-cuerpo.png'
import exhibidor2Cuerpos from '../../assets/img/ingco-2-cuerpos.png'
import exhibidor3Cuerpos from '../../assets/img/ingco-3-cuerpos.png'
import exhibidor4Cuerpos from '../../assets/img/ingco-4-cuerpos.png'
import exhibidor5Cuerpos from '../../assets/img/ingco-5-cuerpos.png'
import exhibidor6Cuerpos from '../../assets/img/ingco-6-cuerpos.png'

export const ArmadoInfo = ({ formData, setFormData }) => {

  const { user } = AuthUser();

  const [tipo, setTipo] = useState('');
  const [showMedidas, setShowMedidas] = useState(false);
  const [ancho, setAncho] = useState(0);
  const [alto, setAlto] = useState(0);

  useEffect(() => {

  }, []);

  const handleSelect = (e) => {
    e.preventDefault();
    // console.log(e.target.value)
    var tipoExhibidor = e.target.value
    setTipo(tipoExhibidor)
    tipoExhibidor === 'Personalizado' ? setShowMedidas(true) : setShowMedidas(false)
  }

  return (
    <>
      <div className="p-5 my-3 rounded border-belmeny">
        <div className="row">
          <div className="col">
            <h4>Arma tu exhibidor</h4>
            <h6 className="text-muted">Escoge entre las opciones mostradas como sería el exhibidor deseado (se mostrarán imagenes de ejemplo en el proceso)</h6>

            <div className="mt-3">
              <h4 className='fw-normal'>
                <label htmlFor="tipo_exhibidor" className='me-3 fw-bold'>Tipo de Exhibidor: </label>
                <div className="form-group">
                  <select name="tipo_exhibidor" id="tipo_exhibidor" className='form-select' onChange={(e) => {
                    handleSelect(e)
                    setFormData({ ...formData, tipoExhibidor: e.target.value })
                  }}
                    value={tipo}
                    required
                  >
                    <option value="select">Selecciona un tipo de exhibidor</option>
                    <option value="Pizarra">Tipo Pizarra</option>
                    <option value="1 Cuerpo">1 Cuerpo</option>
                    <option value="2 Cuerpos">2 Cuerpos</option>
                    <option value="3 Cuerpos">3 Cuerpos</option>
                    <option value="4 Cuerpos">4 Cuerpos</option>
                    <option value="5 Cuerpos">5 Cuerpos</option>
                    <option value="6 Cuerpos">6 Cuerpos</option>
                    <option value="Personalizado">Exhibidor a la medida</option>
                  </select>
                </div>
              </h4>
            </div>

            <div className="mt-3">
              <h5 className="fw-normal">Estado: <span className="text-success fw-bold">En Stock</span> - Disponibilidad: <span className="text-success fw-bold">5 disponibles</span></h5>
              <h5 className="fw-normal py-2">Monto mínimo para optar al exhibidor: <span className="text-success fw-bold">$1000</span></h5>
              <h5 className="fw-normal">Efectividad del exhibidor: <span className="text-warning">Media</span></h5>
              <h5 className="fw-normal py-2"><a href="#">Lista de productos sugeridos para el exhibidor</a></h5>
            </div>

            {
              (showMedidas) ?
                <>
                  <div className="mt-3">
                    <h4><label htmlFor="motivoSolicitud" className="form-label">Medidas del Exhibidor: </label></h4>
                    <input
                      type="number"
                      className="form-control w-25"
                      placeholder="Ancho (en metros)"
                      value={formData.password}
                      onChange={(event) =>
                        setFormData({ ...formData, password: event.target.value })
                      }
                    />
                    <br />
                    <input
                      type="number"
                      className="form-control w-25"
                      placeholder="Alto (en metros)"
                      value={formData.password}
                      onChange={(event) =>
                        setFormData({ ...formData, password: event.target.value })
                      }
                    />
                  </div>
                </> :
                <>
                </>
            }
          </div>
          <div className="col text-center">
            {
              (tipo === 'Pizarra') ?
                <>
                  <img src={exhibidorPizarra} alt="Exhibidor de Pizarra" className='border-belmeny drop-shadow' />
                </> :
                (tipo === '1 Cuerpo') ?
                  <>
                    <img src={exhibidor1Cuerpo} alt="Exhibidor de 1 Cuerpo" className='border-belmeny drop-shadow' />
                  </> :
                  (tipo === '2 Cuerpos') ?
                    <>
                      <img src={exhibidor2Cuerpos} alt="Exhibidor de 2 Cuerpos" className='border-belmeny drop-shadow' />
                    </> :
                    (tipo === '3 Cuerpos') ?
                      <>
                        <img src={exhibidor3Cuerpos} alt="Exhibidor de 3 Cuerpos" className='border-belmeny drop-shadow' />
                      </> :
                      (tipo === '4 Cuerpos') ?
                        <>
                          <img src={exhibidor4Cuerpos} alt="Exhibidor de 4 Cuerpos" className='border-belmeny drop-shadow' />
                        </> :
                        (tipo === '5 Cuerpos') ?
                          <>
                            <img src={exhibidor5Cuerpos} alt="Exhibidor de 5 Cuerpos" className='border-belmeny drop-shadow' />
                          </> :
                          (tipo === '6 Cuerpos') ?
                            <>
                              <img src={exhibidor6Cuerpos} alt="Exhibidor de 6 Cuerpos" className='border-belmeny drop-shadow' />
                            </> :
                            (tipo === 'Personalizado') ?
                              <>
                                <img src={exhibidorPizarra} alt="Exhibidor Personalizado" className='border-belmeny drop-shadow' />
                              </> :
                              <>

                              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}