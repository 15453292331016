import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';

import Chart from "react-apexcharts";

// requests
import { getMetasSemanalVendedores } from '../../api/requestSupervisor';

// assets
import otherUserIcon from '../../assets/img/user-icon-resize.webp';
import notFoundImg from '../../assets/img/404-module-not-found-2.webp';



export const MetasSemanales = () => {

  useEffect(() => {
    loadMetasSemanales()
  }, [])



  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [username, setUsername] = useState('');
  const [userIcon, setUserIcon] = useState(``);
  const [barChart, setBarChart] = useState({});


  // Check user img
  const CheckImg = async () => {
    await axios.get(`https://belmeny.com/fotos_vendedores/${user.CodVendedor}.jpeg`).then(() => {
      setUserIcon(`https://belmeny.com/fotos_vendedores/${user.CodVendedor}.jpeg`)
      return true;
    })
      .catch(() => {
        setUserIcon(otherUserIcon)
        return false;
      });
  }

  // Handlers
  const handleImageError = (event) => {
    event.target.src = notFoundImg;
  };

  // Loaders
  const loadMetasSemanales = async () => {
    const resMetas = await getMetasSemanalVendedores(user.CodVendedor)

    const newMetas = resMetas.Ingco.secondWeek.filter((Zona) => Zona.Sector === 'Andina')

    setBarChart({
      options: {
        chart: {
          id: "chart-VentasZona",
        },
        xaxis: {
          categories: resMetas.Ingco.secondWeekIngco.map((Vendedor) => Vendedor.Nombre)
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
          }
        },
        colors: ['#27ae60'],
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals
            if (goals && goals.length) {
              return `${val.toFixed(2)} / ${goals[0].value.toFixed(2)}`
            }
            return val
          },
          enabled: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ['Ventas actuales', 'Ventas esperadas'],
          markers: {
            fillColors: ['#27ae60', '#e74c3c']
          }
        }
      },
      series: [
        {
          name: 'Ventas en USD',
          data: resMetas.Ingco.secondWeekIngco.map((Vendedor) => ({
            x: Vendedor.Nombre,
            y: Vendedor.ventasIngco.toFixed(2),
            goals: [
              {
                name: 'Meta Semanal en USD',
                value: 20000,
                strokeWidth: 10,
                strokeHeight: 0,
                strokeLineCap: 'round',
                strokeColor: '#e74c3c'
              }
            ]
          }))
        }
      ]
    })
    console.log(resMetas)
    setLoading(false)
  }

  return (
    <>
      <Layout>
        <div className="bg-dashboard">
          <div className="container-fluid mt-5">
            {
              (loading) ?
                <>
                  <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div className="loader-book">
                      <div>
                        <ul>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                      <span>Cargando estadisticas...</span>
                    </div>
                  </div>
                </> :
                <>
                  {/* Div para movil */}
                  <div className="text-center d-sm-block d-md-none">
                    <br />
                    <img src={notFoundImg} alt="..." onError={handleImageError} className="mx-auto w-100" />
                  </div>
                  {/* Div para web */}
                  <div className='d-none d-md-block mt-3'>
                    <div className="container-fluid rounded">
                      <div className="row">
                        <div className="col">
                          <div className="text-belmeny">
                            <h2 className='fs-1'><strong>Bienvenido</strong></h2>
                            <h3>{user.Nombre}</h3>
                            <h5><i>{username}</i></h5>
                          </div>
                        </div>
                        <div className="col">
                          {/* <img src={userIcon} alt="Logo Belmeny Group" onError={handleImageError} className='float-end me-5 rounded-circle drop-shadow' /> */}
                          <img src={userIcon} alt="No se ha cargado la imagen..." className='float-end drop-shadow rounded' style={{ width: "150px", height: "150px", border: "3px solid rgba(0, 41, 117, 1)" }} />
                        </div>
                      </div>
                      <div className="dashboard-title mt-2">
                        <h4 className='bg-belmeny text-light px-5 rounded-pill'>Estadisticas de ventas semanales</h4>
                      </div>
                      <h4 className="text-center text-belmeny mt-5">Ventas de vendedores x semana</h4>

                      <div className="row">
                        <div className="col">
                          <Chart
                            options={barChart.options}
                            series={barChart.series}
                            type="bar"
                            width="100%"
                          />
                        </div>
                        <div className="col">
                          <Chart
                            options={barChart.options}
                            series={barChart.series}
                            type="bar"
                            width="100%"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </>
            }
          </div>
        </div>
      </Layout>
    </>
  )
}