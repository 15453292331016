import React, { useState } from 'react'
import { FormControl, Button, Row, Col } from 'react-bootstrap'

export const Searchbar = (props) => {

  const { onSearch } = props;
  // console.log(props)
  const [search, setSearch] = useState('')

  const onChange = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length === 0) {
      onSearch(null);
    }
  };

  const onClick = async (e) => {
    onSearch(search.toLowerCase());
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      onSearch(search.toLowerCase());
    }

  };

  return (
    <>
      <div className='mb-2 d-none d-md-block d-lg-block'>
        <Row>
          <Col xs={5}>
            <FormControl
              type="search"
              placeholder="Ingrese un término para realizar la búsqueda"
              className="d-flex me-2  searchbar m-auto rounded-pill"
              aria-label="Buscar"
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
          </Col>
          <Col xs={1}>
            <Button variant="success" className="d-flex searchbar-btn rounded-pill m-auto" onClick={onClick} >Buscar</Button>
          </Col>
        </Row>
      </div>

      <div className='mb-2 d-sm-block d-md-none px-3'>
        <Row>
          <FormControl
            type="search"
            placeholder="Ingrese un término para la búsqueda"
            className="searchbar rounded-pill m-auto w-100 text-center"
            aria-label="Buscar"
            onChange={onChange}
            onKeyDown={handleKeyDown}
          />
        </Row>
        <Row>
          <Button variant="success" className="searchbar-btn rounded-pill m-auto mt-3 text-center px-2 w-100" onClick={onClick} >Buscar</Button>
        </Row>
      </div>
    </>
  )
}
