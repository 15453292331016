import React from 'react';
import ReactDOM from 'react-dom/client';

// Routing
import { BrowserRouter } from 'react-router-dom'

// App
import App from './App';

// Pro Sidebar
import { ProSidebarProvider } from 'react-pro-sidebar';

import { PrimeReactProvider } from 'primereact/api';

import "./assets/css/navbar.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ProSidebarProvider>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </ProSidebarProvider>
  </BrowserRouter>
  // {/* </React.StrictMode> */}
);