import React, { useState, useEffect } from 'react'

import { getArticulosAndFotos } from '../../../api/requestProductos';

import brokenImg from '../../../assets/img/broken-image-general.png';

export const Articulos = () => {

  const [articulos, setArticulos] = useState([]);

  var arrArticulos = []
  var data = {}

  const handleImageError = (event) => {
    event.target.src = brokenImg; // Replace with your default image URL
  };

  function checkIfImageExists(url, callback) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  }

  const loadArticulos = async () => {
    const res = await getArticulosAndFotos();
    console.log(res)

    for (let i = 0; i < res.length; i++) {
      data = {
        Nombre_nuevo: res[i].Nombre_nuevo,
        Codigo: res[i].Codigo,
        RutaImagen: res[i].RutaImagen,
      }
      checkIfImageExists(res[i].RutaImagen, (exists) => {
        if (!exists) {
          console.log(res[i].Codigo)
        }
      });
      arrArticulos.push(data)
    }

    setArticulos(arrArticulos)
  }

  useEffect(() => {
    loadArticulos();
  }, []);

  return (
    <>
      {(articulos.map((item) =>
        <>
          <div className="row w-25">
            <h5>{item.Nombre_nuevo}</h5>
            <h5>{item.Codigo}</h5>
            <img src={item.RutaImagen} alt="" className='w-25' width={150} />
          </div>
        </>
      ))}
    </>
  )
}
