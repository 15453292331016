import React, { useEffect, useState } from 'react'

import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import { BsArrowRight } from "react-icons/bs";

import {
  tip_titulo, tip_1, tip_2, tip_3, tip_4, tip_5, tip_6, tip_7, tip_8, tip_9, tip_10, tip_11, tip_12, tip_13, tip_final,
  tip_titulo_movil, tip_1_movil, tip_2_movil, tip_3_movil, tip_4_movil, tip_5_movil, tip_6_movil, tip_7_movil, tip_8_movil, tip_9_movil, tip_10_movil, tip_11_movil, tip_12_movil, tip_13_movil, tip_final_movil,
  tip_titulo_tablet, tip_1_tablet, tip_2_tablet, tip_3_tablet, tip_4_tablet, tip_5_tablet, tip_6_tablet, tip_7_tablet, tip_8_tablet, tip_9_tablet, tip_10_tablet, tip_11_tablet, tip_12_tablet, tip_13_tablet, tip_final_tablet,
  famac1,
  ingco2,
  vert2,
  standExpoLara,
  standExpoPortuguesaVert,
  standExpoPortuguesaIngco,
  standExpoPortuguesa,
} from '../assets/img'

import codigoQR from '../assets/img/codigoQR.webp';

import { getApkLink } from '../api/request';

export const LandingPage = () => {
  const [loading, setLoading] = useState(true);
  const [apkLink, setApkLink] = useState('');

  useEffect(() => {
    onLoad()
  });

  const onLoad = async () => {

    const link = await getApkLink();
    setApkLink(link[0].Link);

    // Loader en false
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }

  return (
    <>
      {
        (loading) ?
          <>
            <div className='container-fluid bg-belmeny home'>
              <div className="text-center">
                <div className="m-auto spinner">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </> :
          <>
            <Header></Header>

            {/* Landing page for mobile version */}
            <div className='d-sm-block d-md-none'>
              <div className="content my-4">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col text-belmeny text-center">
                      <h1 className="fw-bold">Bienvenidos</h1>
                      <h2 className="fw-normal my-2">Belmeny Group C.A.</h2>
                      <img src={codigoQR} alt="Codigo QR" className='w-75 my-2' />
                    </div>
                  </div>
                  <center>
                    <a href={apkLink} className='text-light text-decoration-none' target='_blank' rel='noreferrer'><h4 className='bg-belmeny download-apk text-light px-5 rounded-pill text-center w-75 mb-5'>¡Descarga nuestra aplicación móvil haciendo click aquí!</h4></a>
                  </center>
                  <div className="row m-auto">
                    <div className="col">
                      <div className="bg-belmeny rounded drop-shadow">
                        <Splide hasTrack={false} options={{
                          rewind: true,
                          autoplay: true,
                          interval: 5000,
                          resetProgress: true,
                          gap: '0rem',
                          // autoHeight: true,
                          // autoWidth: true
                        }}>
                          <SplideTrack>
                            <SplideSlide className='text-center'>
                              <img src={tip_titulo_movil} alt="Titulo" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_1_movil} alt="Tip 1" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_2_movil} alt="Tip 2" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_3_movil} alt="Tip 3" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_4_movil} alt="tip 4" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_5_movil} alt="Tip 5" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_6_movil} alt="Tip 6" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_7_movil} alt="Tip 7" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_8_movil} alt="Tip 8" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_9_movil} alt="Tip 9" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_10_movil} alt="Tip 10" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_11_movil} alt="Tip 11" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_12_movil} alt="Tip 12" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_13_movil} alt="Tip 13" className='w-75' type="image/webp" />
                            </SplideSlide>
                            <SplideSlide className='text-center'>
                              <img src={tip_final_movil} alt="Final" className='w-75' type="image/webp" />
                            </SplideSlide>
                          </SplideTrack>
                          <div className="splide__arrows">
                            <button className="splide__arrow splide__arrow--prev"><i className='fs-3 mb-1'><BsArrowRight /></i></button>
                            <button className="splide__arrow splide__arrow--next"><i className='fs-3 mb-1'><BsArrowRight /></i></button>
                          </div>
                        </Splide>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5 text-center">
                    <div className="col">
                      <div className="noticeHeader rounded-pill">
                        <h5 className='text-light'>Expo Ferretera Lara</h5>
                      </div>
                      <div className="row">
                        <div className="col">
                          <img src={standExpoLara} alt="" className='w-75 drop-shadow rounded my-3' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col">
                      <div className="noticeHeader rounded-pill">
                        <h5 className='text-light'>Expo Portuguesa Productiva</h5>
                      </div>
                      <div className="row">
                        <div className="col">
                          <img src={standExpoPortuguesa} alt="" className='w-75 drop-shadow rounded my-3' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col">
                      <div className="noticeHeader rounded-pill">
                        <h5 className='text-light'>Expo Portuguesa INGCO</h5>
                      </div>
                      <div className="row">
                        <div className="col">
                          <img src={standExpoPortuguesaIngco} alt="" className='w-75 drop-shadow rounded my-3' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Landing page for web version */}
            <div className="d-none d-md-block d-lg-block">
              <main className='content my-4'>
                <div className='container-fluid'>

                  {/* Screen between 768px and 1099px */}
                  <div className="d-sm-md-view">
                    <div className="row">
                      <div className="col-md-12 mt-4 text-center">
                        <h1 className='text-belmeny fw-bold title-belmeny'>Bienvenidos</h1>
                        <h2 className='text-belmeny fw-normal subtitle-belmeny mt-2'>Belmeny Group</h2>
                        <img src={codigoQR} alt="Codigo QR" className='w-50' />
                      </div>
                    </div>
                    <center>
                      <a href={apkLink} className='text-light text-decoration-none' target='_blank'><h4 className='bg-belmeny download-apk text-light px-5 rounded-pill text-center w-75 ms-5 mb-5'>¡Descarga nuestra aplicación móvil haciendo click aquí!</h4></a>
                      <div className="row">
                        <div className="col-md-12">
                          <div className='rounded drop-shadow-sm'>
                            <Splide hasTrack={false} options={{
                              rewind: true,
                              autoplay: true,
                              interval: 5000,
                              resetProgress: true,
                              gap: '0rem',
                              width: '1080px',
                              autoHeight: true,
                            }}>
                              <SplideTrack>
                                <SplideSlide className=''>
                                  <img src={tip_titulo_tablet} alt="Titulo" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_1_tablet} alt="Tip 1" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_2_tablet} alt="Tip 2" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_3_tablet} alt="Tip 3" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_4_tablet} alt="Tip 4" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_5_tablet} alt="Tip 5" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_6_tablet} alt="Tip 6" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_7_tablet} alt="Tip 7" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_8_tablet} alt="Tip 8" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_9_tablet} alt="Tip 9" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_10_tablet} alt="Tip 10" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_11_tablet} alt="Tip 11" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_12_tablet} alt="Tip 12" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_13_tablet} alt="Tip 13" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_final_tablet} alt="Tip Final" className='rounded' />
                                </SplideSlide>
                              </SplideTrack>
                              <div className="splide__arrows ">
                                <button className="splide__arrow splide__arrow--prev"><i className='fs-3 mb-1'><BsArrowRight /></i></button>
                                <button className="splide__arrow splide__arrow--next me-2"><i className='fs-3 mb-1'><BsArrowRight /></i></button>
                              </div>
                            </Splide>
                          </div>
                        </div>
                      </div>
                    </center>
                  </div>

                  {/* Screen between 1100px and 1629px */}
                  <div className="d-md-lg-view">
                    <div className="row">
                      <div className="col-md-12 mt-4 text-center">
                        <h1 className='text-belmeny fw-bold title-belmeny'>Bienvenidos</h1>
                        <h2 className='text-belmeny fw-normal subtitle-belmeny mt-2'>Belmeny Group</h2>
                        <img src={codigoQR} alt="Codigo QR" className='w-50' />
                      </div>
                    </div>
                    <center>
                      <a href={apkLink} className='text-light text-decoration-none' target='_blank'><h4 className='bg-belmeny download-apk text-light px-5 rounded-pill text-center w-75 ms-5 mb-5'>¡Descarga nuestra aplicación móvil haciendo click aquí!</h4></a>
                      <div className="row">
                        <div className="col-md-12">
                          <div className='rounded drop-shadow-sm m-auto'>
                            <Splide hasTrack={false} options={{
                              rewind: true,
                              autoplay: true,
                              interval: 5000,
                              resetProgress: true,
                              gap: '0rem',
                              width: '1080px',
                              autoHeight: true,
                            }}>
                              <SplideTrack>
                                <SplideSlide className=''>
                                  <img src={tip_titulo} alt="Titulo" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_1} alt="Tip 1" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_2} alt="Tip 2" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_3} alt="Tip 3" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_4} alt="Tip 4" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_5} alt="Tip 5" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_6} alt="Tip 6" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_7} alt="Tip 7" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_8} alt="Tip 8" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_9} alt="Tip 9" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_10} alt="Tip 10" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_11} alt="Tip 11" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_12} alt="Tip 12" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_13} alt="Tip 13" className='rounded' />
                                </SplideSlide>
                                <SplideSlide className=''>
                                  <img src={tip_final} alt="Tip Final" className='rounded' />
                                </SplideSlide>
                              </SplideTrack>
                              <div className="splide__arrows ">
                                <button className="splide__arrow splide__arrow--prev"><i className='fs-3 mb-1'><BsArrowRight /></i></button>
                                <button className="splide__arrow splide__arrow--next me-2"><i className='fs-3 mb-1'><BsArrowRight /></i></button>
                              </div>
                            </Splide>
                          </div>
                        </div>
                      </div>
                    </center>
                  </div>

                  {/* Screen 1630px and up */}
                  <div className="d-slider">
                    <div className="row m-auto">
                      <div className="col mt-4">
                        <h1 className='text-belmeny fw-bold title-belmeny'>Bienvenidos</h1>
                        <h2 className='text-belmeny fw-normal subtitle-belmeny mt-2'>Belmeny Group</h2>
                        <img src={codigoQR} alt="Codigo QR" className='w-75' />
                        <br />
                      </div>
                      <div className="col mt-4">
                        <div className='slider rounded drop-shadow-sm'>
                          <Splide hasTrack={false} options={{
                            rewind: true,
                            autoplay: true,
                            interval: 5000,
                            resetProgress: true,
                            gap: '0rem',
                            width: '1080px',
                            autoHeight: true,
                          }}>
                            <SplideTrack>
                              <SplideSlide className=''>
                                <img src={tip_titulo} alt="Titulo" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_1} alt="Tip 1" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_2} alt="Tip 2" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_3} alt="Tip 3" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_4} alt="Tip 4" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_5} alt="Tip 5" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_6} alt="Tip 6" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_7} alt="Tip 7" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_8} alt="Tip 8" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_9} alt="Tip 9" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_10} alt="Tip 10" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_11} alt="Tip 11" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_12} alt="Tip 12" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_13} alt="Tip 13" className='rounded' />
                              </SplideSlide>
                              <SplideSlide className=''>
                                <img src={tip_final} alt="Tip Final" className='rounded' />
                              </SplideSlide>
                            </SplideTrack>
                            <div className="splide__arrows ">
                              <button className="splide__arrow splide__arrow--prev"><i className='fs-3 mb-1'><BsArrowRight /></i></button>
                              <button className="splide__arrow splide__arrow--next me-2"><i className='fs-3 mb-1'><BsArrowRight /></i></button>
                            </div>
                          </Splide>
                        </div>
                      </div>
                    </div>
                    <a href={apkLink} className='text-light text-decoration-none bg-belmeny rounded-pill download-apk w-25 p-3 text-center fs-5 ms-1 fw-bold' target='_blank' rel="noreferrer">
                      ¡Descarga nuestra aplicación móvil haciendo click aquí!
                    </a>
                  </div>

                  <div className="row my-5 m-auto text-center">
                    <div className="col">
                      <div className="noticeHeader rounded-pill">
                        <h4 className='text-light'>Expo Ferretera Lara</h4>
                      </div>
                      <div className="row">
                        <div className="col">
                          <img src={standExpoLara} alt="" className='w-75 drop-shadow rounded my-3' />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="noticeHeader rounded-pill">
                        <h4 className='text-light'>Expo Portuguesa Productiva</h4>
                      </div>
                      <div className="row">
                        <div className="col">
                          <img src={standExpoPortuguesa} alt="" className='w-75 drop-shadow rounded my-3' height="210px" />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="noticeHeader rounded-pill">
                        <h4 className='text-light'>Expo Portuguesa INGCO</h4>
                      </div>
                      <div className="row">
                        <div className="col">
                          <img src={standExpoPortuguesaIngco} alt="" className='w-75 drop-shadow rounded my-3' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <Footer></Footer>
          </>
      }

    </>
  )
}
