import axios from "axios";
import { baseURL } from "./ApiURL"


/*
==================================================================================
FACTURAS
==================================================================================
*/

export async function Facturas(Vendedor, Gerente) {
  var res;
  try {
    if (Vendedor === 'none') {
      res = await axios.get(baseURL + `/getFacturasxGerente?CodSupervisor=${Gerente}`);
    } else {
      res = await axios.get(baseURL + `/getFacturas?CodigoVendedor[eq]=${Vendedor}`);
    }
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getProductosFactura(Documento, CodUsuario) {
  try {
    const res = await axios.get(baseURL + `/getProductosFactura?Documento[eq]=${Documento}&CodigoVendedor[eq]=${CodUsuario}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getCantidadProductos(Documento) {
  try {
    const res = await axios.get(baseURL + `/getCantidadProductos?Documento=${Documento}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function SearchFacturas(CodUsuario, Filtro, Busqueda, fechaInicio, fechaFin) {
  try {
    let res;
    if (Filtro === 'Fecha') {
      res = await axios.get(baseURL + `/getFacturasPorBusqueda?CodUsuario=${CodUsuario}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&whatToSearch=${Filtro}`);
    } else {
      res = await axios.get(baseURL + `/getFacturasPorBusqueda?CodUsuario=${CodUsuario}&Busqueda=${Busqueda}&whatToSearch=${Filtro}`)
    }
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getProductosFacturaPDF(Documento,CodigoCliente) {
  try {
    const res = await axios.get(baseURL + `/getProductosFacturaPDF?Documento=${Documento}&CodigoCliente=${CodigoCliente}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

/*
==================================================================================
PETICION PARA OBTENER EL MONTO GLOBAL DE TODAS LAS FACTURAS HECHAS DESDE QUE SE EMPEZARON A REGISTRAR
==================================================================================
*/

export async function getMontoFacturas(Vendedor, fechaInicio, fechaFin) {
  try {
    const res = await axios.get(baseURL + `/MontoTotalFacturas?CodigoVendedor=${Vendedor}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}