import React, { useState } from 'react'

import AuthUser from '../../components/AuthUser';
import { getTopProductos2XLS } from '../../api/requestProductos';
import { bannerINGCO, bannerVERT } from '../../assets/img';
import { Toast, ToastContainer } from 'react-bootstrap';

const ExcelJS = require("exceljs")

export const TopProductosINGCOXLS = ({rif,brand, cliente, listaPrecio}) => {

  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;
  const [data, setData] = useState([]);
  const [enableBtn, setEnableBtn] = useState(true)
  const [showToast, setShowToast] = useState(true)
  const toDataURL = (url) => {
    const promise = new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve({ base64Url: reader.result });
        };
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });

    return promise;
  };

  const exportExcelFile = async () => {
    setEnableBtn(false)
    
    const resProductos = await getTopProductos2XLS(brand, ZonasVenta, rif)
    setData(resProductos)
    
    var response='';
    var marca='';
    var color='';
    
    if (brand.includes("INGCO")) {
      response = await fetch(bannerINGCO);
      marca="INGCO"
      color='FBA10B'
    }
    else{
      response = await fetch(bannerVERT);
      marca="VERT"
      color='0041FF'
    }

    const workbook = new ExcelJS.Workbook()
    const sheet = workbook.addWorksheet(`Lista de Top Productos ${marca}`,{
      views: [{ state: "frozen", ySplit: 1 }],
      
    })
    
    const encabezadoRow = sheet.getRow(1)
    
    encabezadoRow.height = 25
    encabezadoRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color }
    }
    encabezadoRow.font = {
      name: "Arial",
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFF' },
    }

    sheet.getCell('A2').value="Cliente"
    sheet.getCell('A3').value="Rif"
    sheet.getCell('A4').value="Vendedor"

    sheet.getCell('B2').value=cliente
    sheet.getCell('B3').value=rif
    sheet.getCell('B4').value=user.Nombre+" "+user.CodVendedor
   
    
    const buffer = await response.arrayBuffer();
    const imageId1 = workbook.addImage({
      buffer: buffer,
      extension: 'png',
    });
    sheet.addImage(imageId1, {
      tl: { col: 0, row: 3.9 },
      ext: { width: 1480, height: 130 },
    });
    sheet.getRow(2).height = 25
    sheet.getRow(3).height = 25
    sheet.getRow(4).height = 25
    sheet.getRow(5).height = 82
    sheet.columns = [
      { header: "Codigo", key: "codigo", width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center',wrapText: true } } },
      { header: "Nombre", key: "nombre", width: 40, style: { alignment: { vertical: 'middle',wrapText: true } } },
      { header: "Precio", key: "precio", width: 10, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Existencia", key: "existencia", width: 15, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Pedido Sugerido", key: "ventaMinima", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Imagen", key: "imageId2", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' } } },
      { header: "Ultima Compra", key: "ultima", width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center',wrapText: true } } },
    ]
    sheet.getRow(5).addPageBreak()
    const promise = Promise.all(
        resProductos.map(async (product, index) => {
        const rowNumber = index + 5
        
        var fecha=''
        var cantidad=''
        var codigo=''

        if(product.Empresa === 'CCS' && ZonasVenta === '001,003,004,CCS'){
          codigo=product.Codigo+'\n - \nCARACAS CCS'
        }
        else if(ZonasVenta === '001,003,004,CCS'){
          codigo=product.Codigo+'\n - \nMARACAIBO'
        }
        else{
          codigo=product.Codigo
        }

        if(product.FechaHora!=null){
          fecha=new Date(product.FechaHora).toLocaleString().split(',')[0]
          if(product.UnidEmpaque !== ''){
            cantidad= product.Cantidad +" "+product.UnidEmpaque
          }
          else{
            cantidad= product.Cantidad+' UD'
          }
        }
        sheet.addRow({
          rutaImagen: product.RutaImagen,
          codigo: codigo,
          nombre: product.Nombre,
          precio: product[`Precio${listaPrecio}`],
          existencia: product.Existencia,
          ventaMinima: product.VentaMinima,
          ultima:fecha+"\n"+cantidad,

        })
        const result = await toDataURL(product.RutaImagen)
        const splitted = product.RutaImagen.split(".")
        const extName = splitted[splitted.length - 1]

        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: extName,
        })

        sheet.addImage(imageId2, {
          tl: { col: 5, row: rowNumber },
          ext: { width: 120, height: 120 },
        })
        sheet.getRow(rowNumber + 1).height = 95
      })
    )

    promise.then(() => {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement("a")
        anchor.href = url
        anchor.download = `Lista de Top Productos ${marca}.xlsx`
        anchor.click()
        window.URL.revokeObjectURL(url)
        setEnableBtn(true)
      })
    })
  }

  return (
    <>
      <div className='d-md-block d-none ' >
        {
          (enableBtn) ?
            
            <button onClick={exportExcelFile} className='btn btn-primary btn-sm mb-2 w-100'><strong>Generar Lista de Top Productos XLS</strong></button>

            :
            <div>
            <ToastContainer position="top-end" className="p-3 mt-5">
              <Toast onClose={() => setShowToast(false)} show={showToast}>
                <Toast.Header>
                  <strong className="me-auto text-danger">⚠️ AVISO ⚠️</strong>
                  <small>Hace un momento</small>
                </Toast.Header>
                <Toast.Body>La lista de productos en EXCEL tarda en generarse de 1 a 3 minutos, por favor esperar hasta que se genere...</Toast.Body>
              </Toast>
            </ToastContainer>
            <button className='btn btn-primary btn-sm mb-2 w-100 disabled' disabled><strong>Generar Lista de Top Productos XLS</strong></button>
            </div>
        }
        
      </div>
    </>
  )
}
