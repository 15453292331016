import React, { useState, useEffect } from 'react'
import axios from 'axios'

export const FormularioImagen = () => {

    // const [imageData, setImageData] = useState('')
    // const [lotsImg, setLotsImg] = useState([])

    // const handleChange = (files) => {
    //     setLotsImg(files)
    // }

    // const submitData = (e) => {
    //     e.preventDefault();
    //     const fData = new FormData();

    //     console.log(lotsImg)
    //     for (let i = 0; i < lotsImg.length; i++) {
    //         fData.append('image', lotsImg[i]);

    //         axios.post('http://127.0.0.1:8000/api/postImg', fData).then((res) => {
    //             console.log("response", res)
    //         }).catch((error) => {
    //             console.log(error)
    //         })
    //     }
    // }

    // const submitData = (e) => {
    //     e.preventDefault();
    //     const fData = new FormData();

    //     fData.append('image', imageData);

    //     axios.post('http://127.0.0.1:8000/api/postImg', fData).then((res) => {
    //         console.log("response", res)
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }

    return (
        <>
            {/* <form onSubmit={submitData}>
                <label htmlFor="image">Imagen</label>
                <input type="file" name="image" id="image" onChange={e => handleChange(e.target.files)} multiple />
                <button type="submit">Subir imagen</button>
            </form> */}
        </>
    )
}
