import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import AuthUser from '../../../components/AuthUser'
import Layout from '../../../components/Layout';
import { Searchbar } from '../../../components/Searchbar';

import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment/moment';
import Pagination from 'react-responsive-pagination';
import { Modal, Form, Toast, ToastContainer,Image } from 'react-bootstrap'
import { BsCartFill } from "react-icons/bs";
import { RiCloseCircleFill } from "react-icons/ri";
import { DateRangePicker, Stack, Dropdown, ButtonToolbar, Button, IconButton, Popover, Whisper } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import brokenImgGeneral from '../../../assets/img/broken-image-general.png'
import brokenImg from '../../../assets/img/product-img-not-found.png'
import onlyAvailableOnDesktop from '../../../assets/img/404-only-available-on-desktop.webp'

import { getClienteRIF, getClientesVendedor } from '../../../api/requestClientes';
import { getSugeridos, getSugeridosByFecha, getProductoByCodigo, getProductoByNombre, getTopProductos, getProductosPromocion, getGeneralProductos, getTopProductos2 } from '../../../api/requestProductos';
import { getPresupuestoEncabezado, getProductosPresupuestos, postPresupuestoEncabezadoModificado, deletePresupuestoEncabezadoDetalle, updatePrices, copyPresupuestosToTemp } from '../../../api/requestPresupuestos';
import { RequestPage } from '../../../api/request';

import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

export const ModificarPresupuesto = () => {

  const predefinedRanges = [
    {
      label: 'Últimos 3 meses',
      value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Últimos 6 meses',
      value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Último año',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'bottom'
    }
  ];

  const { afterToday } = DateRangePicker;

  const { documento } = useParams();

  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;

  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // Sweet alert
  const MySwal = withReactContent(Swal)
  const customSwal = MySwal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ms-2',
      denyButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  // Datos
  const [moduleTitle, setModuleTitle] = useState('');
  const [subModuleTitle, setSubModuleTitle] = useState('');
  const [catalogo, setCatalogo] = useState('');
  const [marca, setMarca] = useState('');
  const [brand, setBrand] = useState('');
  const [encabezado, setEncabezado] = useState([]);
  const [detalle, setDetalle] = useState([]);
  const [cart, setCart] = useState([])
  const [quantity, setQuantity] = useState({});
  const [cliente, setCliente] = useState('');
  const [rif, setRif] = useState('');
  const [porcentaje_vert, setPorcentaje_vert] = useState('');
  const [porcentaje_ingco, setPorcentaje_ingco] = useState('');
  const [applyDiscount, setApplyDiscount] = useState('CREDITO')
  const [unidades, setUnidades] = useState(0)
  const [subtotal, setSubtotal] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [totalFinal, setTotalFinal] = useState(0)
  const [montoPromo, setMontoPromo] = useState(0)
  const [promoDays, setPromoDays] = useState(0)
  const [promo, setPromo] = useState(0)
  const [promoDiscount, setPromoDiscount] = useState(0)
  const [listaPrecio, setListaPrecio] = useState(0)

  const [tipoPromocion, setTipoPromocion] = useState('')
  const [topProductos, setTopProductos] = useState([])
  const [sugeridos, setSugeridos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [producto, setProducto] = useState([])
  const [productosPromo, setProductosPromo] = useState([])
  const [productosGeneral, setProductosGeneral] = useState([])

  const today = moment().format("YYYY-M-D HH:mm:ss");
  const [start2Months, setStart2Months] = useState(new Date(startOfMonth(addMonths(new Date(), -2))).toISOString().slice(0, 10))

  // States de busqueda
  const [searching, setSearching] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [found, setFound] = useState(false);
  const [term, setTerm] = useState('')

  // States de filtros
  const [fechaStart, setFechaStart] = useState('')
  const [fechaEnd, setFechaEnd] = useState('')
  const [orden, setOrden] = useState('')
  const [searchbarCodigo, setSearchbarCodigo] = useState('d-none')
  const [searchbarNombre, setSearchbarNombre] = useState('d-none')
  const [searchDate, setSearchDate] = useState('d-none')
  const [foundbyFecha, setFoundbyFecha] = useState(false)

  // Paginación
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const [pageSugeridos, setPageSugeridos] = useState('');
  const [totalPagesSugeridos, setTotalPagesSugeridos] = useState('');
  const [urlPageSugeridos, setUrlPageSugeridos] = useState('');
  const [currentPageSugeridos, setCurrentPageSugeridos] = useState(0);

  const productsArray = [
    producto,
    topProductos,
    sugeridos,
    productosPromo,
    productosGeneral,
  ]

  useEffect(() => {
    loadDetallePresupuesto();
  }, []);

  const defaultBrokenImg = (e) => {
    e.target.src = brokenImg
  }

  const defaultBrokenImgMobile = (e) => {
    e.target.src = brokenImgGeneral
  }

  // Manejador de modals
  function handleShow(opt, show) {
    if (cliente !== '') {
      switch (opt) {
        case 'Cart':
          setFullscreen(true);
          setShow(true);

          var calcDescuento, calcTotalFinal, calcDescuentoPromo, promoPercentage = 0;

          if (applyDiscount === 'PREPAGO') {
            if (promo > 0) {
              if (subtotal >= 0 && subtotal <= 1999.99) {
                promoPercentage = 0.05 + 0.02
              } else if (subtotal >= 2000 && subtotal <= 11999.99) {
                promoPercentage = 0.1 + 0.02
              } else if (subtotal >= 12000 && subtotal <= 19999.99) {
                promoPercentage = 0.12 + 0.02
              }

              calcDescuentoPromo = subtotal * promoPercentage
              calcTotalFinal = subtotal - calcDescuentoPromo
              setPromoDiscount(calcDescuentoPromo)
              setDiscount(calcDescuentoPromo)
              setMontoPromo(calcTotalFinal)
              setTotalFinal(calcTotalFinal)
            }
            else {
              calcDescuento = subtotal * 0.10
              calcTotalFinal = subtotal - calcDescuento
              setDiscount(calcDescuento)
              setTotalFinal(calcTotalFinal)
            }
          }
          else {
            if (promo > 0 && promo !== 0.01) {
              calcDescuentoPromo = subtotal * promo
              setMontoPromo((subtotal - calcDescuentoPromo))
              setPromoDiscount(calcDescuentoPromo)
              setDiscount(0)
              setTotalFinal(subtotal)
            }
            else {
              setDiscount(0)
              setTotalFinal(subtotal)
            }
          }
          break;
        default:
          break;
      }
    }
    else {
      customSwal.fire({
        title: '¡Debe escoger un cliente para visualizar estas opciones!',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: 'warning'
      })
    }
  }

  // Manejadores
  const handleApplyDiscount = (e) => {
    if (e.target.checked) {
      setApplyDiscount('PREPAGO')
    } else {
      setApplyDiscount('CREDITO')
    }
  }

  const handlePageChange = async (page, opt) => {
    switch (opt) {
      case 'searchPagination':
        setCurrentPage(page)

        if (searchbarCodigo === 'd-block') {
          loadPage(urlPage.replace(/.$/, page + "&Codigo=" + term + "&rif=" + rif))
        } else if (searchbarNombre === 'd-block') {
          loadPage(urlPage.replace(/.$/, page + "&Nombre=" + term+ "&rif=" + rif))
        }
        break;

      case 'sugeridosPagination':
        setCurrentPageSugeridos(page)

        if (foundbyFecha) {
          loadPageSugeridos(urlPageSugeridos.replace(/.$/, page + "&RifCliente=" + rif + "&fechaInicio=" + fechaStart + "&fechaFin=" + fechaEnd))
        } else {
          loadPageSugeridos(urlPageSugeridos.replace(/.$/, page + "&RifCliente=" + rif + "&marca=" + marca))
        }
        break;

      case 'topPagination':
        if (brand.includes("top")) {
          loadPageTopProductos(urlPageSugeridos.replace(/.$/, page + "&marca=" + brand + "&fechaInicio=" + start2Months + "&fechaFin=" + today + "&rif="+rif))
        }
        else {
          loadPageTopProductos(urlPageSugeridos.replace(/.$/, page + "&marca=" + brand + "&rif="+rif+"&ZonasVenta="+ZonasVenta))
        }
        break;

      case 'promoPagination':
        loadPageProductosPromo(urlPageSugeridos.replace(/.$/, page))
        break;

      case 'generalPagination':
        loadPageProductosGeneral(urlPageSugeridos.replace(/.$/, page + "&catalogo=" + catalogo+ "&rif=" + rif))
        break;

      default:
        break;
    }
  }

  const handleCatalogoDigital = async (e) => {
    if (listaPrecio !== 0) {
      setModuleTitle('General')
      switch (e) {
        case 'general':
          setCatalogo('general')
          setSubModuleTitle('General')
          loadGeneralProductos('general')
          break;

        //ILUMINACIÓN
        case 'bombillos':
          setCatalogo('bombillos')
          setSubModuleTitle('Bombillos')
          loadGeneralProductos('bombillos')
          break;

        case 'cintasLED':
          setCatalogo('cintasLED')
          setSubModuleTitle('Cintas LED y Accesorios')
          loadGeneralProductos('cintasLED')
          break;

        case 'lamparas':
          setCatalogo('lamparas')
          setSubModuleTitle('Lámparas y Bombillos Vintage')
          loadGeneralProductos('lamparas')
          break;

        case 'paneles':
          setCatalogo('paneles')
          setSubModuleTitle('Paneles LED')
          loadGeneralProductos('paneles')
          break;

        case 'tubos':
          setCatalogo('tubos')
          setSubModuleTitle('Tubos LED y Accesorios')
          loadGeneralProductos('tubos')
          break;

        case 'alumbrados':
          setCatalogo('alumbrados')
          setSubModuleTitle('Alumbrados')
          loadGeneralProductos('alumbrados')
          break;

        case 'reflectores':
          setCatalogo('reflectores')
          setSubModuleTitle('Reflectores')
          loadGeneralProductos('reflectores')
          break;

        case 'linternas':
          setCatalogo('linternas')
          setSubModuleTitle('Linternas')
          loadGeneralProductos('linternas')
          break;

        //HOGAR
        case 'hogar':
          setCatalogo('hogar')
          setSubModuleTitle('Hogar')
          loadGeneralProductos('hogar')
          break;

        case 'banos':
          setCatalogo('banos')
          setSubModuleTitle('Baños')
          loadGeneralProductos('banos')
          break;

        case 'jardineria':
          setCatalogo('jardineria')
          setSubModuleTitle('Jardinería')
          loadGeneralProductos('jardineria')
          break;

        case 'plomeria':
          setCatalogo('plomeria')
          setSubModuleTitle('Plomería')
          loadGeneralProductos('plomeria')
          break;

        case 'cerraduras':
          setCatalogo('cerraduras')
          setSubModuleTitle('Cerraduras')
          loadGeneralProductos('cerraduras')
          break;

        //FERRETERÍA EN GENERAL
        case 'ferreteriagral':
          setCatalogo('ferreteriagral')
          setSubModuleTitle('Ferretería General')
          loadGeneralProductos('ferreteriagral')
          break;

        //ELECTRICIDAD
        case 'electricidad':
          setCatalogo('electricidad')
          setSubModuleTitle('Electricidad')
          loadGeneralProductos('electricidad')
          break;

        //AUTOMOTRIZ
        case 'automotriz':
          setCatalogo('automotriz')
          setSubModuleTitle('Automotriz')
          loadGeneralProductos('automotriz')
          break;

        //CONSTRUCCIÓN
        case 'construccion':
          setCatalogo('construccion')
          setSubModuleTitle('Construcción')
          loadGeneralProductos('construccion')
          break;

        //MISCELANEOS
        case 'miscelaneo':
          setCatalogo('miscelaneos')
          setSubModuleTitle('Misceláneos')
          loadGeneralProductos('miscelaneos')
          break;

        //IMOU
        case 'imou':
          setCatalogo('imou')
          setSubModuleTitle('IMOU')
          loadGeneralProductos('imou')
          break;

        //FLEXIMATIC
        case 'fleximatic':
          setCatalogo('fleximatic')
          setSubModuleTitle('FLEXIMATIC')
          loadGeneralProductos('fleximatic')
          break;

        //QUILOSA
        case 'quilosa':
          setCatalogo('quilosa')
          setSubModuleTitle('QUILOSA')
          loadGeneralProductos('quilosa')
          break;

        // INGCO
        //HERRAMIENTAS ELECTRICAS
        case 'electricas':
          setCatalogo('electricas')
          setSubModuleTitle('Herramientas Eléctricas')
          loadGeneralProductos('electricas')
          break;

        //BOMBAS DE AGUA
        case 'bombas-agua':
          setCatalogo('bombas')
          setSubModuleTitle('Bombas de Agua')
          loadGeneralProductos('bombas')
          break;

        //MECHAS
        case 'mechas':
          setCatalogo('mechas')
          setSubModuleTitle('Mechas')
          loadGeneralProductos('mechas')
          break;

        //CONSUMIBLES
        case 'consumibles':
          setCatalogo('consumibles')
          setSubModuleTitle('Consumibles')
          loadGeneralProductos('consumibles')
          break;

        //HERRAMIENTAS AISLADAS
        case 'aisladas':
          setCatalogo('aisladas')
          setSubModuleTitle('Herramientas Aisladas')
          loadGeneralProductos('aisladas')
          break;

        //HERRAMIENTAS MANUALES
        case 'manuales':
          setCatalogo('manuales')
          setSubModuleTitle('Herramientas Manuales')
          loadGeneralProductos('manuales')
          break;

        //HERRAMIENTAS DE MEDICIÓN
        case 'medicion':
          setCatalogo('medicion')
          setSubModuleTitle('Herramientas de Medición')
          loadGeneralProductos('medicion')
          break;

        //HERRAMIENTAS NEUMATICAS
        case 'neumaticas':
          setCatalogo('neumaticas')
          setSubModuleTitle('Herramientas Neumáticas')
          loadGeneralProductos('neumaticas')
          break;

        //GATOS HIDRAULICOS
        case 'hidraulicos':
          setCatalogo('hidraulicos')
          setSubModuleTitle('Gatos Hidráulicos')
          loadGeneralProductos('hidraulicos')
          break;

        //ACCESORIOS
        case 'accesorios':
          setCatalogo('accesorios')
          setSubModuleTitle('Accesorios')
          loadGeneralProductos('accesorios')
          break;

        //JARDINERIA
        case 'jardineria-ingco':
          setCatalogo('jardineria-ingco')
          setSubModuleTitle('Jardinería')
          loadGeneralProductos('jardineria-ingco')
          break;

        //ACCESORIOS PARA PINTAR
        case 'pintura':
          setCatalogo('pintura')
          setSubModuleTitle('Accesorios para Pintar')
          loadGeneralProductos('pintura')
          break;

        //BOLSO DE HERRAMIENTAS
        case 'bolsos':
          setCatalogo('bolsos')
          setSubModuleTitle('Bolso de Herramientas')
          loadGeneralProductos('bolsos')
          break;

        //LINTERNAS INGCO
        case 'linternas-ingco':
          setCatalogo('linternas-ingco')
          setSubModuleTitle('Linternas')
          loadGeneralProductos('linternas-ingco')
          break;

        //SEGURIDAD INDUSTRIAL
        case 'seguridad':
          setCatalogo('seguridad')
          setSubModuleTitle('Equipo de Seguridad Industrial')
          loadGeneralProductos('seguridad')
          break;

        //BOTAS DE SEGURIDAD
        case 'botas':
          setCatalogo('botas')
          setSubModuleTitle('Botas de Seguridad')
          loadGeneralProductos('botas')
          break;

        //CANDADOS
        case 'candados':
          setCatalogo('candados')
          setSubModuleTitle('Candados')
          loadGeneralProductos('candados')
          break;

        //PRODUCTOS VENDIDOS
        case 'vendidosVERT':
          setModuleTitle('Vendidos')
          setCatalogo('vendidosVERT')
          setSubModuleTitle('Vendidos anteriormente en VËRT')
          loadSugeridos('VERT')
          break;

        case 'vendidosINGCO':
          setModuleTitle('Vendidos')
          setCatalogo('vendidosINGCO')
          setSubModuleTitle('Vendidos anteriormente en INGCO')
          loadSugeridos('INGCO')
          break;

          //top 100 productos vert e ingco
        case 'top100VERTgeneral':
          setModuleTitle('Vert')
          loadTopProductos2('VERTgeneral')
          break;

        case 'top100VERTcomprado':
        setModuleTitle('Vert')
        loadTopProductos2('VERTcomprado')
        break;

        case 'top100VERTnocomprado':
        setModuleTitle('Vert')
        loadTopProductos2('VERTnocomprado')
        break;

        case 'top100INGCOgeneral':
          setModuleTitle('Ingco')
          loadTopProductos2('INGCOgeneral')
          break;
          
        case 'top100INGCOcomprado':
          setModuleTitle('Ingco')
          loadTopProductos2('INGCOcomprado')
          break;

        case 'top100INGCOnocomprado':
          setModuleTitle('Ingco')
          loadTopProductos2('INGCOnocomprado')
          break;

        //Top Productos
        case 'topGeneralVert':
          setModuleTitle('Vert')
          loadTopProductos('VERT')
          break;

        case 'topGeneralIngco':
          setModuleTitle('Ingco')
          loadTopProductos('INGCO')
          break;

        case 'topBombillos':
          setModuleTitle('Vert')
          loadTopProductos('topBombillos')
          break;

        case 'topCintasLED':
          setModuleTitle('Vert')
          loadTopProductos('topCintasLED')
          break;

        case 'topLamparas':
          setModuleTitle('Vert')
          loadTopProductos('topLamparas')
          break;

        case 'topPaneles':
          setModuleTitle('Vert')
          loadTopProductos('topPaneles')
          break;

        case 'topTubos':
          setModuleTitle('Vert')
          loadTopProductos('topTubos')
          break;

        case 'topAlumbrados':
          setModuleTitle('Vert')
          loadTopProductos('topAlumbrados')
          break;

        case 'topReflectores':
          setModuleTitle('Vert')
          loadTopProductos('topReflectores')
          break;

        case 'topLinternas':
          setModuleTitle('Vert')
          loadTopProductos('topLinternas')
          break;

        case 'topHogar':
          setModuleTitle('Vert')
          loadTopProductos('topHogar')
          break;

        case 'topBanos':
          setModuleTitle('Vert')
          loadTopProductos('topBanos')
          break;

        case 'topJardineria':
          setModuleTitle('Vert')
          loadTopProductos('topJardineria')
          break;

        case 'topPlomeria':
          setModuleTitle('Vert')
          loadTopProductos('topPlomeria')
          break;

        case 'topCerraduras':
          setModuleTitle('Vert')
          loadTopProductos('topCerraduras')
          break;

        case 'topFerreteriagral':
          setModuleTitle('Vert')
          loadTopProductos('topFerreteriagral')
          break;

        case 'topElectricidad':
          setModuleTitle('Vert')
          loadTopProductos('topElectricidad')
          break;

        case 'topAutomotriz':
          setModuleTitle('Vert')
          loadTopProductos('topAutomotriz')
          break;

        case 'topConstruccion':
          setModuleTitle('Vert')
          loadTopProductos('topConstruccion')
          break;

        case 'topMiscelaneo':
          setModuleTitle('Vert')
          loadTopProductos('topMiscelaneo')
          break;

        case 'topFleximatic':
          setModuleTitle('Vert')
          loadTopProductos('topFleximatic')
          break;

        case 'topImou':
          setModuleTitle('Vert')
          loadTopProductos('topImou')
          break;

        case 'topQuilosa':
          setModuleTitle('Vert')
          loadTopProductos('topQuilosa')
          break;

        case 'topElectricas':
          setModuleTitle('Ingco')
          loadTopProductos('topElectricas')
          break;

        case 'topAisladas':
          setModuleTitle('Ingco')
          loadTopProductos('topAisladas')
          break;

        case 'topManuales':
          setModuleTitle('Ingco')
          loadTopProductos('topManuales')
          break;

        case 'topMedicion':
          setModuleTitle('Ingco')
          loadTopProductos('topMedicion')
          break;

        case 'topNeumaticas':
          setModuleTitle('Ingco')
          loadTopProductos('topNeumaticas')
          break;

        case 'topBolsos':
          setModuleTitle('Ingco')
          loadTopProductos('topBolsos')
          break;

        case 'topSeguridad':
          setModuleTitle('Ingco')
          loadTopProductos('topSeguridad')
          break;

        case 'topBotas':
          setModuleTitle('Ingco')
          loadTopProductos('topBotas')
          break;

        case 'topCandados':
          setModuleTitle('Ingco')
          loadTopProductos('topCandados')
          break;

        case 'topBombas-agua':
          setModuleTitle('Ingco')
          loadTopProductos('topBombas-agua')
          break;

        case 'topHidraulicos':
          setModuleTitle('Ingco')
          loadTopProductos('topHidraulicos')
          break;

        case 'topJardineria-ingco':
          setModuleTitle('Ingco')
          loadTopProductos('topJardineria-ingco')
          break;

        case 'topLinternas-ingco':
          setModuleTitle('Ingco')
          loadTopProductos('topLinternas-ingco')
          break;

        case 'topMechas':
          setModuleTitle('Ingco')
          loadTopProductos('topMechas')
          break;

        case 'topConsumibles':
          setModuleTitle('Ingco')
          loadTopProductos('topConsumibles')
          break;

        case 'topAccesorios':
          setModuleTitle('Ingco')
          loadTopProductos('topAccesorios')
          break;

        case 'topPintura':
          setModuleTitle('Ingco')
          loadTopProductos('topPintura')
          break;

        default:
          break;
      }
    } else {
      customSwal.fire({
        title: <strong>Cliente no encontrado</strong>,
        html: <i>Ingrese un cliente para buscar un producto</i>,
        icon: 'error'
      })
    }
  }

  const changeDate = async (e) => {
    let date = JSON.stringify(e)
    let inicio = date.slice(2, 12) + " " + date.slice(14, 21)
    let fin = date.slice(29, 39) + " " + date.slice(41, 48)
    setFechaStart(inicio)
    setFechaEnd(fin)
    setLoading(true)

    const res = await getSugeridosByFecha(rif, inicio, fin, ZonasVenta)

    if (res.data.length !== 0) {
      setSugeridos(res.data)
      setPageSugeridos(res.current_page)
      setTotalPagesSugeridos(res.last_page)
      setUrlPageSugeridos(res.first_page_url)
      setTimeout(() => {
        setFoundbyFecha(true)
        setLoading(false)
      }, 3000);
    }
    else {
      customSwal.fire({
        title: 'Ups, no se ha encontrado ningún resultado...',
        text: 'Intenta búscar en otro rango de fecha.',
        icon: 'error',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false
      })
      setFoundbyFecha(false)
      setLoading(false)
    }
  }

  const showFilter = (e, filtro) => {
    e.preventDefault();
    switch (filtro) {
      case 'Codigo':
        setSearchbarCodigo('d-block')
        setSearchbarNombre('d-none')
        setSearchDate('d-none')
        break;

      case 'Nombre':
        setSearchbarNombre('d-block')
        setSearchbarCodigo('d-none')
        setSearchDate('d-none')
        break;

      case 'Fecha':
        setSearchDate('d-block')
        setSearchbarNombre('d-none')
        setSearchbarCodigo('d-none')
        break;

      case 'LimpiarFiltroSugerido':
        // loadSugeridos('DESC')
        setSearchDate('d-none')
        break;

      case 'Limpiar':
        setSearchbarCodigo('d-none')
        setSearchbarNombre('d-none')
        setSearchDate('d-none')
        setModuleTitle('')
        break;

      default:
        setSearchbarCodigo('d-none')
        setSearchbarNombre('d-none')
        setSearchDate('d-none')
        break;
    }
  }

  const onSearch = async (busqueda) => {
    if (searchbarCodigo === 'd-block') {
      //Busqueda por codigo
      setLoading(true)
      setModuleTitle('Busquedas')
      setSubModuleTitle('Resultados encontrados por: ' + busqueda)
      if (!busqueda) {
        setFound(false)
        setLoading(false)
        setProducto([])
      }
      if (listaPrecio !== 0) {
        setLoading(true)
        setSearching(true)
        setNotFound(false)
        const codeSearch = await getProductoByCodigo(busqueda, ZonasVenta, rif)
        if (!codeSearch) {
          setNotFound(true)
          setLoading(false)
          setFound(false)
          return
        }
        else if (codeSearch.data.data.length === 0) {
          setFound(false)
          customSwal.fire({
            title: <strong>Producto no encontrado...</strong>,
            html: <i>Verifique el código ingresado e intente nuevamente</i>,
            icon: 'question'
          })
          setProducto([])
          setLoading(false)
        }
        else {
          setTerm(busqueda)
          setProducto(codeSearch.data.data)
          setPage(codeSearch.data.current_page)
          setCurrentPage(codeSearch.data.current_page)
          setTotalPages(codeSearch.data.last_page)
          setUrlPage(codeSearch.data.first_page_url)
          setLoading(false)
        }
      }
      else {
        setLoading(false)
        customSwal.fire({
          title: <strong>Cliente no encontrado</strong>,
          html: <i>Ingrese un cliente para buscar un producto</i>,
          icon: 'error'
        })
      }
    }
    else if (searchbarNombre === 'd-block') {
      //Busqueda por nombre
      setLoading(true)
      setModuleTitle('Busquedas')
      setSubModuleTitle('Resultados encontrados por: ' + busqueda)
      if (!busqueda) {
        setFound(false)
        setLoading(false)
        setProducto([])
      }
      if (listaPrecio !== 0) {
        setLoading(true)
        setSearching(true)
        setNotFound(false)
        const nameSearch = await getProductoByNombre(busqueda, ZonasVenta, rif)
        if (!nameSearch) {
          setNotFound(true)
          setLoading(false)
          setFound(false)
          return
        }
        else if (nameSearch.data.data.length === 0) {
          setFound(false)
          customSwal.fire({
            title: <strong>Producto no encontrado...</strong>,
            html: <i>Verifique el código ingresado e intente nuevamente</i>,
            icon: 'question'
          })
          setProducto([])
          setLoading(false)
        }
        else {
          setTerm(busqueda)
          setProducto(nameSearch.data.data)
          setPage(nameSearch.data.current_page)
          setCurrentPage(nameSearch.data.current_page)
          setTotalPages(nameSearch.data.last_page)
          setUrlPage(nameSearch.data.first_page_url)
          setLoading(false)
        }
      }
      else {
        setLoading(false)
        customSwal.fire({
          title: <strong>Cliente no encontrado</strong>,
          html: <i>Ingrese un cliente para buscar un producto</i>,
          icon: 'error'
        })
      }
    }
  };

  const switchTitles = async (opt) => {
    if (cliente !== 'No se ha seleccionado un cliente...') {
      switch (opt) {
        case 'General':
          setModuleTitle('General')
          loadGeneralProductos()
          break;

        case 'Sugeridos':
          setModuleTitle('Sugeridos')
          loadSugeridos()
          break;

        case 'Ingco':
          setModuleTitle('Ingco')
          loadTopProductos('INGCO')
          break;

        case 'Vert':
          setModuleTitle('Vert')
          loadTopProductos('VERT')
          break;

        case 'Promociones':
          setModuleTitle('Promociones')
          loadProductosPromo()
          break;

        default:
          break;
      }
    } else {
      customSwal.fire({
        title: '¡Debe escoger un cliente para visualizar estas opciones!',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
        icon: 'warning'
      })
    }

  }

  const loadDetallePresupuesto = async () => {
    const encabezado = await getPresupuestoEncabezado(user.CodVendedor, documento);
    const detalle = await getProductosPresupuestos(documento);
    const Client = await getClienteRIF(encabezado.data[0].Codcliente)
    setEncabezado(encabezado.data[0]);
    setDetalle(detalle.data)
    setCliente(Client[0].Nombre)
    setRif(encabezado.data[0].Codcliente)
    setListaPrecio(Client[0].ListaPrecios)
    setPorcentaje_ingco(Client[0].porcentaje_ingco)
    setPorcentaje_vert(Client[0].porcentaje_vert)

    var cartArray = []

    for (let i = 0; i < detalle.data.length; i++) {
      var data = {
        Codigo: detalle.data[i].Codigo,
        Nombre: detalle.data[i].Nombre,
        Precio: parseFloat(detalle.data[i].PrecioUnit),
        Unidades: parseInt(detalle.data[i].Cantidad),
        SubTotal: parseFloat(detalle.data[i].Subtotal),
        RutaImagen: detalle.data[i].RutaImagen,
        VentaMinima: parseInt(detalle.data[i].VentaMinima),
        Grupo: detalle.data[i].Grupo,
        Subgrupo: detalle.data[i].Subgrupo,
        Agencia: detalle.data[i].Agencia
      }
      cartArray.push(data)
    }

    setCart(cartArray);

    setLoading(false)

    var countSubtotal = 0
    for (let i = 0; i < detalle.data.length; i++) {
      countSubtotal += detalle.data[i].Subtotal
      setSubtotal(countSubtotal)
    }
  }

  const loadPage = async (pageData) => {
    setLoading(true)

    const productoData = await RequestPage(pageData);

    setProducto(productoData.data)
    setPage(productoData.current_page)
    setCurrentPage(productoData.current_page)
    setTotalPages(productoData.last_page)
    setUrlPage(productoData.first_page_url)

    setLoading(false)
  }

  const loadPageSugeridos = async (pageData) => {
    setLoading(true)

    const productoData = await RequestPage(pageData);

    setSugeridos(productoData.data)
    setPageSugeridos(productoData.current_page)
    setCurrentPageSugeridos(productoData.current_page)
    setTotalPagesSugeridos(productoData.last_page)
    setUrlPageSugeridos(productoData.first_page_url)

    setLoading(false)
  }

  const loadPageTopProductos = async (pageData) => {
    setLoading(true)

    const productoData = await RequestPage(pageData);

    setTopProductos(productoData.data)
    setPageSugeridos(productoData.current_page)
    setCurrentPageSugeridos(productoData.current_page)
    setTotalPagesSugeridos(productoData.last_page)
    setUrlPageSugeridos(productoData.first_page_url)

    setLoading(false)
  }

  const loadPageProductosPromo = async (pageData) => {
    setLoading(true)

    const productoData = await RequestPage(pageData);

    setProductosPromo(productoData.data)
    setPageSugeridos(productoData.current_page)
    setCurrentPageSugeridos(productoData.current_page)
    setTotalPagesSugeridos(productoData.last_page)
    setUrlPageSugeridos(productoData.first_page_url)

    setLoading(false)
  }

  const loadPageProductosGeneral = async (pageData) => {
    setLoading(true)

    const productoData = await RequestPage(pageData);

    setProductosGeneral(productoData.data)
    setPageSugeridos(productoData.current_page)
    setCurrentPageSugeridos(productoData.current_page)
    setTotalPagesSugeridos(productoData.last_page)
    setUrlPageSugeridos(productoData.first_page_url)

    setLoading(false)
  }

  const loadSugeridos = async (marca) => {
    setLoading(true)
    var productos;

    switch (marca) {
      case 'VERT':
        setMarca('VERT')
        productos = await getSugeridos(rif, marca, ZonasVenta)
        break;

      case 'INGCO':
        setMarca('INGCO')
        productos = await getSugeridos(rif, marca, ZonasVenta)
        break;

      default:
        setMarca('VERT')
        productos = await getSugeridos(rif, 'VERT', ZonasVenta)
        break;
    }

    const sugeridosPromise = new Promise((resolve, reject) => {
      if (productos.length !== 0) {
        setSugeridos(productos.data)
        setPageSugeridos(productos.current_page)
        setCurrentPageSugeridos(productos.current_page)
        setTotalPagesSugeridos(productos.last_page)
        setUrlPageSugeridos(productos.first_page_url)
        resolve()
      }
      else {
        reject()
      }
    });

    sugeridosPromise.then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  const loadTopProductos = async (marca) => {
    setLoading(true)
    setBrand(marca)

    switch (marca) {
      case 'INGCO':
        setSubModuleTitle('Top Productos INGCO')
        const topProductosINGCO = await getTopProductos('INGCO', ZonasVenta, rif)

        const productosINGCOPromise = new Promise((resolve, reject) => {
          if (topProductosINGCO.length !== 0) {
            setTopProductos(topProductosINGCO.data)
            setPageSugeridos(topProductosINGCO.current_page)
            setCurrentPageSugeridos(topProductosINGCO.current_page)
            setTotalPagesSugeridos(12)
            setUrlPageSugeridos(topProductosINGCO.first_page_url)
            resolve()
          }
          else {
            reject()
          }
        });

        productosINGCOPromise.then(() => {
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })

        break;

      case 'VERT':
        setSubModuleTitle('Top Productos VERT')
        const topProductosVERT = await getTopProductos('VERT', ZonasVenta, rif)

        const productosVERTPromise = new Promise((resolve, reject) => {
          if (topProductosVERT.length !== 0) {
            setTopProductos(topProductosVERT.data)
            setPageSugeridos(topProductosVERT.current_page)
            setCurrentPageSugeridos(topProductosVERT.current_page)
            setTotalPagesSugeridos(12)
            setUrlPageSugeridos(topProductosVERT.first_page_url)
            resolve()
          }
          else {
            reject()
          }
        });

        productosVERTPromise.then(() => {
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
        break;

      case 'topBombillos':
        setSubModuleTitle('Top Productos en Bombillos')
        const topBombillos = await getTopProductos('topBombillos', ZonasVenta, rif, start2Months, today )

        if (topBombillos.length !== 0) {
          setTopProductos(topBombillos.data)
          setPageSugeridos(topBombillos.current_page)
          setCurrentPageSugeridos(topBombillos.current_page)
          setTotalPagesSugeridos(topBombillos.last_page)
          setUrlPageSugeridos(topBombillos.first_page_url)
          setLoading(false)
        }
        break;

      case 'topCintasLED':
        setSubModuleTitle('Top Productos en Cintas LED')
        const topCintasLED = await getTopProductos('topCintasLED', ZonasVenta,rif,start2Months, today)

        if (topCintasLED.length !== 0) {
          setTopProductos(topCintasLED.data)
          setPageSugeridos(topCintasLED.current_page)
          setCurrentPageSugeridos(topCintasLED.current_page)
          setTotalPagesSugeridos(topCintasLED.last_page)
          setUrlPageSugeridos(topCintasLED.first_page_url)
          setLoading(false)
        }
        break;

      case 'topLamparas':
        setSubModuleTitle('Top Productos en Lamparas')
        const topLamparas = await getTopProductos('topLamparas',ZonasVenta, rif,start2Months, today )

        if (topLamparas.length !== 0) {
          setTopProductos(topLamparas.data)
          setPageSugeridos(topLamparas.current_page)
          setCurrentPageSugeridos(topLamparas.current_page)
          setTotalPagesSugeridos(topLamparas.last_page)
          setUrlPageSugeridos(topLamparas.first_page_url)
          setLoading(false)
        }
        break;

      case 'topPaneles':
        setSubModuleTitle('Top Productos en Paneles LED')
        const topPaneles = await getTopProductos('topPaneles',ZonasVenta, rif,start2Months, today)

        if (topPaneles.length !== 0) {
          setTopProductos(topPaneles.data)
          setPageSugeridos(topPaneles.current_page)
          setCurrentPageSugeridos(topPaneles.current_page)
          setTotalPagesSugeridos(topPaneles.last_page)
          setUrlPageSugeridos(topPaneles.first_page_url)
          setLoading(false)
        }
        break;

      case 'topTubos':
        setSubModuleTitle('Top Productos en Tubos LED')
        const topTubos = await getTopProductos('topTubos', ZonasVenta,rif,start2Months, today)

        if (topTubos.length !== 0) {
          setTopProductos(topTubos.data)
          setPageSugeridos(topTubos.current_page)
          setCurrentPageSugeridos(topTubos.current_page)
          setTotalPagesSugeridos(topTubos.last_page)
          setUrlPageSugeridos(topTubos.first_page_url)
          setLoading(false)
        }
        break;

      case 'topAlumbrados':
        setSubModuleTitle('Top Productos en Alumbrados')
        const topAlumbrados = await getTopProductos('topAlumbrados', ZonasVenta,rif,start2Months, today)

        if (topAlumbrados.length !== 0) {
          setTopProductos(topAlumbrados.data)
          setPageSugeridos(topAlumbrados.current_page)
          setCurrentPageSugeridos(topAlumbrados.current_page)
          setTotalPagesSugeridos(topAlumbrados.last_page)
          setUrlPageSugeridos(topAlumbrados.first_page_url)
          setLoading(false)
        }
        break;

      case 'topReflectores':
        setSubModuleTitle('Top Productos en Reflectores')
        const topReflectores = await getTopProductos('topReflectores', ZonasVenta,rif,start2Months, today)

        if (topReflectores.length !== 0) {
          setTopProductos(topReflectores.data)
          setPageSugeridos(topReflectores.current_page)
          setCurrentPageSugeridos(topReflectores.current_page)
          setTotalPagesSugeridos(topReflectores.last_page)
          setUrlPageSugeridos(topReflectores.first_page_url)
          setLoading(false)
        }
        break;

      case 'topLinternas':
        setSubModuleTitle('Top Productos en Linternas')
        const topLinternas = await getTopProductos('topLinternas',ZonasVenta, rif,start2Months, today)

        if (topLinternas.length !== 0) {
          setTopProductos(topLinternas.data)
          setPageSugeridos(topLinternas.current_page)
          setCurrentPageSugeridos(topLinternas.current_page)
          setTotalPagesSugeridos(topLinternas.last_page)
          setUrlPageSugeridos(topLinternas.first_page_url)
          setLoading(false)
        }
        break;

      case 'topHogar':
        setSubModuleTitle('Top Productos en Hogar')
        const topHogar = await getTopProductos('topHogar', ZonasVenta,rif,start2Months, today)

        if (topHogar.length !== 0) {
          setTopProductos(topHogar.data)
          setPageSugeridos(topHogar.current_page)
          setCurrentPageSugeridos(topHogar.current_page)
          setTotalPagesSugeridos(topHogar.last_page)
          setUrlPageSugeridos(topHogar.first_page_url)
          setLoading(false)
        }
        break;

      case 'topBanos':
        setSubModuleTitle('Top Productos en Baños')
        const topBanos = await getTopProductos('topBanos', ZonasVenta,rif,start2Months, today)

        if (topBanos.length !== 0) {
          setTopProductos(topBanos.data)
          setPageSugeridos(topBanos.current_page)
          setCurrentPageSugeridos(topBanos.current_page)
          setTotalPagesSugeridos(topBanos.last_page)
          setUrlPageSugeridos(topBanos.first_page_url)
          setLoading(false)
        }
        break;

      case 'topJardineria':
        setSubModuleTitle('Top Productos en Jardinería')
        const topJardineria = await getTopProductos('topJardineria',ZonasVenta,rif, start2Months, today)

        if (topJardineria.length !== 0) {
          setTopProductos(topJardineria.data)
          setPageSugeridos(topJardineria.current_page)
          setCurrentPageSugeridos(topJardineria.current_page)
          setTotalPagesSugeridos(topJardineria.last_page)
          setUrlPageSugeridos(topJardineria.first_page_url)
          setLoading(false)
        }
        break;

      case 'topPlomeria':
        setSubModuleTitle('Top Productos en Plomería')
        const topPlomeria = await getTopProductos('topPlomeria', ZonasVenta,rif,start2Months, today)

        if (topPlomeria.length !== 0) {
          setTopProductos(topPlomeria.data)
          setPageSugeridos(topPlomeria.current_page)
          setCurrentPageSugeridos(topPlomeria.current_page)
          setTotalPagesSugeridos(topPlomeria.last_page)
          setUrlPageSugeridos(topPlomeria.first_page_url)
          setLoading(false)
        }
        break;

      case 'topCerraduras':
        setSubModuleTitle('Top Productos en Cerraduras')
        const topCerraduras = await getTopProductos('topCerraduras', ZonasVenta,rif,start2Months, today)

        if (topCerraduras.length !== 0) {
          setTopProductos(topCerraduras.data)
          setPageSugeridos(topCerraduras.current_page)
          setCurrentPageSugeridos(topCerraduras.current_page)
          setTotalPagesSugeridos(topCerraduras.last_page)
          setUrlPageSugeridos(topCerraduras.first_page_url)
          setLoading(false)
        }
        break;

      case 'topFerreteriagral':
        setSubModuleTitle('Top Productos en Ferreteria en General')
        const topFerreteriagral = await getTopProductos('topFerreteriagral', ZonasVenta,rif,start2Months, today)

        if (topFerreteriagral.length !== 0) {
          setTopProductos(topFerreteriagral.data)
          setPageSugeridos(topFerreteriagral.current_page)
          setCurrentPageSugeridos(topFerreteriagral.current_page)
          setTotalPagesSugeridos(topFerreteriagral.last_page)
          setUrlPageSugeridos(topFerreteriagral.first_page_url)
          setLoading(false)
        }
        break;

      case 'topElectricidad':
        setSubModuleTitle('Top Productos en Electricidad')
        const topElectricidad = await getTopProductos('topElectricidad', ZonasVenta,rif,start2Months, today)

        if (topElectricidad.length !== 0) {
          setTopProductos(topElectricidad.data)
          setPageSugeridos(topElectricidad.current_page)
          setCurrentPageSugeridos(topElectricidad.current_page)
          setTotalPagesSugeridos(topElectricidad.last_page)
          setUrlPageSugeridos(topElectricidad.first_page_url)
          setLoading(false)
        }
        break;

      case 'topAutomotriz':
        setSubModuleTitle('Top Productos en Automotriz')
        const topAutomotriz = await getTopProductos('topAutomotriz', ZonasVenta,rif,start2Months, today)

        if (topAutomotriz.length !== 0) {
          setTopProductos(topAutomotriz.data)
          setPageSugeridos(topAutomotriz.current_page)
          setCurrentPageSugeridos(topAutomotriz.current_page)
          setTotalPagesSugeridos(topAutomotriz.last_page)
          setUrlPageSugeridos(topAutomotriz.first_page_url)
          setLoading(false)
        }
        break;

      case 'topConstruccion':
        setSubModuleTitle('Top Productos en Construcción')
        const topConstruccion = await getTopProductos('topConstruccion', ZonasVenta,rif,start2Months, today)

        if (topConstruccion.length !== 0) {
          setTopProductos(topConstruccion.data)
          setPageSugeridos(topConstruccion.current_page)
          setCurrentPageSugeridos(topConstruccion.current_page)
          setTotalPagesSugeridos(topConstruccion.last_page)
          setUrlPageSugeridos(topConstruccion.first_page_url)
          setLoading(false)
        }
        break;

      case 'topMiscelaneo':
        setSubModuleTitle('Top Productos en Misceláneo')
        const topMiscelaneo = await getTopProductos('topMiscelaneo', ZonasVenta,rif,start2Months, today)

        if (topMiscelaneo.length !== 0) {
          setTopProductos(topMiscelaneo.data)
          setPageSugeridos(topMiscelaneo.current_page)
          setCurrentPageSugeridos(topMiscelaneo.current_page)
          setTotalPagesSugeridos(topMiscelaneo.last_page)
          setUrlPageSugeridos(topMiscelaneo.first_page_url)
          setLoading(false)
        }
        break;

      case 'topFleximatic':
        setSubModuleTitle('Top Productos en Fleximatic')
        const topFleximatic = await getTopProductos('topFleximatic', ZonasVenta,rif,start2Months, today)

        if (topFleximatic.length !== 0) {
          setTopProductos(topFleximatic.data)
          setPageSugeridos(topFleximatic.current_page)
          setCurrentPageSugeridos(topFleximatic.current_page)
          setTotalPagesSugeridos(topFleximatic.last_page)
          setUrlPageSugeridos(topFleximatic.first_page_url)
          setLoading(false)
        }
        break;

      case 'topImou':
        setSubModuleTitle('Top Productos en IMOU')
        const topImou = await getTopProductos('topImou', ZonasVenta,rif,start2Months, today)

        if (topImou.length !== 0) {
          setTopProductos(topImou.data)
          setPageSugeridos(topImou.current_page)
          setCurrentPageSugeridos(topImou.current_page)
          setTotalPagesSugeridos(topImou.last_page)
          setUrlPageSugeridos(topImou.first_page_url)
          setLoading(false)
        }
        break;

      case 'topQuilosa':
        setSubModuleTitle('Top Productos en Quilosa')
        const topQuilosa = await getTopProductos('topQuilosa', ZonasVenta,rif,start2Months, today)
        if (topQuilosa.length !== 0) {
          setTopProductos(topQuilosa.data)
          setPageSugeridos(topQuilosa.current_page)
          setCurrentPageSugeridos(topQuilosa.current_page)
          setTotalPagesSugeridos(topQuilosa.last_page)
          setUrlPageSugeridos(topQuilosa.first_page_url)
          setLoading(false)
        }
        break;

      case 'topElectricas':
        setSubModuleTitle('Top Productos en Herramientas Eléctricas')
        const topElectricas = await getTopProductos('topElectricas', ZonasVenta,rif,start2Months, today)

        if (topElectricas.length !== 0) {
          setTopProductos(topElectricas.data)
          setPageSugeridos(topElectricas.current_page)
          setCurrentPageSugeridos(topElectricas.current_page)
          setTotalPagesSugeridos(topElectricas.last_page)
          setUrlPageSugeridos(topElectricas.first_page_url)
          setLoading(false)
        }
        break;

      case 'topAisladas':
        setSubModuleTitle('Top Productos en Herramientas Aisladas')
        const topAisladas = await getTopProductos('topAisladas',ZonasVenta, rif,start2Months, today)

        if (topAisladas.length !== 0) {
          setTopProductos(topAisladas.data)
          setPageSugeridos(topAisladas.current_page)
          setCurrentPageSugeridos(topAisladas.current_page)
          setTotalPagesSugeridos(topAisladas.last_page)
          setUrlPageSugeridos(topAisladas.first_page_url)
          setLoading(false)
        }
        break;

      case 'topManuales':
        setSubModuleTitle('Top Productos en Herramientas Manuales')
        const topManuales = await getTopProductos('topManuales', ZonasVenta,rif,start2Months, today)

        if (topManuales.length !== 0) {
          setTopProductos(topManuales.data)
          setPageSugeridos(topManuales.current_page)
          setCurrentPageSugeridos(topManuales.current_page)
          setTotalPagesSugeridos(topManuales.last_page)
          setUrlPageSugeridos(topManuales.first_page_url)
          setLoading(false)
        }
        break;

      case 'topMedicion':
        setSubModuleTitle('Top Productos en Herramientas de Medición')
        const topMedicion = await getTopProductos('topMedicion', ZonasVenta,rif,start2Months, today)

        if (topMedicion.length !== 0) {
          setTopProductos(topMedicion.data)
          setPageSugeridos(topMedicion.current_page)
          setCurrentPageSugeridos(topMedicion.current_page)
          setTotalPagesSugeridos(topMedicion.last_page)
          setUrlPageSugeridos(topMedicion.first_page_url)
          setLoading(false)
        }
        break;

      case 'topNeumaticas':
        setSubModuleTitle('Top Productos en Herramientas Neumáticas')
        const topNeumaticas = await getTopProductos('topNeumaticas', ZonasVenta,rif,start2Months, today)

        if (topNeumaticas.length !== 0) {
          setTopProductos(topNeumaticas.data)
          setPageSugeridos(topNeumaticas.current_page)
          setCurrentPageSugeridos(topNeumaticas.current_page)
          setTotalPagesSugeridos(topNeumaticas.last_page)
          setUrlPageSugeridos(topNeumaticas.first_page_url)
          setLoading(false)
        }
        break;

      case 'topBolsos':
        setSubModuleTitle('Top Productos en Bolsos de Herramientas')
        const topBolsos = await getTopProductos('topBolsos',ZonasVenta, rif,start2Months, today)

        if (topBolsos.length !== 0) {
          setTopProductos(topBolsos.data)
          setPageSugeridos(topBolsos.current_page)
          setCurrentPageSugeridos(topBolsos.current_page)
          setTotalPagesSugeridos(topBolsos.last_page)
          setUrlPageSugeridos(topBolsos.first_page_url)
          setLoading(false)
        }
        break;

      case 'topSeguridad':
        setSubModuleTitle('Top Productos en Seguridad Industrial')
        const topSeguridad = await getTopProductos('topSeguridad',ZonasVenta, rif,start2Months, today)

        if (topSeguridad.length !== 0) {
          setTopProductos(topSeguridad.data)
          setPageSugeridos(topSeguridad.current_page)
          setCurrentPageSugeridos(topSeguridad.current_page)
          setTotalPagesSugeridos(topSeguridad.last_page)
          setUrlPageSugeridos(topSeguridad.first_page_url)
          setLoading(false)
        }
        break;

      case 'topBotas':
        setSubModuleTitle('Top Productos en Botas de Seguridad')
        const topBotas = await getTopProductos('topBotas', ZonasVenta,rif,start2Months, today)

        if (topBotas.length !== 0) {
          setTopProductos(topBotas.data)
          setPageSugeridos(topBotas.current_page)
          setCurrentPageSugeridos(topBotas.current_page)
          setTotalPagesSugeridos(topBotas.last_page)
          setUrlPageSugeridos(topBotas.first_page_url)
          setLoading(false)
        }
        break;

      case 'topCandados':
        setSubModuleTitle('Top Productos en Candados')
        const topCandados = await getTopProductos('topCandados', ZonasVenta,rif,start2Months, today)

        if (topCandados.length !== 0) {
          setTopProductos(topCandados.data)
          setPageSugeridos(topCandados.current_page)
          setCurrentPageSugeridos(topCandados.current_page)
          setTotalPagesSugeridos(topCandados.last_page)
          setUrlPageSugeridos(topCandados.first_page_url)
          setLoading(false)
        }
        break;

      case 'topBombas-agua':
        setSubModuleTitle('Top Productos en Bombas de Agua')
        const topBombasAgua = await getTopProductos('topBombasAgua', ZonasVenta,rif,start2Months, today)

        if (topBombasAgua.length !== 0) {
          setTopProductos(topBombasAgua.data)
          setPageSugeridos(topBombasAgua.current_page)
          setCurrentPageSugeridos(topBombasAgua.current_page)
          setTotalPagesSugeridos(topBombasAgua.last_page)
          setUrlPageSugeridos(topBombasAgua.first_page_url)
          setLoading(false)
        }
        break;

      case 'topHidraulicos':
        setSubModuleTitle('Top Productos en Hidráulicos')
        const topHidraulicos = await getTopProductos('topHidraulicos', ZonasVenta,rif,start2Months, today)

        if (topHidraulicos.length !== 0) {
          setTopProductos(topHidraulicos.data)
          setPageSugeridos(topHidraulicos.current_page)
          setCurrentPageSugeridos(topHidraulicos.current_page)
          setTotalPagesSugeridos(topHidraulicos.last_page)
          setUrlPageSugeridos(topHidraulicos.first_page_url)
          setLoading(false)
        }
        break;

      case 'topJardineria-ingco':
        setSubModuleTitle('Top Productos en Jardineria INGCO')
        const topJardineriaIngco = await getTopProductos('topJardineriaIngco', ZonasVenta,rif,start2Months, today)

        if (topJardineriaIngco.length !== 0) {
          setTopProductos(topJardineriaIngco.data)
          setPageSugeridos(topJardineriaIngco.current_page)
          setCurrentPageSugeridos(topJardineriaIngco.current_page)
          setTotalPagesSugeridos(topJardineriaIngco.last_page)
          setUrlPageSugeridos(topJardineriaIngco.first_page_url)
          setLoading(false)
        }
        break;

      case 'topLinternas-ingco':
        setSubModuleTitle('Top Productos en Linternas INGCO')
        const topLinternasIngco = await getTopProductos('topLinternasIngco', ZonasVenta,rif,start2Months, today)

        if (topLinternasIngco.length !== 0) {
          setTopProductos(topLinternasIngco.data)
          setPageSugeridos(topLinternasIngco.current_page)
          setCurrentPageSugeridos(topLinternasIngco.current_page)
          setTotalPagesSugeridos(topLinternasIngco.last_page)
          setUrlPageSugeridos(topLinternasIngco.first_page_url)
          setLoading(false)
        }
        break;

      case 'topMechas':
        setSubModuleTitle('Top Productos en Mechas')
        const topMechas = await getTopProductos('topMechas', ZonasVenta,rif,start2Months, today)

        if (topMechas.length !== 0) {
          setTopProductos(topMechas.data)
          setPageSugeridos(topMechas.current_page)
          setCurrentPageSugeridos(topMechas.current_page)
          setTotalPagesSugeridos(topMechas.last_page)
          setUrlPageSugeridos(topMechas.first_page_url)
          setLoading(false)
        }
        break;

      case 'topConsumibles':
        setSubModuleTitle('Top Productos en Consumibles')
        const topConsumibles = await getTopProductos('topConsumibles', ZonasVenta,rif,start2Months, today)

        if (topConsumibles.length !== 0) {
          setTopProductos(topConsumibles.data)
          setPageSugeridos(topConsumibles.current_page)
          setCurrentPageSugeridos(topConsumibles.current_page)
          setTotalPagesSugeridos(topConsumibles.last_page)
          setUrlPageSugeridos(topConsumibles.first_page_url)
          setLoading(false)
        }
        break;

      case 'topAccesorios':
        setSubModuleTitle('Top Productos en Accesorios')
        const topAccesorios = await getTopProductos('topAccesorios', ZonasVenta,rif,start2Months, today)

        if (topAccesorios.length !== 0) {
          setTopProductos(topAccesorios.data)
          setPageSugeridos(topAccesorios.current_page)
          setCurrentPageSugeridos(topAccesorios.current_page)
          setTotalPagesSugeridos(topAccesorios.last_page)
          setUrlPageSugeridos(topAccesorios.first_page_url)
          setLoading(false)
        }
        break;

      case 'topPintura':
        setSubModuleTitle('Top Productos en Pintura')
        const topPintura = await getTopProductos('topPintura',ZonasVenta,rif,start2Months, today)

        if (topPintura.length !== 0) {
          setTopProductos(topPintura.data)
          setPageSugeridos(topPintura.current_page)
          setCurrentPageSugeridos(topPintura.current_page)
          setTotalPagesSugeridos(topPintura.last_page)
          setUrlPageSugeridos(topPintura.first_page_url)
          setLoading(false)
        }
        break;

      default:
        break;
    }
  }

  const loadTopProductos2= async(marca)=>{
    setLoading(true)
    setBrand(marca)
    switch (marca) {
      case 'INGCOgeneral':
        setSubModuleTitle('Top 100 Productos INGCO')
        const topProductosINGCO = await getTopProductos2('INGCOgeneral', ZonasVenta, rif)

        const productosINGCOPromise = new Promise((resolve, reject) => {
          if (topProductosINGCO.length !== 0) {
            setTopProductos(topProductosINGCO.data)
            setPageSugeridos(topProductosINGCO.current_page)
            setCurrentPageSugeridos(topProductosINGCO.current_page)
            setTotalPagesSugeridos(topProductosINGCO.last_page)
            setUrlPageSugeridos(topProductosINGCO.first_page_url)
            resolve()
          }
          else {
            reject()
          }
        });

        productosINGCOPromise.then(() => {
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })

        break;
      case 'INGCOcomprado':
        setSubModuleTitle('Top 100 Productos INGCO')
        const topProductosINGCOcomprado = await getTopProductos2('INGCOComprado', ZonasVenta, rif)

        const productosINGCOcompradoPromise = new Promise((resolve, reject) => {
          if (topProductosINGCOcomprado.length !== 0) {
            setTopProductos(topProductosINGCOcomprado.data)
            setPageSugeridos(topProductosINGCOcomprado.current_page)
            setCurrentPageSugeridos(topProductosINGCOcomprado.current_page)
            setTotalPagesSugeridos(topProductosINGCOcomprado.last_page)
            setUrlPageSugeridos(topProductosINGCOcomprado.first_page_url)
            resolve()
          }
          else {
            reject()
          }
        });

        productosINGCOcompradoPromise.then(() => {
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })

        break;
        case 'INGCOnocomprado':
        setSubModuleTitle('Top 100 Productos INGCO')
        const topProductosINGCOnocomprado = await getTopProductos2('INGCOnocomprado', ZonasVenta, rif)

        const productosINGCOnocompradoPromise = new Promise((resolve, reject) => {
          if (topProductosINGCOnocomprado.length !== 0) {
            setTopProductos(topProductosINGCOnocomprado.data)
            setPageSugeridos(topProductosINGCOnocomprado.current_page)
            setCurrentPageSugeridos(topProductosINGCOnocomprado.current_page)
            setTotalPagesSugeridos(topProductosINGCOnocomprado.last_page)
            setUrlPageSugeridos(topProductosINGCOnocomprado.first_page_url)
            resolve()
          }
          else {
            reject()
          }
        });

        productosINGCOnocompradoPromise.then(() => {
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })

        break;

      case 'VERTgeneral':
        setSubModuleTitle('Top 100 Productos VERT')
        const topProductosVERT = await getTopProductos2('VERTgeneral', ZonasVenta, rif)

        const productosVERTPromise = new Promise((resolve, reject) => {
          if (topProductosVERT.length !== 0) {
            setTopProductos(topProductosVERT.data)
            setPageSugeridos(topProductosVERT.current_page)
            setCurrentPageSugeridos(topProductosVERT.current_page)
            setTotalPagesSugeridos(topProductosVERT.last_page)
            setUrlPageSugeridos(topProductosVERT.first_page_url)
            resolve()
          }
          else {
            reject()
          }
        });

        productosVERTPromise.then(() => {
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
        break;
      

      case 'VERTcomprado':
        setSubModuleTitle('Top 100 Productos VERT')
        const topProductosVERTcomprado = await getTopProductos2('VERTComprado', ZonasVenta, rif)

        const productosVERTcompradoPromise = new Promise((resolve, reject) => {
          if (topProductosVERTcomprado.length !== 0) {
            setTopProductos(topProductosVERTcomprado.data)
            setPageSugeridos(topProductosVERTcomprado.current_page)
            setCurrentPageSugeridos(topProductosVERTcomprado.current_page)
            setTotalPagesSugeridos(topProductosVERTcomprado.last_page)
            setUrlPageSugeridos(topProductosVERTcomprado.first_page_url)
            resolve()
          }
          else {
            reject()
          }
        });

        productosVERTcompradoPromise.then(() => {
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
        break;
      

      case 'VERTnocomprado':
        setSubModuleTitle('Top 100 Productos VERT')
        const topProductosVERTnocomprado = await getTopProductos2('VERTnocomprado', ZonasVenta, rif)

        const productosVERTnocompradoPromise = new Promise((resolve, reject) => {
          if (topProductosVERTnocomprado.length !== 0) {
            setTopProductos(topProductosVERTnocomprado.data)
            setPageSugeridos(topProductosVERTnocomprado.current_page)
            setCurrentPageSugeridos(topProductosVERTnocomprado.current_page)
            setTotalPagesSugeridos(topProductosVERTnocomprado.last_page)
            setUrlPageSugeridos(topProductosVERTnocomprado.first_page_url)
            resolve()
          }
          else {
            reject()
          }
        });

        productosVERTnocompradoPromise.then(() => {
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
        break;
      }
  }

  const loadProductosPromo = async () => {
    setLoading(true)

    const productosPromocion = await getProductosPromocion(ZonasVenta)

    const productosPromoPromise = new Promise((resolve, reject) => {
      if (productosPromocion.length !== 0) {
        setProductosPromo(productosPromocion.data)
        setPageSugeridos(productosPromocion.current_page)
        setCurrentPageSugeridos(productosPromocion.current_page)
        setTotalPagesSugeridos(productosPromocion.last_page)
        setUrlPageSugeridos(productosPromocion.first_page_url)
        resolve()
      }
      else {
        reject()
      }
    });

    productosPromoPromise.then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  const loadGeneralProductos = async (catalogo) => {
    setLoading(true)

    const generalProds = await getGeneralProductos(catalogo, ZonasVenta,rif)

    const generalProdsPromise = new Promise((resolve, reject) => {
      if (generalProds.length !== 0) {
        setProductosGeneral(generalProds.data)
        setPageSugeridos(generalProds.current_page)
        setCurrentPageSugeridos(generalProds.current_page)
        setTotalPagesSugeridos(generalProds.last_page)
        setUrlPageSugeridos(generalProds.first_page_url)
        resolve()
      }
      else {
        reject()
      }
    });

    generalProdsPromise.then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  // Procesos para agregar, borrar, modificar, verificar cantidades de los productos y procesar para guardar en la nube
  const addProduct = async (codigo, nombre, existencia, cantidad, precio, rutaImagen, ventaMinima, grupo, subgrupo, agencia) => {
    var producto = []
    var data = {}

    if (cantidad) {
      if ((cart.find((producto) => producto.Codigo === codigo && producto.Agencia === agencia)) && cart.length > 0) {
        customSwal.fire({
          title: '¡No puedes agregar el mismo producto 2 veces al presupuesto!',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true
        });
      } else if (cantidad % ventaMinima !== 0 && existencia < 20) {
        customSwal.fire(`Has agregado ${cantidad} unidades del producto: ${codigo}`)
        data = {
          Codigo: codigo,
          Nombre: nombre,
          Existencia: existencia,
          Precio: parseFloat(precio),
          Unidades: parseInt(cantidad),
          SubTotal: parseFloat((cantidad * precio).toFixed(2)),
          RutaImagen: rutaImagen,
          VentaMinima: parseInt(ventaMinima),
          Grupo: grupo,
          Subgrupo: subgrupo,
          Agencia: agencia
        }
        producto.push(data)
        setCart(producto => [...producto, data]);
        setSubtotal((parseFloat(subtotal) + parseFloat(data.SubTotal)))

        localStorage.setItem('subtotal', (parseFloat(subtotal) + parseFloat(data.SubTotal)))
        localStorage.setItem('Vendedor', user.CodVendedor)
      } else if (cantidad % ventaMinima !== 0 && cantidad <= existencia && existencia >= 20) {
        customSwal.fire({
          title: `La cantidad debe ser múltiplo de ${ventaMinima}`,
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true
        });
      } else {
        customSwal.fire(`Has agregado ${cantidad} unidades del producto: ${codigo}`)
        data = {
          Codigo: codigo,
          Nombre: nombre,
          Existencia: existencia,
          Precio: parseFloat(precio),
          Unidades: parseInt(cantidad),
          SubTotal: parseFloat((cantidad * precio).toFixed(2)),
          RutaImagen: rutaImagen,
          VentaMinima: parseInt(ventaMinima),
          Grupo: grupo,
          Subgrupo: subgrupo,
          Agencia: agencia
        }
        producto.push(data)
        setCart(producto => [...producto, data]);
        setSubtotal((parseFloat(subtotal) + parseFloat(data.SubTotal)))
        setQuantity({ ...quantity, [`${codigo}_${agencia}`]: ventaMinima })

        localStorage.setItem('subtotal', (parseFloat(subtotal) + parseFloat(data.SubTotal)))
        localStorage.setItem('Vendedor', user.CodVendedor)
      }
    }
  }

  const deleteProduct = (productCode, productAmount, productEmpresa) => {
    customSwal.fire({
      title: '¿Deseas eliminar el producto del presupuesto?',
      icon: 'question',
      confirmButtonText: 'Si, elimínalo.',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        if (cart.length > 1) {
          const newArr = cart.filter(producto => {
            if (producto.Codigo === productCode) {
              // En el caso de haber un mismo código pero con diferente agencia, no se borra
              return producto.Agencia !== productEmpresa;
            }
            return true;
          });
          setCart(newArr);

          var newSubtotal = parseFloat(subtotal) - parseFloat(productAmount);
          setSubtotal(newSubtotal);

          if (applyDiscount === 'PREPAGO') {
            setDiscount(parseFloat(subtotal) * 0.1);
            setTotalFinal(((parseFloat(subtotal) - parseFloat(productAmount)) - (parseFloat(subtotal) * 0.1)));
          } else {
            setDiscount(0);
            setTotalFinal((parseFloat(subtotal) - parseFloat(productAmount)));
          }

          if (subtotal === -0) {
            setSubtotal(parseFloat(subtotal) * (-1));
          } else if (subtotal < 0 || isNaN(subtotal)) {
            setSubtotal(0);
          }

          if (totalFinal === -0) {
            setTotalFinal(parseFloat(totalFinal) * (-1));
          } else if (totalFinal < 0 || isNaN(totalFinal)) {
            setTotalFinal(0);
          }
        } else {
          customSwal.fire({
            title: '¡No puedes eliminar el último producto del presupuesto!',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }
      } else if (result.isDismissed) {
        //Code for dismiss goes here...
      }
    });
  };

  const modifyProduct = (e, itemCodigo, itemPrecio, itemVentaMinima, itemAgencia) => {
    let newSubtotal = 0
    let newCantidad = parseInt(e.target.value)
    if (isNaN(newCantidad) || isNaN(newSubtotal)) {
      newCantidad = e.target.placeholder
    }
    const newState = cart.map(producto => {
      if (producto.Codigo === itemCodigo && producto.Agencia === itemAgencia) {
        newSubtotal = parseFloat(parseInt(newCantidad) * parseFloat(itemPrecio))
        return { ...producto, SubTotal: parseFloat(newSubtotal), Unidades: parseInt(newCantidad) }
      }
      return producto;
    })
    setCart(newState)
  }

  const verifyQuantity = (e, itemVentaMinima, itemPrecio, itemCodigo, itemSubTotal, itemAgencia) => {
    let calcDescuento = 0
    let newSubtotal = 0
    let newCantidad = e.target.value

    var calcularPromise = new Promise((resolve, reject) => {
      if (newCantidad < itemVentaMinima) {
        newCantidad = itemVentaMinima
        e.target.value = itemVentaMinima
        e.placeholder = itemVentaMinima

        customSwal.fire({
          title: `Venta mínima permitida para este producto: ${itemVentaMinima} unidades`,
          showCancelButton: false,
          showConfirmButton: false,
          icon: 'warning',
          timer: 1500,
          timerProgressBar: true
        })

        for (let i = 0; i < cart.length; i++) {
          if (cart[i].Codigo === itemCodigo && producto.Agencia === itemAgencia) {
            cart[i].Unidades = itemVentaMinima
            cart[i].SubTotal = itemVentaMinima * itemPrecio
          }
          newSubtotal += parseFloat(cart[i].SubTotal)
        }

        setSubtotal(newSubtotal)
        localStorage.setItem('subtotal', (parseFloat(newSubtotal)))
        reject()

      } else if (isNaN(newCantidad)) {
        newCantidad = e.target.placeholder
        e.target.value = e.target.placeholder

        for (let i = 0; i < cart.length; i++) {
          if (cart[i].Codigo === itemCodigo && producto.Agencia === itemAgencia) {
            cart[i].Unidades = itemVentaMinima
            cart[i].SubTotal = itemVentaMinima * itemPrecio
          }
          newSubtotal += parseFloat(cart[i].SubTotal)
        }

        setSubtotal(newSubtotal)
        localStorage.setItem('subtotal', (parseFloat(newSubtotal)))
        reject()

      } else if (newCantidad % itemVentaMinima !== 0) {
        newCantidad = itemVentaMinima
        e.target.value = itemVentaMinima
        e.placeholder = itemVentaMinima

        customSwal.fire({
          title: `La cantidad debe ser múltiplo de ${itemVentaMinima}`,
          showCancelButton: false,
          showConfirmButton: false,
          icon: 'warning',
          timer: 1500,
          timerProgressBar: true
        })

        for (let i = 0; i < cart.length; i++) {
          if (cart[i].Codigo === itemCodigo && producto.Agencia === itemAgencia) {
            cart[i].Unidades = itemVentaMinima
            cart[i].SubTotal = itemVentaMinima * itemPrecio
          }
          newSubtotal += parseFloat(cart[i].SubTotal)
        }

        setSubtotal(newSubtotal)
        setCart(cart)
        localStorage.setItem('subtotal', (parseFloat(newSubtotal)));
        localStorage.setItem('cart', JSON.stringify(cart));
        reject()
      } else { resolve(); }
    })

    calcularPromise.then(() => {
      var cartSubTotal = 0
      for (let i = 0; i < cart.length; i++) {
        cartSubTotal += (cart[i].Precio * cart[i].Unidades)
      }
      setSubtotal(cartSubTotal)
      localStorage.setItem('subtotal', (parseFloat(cartSubTotal)))
      if (applyDiscount === 'PREPAGO') {
        calcDescuento = Number(cartSubTotal).toFixed(2) * 0.1
      }
      var calcTotalFinal = Number(cartSubTotal).toFixed(2) - calcDescuento
      setDiscount(calcDescuento)
      setTotalFinal(calcTotalFinal)

    }).catch(() => {
      const newState = cart.map(producto => {
        if (producto.Codigo === itemCodigo && producto.Agencia === itemAgencia) {
          newSubtotal = parseFloat(parseInt(newCantidad) * parseFloat(itemPrecio))
          return { ...producto, SubTotal: parseFloat(newSubtotal), Unidades: parseInt(newCantidad) }
        }
        return producto;
      })
      setCart(newState)
      localStorage.setItem('cart', JSON.stringify(newState));
      var cartSubTotal = 0
      for (let i = 0; i < cart.length; i++) {
        cartSubTotal += (cart[i].Precio * cart[i].Unidades)
      }
      setSubtotal(cartSubTotal)
      localStorage.setItem('subtotal', (parseFloat(cartSubTotal)))
      if (applyDiscount === 'PREPAGO') {
        calcDescuento = Number(cartSubTotal).toFixed(2) * 0.1
      }
      var calcTotalFinal = Number(cartSubTotal).toFixed(2) - calcDescuento
      setDiscount(calcDescuento)
      setTotalFinal(calcTotalFinal)
    })
  }

  const procesarPresupuesto = () => {
    customSwal.fire({
      title: 'Modificar Presupuesto',
      text: '¿Estás seguro de querer modificar este presupuesto?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      icon: 'question'
    }).then(async (result) => {
      if (result.isConfirmed) {

        if (cart.length > 0) {
          const resTemporal = await copyPresupuestosToTemp(documento)
          const deletePresupuestoAModificar = await deletePresupuestoEncabezadoDetalle(documento, rif);
          const encodedCliente = cliente.replace(/&/g, '%26');
          const res = await postPresupuestoEncabezadoModificado(documento, rif, `${encodedCliente}`, user.CodVendedor, applyDiscount, today, totalFinal, 0, promo, promoDays, tipoPromocion, montoPromo)

          var docUploaded = res.data.Documento;
          // var docUploaded = 'PR0999999'
          var cartWithoutDuplicates = Array.from(new Set(cart)); // Remove duplicates from cart
          var query = ""
          var finalQuery = "";
          var count = 0;

          for (let i = 0; i < cartWithoutDuplicates.length; i++) {
            if (query === "") {
              query = `('${docUploaded}', '${cartWithoutDuplicates[i].Agencia}', '${rif}', '${cartWithoutDuplicates[i].Codigo}', '${cartWithoutDuplicates[i].Nombre}', ${listaPrecio}, ${cartWithoutDuplicates[i].Precio}, ${cartWithoutDuplicates[i].Unidades}, ${cartWithoutDuplicates[i].SubTotal}, 0, '${cartWithoutDuplicates[i].Grupo}', '${cartWithoutDuplicates[i].Subgrupo}'), `;
            } else {
              query = query + `('${docUploaded}', '${cartWithoutDuplicates[i].Agencia}', '${rif}', '${cartWithoutDuplicates[i].Codigo}', '${cartWithoutDuplicates[i].Nombre}', ${listaPrecio}, ${cartWithoutDuplicates[i].Precio}, ${cartWithoutDuplicates[i].Unidades}, ${cartWithoutDuplicates[i].SubTotal}, 0, '${cartWithoutDuplicates[i].Grupo}', '${cartWithoutDuplicates[i].Subgrupo}'), `;
            }

            count = i + 1;
            if (count === cartWithoutDuplicates.length) {
              if (query.endsWith(', ')) {
                finalQuery = query.slice(0, (query.length - 2));
              }
            }
          }

          const fData = new FormData();
          fData.append('finalQuery', finalQuery)

          const postDetalle = {
            url: 'https://belmeny.com/api/public/api/postPresupuestoDetalle',
            // url: 'http://127.0.0.1:8000/api/postPresupuestoDetalle',
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin': '*',
              // 'origin': 'x-requested-with',
              'Access-Control-Allow-Headers': 'POST, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
              // 'Content-Type': 'application/json',
            },
            data: fData
          }

          axios(postDetalle).then((res) => {
            if (res.status === 200) {
              customSwal.fire({
                title: '¡Presupuesto modificado de manera exitosa!',
                text: `Número de documento modificado: ${docUploaded}`,
                icon: 'success',
                showConfirmButton: false,
                showLoaderOnConfirm: true,
                timer: 3000,
                timerProgressBar: true
              }).then(async () => {

                const updatePreciosPresupuesto = await updatePrices(docUploaded, rif)

                customSwal.fire({
                  title: `Verificando y actualizando precios del documento ${docUploaded}...`,
                  text: 'Espere un momento por favor, al terminar será redireccionado.',
                  icon: 'info',
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true
                })

                if (updatePreciosPresupuesto.status === 200 && updatePreciosPresupuesto.data.estado === 200) {
                  navigate('/preorders')
                } else {
                  customSwal.fire({
                    title: `Algo ha salido mal en la verificación del documento ${docUploaded}...`,
                    text: 'Por favor comunicarse con el departamento de sistemas.',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                  }).then(() => {
                    navigate('/preorders')
                  })
                }
              })
            }
          }).catch((error) => {
            console.log(error)
          })
        }
        else {
          customSwal.fire({
            title: '¡No hay items para procesar el presupuesto!',
            icon: 'error',
            showConfirmButton: false,
            showLoaderOnConfirm: true,
            timer: 2000,
            timerProgressBar: true
          });
        }
      }
      else if (result.isDismissed) {
        // Code for dismiss is here
      }
    })
  }

  // Menu's
  const renderMenu = ({ onClose, left, top, className }, ref) => {
    const handleDropdown = (e) => {
      onClose();
      switch (e) {
        case 'Codigo':
          setSearchbarCodigo('d-block')
          setSearchbarNombre('d-none')
          setSearchDate('d-none')
          break;

        case 'Nombre':
          setSearchbarNombre('d-block')
          setSearchbarCodigo('d-none')
          setSearchDate('d-none')
          break;

        case 'Limpiar':
          setSearchbarCodigo('d-none')
          setSearchbarNombre('d-none')
          setSearchDate('d-none')
          break;
      }
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleDropdown} size="lg">
          <Dropdown.Item eventKey={'Codigo'}>Por Código</Dropdown.Item>
          <Dropdown.Item eventKey={'Nombre'}>Por Nombre</Dropdown.Item>
          <Dropdown.Item divider />
          <Dropdown.Item eventKey={'Limpiar'} className='bg-danger text-light text-decoration-none'>Limpiar</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  const listaPrecioOptions = [1, 2, 3, 4, 5]

  const renderProductColumn = (product) => (
    product.Nombre!=null ? (
      <div className="row fs-6 border-bottom border-1 pb-2" key={product.Codigo}>
        <div className="col-2 my-auto" >
          <Image src={`https://belmeny.com/catalogo/${product.Codigo}.png`} alt="No se ha podido cargar la imagen..." onError={defaultBrokenImg} className='rounded p-0 mx-auto' fluid loading="lazy"/>
        </div>
        <div className="col my-auto">
          <span className="fw-bold text-danger">{product.Codigo}{(product.Empresa === 'CCS' && ZonasVenta === '001,003,004,CCS') ? ' - CARACAS CCS' : (ZonasVenta === '001,003,004,CCS') ? ' - MARACAIBO' : ''}</span>
        </div>
        <div className="col-3 my-auto">
          {product.Nombre}
        </div>
        <div className="col my-auto">
          <span>{(product.FechaHora!=null) ? new Date(product.FechaHora).toLocaleString().split(',')[0]  : " "}</span><br/>
          <span>{(product.Cantidad!=null) ? ((product.UnidEmpaque !== '') ? product.Cantidad +" "+product.UnidEmpaque : product.Cantidad+' UD'): " "}</span>
        </div>
        <div className="col my-auto">
          <span className='fw-bold'>{product.Existencia} {(product.UnidEmpaque !== '') ? product.UnidEmpaque : 'UD'}</span>
        </div>
        {
          listaPrecioOptions.map((precio, btnIndex) => (
            listaPrecio === precio && (
              <>
                <div className="col my-auto">
                  <span className='text-success fw-bold'>${product[`Precio${precio}`]?.toFixed(2)}</span>
                </div>
                <div className="col-2 my-auto">
                  <div className="row my-2 mx-auto">
                    <div className='col-2'>
                      <button key={`subtract-btn-${btnIndex}`} className="btn btn-danger fw-bold" onClick={() => handleQuantityChange(product, 'subtract', product.VentaMinima)}>
                        -
                      </button>
                    </div>
                    <div className='col'>
                      <input type="number" placeholder={quantity[`${product.Codigo}_${product.Empresa}`] || product.VentaMinima} onChange={(e) => handleQuantityChange(product, 'update', e.target.value)} step={product.VentaMinima} min="0" max={product.Existencia} className="form-control w-100 mx-1" />
                    </div>
                    <div className='col-2'>
                      <button key={`add-btn-${btnIndex}`} className="btn btn-success" onClick={() => handleQuantityChange(product, 'add', product.VentaMinima)}>
                        +
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className='col text-center'>
                      {
                        (cart.find((producto) => producto.Codigo === product.Codigo && producto.Agencia === product.Empresa)) ?
                          <button key={btnIndex} className="btn btn-success">
                            Agregado
                          </button>
                          :
                          <button key={btnIndex} className="btn btn-primary"
                            onClick={() => addProduct(product.Codigo, product.Nombre, product.Existencia, (quantity[`${product.Codigo}_${product.Empresa}`] || product.VentaMinima), product[`Precio${precio}`]?.toFixed(2), product.RutaImagen, product.VentaMinima, product.Grupo, product.Subgrupo, product.Empresa)}>
                            Agregar
                          </button>
                      }
                    </div>
                  </div>
                </div>
              </>
            )
          ))
        }
      </div>
    ) : null
  )

  const renderProductColumnMobile = (product) => (
    product.Nombre!=null ? (
      <>
        <div className="col my-2 text-center" key={product.Codigo}>
          <h5 className="text-danger fw-bold">{product.Codigo}{(product.Empresa === 'CCS' && ZonasVenta === '001,003,004,CCS') ? ' - CARACAS CCS' : (ZonasVenta === '001,003,004,CCS') ? ' - MARACAIBO' : ''}</h5>
          <h5 className="text-belmeny fw-bold">{product.Nombre}</h5>
          <div className="row"><img src={`https://belmeny.com/catalogo/${product.Codigo}.png`} alt="No se ha podido cargar la imagen..." onError={defaultBrokenImgMobile} className='border-belmeny rounded my-3 w-75 mx-auto' /></div>
          <h5 className="fw-bo">{(product.FechaHora!=null) ? "Ultima Compra: "+new Date(product.FechaHora).toLocaleString().split(',')[0] : " "} {(product.Cantidad!=null) ? ((product.UnidEmpaque !== '') ? product.Cantidad +" "+product.UnidEmpaque : product.Cantidad+' UD'): " "} </h5>
          <h5 className="fw-bold">Existencia: {product.Existencia} {(product.UnidEmpaque !== '') ? product.UnidEmpaque : 'UD'}</h5>
          {
            listaPrecioOptions.map((precio, btnIndex) => (
              listaPrecio === precio && (
                <>
                  <h5>Precio: <span className='fw-bold text-success'>${product[`Precio${precio}`]?.toFixed(2)}</span>
                  </h5>
                  <div className="row my-2 mx-auto">
                    <div className='col-2'>
                      <button key={`subtract-btn-${btnIndex}`} className="btn btn-danger fw-bold" onClick={() => handleQuantityChange(product, 'subtract', product.VentaMinima)}>
                        -
                      </button>
                    </div>
                    <div className='col'>
                      <input type="number" placeholder={quantity[`${product.Codigo}_${product.Empresa}`] || product.VentaMinima} onChange={(e) => handleQuantityChange(product, 'update', e.target.value)} step={product.VentaMinima} min="0" max={product.Existencia} className="form-control w-100 mx-1" />
                    </div>
                    <div className='col-2'>
                      <button key={`add-btn-${btnIndex}`} className="btn btn-success" onClick={() => handleQuantityChange(product, 'add', product.VentaMinima)}>
                        +
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    {
                      (cart.find((producto) => producto.Codigo === product.Codigo && producto.Agencia === product.Empresa)) ?
                        <button key={btnIndex} className="btn btn-success rounded-pill my-2">
                          Agregado
                        </button>
                        :
                        <button key={btnIndex} className="btn btn-primary rounded-pill my-2"
                          onClick={() => addProduct(product.Codigo, product.Nombre, product.Existencia, (quantity[`${product.Codigo}_${product.Empresa}`] || product.VentaMinima), product[`Precio${precio}`]?.toFixed(2), product.RutaImagen, product.VentaMinima, product.Grupo, product.Subgrupo, product.Empresa)}>
                          Agregar
                        </button>
                    }
                  </div>
                </>
              )
            ))
          }
        </div>
        <div className="division"></div>
      </>
    ) : null
  )

  const handleQuantityChange = (product, action, value) => {
    if (action === 'update') {
      setQuantity({ ...quantity, [`${product.Codigo}_${product.Empresa}`]: parseInt(value, 10) });
    } else if (action === 'add') {
      setQuantity({ ...quantity, [`${product.Codigo}_${product.Empresa}`]: (quantity[`${product.Codigo}_${product.Empresa}`] || 0) + value });
    } else if (action === 'subtract') {
      if (quantity[`${product.Codigo}_${product.Empresa}`] > 0) {
        setQuantity({ ...quantity, [`${product.Codigo}_${product.Empresa}`]: quantity[`${product.Codigo}_${product.Empresa}`] - value });
      }
    }
  };

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-documentos"></span>
              </div> :
              <>
                {/* Mobile */}
                <div className="d-sm-block d-md-none">
                  <div className="my-4">
                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Presupuestos</h4>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="p-3 my-3 rounded border-belmeny">
                      <div className="row">
                        <div className="col">
                          <button className="btn btn-md btn-success w-100" onClick={() => handleShow('Cart')}>Carrito</button>
                          <h5 className="pt-2">Total de productos: <span className="text-belmeny fw-bold">{cart.length}</span></h5>
                          <h5 className="pt-1">Subtotal: <span className="text-success fw-bold">${subtotal.toFixed(2)}</span></h5>
                        </div>
                      </div>
                      <h5 className="text-belmeny mt-4">Presupuesto: <span className="text-danger">{documento}</span></h5>
                      <h5 className="text-belmeny">Cliente: {cliente}</h5>
                      <div className="row mt-2">
                        <div className="col-2" style={{ width: '150px' }}>
                          <h6 className="text-belmeny">Forma de Pago:</h6>
                        </div>
                        <div className="col fs-6">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label={applyDiscount}
                            onChange={(e) => { handleApplyDiscount(e) }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col">
                          <div className="my-2">
                            <ButtonToolbar appearance="primary">
                              <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu} size="lg" className='w-100'>
                                <IconButton appearance="primary" icon={<SearchIcon />} placement="left" size="lg" className='w-100'>
                                  Buscar Productos
                                </IconButton>
                              </Whisper>
                            </ButtonToolbar>
                            <div className="mt-3">
                              <div className={searchbarCodigo}>
                                <h5 className='my-2 ms-2 text-belmeny'>Búsqueda por Código</h5>
                                <Searchbar onSearch={onSearch} />
                              </div>
                              <div className={searchbarNombre}>
                                <h5 className='my-2 ms-2 text-belmeny'>Búsqueda por Nombre</h5>
                                <Searchbar onSearch={onSearch} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <Button appearance="primary" color="blue" size="lg" className='w-100' onClick={() => handleCatalogoDigital('general')}>Todos los Productos</Button>
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col">
                          <ButtonToolbar className='w-100'>
                            <Dropdown title="VËRT" appearance="primary" size="lg" className='w-100' placement="bottomStart" onSelect={handleCatalogoDigital}>
                              <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                                <strong>Iluminación</strong>
                              </Dropdown.Item>
                              <Dropdown.Item divider />
                              <Dropdown.Item eventKey="bombillos">Bombillos</Dropdown.Item>
                              <Dropdown.Item eventKey="cintasLED">Cinta LED y Accesorios</Dropdown.Item>
                              <Dropdown.Item eventKey="lamparas">Lámparas y Bombillos Vintage</Dropdown.Item>
                              <Dropdown.Item eventKey="paneles">Paneles LED</Dropdown.Item>
                              <Dropdown.Item eventKey="tubos">Tubos LED y Accesorios</Dropdown.Item>
                              <Dropdown.Item eventKey="alumbrados">Alumbrados</Dropdown.Item>
                              <Dropdown.Item eventKey="reflectores">Reflectores</Dropdown.Item>
                              <Dropdown.Item eventKey="linternas">Linternas</Dropdown.Item>
                              <Dropdown.Item divider />
                              <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                                <strong>Hogar</strong>
                              </Dropdown.Item>
                              <Dropdown.Item divider />
                              <Dropdown.Item eventKey="hogar">Decoración, Accesorios y Útiles</Dropdown.Item>
                              <Dropdown.Item eventKey="banos">Baños</Dropdown.Item>
                              <Dropdown.Item eventKey="jardineria">Jardineria</Dropdown.Item>
                              <Dropdown.Item eventKey="plomeria">Plomeria y Grifería</Dropdown.Item>
                              <Dropdown.Item eventKey="cerraduras">Cerraduras</Dropdown.Item>
                              <Dropdown.Item divider />
                              <Dropdown.Item eventKey="ferreteriagral">Ferreteria en General</Dropdown.Item>
                              <Dropdown.Item eventKey="electricidad">Electricidad</Dropdown.Item>
                              <Dropdown.Item eventKey="automotriz">Automotriz</Dropdown.Item>
                              <Dropdown.Item eventKey="construccion">Construcción</Dropdown.Item>
                              <Dropdown.Item eventKey="miscelaneo">Miscelaneos</Dropdown.Item>
                            </Dropdown>
                          </ButtonToolbar>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <ButtonToolbar className='w-100'>
                            <Dropdown title="INGCO" appearance="primary" color="yellow" size="lg" className='w-100' placement="bottomStart" onSelect={handleCatalogoDigital}>
                              <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                                <strong>Herramientas</strong>
                              </Dropdown.Item>
                              <Dropdown.Item divider />
                              <Dropdown.Item eventKey="electricas">Electricas</Dropdown.Item>
                              <Dropdown.Item eventKey="aisladas">Aisladas</Dropdown.Item>
                              <Dropdown.Item eventKey="manuales">Manuales</Dropdown.Item>
                              <Dropdown.Item eventKey="medicion">Medición</Dropdown.Item>
                              <Dropdown.Item eventKey="neumaticas">Neumaticas</Dropdown.Item>
                              <Dropdown.Item eventKey="bolsos">Bolso para Herramientas</Dropdown.Item>
                              <Dropdown.Item divider />
                              <Dropdown.Item panel style={{ padding: 10, width: 160 }}>
                                <strong>Seguridad</strong>
                              </Dropdown.Item>
                              <Dropdown.Item divider />
                              <Dropdown.Item eventKey="seguridad">Equipo de Seguridad Industrial</Dropdown.Item>
                              <Dropdown.Item eventKey="botas">Botas de Seguridad</Dropdown.Item>
                              <Dropdown.Item eventKey="candados">Candados</Dropdown.Item>
                              <Dropdown.Item divider />
                              <Dropdown.Item eventKey="bombas-agua">Bombas de Agua</Dropdown.Item>
                              <Dropdown.Item eventKey="hidraulicos">Gatos Hidraulicos</Dropdown.Item>
                              <Dropdown.Item eventKey="jardineria-ingco">Jardineria</Dropdown.Item>
                              <Dropdown.Item eventKey="linternas-ingco">Linternas</Dropdown.Item>
                              <Dropdown.Item eventKey="mechas">Mechas</Dropdown.Item>
                              <Dropdown.Item eventKey="consumibles">Consumibles</Dropdown.Item>
                              <Dropdown.Item eventKey="accesorios">Accesorios</Dropdown.Item>
                              <Dropdown.Item eventKey="pintura">Accesorios para Pintar</Dropdown.Item>
                            </Dropdown>
                          </ButtonToolbar>
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col">
                          <Button appearance="primary" color="blue" size="lg" className='w-100' onClick={() => handleCatalogoDigital('quilosa')}>QUILOSA</Button>
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col">
                          <Button appearance="primary" color="blue" size="lg" className='w-100' onClick={() => handleCatalogoDigital('fleximatic')}>FLEXIMATIC</Button>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <Button appearance="primary" color="blue" size="lg" className='w-100' onClick={() => handleCatalogoDigital('imou')}>IMOU</Button>
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col">
                          <Button appearance="primary" color="cyan" size="lg" className='w-100' onClick={() => switchTitles('Promociones')}>Promociones</Button>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <ButtonToolbar className='w-100'>
                            <Dropdown title="Productos Vendidos" appearance="primary" color="blue" size="lg" className='w-100' placement="bottomStart" onSelect={handleCatalogoDigital}>
                              <Dropdown.Item eventKey="vendidosVERT">VËRT</Dropdown.Item>
                              <Dropdown.Item eventKey="vendidosINGCO">INGCO</Dropdown.Item>
                            </Dropdown>
                          </ButtonToolbar>
                        </div>
                      </div>

                      <div className="row my-2">
                        <div className="col">
                        <ButtonToolbar className='w-100'>
                            <Dropdown title="Top Vendidos INGCO" appearance="primary" color="yellow" size="lg" className='w-100' placement="bottomStart" onSelect={handleCatalogoDigital}>
                            <Dropdown.Item eventKey="top100INGCOgeneral">Todos los Productos</Dropdown.Item>   
                                    <Dropdown.Item eventKey="top100INGCOcomprado">Comprados ({porcentaje_ingco}%)</Dropdown.Item>
                                    <Dropdown.Item eventKey="top100INGCOnocomprado">No Comprados ({100-porcentaje_ingco}%) </Dropdown.Item>
                            </Dropdown>
                          </ButtonToolbar>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                        <ButtonToolbar className='w-100'>
                            <Dropdown title="Top vendidos VËRT" appearance="primary" color="blue" size="lg" className='w-100' placement="bottomStart" onSelect={handleCatalogoDigital}>
                                    <Dropdown.Item eventKey="top100INGCOgeneral">Todos los Productos</Dropdown.Item>   
                                    <Dropdown.Item eventKey="top100INGCOcomprado">Comprados ({porcentaje_ingco}%)</Dropdown.Item>
                                    <Dropdown.Item eventKey="top100INGCOnocomprado">No Comprados ({100-porcentaje_ingco}%) </Dropdown.Item>
                            </Dropdown>
                          </ButtonToolbar>
                          
                        </div>
                      </div>


                      {/* Lista de productos */}
                      {
                        (moduleTitle !== '') ?
                          <>
                            {
                              (producto.length > 0 || sugeridos.length > 0 || topProductos.length > 0 || productosPromo.length > 0 || productosGeneral.length > 0) ?
                                <>
                                  <h4 className="text-center fst-italic text-belmeny mt-4 mb-3">Lista de Productos</h4>
                                  {
                                    (moduleTitle === 'Busquedas') ?
                                      <>
                                        {(producto.map((item, index) => <>
                                          {
                                            (index % 1 === 0) ?
                                              <div className="row">
                                                {renderProductColumnMobile(producto[index])}
                                              </div> : null
                                          }
                                        </>))}
                                      </> : (moduleTitle === 'Vendidos') ?
                                        <>
                                          {(sugeridos.map((item, index) => <>
                                            {
                                              (index % 1 === 0) ?
                                                <div className="row">
                                                  {renderProductColumnMobile(sugeridos[index])}
                                                </div> : null
                                            }
                                          </>))}
                                        </> : (moduleTitle === 'Ingco') ?
                                          <>
                                            {(topProductos.map((item, index) => <>
                                              {
                                                (index % 1 === 0) ?
                                                  <div className="row">
                                                    {renderProductColumnMobile(topProductos[index])}
                                                  </div> : null
                                              }
                                            </>))}
                                          </> : (moduleTitle === 'Vert') ?
                                            <>
                                              {(topProductos.map((item, index) => <>
                                                {
                                                  (index % 1 === 0) ?
                                                    <div className="row">
                                                      {renderProductColumnMobile(topProductos[index])}
                                                    </div> : null
                                                }
                                              </>))}
                                            </> : (moduleTitle === 'Promociones') ?
                                              <>
                                                {(productosPromo.map((item, index) => <>
                                                  {
                                                    (index % 1 === 0) ?
                                                      <div className="row">
                                                        {renderProductColumnMobile(productosPromo[index])}
                                                      </div> : null
                                                  }
                                                </>))}
                                              </> :
                                              <div className='container'>
                                                {(productosGeneral.map((item, index) => <>
                                                  {
                                                    (index % 1 === 0) ?
                                                      <div className="row">
                                                        {renderProductColumnMobile(productosGeneral[index])}
                                                      </div> : null
                                                  }
                                                </>))}
                                              </div>
                                  }


                                  {/* Pagination by modulesTitles */}
                                  <div className="mt-3">
                                    {
                                      (moduleTitle === 'Busquedas') ?
                                        <Pagination
                                          total={totalPages}
                                          current={currentPage}
                                          onPageChange={page => handlePageChange(page, 'searchPagination')} /> : (moduleTitle === 'Sugeridos') ?
                                          <Pagination
                                            total={totalPagesSugeridos}
                                            current={currentPageSugeridos}
                                            onPageChange={pageSugeridos => handlePageChange(pageSugeridos, 'sugeridosPagination')} /> : (moduleTitle === 'Ingco' || moduleTitle === 'Vert') ?
                                            <Pagination
                                              total={totalPagesSugeridos}
                                              current={currentPageSugeridos}
                                              onPageChange={pageSugeridos => handlePageChange(pageSugeridos, 'topPagination')} /> : (moduleTitle === 'Promociones') ?
                                              <Pagination
                                                total={totalPagesSugeridos}
                                                current={currentPageSugeridos}
                                                onPageChange={pageSugeridos => handlePageChange(pageSugeridos, 'promoPagination')} /> :
                                              <Pagination
                                                total={totalPagesSugeridos}
                                                current={currentPageSugeridos}
                                                onPageChange={pageSugeridos => handlePageChange(pageSugeridos, 'generalPagination')} />
                                    }
                                  </div>
                                </> :
                                <>
                                  <div className="division my-3"></div>x
                                  <h4 className="text-center fst-italic alert alert-danger">No se han encontrado resultados, intente de nuevo por favor...</h4>x
                                </>
                            }
                          </> :
                          <>
                            <div className="division my-3"></div>
                            <h4 className="text-center fst-italic text-belmeny alert alert-info">Selecciona un cliente y realiza una búsqueda para agregar los productos al presupuesto</h4>
                          </>
                      }
                    </div>
                  </div>
                </div>

                {/* Desktop */}
                <div className='d-none d-md-block d-lg-block'>
                  <div className="container-fluid rounded">
                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Presupuestos</h4>
                    </div>

                    <h5 className="text-center mt-2 mb-4 text-belmeny"><i>Vendedor - {user.Nombre} - {user.CodVendedor}</i></h5>

                    <div className="mt-5">
                      <div className='p-5 my-3 rounded border-belmeny'>

                        <div className="row">

                          <div className="col">
                            <h5 className="text-belmeny">Presupuesto: <span className="text-danger">{documento}</span></h5>
                            <h5 className="text-belmeny">Cliente: {cliente}</h5>
                            <div className="row mt-2">
                              <div className="col-2" style={{ width: '150px' }}>
                                <h6 className="text-belmeny">Forma de Pago:</h6>
                              </div>
                              <div className="col fs-6">
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label={applyDiscount}
                                  onChange={(e) => { handleApplyDiscount(e) }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col mx-end text-end">
                            <div className="row">
                              <div className="col">
                                <button className="btn btn-success d-flex ms-auto" onClick={() => handleShow('Cart')}><BsCartFill className='fs-3' /></button>
                              </div>
                            </div>
                            <h5 className="text-end my-3">Total de productos: <span className="text-belmeny fw-bold">{cart.length}</span> - Subtotal: <span className="text-success fw-bold">${(subtotal).toFixed(2)}</span></h5>
                          </div>

                        </div>

                        {/* Search Button */}
                        <div className="row mt-3">
                          <div className="my-3">
                            <div className="row">
                              <div className="col">
                                <ButtonToolbar appearance="primary">
                                  <Whisper placement="bottomEnd" trigger="click" speaker={renderMenu} size="lg">
                                    <IconButton appearance="primary" icon={<SearchIcon />} placement="left" size="lg">
                                      Buscar Productos
                                    </IconButton>
                                  </Whisper>
                                </ButtonToolbar>
                              </div>
                              <div className="col text-center">
                            <ButtonToolbar>
                                <Dropdown title="Top 100 Productos" appearance="primary"  color="green" size="lg" placement="rightStart" onSelect={handleCatalogoDigital}
                                >
                                  <Dropdown.Menu title={"VËRT (" +porcentaje_vert+"%)"}>
                                    <Dropdown.Item eventKey="top100VERTgeneral">Todos los Productos</Dropdown.Item>
                                    <Dropdown.Item eventKey="top100VERTcomprado">Comprados ({porcentaje_vert}%)</Dropdown.Item>
                                    <Dropdown.Item eventKey="top100VERTnocomprado">No Comprados ({100-porcentaje_vert}%) </Dropdown.Item>
                                  </Dropdown.Menu>
                                  <Dropdown.Menu title={"INGCO ("+porcentaje_ingco+"%)"} >
                                    <Dropdown.Item eventKey="top100INGCOgeneral">Todos los Productos</Dropdown.Item>   
                                    <Dropdown.Item eventKey="top100INGCOcomprado">Comprados ({porcentaje_ingco}%)</Dropdown.Item>
                                    <Dropdown.Item eventKey="top100INGCOnocomprado">No Comprados ({100-porcentaje_ingco}%) </Dropdown.Item>
                                  </Dropdown.Menu>
                                  
                                </Dropdown>
                              </ButtonToolbar>
                              </div>
                                  <div className="col text-center">
                                    <ButtonToolbar>
                                      <Dropdown title="General" appearance="primary" size="lg" placement="rightStart" onSelect={handleCatalogoDigital}>
                                        <Dropdown.Item eventKey="general">Todos los Productos</Dropdown.Item>
                                        <Dropdown.Menu title="VËRT">
                                          <Dropdown.Menu title="Iluminación">
                                            <Dropdown.Item eventKey="bombillos">Bombillos</Dropdown.Item>
                                            <Dropdown.Item eventKey="cintasLED">Cinta LED y Accesorios</Dropdown.Item>
                                            <Dropdown.Item eventKey="lamparas">Lámparas y Bombillos Vintage</Dropdown.Item>
                                            <Dropdown.Item eventKey="paneles">Paneles LED</Dropdown.Item>
                                            <Dropdown.Item eventKey="tubos">Tubos LED y Accesorios</Dropdown.Item>
                                            <Dropdown.Item eventKey="alumbrados">Alumbrados</Dropdown.Item>
                                            <Dropdown.Item eventKey="reflectores">Reflectores</Dropdown.Item>
                                            <Dropdown.Item eventKey="linternas">Linternas</Dropdown.Item>
                                          </Dropdown.Menu>
                                          <Dropdown.Menu title="Hogar">
                                            <Dropdown.Item eventKey="hogar">Decoración, Accesorios y Útiles</Dropdown.Item>
                                            <Dropdown.Item eventKey="banos">Baños</Dropdown.Item>
                                            <Dropdown.Item eventKey="jardineria">Jardineria</Dropdown.Item>
                                            <Dropdown.Item eventKey="plomeria">Plomeria y Grifería</Dropdown.Item>
                                            <Dropdown.Item eventKey="cerraduras">Cerraduras</Dropdown.Item>
                                          </Dropdown.Menu>
                                          <Dropdown.Item eventKey="ferreteriagral">Ferreteria en General</Dropdown.Item>
                                          <Dropdown.Item eventKey="electricidad">Electricidad</Dropdown.Item>
                                          <Dropdown.Item eventKey="automotriz">Automotriz</Dropdown.Item>
                                          <Dropdown.Item eventKey="construccion">Construcción</Dropdown.Item>
                                          <Dropdown.Item eventKey="miscelaneo">Miscelaneos</Dropdown.Item>
                                        </Dropdown.Menu>
                                        <Dropdown.Menu title="INGCO">
                                          <Dropdown.Menu title="Herramientas">
                                            <Dropdown.Item eventKey="electricas">Electricas</Dropdown.Item>
                                            <Dropdown.Item eventKey="aisladas">Aisladas</Dropdown.Item>
                                            <Dropdown.Item eventKey="manuales">Manuales</Dropdown.Item>
                                            <Dropdown.Item eventKey="medicion">Medición</Dropdown.Item>
                                            <Dropdown.Item eventKey="neumaticas">Neumaticas</Dropdown.Item>
                                            <Dropdown.Item eventKey="bolsos">Bolso para Herramientas</Dropdown.Item>
                                          </Dropdown.Menu>
                                          <Dropdown.Menu title="Seguridad">
                                            <Dropdown.Item eventKey="seguridad">Equipo de Seguridad Industrial</Dropdown.Item>
                                            <Dropdown.Item eventKey="botas">Botas de Seguridad</Dropdown.Item>
                                            <Dropdown.Item eventKey="candados">Candados</Dropdown.Item>
                                          </Dropdown.Menu>
                                          <Dropdown.Item eventKey="bombas-agua">Bombas de Agua</Dropdown.Item>
                                          <Dropdown.Item eventKey="hidraulicos">Gatos Hidraulicos</Dropdown.Item>
                                          <Dropdown.Item eventKey="jardineria-ingco">Jardineria</Dropdown.Item>
                                          <Dropdown.Item eventKey="linternas-ingco">Linternas</Dropdown.Item>
                                          <Dropdown.Item eventKey="mechas">Mechas</Dropdown.Item>
                                          <Dropdown.Item eventKey="consumibles">Consumibles</Dropdown.Item>
                                          <Dropdown.Item eventKey="accesorios">Accesorios</Dropdown.Item>
                                          <Dropdown.Item eventKey="pintura">Accesorios para Pintar</Dropdown.Item>
                                        </Dropdown.Menu>
                                        <Dropdown.Item eventKey="fleximatic">FLEXIMATIC</Dropdown.Item>
                                        <Dropdown.Item eventKey="imou">IMOU</Dropdown.Item>
                                        <Dropdown.Item eventKey="quilosa">QUILOSA</Dropdown.Item>
                                      </Dropdown>
                                    </ButtonToolbar>
                                  </div>
                                  <div className="col text-center">
                                    {/* <Button appearance="primary" size="lg" onClick={() => switchTitles('Sugeridos')}>Productos Vendidos</Button> */}
                                    <ButtonToolbar>
                                      <Dropdown title="Productos Vendidos" appearance="primary" size="lg" placement="rightStart" onSelect={handleCatalogoDigital}>
                                        <Dropdown.Item eventKey="vendidosVERT">VËRT</Dropdown.Item>
                                        <Dropdown.Item eventKey="vendidosINGCO">INGCO</Dropdown.Item>
                                      </Dropdown>
                                    </ButtonToolbar>
                                  </div>
                                  <div className="col text-center">
                                    <Button appearance="primary" color="cyan" size="lg" onClick={() => switchTitles('Promociones')}>Promociones</Button>
                                  </div>
                            </div>

                            <div className="mt-3">
                              <div className={searchbarCodigo}>
                                <h5 className='my-2 ms-2 text-belmeny'>Búsqueda por Código</h5>
                                <Searchbar onSearch={onSearch} />
                              </div>
                              <div className={searchbarNombre}>
                                <h5 className='my-2 ms-2 text-belmeny'>Búsqueda por Nombre</h5>
                                <Searchbar onSearch={onSearch} />
                              </div>
                            </div>
                          </div>
                        </div>

                        {
                          (moduleTitle === 'Sugeridos') ?
                            <>
                              <div className="row mt-2">
                                <div className="col">
                                  <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                                    <DateRangePicker
                                      appearance={"subtle"}
                                      ranges={predefinedRanges}
                                      placeholder="Buscar por fecha"
                                      style={{ width: 300 }}
                                      onChange={e => changeDate(e)}
                                      className="border rounded-pill mb-2"
                                      showOneCalendar
                                      disabledDate={afterToday()}
                                    />
                                  </Stack>
                                </div>
                              </div>
                            </> : <></>
                        }

                        {/* Lista de productos */}
                        <div className="row my-3">
                          {
                            (moduleTitle !== '') ?
                              <>
                                {
                                  (productsArray.some(arr => arr.length > 0)) ?
                                    <>
                                      <h4 className="text-center fst-italic text-belmeny">Lista de Productos</h4>
                                      <h5 className="text-center fst-italic text-belmeny">{subModuleTitle}</h5>
                                      <div className="mt-4 mb-2 border-belmeny">
                                        <div className="row text-center bg-belmeny3 text-white fw-bold py-2 rounded-top fs-6">
                                        <div className="col-2">Imagen</div>
                                          <div className="col">Código</div>
                                          <div className="col-3">Nombre</div>
                                          <div className="col">Ultima Compra</div>
                                          <div className="col">Existencia</div>
                                          <div className="col">Precio</div>
                                          <div className="col-2">Acción</div>
                                        </div>
                                        {
                                          (moduleTitle === 'Busquedas') ?
                                            <>
                                              <div className="container-fluid">
                                                {(producto.map((item, index) => <>
                                                  <div className="my-2 text-center">
                                                    {renderProductColumn(producto[index])}
                                                  </div>
                                                </>))}
                                              </div>
                                            </> : (moduleTitle === 'Vendidos') ?
                                              <>
                                                <div className="container-fluid">
                                                  {(sugeridos.map((item, index) => <>
                                                    <div className="my-2 text-center">
                                                      {renderProductColumn(sugeridos[index])}
                                                    </div>
                                                  </>))}
                                                </div>
                                              </> : (moduleTitle === 'Ingco') ?
                                                <>
                                                  <div className="container-fluid">
                                                    {(topProductos.map((item, index) => <>
                                                      <div className="my-2 text-center">
                                                        {renderProductColumn(topProductos[index])}
                                                      </div>
                                                    </>))}
                                                  </div>
                                                </> : (moduleTitle === 'Vert') ?
                                                  <>
                                                    <div className="container-fluid">
                                                      {(topProductos.map((item, index) => <>
                                                        <div className="my-2 text-center">
                                                          {renderProductColumn(topProductos[index])}
                                                        </div>
                                                      </>))}
                                                    </div>
                                                  </> : (moduleTitle === 'Promociones') ?
                                                    <>
                                                      <div className="container-fluid">
                                                        {(productosPromo.map((item, index) => <>
                                                          <div className="my-2 text-center">
                                                            {renderProductColumn(productosPromo[index])}
                                                          </div>
                                                        </>))}
                                                      </div>
                                                    </> :
                                                    <>
                                                      <div className="container-fluid">
                                                        {(productosGeneral.map((item, index) =>
                                                          <div className='my-2 text-center'>
                                                            {renderProductColumn(productosGeneral[index])}
                                                          </div>))}
                                                      </div>
                                                    </>
                                        }

                                        {/* Pagination by modulesTitles */}
                                        {
                                          (moduleTitle === 'Busquedas') ?
                                            <Pagination
                                              total={totalPages}
                                              current={currentPage}
                                              onPageChange={page => handlePageChange(page, 'searchPagination')} /> :
                                            (moduleTitle === 'Vendidos') ?
                                              <Pagination
                                                total={totalPagesSugeridos}
                                                current={currentPageSugeridos}
                                                onPageChange={pageSugeridos => handlePageChange(pageSugeridos, 'sugeridosPagination')} /> :
                                              (moduleTitle === 'Ingco' || moduleTitle === 'Vert') ?
                                                <Pagination
                                                  total={totalPagesSugeridos}
                                                  current={currentPageSugeridos}
                                                  onPageChange={pageSugeridos => handlePageChange(pageSugeridos, 'topPagination')} /> :
                                                (moduleTitle === 'Promociones') ?
                                                  <Pagination
                                                    total={totalPagesSugeridos}
                                                    current={currentPageSugeridos}
                                                    onPageChange={pageSugeridos => handlePageChange(pageSugeridos, 'promoPagination')} /> :
                                                  (moduleTitle === 'General') ?
                                                    <Pagination
                                                      total={totalPagesSugeridos}
                                                      current={currentPageSugeridos}
                                                      onPageChange={pageSugeridos => handlePageChange(pageSugeridos, 'generalPagination')} /> : <></>
                                        }
                                      </div>
                                    </> :
                                    <>
                                      <div className="division my-3"></div>
                                      <div className="row my-2">
                                        <h4 className="text-center fst-italic alert alert-danger">No se han encontrado resultados, intente de nuevo por favor...</h4>
                                      </div>
                                    </>
                                }
                              </> :
                              <>
                                <div className="division my-3"></div>
                                <div className="row my-2">
                                  <h4 className="text-center fst-italic text-belmeny alert alert-info">
                                    Para ver los productos actuales en su presupuesto presiona el botón <span className="btn btn-success" onClick={() => handleShow('Cart')} ><BsCartFill className='fs-3' /></span> <br />
                                    Para agregar nuevos productos al presupuesto actual utilice los botones en la parte superior.
                                  </h4>
                                </div>
                              </>
                          }
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </Layout>

      {/* Carrito */}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>Información del presupuesto</Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-4"></div>
        <Modal.Body>
          <div className="container-fluid">
            {/* Body for desktop version */}
            <div className='d-none d-md-block'>
              <h4 className="text-center text-belmeny fst-italic">Detalle del presupuesto</h4>
              <div className="row mb-3">
                <div className="col my-auto">
                  <button className="btn btn-success" onClick={(e) => procesarPresupuesto()}>Guardar Presupuesto</button>
                </div>
                {
                  (promo > 0 && applyDiscount === 'CREDITO') ?
                    <>
                      <div className="col">
                        <h5 className="text-end mb-3">Días de Promoción: {promoDays} días</h5>
                        <h5 className="text-end mb-3">Total Descuento Promoción: <span className="text-success fw-bold">${(promoDiscount).toFixed(2)}</span></h5>
                        <h5 className="text-end mb-3">Total Final Promoción: <span className="text-success fw-bold">${((montoPromo).toFixed(2))}</span></h5>
                      </div>
                    </> :
                    <>
                    </>
                }
                <div className="col-2">
                  <h5 className="text-end mb-1">Total de productos: <span className="text-belmeny fw-bold">{cart.length}</span></h5>
                  <h5 className="text-end mb-1">Subtotal: <span className="text-success fw-bold">${(subtotal).toFixed(2)}</span></h5>
                  <h5 className="text-end mb-1">Total descuento: <span className="text-success fw-bold">${(discount).toFixed(2)}</span></h5>
                  <h5 className="text-end mb-1">Total Final: <span className="text-success fw-bold">${(totalFinal).toFixed(2)}</span></h5>
                </div>
              </div>
              {
                (cart.length > 0) ?
                  <>
                    <table className="table table-responsive table-bordered border-belmeny">
                      <thead className="bg-belmeny text-light">
                        <tr>
                          <th className='text-center'>Código</th>
                          <th className='text-center'>Imagen</th>
                          <th className='text-center'>Nombre</th>
                          <th className='text-center'>Cantidad</th>
                          <th className='text-center'>Precio Unitario</th>
                          <th className='text-center'>Sub Total</th>
                          <th className='text-center'>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (cart.map((item) => <>
                            <tr>
                              <td key="{item.Codigo}" className='text-center text-danger fw-bold'>{item.Codigo}{(item.Agencia === 'CCS' && ZonasVenta === '001,003,004,CCS') ? ' - CARACAS CCS' : (ZonasVenta === '001,003,004,CCS') ? ' - MARACAIBO' : ''}</td>
                              <td className="text-center"><img src={'https://belmeny.com/catalogo/' + item.Codigo + '.png'} onError={defaultBrokenImg} alt={item.Codigo} /></td>
                              <td>{item.Nombre}</td>
                              <td className='text-center'>
                                <div className="row">
                                  <div className="col-3"></div>
                                  <div className="col">
                                    <input type="number" name="cantidad" id="cantidad" className='form-control text-center' placeholder={item.Unidades} min="1" onChange={(e) => modifyProduct(e, item.Codigo, item.Precio, item.VentaMinima, item.Agencia)} onBlur={(e) => verifyQuantity(e, item.VentaMinima, item.Precio, item.Codigo, item.SubTotal, item.Agencia)} step={item.VentaMinima} />
                                  </div>
                                  <div className="col-3"></div>
                                </div>
                              </td>
                              <td className='text-center'><span className="text-success fw-bold">${item.Precio.toFixed(2)}</span></td>
                              <td className='text-center'><span className="text-success fw-bold">${item.SubTotal.toFixed(2)}</span></td>
                              <td className='text-center'>
                                <button className="btn btn-danger w-100" onClick={(e) => deleteProduct(item.Codigo, item.SubTotal, item.Agencia)}>Eliminar Producto</button>
                              </td>
                            </tr>
                          </>
                          ))
                        }
                      </tbody>
                    </table>
                  </> :
                  <>
                    <div className="alert alert-danger text-center" role="alert">
                      <h5>No has agregado productos al carrito...</h5>
                    </div>
                  </>
              }
            </div>

            {/* Body for mobile version */}
            <div className='d-block d-sm-block d-md-none'>
              <h4 className="text-center text-belmeny">Detalle del presupuesto</h4>

              <button className="btn btn-success w-100 my-3" onClick={(e) => procesarPresupuesto()}>Procesar Presupuesto</button>


              <div className="row mt-3">
                {
                  (promo > 0) ?
                    <>
                      <div className="col">
                        <h5 className="mb-1">Días de Promoción: {promoDays} días</h5>
                        <h5 className="mb-1">Total Descuento Promoción: <span className="text-success fw-bold">${(promoDiscount).toFixed(2)}</span></h5>
                        <h5 className="mb-1">Total Final Promoción: <span className="text-success fw-bold">${((montoPromo).toFixed(2))}</span></h5>
                      </div>
                    </> :
                    <>
                    </>
                }
              </div>
              <div className="row">
                <div className="col">
                  <h5 className="mb-1">Total de productos: <span className="text-belmeny fw-bold">{cart.length}</span></h5>
                  <h5 className="mb-1">Subtotal: <span className="text-success fw-bold">${(subtotal).toFixed(2)}</span></h5>
                  <h5 className="mb-1">Total descuento: <span className="text-success fw-bold">${(discount).toFixed(2)}</span></h5>
                  <h5 className="mb-1">Total Final: <span className="text-success fw-bold">${(totalFinal).toFixed(2)}</span></h5>
                </div>
              </div>

              <h4 className="text-center fst-italic text-belmeny text-light bg-belmeny rounded-pill my-4">Lista de Productos</h4>
              {
                (cart.length > 0) ?
                  <>
                    {
                      (cart.map((item) => <>
                        <div className="row my-2">
                          <h5 className="text-center text-danger fw-bold" key={item.Codigo}>{item.Codigo}{(item.Agencia === 'CCS' && ZonasVenta === '001,003,004,CCS') ? ' - CARACAS CCS' : (ZonasVenta === '001,003,004,CCS') ? ' - MARACAIBO' : ''}</h5>
                          <h5 className="text-center text-belmeny fw-bold">{item.Nombre}</h5>
                          <img src={'https://belmeny.com/catalogo/' + item.Codigo + '.png'} alt="No se ha podido cargar la imagén..." onError={defaultBrokenImg} className='border-belmeny rounded my-3 w-50 mx-auto' />
                          <h5>Cantidad: <input type="number" name="cantidad" id="cantidad" className='form-control text-center' placeholder={item.Unidades} min="1" onChange={(e) => modifyProduct(e, item.Codigo, item.Precio, item.VentaMinima, item.Agencia)} onBlur={(e) => verifyQuantity(e, item.VentaMinima, item.Precio, item.Codigo, item.SubTotal, item.Agencia)} step={item.VentaMinima} /></h5>
                          <h5 className="mt-2">Precio Unitario: <span className="text-success fw-bold">${item.Precio.toFixed(2)}</span></h5>
                          <h5 className="">Subtotal: <span className="text-success fw-bold">${item.SubTotal.toFixed(2)}</span></h5>
                          <button className="btn btn-danger w-100 mt-3" onClick={(e) => deleteProduct(item.Codigo, item.SubTotal, item.Agencia)}>Eliminar Producto</button>
                          <div className="division my-3"></div>
                        </div>
                      </>
                      ))
                    }
                  </> : <>
                  </>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  )
}

