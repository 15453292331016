import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import AuthUser from '../components/AuthUser';


export const Redirect = () => {

    const { user } = AuthUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.CodVendedor !== null && user.CodSupervisor !== null && user.CodGerente === null) {
            navigate('/dashboard')
        } else if (user.CodGerente === 'MASTER' && user.CodVendedor === null && user.CodSupervisor === null) {
            navigate('/register')
        } else if((user.CodSupervisor === "SSAAB" && user.CodGerente === null && user.CodVendedor === null)) {
            navigate('/register-videos')
        } else if ((user.CodGerente === 'CONSULTA' || user.CodGerente === 'MARKETINGBELMENY') && user.CodSupervisor === null && user.CodVendedor === null) {
            navigate('/products')
        } else if (user.CodGerente === 'HABOULMOUNA' && user.CodSupervisor === null && user.CodVendedor === null) {
            navigate('/dashboard-gerente')
        } else if (user.CodGerente === 'COBRANZASBG' && user.CodSupervisor === null && user.CodVendedor === null) {
            navigate('/appraisals')
        } else if (user.CodGerente === 'CADENASBG' && user.CodSupervisor === null && user.CodVendedor === null) {
            navigate('/price-list')
        } else if (user.CodSupervisor === null && user.CodGerente !== null && user.CodGerente !== 'MASTER') {
            navigate('/dashboard-supervisor')
        }

    });

    return (
        <div className="vertical-center bg-lighten">
            <div className="container">
                <div id="notfound" className="text-center">
                    <h1>🌐</h1>
                    <h2>Redireccionando...</h2>
                    <p>¡En un momento serás redireccionado a tu destino!</p>
                </div>
            </div>
        </div>
    )
}
