import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';

import { Dropdown, DropdownButton } from 'react-bootstrap'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import belmenyLogo from '../../assets/img/logoBG.webp';
import userIcon from '../../assets/img/user-icon-resize.webp';
import notFoundImg from '../../assets/img/404-module-not-found-2.webp';

import { getListaMetas, getZonasSupervisor, actualizarMetas } from '../../api/requestSupervisor';

export const Metas = () => {

  useEffect(() => {
    setLoading(false)
    loadLista()
  }, []);

  // Sweet alert
  const MySwal = withReactContent(Swal)
  const customSwal = MySwal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ms-2',
      denyButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  // Mes actual
  const now = new Date();
  const firstDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth(), 1)).slice(1, 11);
  const lastDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth() + 1, 0)).slice(1, 11);

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [vendedorData, setVendedorData] = useState([])
  const [lista, setLista] = useState([])
  const [zonas, setZonas] = useState([])
  const [show, setShow] = useState(false)
  const [metaMensual, setMetaMensual] = useState(0)
  const [metaTotalGerente, setMetaTotalGerente] = useState(0)

  const loadLista = async () => {
    setLoading(true)
    const [listVend, listZonas] = await Promise.all([getListaMetas('', user.CodVendedor), getZonasSupervisor(user.CodVendedor)])

    const resListVend = await listVend;
    const resListZona = await listZonas;

    var metaTotalVendedores = 0

    for (let i = 0; i < resListVend.length; i++) {
      metaTotalVendedores += resListVend[i].global;
    }

    setLista(resListVend)
    setZonas(resListZona)
    setMetaTotalGerente(metaTotalVendedores)
    setLoading(false)
  }

  const handleFilter = async (e, zona) => {
    e.preventDefault();
    setLoading(true)
    let listaFilter;
    if (zona === 'Limpiar') {
      listaFilter = await getListaMetas('', user.CodVendedor)
    } else {
      listaFilter = await getListaMetas(zona, user.CodVendedor)
    }
    setLista(listaFilter)
    setLoading(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await actualizarMetas(vendedorData.CodVendedor, metaMensual, firstDay, lastDay)
    if (res === 1) {
      customSwal.fire({
        title: 'Meta cambiada con éxito.',
        icon: 'success',
        timerProgressBar: true,
        timer: 1500,
        showConfirmButton: false
      }).then(() => {
        setMetaMensual(0)
        setShow(false)
        loadLista()
      })
    }
  }

  const nuevaMeta = (e, data) => {
    e.preventDefault();
    setShow(true)
    setVendedorData(data)
  }

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  <span className="loader-clientes"></span>
                </div>
              </> :
              <>
                {/* Div para móvil */}
                <div className="d-sm-none">
                  <div className="text-center d-sm-block d-md-none">
                    <br />
                    <img src={notFoundImg} alt="No se ha podido cargar la imagén..." className="mx-auto w-100" />
                  </div>
                  {/* Más contenido... */}
                </div>


                {/* Div para escritorio */}
                <div className='d-none d-md-block'>
                  <div className="container-fluid rounded">
                    <div className="row">
                      <div className="col">
                        <div className="text-belmeny">
                          <h2>{user.Nombre}</h2>
                          <h5><i>{user.CodVendedor}</i></h5>
                        </div>
                      </div>
                      <div className="col">
                        <img src={userIcon} alt="Logo Belmeny Group" className='float-end me-5 drop-shadow-sm' />
                      </div>
                    </div>

                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Metas de vendedores</h4>
                    </div>

                    <div className=' m-auto pt-4'>
                      <h4 className="text-center text-belmeny fst-italic">Lista de vendedores con sus metas actuales</h4>
                      <h5 className="text-center text-belmeny fst-italic">Meta actual entre todos los vendedores: <span className="text-success">${metaTotalGerente}</span></h5>
                      <div className="row">
                        <div className="col">
                          <div className="mb-2">
                            <DropdownButton
                              key={'bottom'}
                              id={`dropdown-button-drop-${'bottom'}`}
                              drop={'bottom'}
                              variant="primary"
                              title={` Zona `}
                            >
                              {zonas.map((item) =>
                                <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => handleFilter(e, item.Sector)}>{item.Sector}</Dropdown.Item>
                              )}
                              <Dropdown.Divider />
                              <Dropdown.Item eventKey="1" className='text-decoration-none bg-danger text-light' onClick={e => handleFilter(e, 'Limpiar')}>Limpiar Filtro</Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </div>
                      </div>

                      <div className='m-auto my-3'>
                        {
                          (show) ?
                            <>
                              <h5 className="text-center mb-2"><i className="text-belmeny">Establecer nueva meta para {vendedorData.nombre} - {vendedorData.CodVendedor}</i></h5><form action="">
                                <div className="row w-50 m-auto">
                                  <div className="col">
                                    <p htmlFor="mensual" className='text-center fw-bold text-belmeny'>Nueva Meta mensual</p>
                                    <input type="number"
                                      name="mensual"
                                      id="mensual"
                                      className="form-control"
                                      onChange={(e) => { setMetaMensual(e.target.value) }}
                                      placeholder={vendedorData.global} />
                                  </div>
                                  <span className='text-muted mt-2'>Si no desea hacer ningún cambio simplemente haga click en cancelar</span>
                                </div>
                                <div className="row text-center">
                                  <div className="col w-25 mt-3">
                                    <button className="btn btn-danger w-25 float-end" onClick={(e) => setShow(false)} type='button'>Cancelar</button>
                                  </div>
                                  <div className="col w-25 mt-3">
                                    <button className="btn btn-success w-25 float-start" onClick={handleSubmit} type='submit'>Guardar nueva meta</button>
                                  </div>
                                </div>
                              </form>
                            </> :
                            <>
                              <h5 className="text-center mb-2"><i className="text-belmeny">Escoja un vendedor para establecer una meta...</i></h5><form action="">
                                <div className="row w-50 m-auto">
                                  <div className="col">
                                    <p htmlFor="ingco" className='text-center fw-bold text-belmeny'>Nueva Meta mensual</p>
                                    <input type="number"
                                      name="mensual"
                                      id="mensual"
                                      className="form-control"
                                      placeholder="0"
                                      disabled />
                                  </div>
                                </div>
                              </form>
                            </>
                        }
                      </div>

                      <table className="table table-responsive table-bordered border-belmeny rounded">
                        <thead className='text-center text-white bg-belmeny'>
                          <tr>
                            <th>Código</th>
                            <th>Vendedor</th>
                            <th>Zona</th>
                            <th>Ventas VËRT</th>
                            <th>Ventas INGCO</th>
                            <th>Total Vendido en el Mes</th>
                            <th>Meta mensual</th>
                            <th>Opciones</th>
                          </tr>
                        </thead>
                        <tbody className='text-center'>
                          {lista.map((item) =>
                            <tr>
                              <td><span className="text-danger fw-bold">{item.CodVendedor}</span></td>
                              <td>{item.nombre}</td>
                              <td>{item.Sector}</td>
                              <td><span className="text-belmeny fw-bold">${item.VentasVert}</span></td>
                              <td><span className="text-belmeny fw-bold">${item.VentasIngco}</span></td>
                              <td><span className="text-success fw-bold">${item.total_vendido}</span></td>
                              <td><span className="text-belmeny fw-bold">${item.global}</span></td>
                              <td className='py-2'>
                                <button onClick={e => nuevaMeta(e, item)} className='btn btn-primary'>Cambiar meta</button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </Layout>
    </>
  )
}
