import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'

import moment from 'moment/moment';

// HTTP
import AuthUser from '../components/AuthUser';
import { registerOrUpdateSession } from '../api/request';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'
import { AiFillCloseCircle } from "react-icons/ai";

// Logo
import belmenyLogo from '../assets/img/logoBG.webp';

export const Login = () => {

  const { http, setToken } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [loadLogin, setLoadLogin] = useState(false);
  const [Usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');

  const today = moment().format("YYYY-M-D HH:mm:ss");

  const [show, setShow] = useState(false);

  const loader = () => {
    setLoadLogin(true);
  }

  // Manejador del submit del form
  const handleSubmit = (e) => {
    loader()
    e.preventDefault();
    http.post('/login', { Usuario: Usuario, password: password }).then((res) => {
      res.data.user.Usuario = Usuario
      setToken(res.data.user, res.data.access_token);
      if (res.data.user.CodVendedor !== null) {
        updateSession(res.data.user.CodVendedor, res.data.user.Nombre)
      }
      else if (res.data.user.CodSupervisor !== null) {
        updateSession(res.data.user.CodSupervisor, res.data.user.Nombre)
      }
      else if (res.data.user.CodGerente !== null) {
        updateSession(res.data.user.CodGerente, res.data.user.Nombre)
      }
    }).catch((error) => {
      console.log(error)
      setLoadLogin(false);
      setShow(true)
    })
  }

  const updateSession = async (Codigo, Nombre) => {
    const actualizarSesion = await registerOrUpdateSession(Codigo, Nombre, today);
  }

  useEffect(() => {
    onLoad()
  });

  const onLoad = async () => {
    // Loader en false
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }

  return (
    <>
      {
        (loading) ?
          <>
            <Container fluid className='bg-vert bg-psh home'>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <div className="m-auto spinner-login -ml-1 mr-3 h-5 w-5">
                </div>
                <h5 className='text-center text-light mt-2'>Cargando...</h5>
              </div>
            </Container>
          </> :
          <>
            <Container fluid className='bg-vert bg-psh home'>
              <Alert show={show} variant="danger" className='mt-3 alert-login'>
                <Alert.Heading>
                  <Row>
                    <Col>
                      Oops, ¡Credenciales incorrectas!
                    </Col>
                    <Col className='text-end me-auto' xs={3}>
                      <AiFillCloseCircle className='fs-3 text-danger close-alert' onClick={() => setShow(false)} />
                    </Col>
                  </Row>
                </Alert.Heading>
                <hr />
                <p>
                  Verifica los datos ingresados e intente iniciar sesión de nuevo por favor.
                </p>
              </Alert>

              <Container className='login-form bg-light'>
                <div>
                  <h2 className='text-center fw-semibold  fs-3 mt-3 mb-4'>
                    <img width={80} src={belmenyLogo} alt="Logo Belmeny Group" className='text-center mt-3 drop-shadow-sm' />
                  </h2>
                  <h5 className='text-center fw-semibold mb-4'>Iniciar sesión</h5>
                  <form action="" className='mb-4 w-75 m-auto' onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label htmlFor="Usuario">Usuario</label>
                      <input type="text"
                        name="Usuario"
                        id="Usuario"
                        className="form-control"
                        value={Usuario}
                        onChange={(e) => setUsuario(e.target.value)} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password">Contraseña</label>
                      <input type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }} />
                    </div>
                    <button className='btn btn-hover-login rounded-pill bg-belmeny text-light w-100 mt-3' type='submit'>Iniciar sesión <FontAwesomeIcon icon={faArrowAltCircleRight} /></button>

                    <div className={loadLogin ? 'login-load mt-3' : 'd-none'}>
                      <div className="m-auto spinner-login">
                      </div>
                      <h5 className='text-center spinner-login-text mt-2'>Cargando...</h5>
                    </div>
                  </form>
                </div>
              </Container>
            </Container>
          </>
      }
    </>
  )
}
