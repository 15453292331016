import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Header } from '../../components/Header'

import {
  portadaVert,
  portadaVertON,
  portadaIngco,
  portadaIngcoON,
  portadaQuilosa,
  portadaQuilosaON,
  portadaCorona,
  portadaCoronaON,
  portadaImou,
  portadaImouON,
  portadaFleximatic,
  portadaFleximaticON,
  portadaWadfow,
  portadaWadfowON
} from '../../assets/img/';

export const CatalogoPortada = () => {

  const [isVertHovered, setIsVertHovered] = useState(false);

  const [vertImg, setVertImg] = useState(portadaVert)
  const [ingcoImg, setIngcoImg] = useState(portadaIngco)
  const [quilosaImg, setQuilosaImg] = useState(portadaQuilosa)
  const [coronaImg, setCoronaImg] = useState(portadaCorona)
  const [fleximaticImg, setFleximaticImg] = useState(portadaFleximatic)
  const [imouImg, setImouImg] = useState(portadaImou)
  const [wadfowImg, setWadfowImg] = useState(portadaWadfow)

  const handleImageHover = (brand) => {

    switch (brand) {

      case 'vert':
        setVertImg(portadaVert)
        break;

      case 'vert-ON':
        setVertImg(portadaVertON)
        break;

      case 'ingco':
        setIngcoImg(portadaIngco)
        break;

      case 'ingco-ON':
        setIngcoImg(portadaIngcoON)
        break;

      case 'quilosa':
        setQuilosaImg(portadaQuilosa)
        break;

      case 'quilosa-ON':
        setQuilosaImg(portadaQuilosaON)
        break;

      case 'corona':
        setCoronaImg(portadaCorona)
        break;

      case 'corona-ON':
        setCoronaImg(portadaCoronaON)
        break;

      case 'fleximatic':
        setFleximaticImg(portadaFleximatic)
        break;

      case 'fleximatic-ON':
        setFleximaticImg(portadaFleximaticON)
        break;

      case 'imou':
        setImouImg(portadaImou)
        break;

      case 'imou-ON':
        setImouImg(portadaImouON)
        break;

      case 'wadfow':
        setWadfowImg(portadaWadfow)
        break;

      case 'wadfow-ON':
        setWadfowImg(portadaWadfowON)
        break;

      default:
        setVertImg(portadaVert)
        setIngcoImg(portadaIngco)
        setQuilosaImg(portadaQuilosa)
        setCoronaImg(portadaCorona)
        setImouImg(portadaImou)
        setFleximaticImg(portadaFleximatic)
        setWadfowImg(portadaWadfow)
        break;
    }
  }

  return (
    <>
      <div className="bg-vert bg-psh">
        <Header></Header>

        <div className="d-none d-md-block d-lg-block">
          <div className="container-fluid pb-5">
            <h3 className="text-center fw-bold text-light mb-5 pt-5">CATÁLOGO DE PRODUCTOS</h3>

            <div className="row">
              {/* INGCO */}
              <div className="col d-flex">
                <Link
                  to='/catalogo-ingco'
                  className="mx-auto portada"
                  onMouseEnter={() => handleImageHover('ingco-ON')}
                  onMouseLeave={() => handleImageHover('ingco')}>
                  <img src={ingcoImg} className="px-0 rounded mx-auto portada" alt="hover" />
                </Link>
              </div>
              {/* VERT */}
              <div className="col d-flex">
                <Link
                  to='/catalogo-vert'
                  className="mx-auto portada"
                  onMouseEnter={() => handleImageHover('vert-ON')}
                  onMouseLeave={() => handleImageHover('vert')}>
                  <img src={vertImg} className="px-0 rounded mx-auto portada" alt="hover" />
                </Link>
              </div>
              {/* WADFOW */}
              <div className="col d-flex">
                <Link
                  to='/catalogo-wadfow'
                  className="mx-auto portada"
                  onMouseEnter={() => handleImageHover('wadfow-ON')}
                  onMouseLeave={() => handleImageHover('wadfow')}>
                  <img src={wadfowImg} className="px-0 rounded mx-auto portada" alt="hover" />
                </Link>
              </div>
            </div>

            <div className="row mt-3">
              {/* CORONA */}
              <div className="col d-flex">
                <Link
                  to='/catalogo-corona'
                  className="mx-auto portada"
                  onMouseEnter={() => handleImageHover('corona-ON')}
                  onMouseLeave={() => handleImageHover('corona')}>
                  <img src={coronaImg} className="px-0 rounded mx-auto portada" alt="hover" />
                </Link>
              </div>
              {/* FLEXIMATIC */}
              <div className="col d-flex">
                <Link
                  to='/catalogo-fleximatic'
                  className="mx-auto portada"
                  onMouseEnter={() => handleImageHover('fleximatic-ON')}
                  onMouseLeave={() => handleImageHover('fleximatic')}>
                  <img src={fleximaticImg} className="px-0 rounded mx-auto portada" alt="hover" />
                </Link>
              </div>
              {/* QUILOSA */}
              <div className="col d-flex">
                <Link
                  to='/catalogo-quilosa'
                  className="mx-auto portada"
                  onMouseEnter={() => handleImageHover('quilosa-ON')}
                  onMouseLeave={() => handleImageHover('quilosa')}>
                  <img src={quilosaImg} className="px-0 rounded mx-auto portada" alt="hover" />
                </Link>
              </div>
              {/* IMOU */}
              <div className="col d-flex">
                <Link
                  to='/catalogo-imou'
                  className="mx-auto portada"
                  onMouseEnter={() => handleImageHover('imou-ON')}
                  onMouseLeave={() => handleImageHover('imou')}>
                  <img src={imouImg} className="px-0 rounded mx-auto portada" alt="hover" />
                </Link>
              </div>
            </div>

            {/* VËRT */}
            {/* <div className="row cintillo-wrapper">
              <img src={cintilloVertProductos} className="px-0 rounded" alt="normal" />
              <Link to='/catalogo-vert'><img src={cintilloVertProductosON} className="px-0 cintillo-vert rounded" alt="hover" /></Link>
            </div> */}
            {/* INGCO */}
            {/* <div className="row cintillo-wrapper mt-5">
              <img src={cintilloIngcoProductos} className="px-0 rounded" alt="normal" />
              <img src={cintilloIngcoProductosON} className="px-0 cintillo-vert rounded" alt="hover" />
            </div> */}
            {/* IMOU */}
            {/* <div className="row cintillo-wrapper mt-5">
              <img src={cintilloImouProductos} className="px-0 rounded" alt="normal" />
              <img src={cintilloImouProductosON} className="px-0 cintillo-vert rounded" alt="hover" />
            </div> */}
            {/* QUILOSA */}
            {/* <div className="row cintillo-wrapper mt-5">
              <img src={cintilloQuilosaProductos} className="px-0 rounded" alt="normal" />
              <img src={cintilloQuilosaProductosON} className="px-0 cintillo-vert rounded" alt="hover" />
            </div> */}
            {/* FLEXIMATIC */}
            {/* <div className="row cintillo-wrapper mt-5">
              <img src={cintilloFleximaticProductos} className="px-0 rounded" alt="normal" />
              <img src={cintilloFleximaticProductosON} className="px-0 cintillo-vert rounded" alt="hover" />
            </div> */}
            {/* CORONA */}
            {/* <div className="row cintillo-wrapper mt-5">
              <img src={cintilloCoronaProductos} className="px-0 rounded" alt="normal" />
              <img src={cintilloCoronaProductosON} className="px-0 cintillo-vert rounded" alt="hover" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
