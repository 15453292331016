import React, { useState, useEffect } from 'react'

import AuthUser from '../../components/AuthUser'

import { Typeahead } from 'react-bootstrap-typeahead';

import { getClientesVendedor, getAllClientes, getClienteRIF } from '../../api/requestClientes';

import 'react-bootstrap-typeahead/css/Typeahead.css';

export const SolicitudInfo = ({ formData, setFormData }) => {

	const { user } = AuthUser();

	const [clientes, setClientes] = useState([]);
	const [saldoPendiente, setSaldoPendiente] = useState(0)
	const [singleSelections, setSingleSelections] = useState([]);

	const loadClientes = async () => {
		if (user.CodVendedor != null) {
			const clientRes = await getClientesVendedor(user.CodVendedor)

			var data = {}
			var arrClient = []

			for (let i = 0; i < clientRes.length; i++) {
				data = {
					name: clientRes[i].Nombre,
					id: clientRes[i].Codigo,
				}
				arrClient.push(data)
			}
			setClientes(arrClient)
		}
		else if (user.CodSupervisor != null) {
			const clientRes = await getAllClientes()

			var data = {}
			var arrClient = []

			for (let i = 0; i < clientRes.length; i++) {
				data = {
					name: clientRes[i].Nombre,
					id: clientRes[i].Codigo,
				}
				arrClient.push(data)
			}
			setClientes(arrClient)
		}
	}

	const handleSelect = async (e) => {
		try {
			const clientRes = await getClienteRIF(e[0].id)
			setFormData({ ...formData, cliente: e[0].name })
			setSaldoPendiente(clientRes.data[0].SaldoPendiente)
		} catch (error) {

		}
	}

	useEffect(() => {
		loadClientes()
	}, []);

	return (
		<>
			<div className="p-5 my-3 rounded border-belmeny">
				<div className="row">
					<div className="col">
						<h4>{
							(user.CodVendedor != null) ?
								<>
									Vendedor: {user.Nombre}
								</> :
								<>
									Supervisor: {user.Nombre}
								</>
						}
						</h4>
						<div className="mb-3 row">
							<div className="col-2">
								<h4>Pedido:</h4>
							</div>
							<div className="col-6">
								<div className="form-group">
									<input type="text" name="nroPedido" id="nroPedido" className='form-control'
										onChange={(e) =>
											setFormData({ ...formData, nro_pedido: e.target.value })
										}
										required />
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-2">
								<h4><label htmlFor="selectCliente">Clientes: </label></h4>
							</div>
							<div className="col-6">
								<div className="form-group">
									<Typeahead
										id="basic-typeahead-single"
										labelKey="name"
										onChange={(e) => {
											setSingleSelections(e.name)
											handleSelect(e)
										}}
										options={clientes}
										placeholder="Selecciona un cliente..."
										selected={singleSelections}
										preventOverflow={false}
										required
									/>
								</div>
							</div>
							<div className="col-4">
								<h5 className='fw-normal'>Saldo Pendiente: <span className="text-danger fw-bold">${saldoPendiente}</span></h5>
							</div>
						</div>

						<div className="mt-3">
							<h4 className='fw-normal'>
								<label htmlFor="marca" className='me-3 fw-bold'>Marca: </label>
								<div className="form-check form-check-inline">
									<input
										className="form-check-input"
										type="radio"
										name="marca"
										id="marcaExhibidorVert"
										value="VERT"
										onChange={(e) =>
											setFormData({ ...formData, marca: e.target.value })
										}
										required
									/>
									<label className="form-check-label" htmlFor="marcaExhibidorVert">VËRT</label>
								</div>
								<div className="form-check form-check-inline">
									<input
										className="form-check-input"
										type="radio"
										name="marca"
										id="marcaExhibidorIngco"
										value="INGCO"
										onChange={(e) =>
											setFormData({ ...formData, marca: e.target.value })
										}
										required
									/>
									<label className="form-check-label" htmlFor="marcaExhibidorIngco">INGCO</label>
								</div>
							</h4>
						</div>

						<div className="mt-3">
							<h4><label htmlFor="motivoSolicitud" className="form-label">Motivo de la solicitud: </label></h4>
							<textarea
								className="form-control"
								id="motivoSolicitud"
								name="motivoSolicitud"
								value={formData.motivoSolicitud}
								onChange={(e) =>
									setFormData({ ...formData, motivoSolicitud: e.target.value })
								}
								rows="3"
								required
							></textarea>
						</div>
					</div>
					<div className="col">
						<h4 className="text-center">Ventas realizadas al cliente: <span className="text-success fw-bold">$100.000</span></h4>
					</div>
				</div>
			</div>
		</>
	)
}
