import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';

import userIcon from '../../assets/img/user-icon-resize.webp';
import notFoundImg from '../../assets/img/404-module-not-found-2.webp';

// SVG LOGOS
import brokenImg from '../../assets/img/broken-image-general.png';
import belmenySvg from '../../assets/img/logos/BELMENY-SVG.svg'
import ingcoSvg from '../../assets/img/logos/INGCO-SVG.svg'
import vertSvg from '../../assets/img/logos/VERT-SVG.svg'
import quilosaSvg from '../../assets/img/logos/QUILOSA-SVG.svg'
import fleximaticSvg from '../../assets/img/logos/FLEXIMATIC-SVG.svg'
import imouSvg from '../../assets/img/logos/IMOU-SVG.svg'
import coronaSvg from '../../assets/img/logos/CORONA-SVG.svg'

import { getZonasSupervisor, getHistoricoMetas, getCantidadVentasxGerente, getVentasZona } from '../../api/requestSupervisor';
import { getTotalClientesxSupervisor, getClientesAtendidosxZona } from '../../api/requestClientes';

import Chart from "react-apexcharts";

import { startOfMonth, endOfMonth, addMonths, endOfDay, startOfWeek, endOfWeek, subWeeks, format, set } from 'date-fns';
import moment from 'moment/moment';

export const EstadisticasGerente = () => {

  useEffect(() => {
    loadZonasSupervisor()
    generateStats('...', oneMonthName, 'si')
    // handleMonthLoader('1mes')
  }, []);

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [username, setUsername] = useState('');
  const [loadingText, setLoadingText] = useState('estadisticas...');
  const [whichMonthLoad, setWhichMonthLoad] = useState('1mes');
  const [zonaSelected, setZonaSelected] = useState('Ningúna...');
  const [mesSelected, setMesSelected] = useState('Ningúno...');
  const [totalMeta, setTotalMeta] = useState(0);
  const [totalClientes, setTotalClientes] = useState(0)
  const [totalAtendidos, setTotalAtendidos] = useState(0);
  const [totalDesatendidos, setTotalDesatendidos] = useState(0)
  const [montoProductos, setMontoProductos] = useState(0);
  const [generalReport, setGeneralReport] = useState(false);
  const [multipleTops, setMultipleTops] = useState(false);
  const [zonasSupervisor, setZonasSupervisor] = useState([]);
  const [ventasProductos, setVentasProductos] = useState([]);
  const [clientesAtendidos, setClientesAtendidos] = useState([]);
  const [clientesChart, setClientesChart] = useState({});
  const [barChartVentas, setBarChartVentas] = useState({});
  const [topDataMetas, setTopDataMetas] = useState({});
  const [topDataMetas2, setTopDataMetas2] = useState({});
  const [topDataMetas3, setTopDataMetas3] = useState({});

  // Nombres de los meses
  const [threeMonthsName, setThreeMonthsName] = useState(startOfMonth(addMonths(new Date(), -3)).toLocaleString("es-MX", { month: "long" }));
  const [twoMonthsName, setTwoMonthsName] = useState(startOfMonth(addMonths(new Date(), -2)).toLocaleString("es-MX", { month: "long" }));
  const [oneMonthName, setOneMonthName] = useState(startOfMonth(addMonths(new Date(), -1)).toLocaleString("es-MX", { month: "long" }));

  //Fecha inicio y fin de los meses
  const [start3Months, setStart3Months] = useState(new Date(startOfMonth(addMonths(new Date(), -3))).toISOString().slice(0, 10))
  const [end3Months, setEnd3Months] = useState(format(new Date(endOfDay(endOfMonth(addMonths(new Date(), -3))).toISOString().slice(0, 10)), 'yyyy-MM-dd'))
  // const [end3Months, setEnd3Months] = useState(new Date(endOfMonth(addMonths(new Date(), -3))).toISOString().slice(0, 10))
  const [start2Months, setStart2Months] = useState(new Date(startOfMonth(addMonths(new Date(), -2))).toISOString().slice(0, 10))
  const [end2Months, setEnd2Months] = useState(format(new Date(endOfDay(endOfMonth(addMonths(new Date(), -2))).toISOString().slice(0, 10)), 'yyyy-MM-dd'))
  // const [end2Months, setEnd2Months] = useState(new Date(endOfMonth(addMonths(new Date(), -2))).toISOString().slice(0, 10))
  const [start1Month, setStart1Month] = useState(new Date(startOfMonth(addMonths(new Date(), -1))).toISOString().slice(0, 10))
  const [end1Month, setEnd1Month] = useState(format(new Date(endOfDay(endOfMonth(addMonths(new Date(), -1))).toISOString().slice(0, 10)), 'yyyy-MM-dd'));

  function sortByMonth(arr) {
    var months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
      "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    arr.sort(function (a, b) {
      return months.indexOf(a.mes)
        - months.indexOf(b.mes);
    });
  }

  const handleChartError = (error, chart, options) => {
    console.error('Chart Error:', error);
  };

  const handleImageError = (event) => {
    event.target.src = brokenImg;
  };

  const handleMonthLoader = (opt) => {
    switch (opt) {
      case '1mes':
        setWhichMonthLoad('1mes')
        loadHistorico1erMes()
        break;

      case '2mes':
        setWhichMonthLoad('2mes')
        // loadHistorico2doMes()
        break;

      case '3mes':
        setWhichMonthLoad('3mes')
        // loadHistorico3erMes()
        break;

      default:
        loadHistorico1erMes()
        break;
    }
  }

  const loadZonasSupervisor = async () => {
    const resZonasSup = await getZonasSupervisor(user.CodGerente)
    setZonasSupervisor(resZonasSup)
  }

  const generateStats = async (zona, mes, general) => {
    setLoading(true)
    // variables para promise
    let resVentasZona, resCantidadVentas, resHistoricoMetas, resHistoricoMetasVendedores, resClientesFacturados, resTotalClientes;
    // variables para los promises resueltos
    let ventasxZona, cantidadVentas, histMetas, histMetasVendedores, clientesFacturados, totalClientes;
    // fechas
    let fInicio, fFin;

    if (general === 'si') {
      if (mes === threeMonthsName) {
        fInicio = start3Months
        fFin = end3Months
        setWhichMonthLoad('3mes')
        setLoadingText(`reporte de estadisticas general de ${threeMonthsName}...`)
      } else if (mes === twoMonthsName) {
        fInicio = start2Months
        fFin = end2Months
        setWhichMonthLoad('2mes')
        setLoadingText(`reporte de estadisticas general de ${twoMonthsName}...`)
      } else {
        fInicio = start1Month
        fFin = end1Month
        setWhichMonthLoad('1mes')
        setLoadingText(`reporte de estadisticas general de ${oneMonthName}...`)
      }

      [resVentasZona, resCantidadVentas, resHistoricoMetas] = await Promise.all([getVentasZona(user.CodGerente), getCantidadVentasxGerente(user.CodGerente, fInicio, fFin), getHistoricoMetas(user.CodVendedor, fInicio, fFin, 'no')])

      ventasxZona = await resVentasZona
      cantidadVentas = await resCantidadVentas
      histMetas = await resHistoricoMetas

      let metasSum = 0
      let vendidoSum = 0

      for (let i = 0; i < ventasxZona.length; i++) {
        metasSum += ventasxZona[i].meta_zona;
      }

      for (let i = 0; i < histMetas.historicoMetas.length; i++) {
        vendidoSum += histMetas.historicoMetas[i].totalVendido;
      }

      setMontoProductos(vendidoSum)
      setBarChartVentas({
        options: {
          chart: {
            id: "chart-VentasZona",
          },
          xaxis: {
            categories: histMetas.historicoMetas.map((Zona) => `${Zona.Sector}`)
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            }
          },
          colors: ['#0C2C73'],
          dataLabels: {
            formatter: function (val, opt) {
              const goals =
                opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                  .goals
              if (goals && goals.length) {
                return `${val}`
              }
              return val
            },
            enabled: true,
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ['Ventas logradas en el mes'],
            markers: {
              fillColors: ['#0C2C73']
            }
          }
        },
        series: [
          {
            name: 'Ventas en USD',
            data: histMetas.historicoMetas.map((Zona) => ({
              x: Zona.Sector,
              y: Zona.totalVendido.toFixed(2)
            }))
          }
        ]
      })

      setTotalMeta(metasSum)
      setVentasProductos(cantidadVentas)
      setGeneralReport(true)
      setLoading(false)
    } else if (general === 'no') {
      if (mes === threeMonthsName) {
        fInicio = start3Months
        fFin = end3Months
        setWhichMonthLoad('3mes')
        setLoadingText(`reporte de estadisticas general de ${threeMonthsName}...`)
      } else if (mes === twoMonthsName) {
        fInicio = start2Months
        fFin = end2Months
        setWhichMonthLoad('2mes')
        setLoadingText(`reporte de estadisticas general de ${twoMonthsName}...`)
      } else {
        fInicio = start1Month
        fFin = end1Month
        setWhichMonthLoad('1mes')
        setLoadingText(`reporte de estadisticas general de ${oneMonthName}...`)
      }

      [resVentasZona, resCantidadVentas, resHistoricoMetas, resHistoricoMetasVendedores, resClientesFacturados, resTotalClientes] = await Promise.all([getVentasZona(user.CodGerente), getCantidadVentasxGerente(user.CodGerente, fInicio, fFin, zona), getHistoricoMetas(user.CodGerente, fInicio, fFin, 'no', zona), getHistoricoMetas(user.CodGerente, fInicio, fFin, 'si', zona), getClientesAtendidosxZona(user.CodGerente, fInicio, fFin, zona), getTotalClientesxSupervisor(user.CodGerente, zona)])

      ventasxZona = await resVentasZona
      cantidadVentas = await resCantidadVentas
      histMetas = await resHistoricoMetas
      histMetasVendedores = await resHistoricoMetasVendedores
      clientesFacturados = await resClientesFacturados
      totalClientes = await resTotalClientes

      setBarChartVentas({
        options: {
          chart: {
            id: "chart-VentasZona",
          },
          xaxis: {
            categories: histMetas.historicoMetas.map((Zona) => `${Zona.Sector}`)
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            }
          },
          colors: ['#0C2C73'],
          dataLabels: {
            formatter: function (val, opt) {
              const goals =
                opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                  .goals
              if (goals && goals.length) {
                return `${val}`
              }
              return val
            },
            enabled: true,
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ['Ventas logradas en el mes'],
            markers: {
              fillColors: ['#0C2C73']
            }
          }
        },
        series: [
          {
            name: 'Ventas en USD',
            data: histMetas.historicoMetas.map((Zona) => ({
              x: Zona.Sector,
              y: Zona.totalVendido.toFixed(2)
            }))
          }
        ]
      })

      setTopDataMetas(
        {
          options: {
            chart: {
              id: "historicoTopData1",
              type: 'bar',
            },
            xaxis: {
              categories: histMetasVendedores.historicoMetas.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: false,
              position: 'bottom'
            },
            dataLabels: {
              enabled: true,
              style: {
                colors: ['#000']
              }
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: histMetasVendedores.historicoMetas.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: Vendedor.totalVendido.toFixed(2),
              }))
            }
          ]
        }
      )

      // Donut chart de clientes facturados en el mes
      setClientesChart({
        options:
        {
          labels: clientesFacturados.map((Zona) => `${Zona.CodVendedor} - ${Zona.Nombre}`), // Replace with your own labels
          legend: {
            show: false,
            position: "bottom",
          },
          plotOptions: {
            pie: {
              donut: {
                size: "70%",
                labels: {
                  show: true
                }
              },
              dataLabels: {
                enabled: true,
              },
            },
          },
        },
        series: clientesFacturados.map((Zona) => Zona.uniqueClientes)
      })

      let atendidos = 0;
      for (let i = 0; i < clientesFacturados.length; i++) {
        atendidos += clientesFacturados[i].uniqueClientes
      }

      setTotalMeta(histMetas.totalMetas[0].metaTotalGerente)
      setMontoProductos(histMetas.historicoMetas[0].totalVendido)
      setVentasProductos(cantidadVentas)
      setTotalClientes(totalClientes[0].totalClientes)
      setClientesAtendidos(clientesFacturados)
      setTotalAtendidos(atendidos)

      setGeneralReport(false)
      setLoading(false)
    }
  }

  const loadHistorico1erMes = async () => {
    setLoading(true)
    setLoadingText(`reporte de estadisticas de ${oneMonthName}...`)
    const [resVentasZona, resVentasCantidad, resHistoricoMetas, resHistoricoMetasVendedores, resZonasSup] = await Promise.all([getVentasZona(user.CodVendedor), getCantidadVentasxGerente(user.CodVendedor, start1Month, end1Month), getHistoricoMetas(user.CodVendedor, start1Month, end1Month, 'no'), getHistoricoMetas(user.CodVendedor, start1Month, end1Month, 'si'), getZonasSupervisor(user.CodVendedor)])

    const ventasxZona = await resVentasZona;
    const ventasCantidad = await resVentasCantidad;
    const histMetas = await resHistoricoMetas;
    const histMetasVendedores = await resHistoricoMetasVendedores;
    const zonasSup = await resZonasSup;

    let metasSum = 0
    let vendidoSum = 0

    for (let i = 0; i < ventasxZona.length; i++) {
      metasSum += ventasxZona[i].meta_zona;
    }

    for (let i = 0; i < histMetas.length; i++) {
      vendidoSum += histMetas[i].totalVendido;
    }

    setTotalMeta(metasSum)
    setMontoProductos(vendidoSum)
    setVentasProductos(ventasCantidad)

    const separatedData = histMetasVendedores.reduce((result, item) => {
      if (!result[item.Sector]) {
        result[item.Sector] = [];
      }
      result[item.Sector].push(item);
      return result;
    }, {});

    // S01 ANDINA, OCCIDENTE, CENTRO - OCCIDENTE - S02 CENTRO - S03 CENTRO - OCCIDENTE, LLANOS, CENTRO - S04 ORIENTE
    let topData1, topData2, topData3, topData4, topData5, topData6, topData7, topData8;

    if (user.CodVendedor === 'S01') {
      topData1 = separatedData["Andina"];
      topData2 = separatedData["Falcón"];
      topData3 = separatedData["Zulia"];
    } else if (user.CodVendedor === 'S02') {
      topData1 = separatedData["Gran Caracas"];
    } else if (user.CodVendedor === 'S03') {
      topData1 = separatedData["Centro"];
      topData2 = separatedData["Centro - Occidente"];
      topData3 = separatedData["Llanos"];
    } else if (user.CodVendedor === 'S04') {
      topData1 = separatedData["Oriente"];
    } else {
      topData1 = separatedData["Andina"];
      topData2 = separatedData["Falcón"];
      topData3 = separatedData["Zulia"];
      topData4 = separatedData["Gran Caracas"];
      topData5 = separatedData["Centro"];
      topData6 = separatedData["Centro - Occidente"];
      topData7 = separatedData["Llanos"];
      topData8 = separatedData["Oriente"];
    }


    setLoading(false)
  }

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div className="text-center">
                  <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div className="loader-book">
                      <div>
                        <ul>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                      <span>Cargando {loadingText}</span>
                    </div>
                  </div>
                </div>
              </> :
              <>

                {/* Div para movil */}
                <div className="text-center d-sm-block d-md-none">
                  {/* <img width={80} src={belmenyLogo} alt="Logo Belmeny Group" className='text-center mt-3 drop-shadow' /> */}
                  <br />
                  <img src={notFoundImg} className="mx-auto w-100" alt="No se ha podido cargar la imagen..." />
                </div>

                {/* Div para web */}
                <div className='d-none d-md-block'>
                  <div className="container-fluid rounded">
                    <div className="row">
                      <div className="col">
                        <div className="text-belmeny">
                          <h2 className='fs-1'><strong>Bienvenido</strong></h2>
                          <h3>{user.Nombre}</h3>
                          <h5><i>{username}</i></h5>
                        </div>
                      </div>
                      <div className="col">
                        <img src={userIcon} alt="Logo Belmeny Group" className='float-end me-5 drop-shadow-sm' />
                      </div>
                    </div>

                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Estadisticas de ventas</h4>
                    </div>

                    <div className="row mt-4">
                      {
                        zonasSupervisor.map((zona) =>
                          <div className='col'>
                            <button className='buttonMonths w-100' onClick={(e) => setZonaSelected(zona.Sector)}><span>{zona.Sector}</span></button>
                          </div>)
                      }
                    </div>

                    <div className="row my-4">
                      <div className="col-3">
                        <button className='buttonMonths' onClick={(e) => setMesSelected(threeMonthsName)}><span>Cargar datos de</span> <span className="text-capitalize">{threeMonthsName}</span></button>
                      </div>
                      <div className="col-3">
                        <button className='buttonMonths' onClick={(e) => setMesSelected(twoMonthsName)}><span>Cargar datos de</span> <span className="text-capitalize">{twoMonthsName}</span></button>
                      </div>
                      <div className="col-3">
                        <button className='buttonMonths' onClick={(e) => setMesSelected(oneMonthName)}><span>Cargar datos de</span> <span className="text-capitalize">{oneMonthName}</span></button>
                      </div>
                    </div>

                    <div className="row mt-4 mb-3">
                      <div className="col-3">
                        <div className="row">
                          <div className="col">
                            <h5 className="text-belmeny">Zona Seleccionada: {zonaSelected}</h5>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col">
                            <h5 className="text-belmeny">Mes Seleccionado: <span className="text-capitalize">{mesSelected}</span></h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      (zonaSelected !== 'Ningúna...' && mesSelected !== 'Ningúno...') ?
                        <div className="row mb-5">
                          <div className="col">
                            <button className='buttonMonths' onClick={(e) => generateStats(zonaSelected, mesSelected, 'no')}><span>Generar Estadísticas de {zonaSelected}</span></button>
                            <div className="my-3"></div>
                            <button className='buttonMonths' onClick={(e) => generateStats(zonaSelected, mesSelected, 'si')}><span>Generar Estadísticas General</span></button>
                          </div>
                        </div> : null
                    }

                    <h4 className="text-center text-belmeny mb-4">{(generalReport) ? 'Reporte de estadísticas generales' : `Reporte de estadísticas de la zona ${zonaSelected}`}</h4>

                    {
                      (generalReport) ?
                        <>
                          {/* CHART DE VENTAS EN EL MES X ZONAS */}
                          <div className="row">
                            <div className="col">
                              <div className="h-100 bg-white p-3 rounded drop-shadow-card">
                                <h4 className='text-belmeny ps-3'>Ventas en total del mes {(whichMonthLoad === '1mes') ? <span className="text-uppercase">{oneMonthName}</span> : (whichMonthLoad === '2mes') ? <span className="text-uppercase">{twoMonthsName}</span> : <span className="text-uppercase">{threeMonthsName}</span>}</h4>
                                <Chart
                                  options={barChartVentas.options}
                                  series={barChartVentas.series}
                                  type="bar"
                                  width="100%"
                                  height="300px"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="division my-5"></div>

                          {/* CHART DE VENTAS X MARCA */}
                          <div className="row">
                            <div className="col">
                              <div className="m-auto bg-white p-3 rounded drop-shadow-card h-100">
                                <h4 className="text-belmeny mb-3">Ventas por marca del mes: {(whichMonthLoad === '1mes') ? <span className="text-uppercase">{oneMonthName}</span> : (whichMonthLoad === '2mes') ? <span className="text-uppercase">{twoMonthsName}</span> : <span className="text-uppercase">{threeMonthsName}</span>}
                                </h4>
                                <h5 className="text-belmeny my-3">Total vendido en el mes: <span className="text-success fw-bold">${montoProductos.toFixed(2)}</span></h5>
                                <div className="container-fluid h-100">
                                  <div className="row bg-lighten py-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase text-belmeny">Marca</h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-uppercase text-belmeny text-center">Productos Vendidos</h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-uppercase text-belmeny text-center">Ventas Generadas</h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-uppercase text-belmeny text-center">% Representado</h5>
                                    </div>
                                  </div>
                                  {/* VERT */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase pt-1"><img src={vertSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal">
                                        {
                                          (ventasProductos.vert[0].CantidadProductos === null || ventasProductos.vert[0].CantidadProductos === 0) ? 0 : ventasProductos.vert[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                        {
                                          (ventasProductos.vert[0].TotalVendidoProductos === null || ventasProductos.vert[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.vert[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                        {
                                          ((((ventasProductos.vert[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.vert[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.vert[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* INGCO */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase"><img src={ingcoSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal">
                                        {
                                          (ventasProductos.ingco[0].CantidadProductos === null || ventasProductos.ingco[0].CantidadProductos === 0) ? 0 : ventasProductos.ingco[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal text-success">
                                        {
                                          (ventasProductos.ingco[0].TotalVendidoProductos === null || ventasProductos.ingco[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.ingco[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal text-info">
                                        {
                                          ((((ventasProductos.ingco[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.ingco[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.ingco[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* QUILOSA */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase"><img src={quilosaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal">
                                        {
                                          (ventasProductos.quilosa[0].CantidadProductos === null || ventasProductos.quilosa[0].CantidadProductos === 0) ? 0 : ventasProductos.quilosa[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                        {
                                          (ventasProductos.quilosa[0].TotalVendidoProductos === null || ventasProductos.quilosa[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.quilosa[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                        {
                                          ((((ventasProductos.quilosa[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.quilosa[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.quilosa[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* IMOU */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase pt-1"><img src={imouSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal">
                                        {
                                          (ventasProductos.imou[0].CantidadProductos === null || ventasProductos.imou[0].CantidadProductos === 0) ? 0 : ventasProductos.imou[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                        {
                                          (ventasProductos.imou[0].TotalVendidoProductos === null || ventasProductos.imou[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.imou[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                        {
                                          ((((ventasProductos.imou[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.imou[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.imou[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* FLEXIMATIC */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase pt-1"><img src={fleximaticSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal">
                                        {
                                          (ventasProductos.fleximatic[0].CantidadProductos === null || ventasProductos.fleximatic[0].CantidadProductos === 0) ? 0 : ventasProductos.fleximatic[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                        {
                                          (ventasProductos.fleximatic[0].TotalVendidoProductos === null || ventasProductos.fleximatic[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.fleximatic[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                        {
                                          ((((ventasProductos.fleximatic[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.fleximatic[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.fleximatic[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* CORONA */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase pt-1"><img src={coronaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal">
                                        {
                                          (ventasProductos.corona[0].CantidadProductos === null || ventasProductos.corona[0].CantidadProductos === 0) ? 0 : ventasProductos.corona[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal text-success">
                                        {
                                          (ventasProductos.corona[0].TotalVendidoProductos === null || ventasProductos.corona[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.corona[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal text-info">
                                        {
                                          ((((ventasProductos.corona[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.corona[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.corona[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </> :
                        <>
                          <div className="row">
                            {/* CHART DE VENTAS X MARCA */}
                            <div className="col">
                              <div className="m-auto bg-white p-3 rounded drop-shadow-card h-100">
                                <h4 className="text-belmeny mb-3">Ventas por marca del mes: {(whichMonthLoad === '1mes') ? <span className="text-uppercase">{oneMonthName}</span> : (whichMonthLoad === '2mes') ? <span className="text-uppercase">{twoMonthsName}</span> : <span className="text-uppercase">{threeMonthsName}</span>}
                                </h4>
                                <h5 className="text-belmeny my-3">Total vendido en el mes: <span className="text-success fw-bold">${montoProductos.toFixed(2)}</span></h5>
                                <div className="container-fluid h-100">
                                  <div className="row bg-lighten py-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase text-belmeny">Marca</h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-uppercase text-belmeny text-center">Productos Vendidos</h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-uppercase text-belmeny text-center">Ventas Generadas</h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-uppercase text-belmeny text-center">% Representado</h5>
                                    </div>
                                  </div>
                                  {/* VERT */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase pt-1"><img src={vertSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal">
                                        {
                                          (ventasProductos.vert[0].CantidadProductos === null || ventasProductos.vert[0].CantidadProductos === 0) ? 0 : ventasProductos.vert[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                        {
                                          (ventasProductos.vert[0].TotalVendidoProductos === null || ventasProductos.vert[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.vert[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                        {
                                          ((((ventasProductos.vert[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.vert[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.vert[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* INGCO */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase"><img src={ingcoSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal">
                                        {
                                          (ventasProductos.ingco[0].CantidadProductos === null || ventasProductos.ingco[0].CantidadProductos === 0) ? 0 : ventasProductos.ingco[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal text-success">
                                        {
                                          (ventasProductos.ingco[0].TotalVendidoProductos === null || ventasProductos.ingco[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.ingco[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal text-info">
                                        {
                                          ((((ventasProductos.ingco[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.ingco[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.ingco[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* QUILOSA */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase"><img src={quilosaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal">
                                        {
                                          (ventasProductos.quilosa[0].CantidadProductos === null || ventasProductos.quilosa[0].CantidadProductos === 0) ? 0 : ventasProductos.quilosa[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                        {
                                          (ventasProductos.quilosa[0].TotalVendidoProductos === null || ventasProductos.quilosa[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.quilosa[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                        {
                                          ((((ventasProductos.quilosa[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.quilosa[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.quilosa[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* IMOU */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase pt-1"><img src={imouSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal">
                                        {
                                          (ventasProductos.imou[0].CantidadProductos === null || ventasProductos.imou[0].CantidadProductos === 0) ? 0 : ventasProductos.imou[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                        {
                                          (ventasProductos.imou[0].TotalVendidoProductos === null || ventasProductos.imou[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.imou[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                        {
                                          ((((ventasProductos.imou[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.imou[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.imou[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* FLEXIMATIC */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase pt-1"><img src={fleximaticSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal">
                                        {
                                          (ventasProductos.fleximatic[0].CantidadProductos === null || ventasProductos.fleximatic[0].CantidadProductos === 0) ? 0 : ventasProductos.fleximatic[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                        {
                                          (ventasProductos.fleximatic[0].TotalVendidoProductos === null || ventasProductos.fleximatic[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.fleximatic[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                        {
                                          ((((ventasProductos.fleximatic[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.fleximatic[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.fleximatic[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                  {/* CORONA */}
                                  <div className="row mt-3">
                                    <div className="col-3">
                                      <h5 className="text-uppercase pt-1"><img src={coronaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal">
                                        {
                                          (ventasProductos.corona[0].CantidadProductos === null || ventasProductos.corona[0].CantidadProductos === 0) ? 0 : ventasProductos.corona[0].CantidadProductos
                                        }
                                        <span className="ps-1">Productos</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal text-success">
                                        {
                                          (ventasProductos.corona[0].TotalVendidoProductos === null || ventasProductos.corona[0].TotalVendidoProductos === 0) ? 0 : ventasProductos.corona[0].TotalVendidoProductos.toFixed(2)
                                        }
                                        <span className="ps-1">USD</span></h5>
                                    </div>
                                    <div className="col">
                                      <h5 className="text-center text-uppercase pt-2 fw-normal text-info">
                                        {
                                          ((((ventasProductos.corona[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === null || (((ventasProductos.corona[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)) === 0) ? 0 : ((ventasProductos.corona[0].TotalVendidoProductos * 100) / montoProductos).toFixed(2)
                                        }%</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* CHART DE VENTAS EN EL MES X ZONAS */}
                            <div className="col-4">
                              <div className="h-100 bg-white p-3 rounded drop-shadow-card">
                                <h4 className='text-belmeny ps-3'>Ventas en total del mes <span className="text">{(whichMonthLoad === '1mes') ? <span className="text-uppercase">{oneMonthName}</span> : (whichMonthLoad === '2mes') ? <span className="text-uppercase">{twoMonthsName}</span> : <span className="text-uppercase">{threeMonthsName}</span>}</span></h4>
                                <h5 className="text-belmeny ps-3">Meta de la zona: <span className="text-success fw-bold">${totalMeta}</span></h5>
                                <Chart
                                  options={barChartVentas.options}
                                  series={barChartVentas.series}
                                  type="bar"
                                  width="95%"
                                  height="100%"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="division my-5"></div>


                          <div className="row">
                            {/* CHART DE TOP DE VENDEDORES */}
                            <div className="col drop-shadow-card me-2 bg-white">
                              <h4 className="text-center text-belmeny fst-italic mt-3">Top vendedores zona: {zonaSelected}</h4>
                              <Chart
                                options={topDataMetas.options}
                                series={topDataMetas.series}
                                type="bar"
                                width="100%"
                              />
                            </div>
                            {/* CHART DE CLIENTES ATENDIDOS */}
                            <div className="col-4 drop-shadow-card me-2 bg-white">
                              <h4 className="text-center text-belmeny fst-italic mt-3">Clientes facturados en el mes</h4>
                              <h5 className="text-center text-belmeny mt-1">Total de clientes: {totalClientes}</h5>
                              <Chart
                                options={clientesChart.options}
                                series={clientesChart.series}
                                onError={handleChartError}
                                type="donut"
                                width="80%"
                                className="d-flex align-middle justify-content-center"
                              />
                              <div className="row text-center">
                                {
                                  clientesAtendidos.map((item, idx) =>
                                    <>
                                      <div className="col-8 mx-auto" key={item.CodVendedor}>
                                        <div className="row">
                                          <div className="col text-start">
                                            <span className="text-belmeny fw-bold">{item.CodVendedor} {item.Nombre}</span>:
                                          </div>
                                          <div className="col">
                                            <span className="text-danger fw-bold">{item.uniqueClientes}</span> ≈ <span className="text-danger fw-bold">{((item.uniqueClientes * 100) / totalClientes).toFixed(2)}%</span> Facturado(s)
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </div>
                              <div className="row text-center mt-2 pb-1">
                                <div className="col">Atendidos: <span className="text-belmeny fw-bold">{totalAtendidos}</span> ≈ <span className="text-belmeny fw-bold">{((totalAtendidos * 100) / totalClientes).toFixed(2)}%</span></div>
                                <div className="col">Desatendidos: <span className="text-danger fw-bold">{(totalClientes - totalAtendidos)}</span> ≈ <span className="text-danger fw-bold">{(((totalClientes - totalAtendidos) * 100) / totalClientes).toFixed(2)}%</span></div>
                              </div>
                            </div>
                          </div>
                        </>
                    }

                    <div className="division my-5"></div>

                    <div className="row">
                      <div className="px-2">
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </Layout >
    </>
  )
}
