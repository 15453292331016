import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';

import AuthUser from '../../../components/AuthUser'
import Layout from '../../../components/Layout';
import { Searchbar } from '../../../components/Searchbar';
import { DetalleConsultaProductos } from './DetalleConsultaProductos';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Pagination from 'react-responsive-pagination';
import { Dropdown, DropdownButton, Collapse, Modal, Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import onlyAvailableOnDesktop from '../../../assets/img/404-only-available-on-desktop.webp'

import { RequestPage } from '../../../api/request';
import { getClientesAndZonaByCodigo, getClientesAndZonaByNombre, getClientesByZonaCodigoAndTerm, getClientesByZonaNombreAndTerm, getZonasClientes } from '../../../api/requestClientes';
import { set } from 'date-fns/esm';

export const ConsultaProductos = () => {

  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(false)
  const [reloadWarning, setReloadWarning] = useState(<><h5 className="text-center text-belmeny">Tardando mucho? <Link className='btn btn-sm btn-primary' onClick={(e) => window.reload.location()}>Prueba con otra búsqueda</Link></h5></>)

  // States de busqueda
  const [zonaTerm, setZonaTerm] = useState('Ninguna...')
  const [searchTerm, setSearchTerm] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [found, setFound] = useState(false);
  const [clientFound, setClientFound] = useState([]);
  const [foundCliente, setFoundCliente] = useState([]);
  const [foundByCliente, setFoundByCliente] = useState(false);

  // States de filtros
  const [searchbarCodigo, setSearchbarCodigo] = useState('d-block')
  const [searchbarNombre, setSearchbarNombre] = useState('d-none')

  //States de datos
  const [zona, setZona] = useState([])
  const [producto, setProducto] = useState([])
  const [clientes, setClientes] = useState([])

  // Sweet alert
  const MySwal = withReactContent(Swal)
  const customSwal = MySwal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ms-2',
      denyButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const loadPage = async (pageData) => {
    setLoading(true)

    const productoData = await RequestPage(pageData);

    var arrProducto = []
    var data = {}

    for (let i = 0; i < productoData.data.length; i++) {
      data = {
        Cliente: productoData.data[i].Cliente,
        NombreCliente: productoData.data[i].NombreCliente,
        Zona: productoData.data[i].Zona,
        SubZona: productoData.data[i].SubZona,
        DireccionDespacho: productoData.data[i].DireccionDespacho,
        CodigoVendedor: productoData.data[i].CodigoVendedor,
        NombreVendedor: productoData.data[i].Vendedor,
        CodProducto: productoData.data[i].CodProducto,
        NomProducto: productoData.data[i].NomProducto,
        Cantidad: productoData.data[i].Cantidad,
        fechadoc: productoData.data[i].fechadoc,
        RutaImagen: productoData.data[i].RutaImagen,
      }
      arrProducto.push(data)
    }

    setPage(productoData.current_page)
    setCurrentPage(productoData.current_page)
    setTotalPages(productoData.last_page)
    setUrlPage(productoData.first_page_url)

    setProducto(arrProducto)
    setLoading(false)
  }

  const showFilter = (e, filtro) => {
    e.preventDefault();
    switch (filtro) {
      case 'Codigo':
        setSearchbarCodigo('d-block')
        setSearchbarNombre('d-none')
        break;
      case 'Nombre':
        setSearchbarCodigo('d-none')
        setSearchbarNombre('d-block')
        break;

      case 'Limpiar':
        setSearchbarCodigo('d-none')
        setSearchbarNombre('d-none')
        break;

      default:
        setSearchbarCodigo('d-none')
        setSearchbarNombre('d-none')
        break;
    }
  }

  const onSearch = async (busqueda) => {
    if (searchbarCodigo === 'd-block') {
      //Búsqueda por código
      setLoading(true)
      if (!busqueda) {
        setFound(false)
        setLoading(false)
      } else if (zonaTerm === 'Ninguna...' && user.CodGerente === 'MARKETINGBELMENY') {
        customSwal.fire({
          title: <strong>No se ha seleccionado una zona...</strong>,
          html: <i>Seleccione una zona y continue con la búsqueda por favor.</i>,
          icon: 'question'
        })
      } else {
        setLoading(true)
        setNotFound(false)
        setSearching(true)
        setSearchTerm(busqueda)
        setTimeout(() => {
          setTimer(true)
        }, 60000);
        const codeSearch = await getClientesByZonaCodigoAndTerm(busqueda, zonaTerm, user.CodGerente, ZonasVenta)
        console.log(codeSearch)
        if (!codeSearch) {
          setNotFound(true)
          setLoading(false)
          setFound(false)
          setTimer(false)
          return
        } else if (codeSearch.data.length === 0) {
          setFound(false)
          customSwal.fire({
            title: <strong>Producto no encontrado...</strong>,
            html: <i>Verifique el código ingresado e intente nuevamente</i>,
            icon: 'question'
          })
          setLoading(false)
        } else {
          // setZona(codeSearch.zonas.data)
          setProducto(codeSearch.data)
          setPage(codeSearch.current_page)
          setTotalPages(codeSearch.last_page)
          setUrlPage(codeSearch.first_page_url)
          setLoading(false)
          setTimer(false)
        }
      }
    }
    else if (searchbarNombre === 'd-block') {
      //Búsqueda por nombre
      setLoading(true)
      if (!busqueda) {
        setFound(false)
        setLoading(false)
      } else if (zonaTerm === 'Ninguna...' && user.CodGerente === 'MARKETINGBELMENY') {
        customSwal.fire({
          title: <strong>No se ha seleccionado una zona...</strong>,
          html: <i>Seleccione una zona y continue con la búsqueda por favor.</i>,
          icon: 'question'
        })
      } else {
        setLoading(true)
        setNotFound(false)
        setSearching(true)
        setSearchTerm(busqueda)
        setTimeout(() => {
          setTimer(true)
        }, 60000);
        const nameSearch = await getClientesByZonaNombreAndTerm(busqueda, zonaTerm, user.CodGerente, ZonasVenta)
        if (!nameSearch) {
          setNotFound(true)
          setLoading(false)
          setFound(false)
          setTimer(false)
          return
        } else if (nameSearch.data.length === 0) {
          setFound(false)
          customSwal.fire({
            title: <strong>Producto no encontrado...</strong>,
            html: <i>Verifique el código ingresado e intente nuevamente</i>,
            icon: 'question'
          })
          setLoading(false)
        } else {
          // setZona(nameSearch.zonas.data)
          setProducto(nameSearch.data)
          setPage(nameSearch.current_page)
          setTotalPages(nameSearch.last_page)
          setUrlPage(nameSearch.first_page_url)
          setLoading(false)
          setTimer(false)
        }
      }
    }
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    if (searchbarCodigo === 'd-block') {
      if (zonaTerm !== 'Ninguna...') {
        loadPage(urlPage.replace(/.$/, page + "&CodigoProducto=" + searchTerm + "&Zona=" + zonaTerm + "&CodUsuario=" + user.CodGerente))
      } else {
        loadPage(urlPage.replace(/.$/, page + "&CodigoProducto=" + searchTerm))
      }
    }
    else if (searchbarNombre === 'd-block') {
      if (zonaTerm !== 'Ninguna...') {
        loadPage(urlPage.replace(/.$/, page + "&NombreProducto=" + searchTerm + "&Zona=" + zonaTerm + "&CodUsuario=" + user.CodGerente))
      } else {
        loadPage(urlPage.replace(/.$/, page + "&NombreProducto=" + searchTerm))
      }
    }
  }

  const productosByZona = async (Subzona) => {
    setLoading(true)
    if (searchbarCodigo === 'd-block') {
      setZonaTerm(Subzona)
      const resZona = await getClientesByZonaCodigoAndTerm(searchTerm, Subzona, user.CodGerente, ZonasVenta)
      setProducto(resZona.data)
      setPage(resZona.current_page)
      setTotalPages(resZona.last_page)
      setUrlPage(resZona.first_page_url)
      setLoading(false)
    }
    else if (searchbarNombre === 'd-block') {
      setZonaTerm(Subzona)
      const resZona = await getClientesByZonaNombreAndTerm(searchTerm, Subzona, user.CodGerente, ZonasVenta)
      setProducto(resZona.data)
      setPage(resZona.current_page)
      setTotalPages(resZona.last_page)
      setUrlPage(resZona.first_page_url)
      setLoading(false)
    }
  }

  const handleZona = (Zona) => {
    if (zonaTerm === 'Ninguna...') {
      setZonaTerm(Zona)
    } else {
      productosByZona(Zona)
    }
  }

  const loadZonasClientes = async () => {
    const zonasCli = await getZonasClientes();
    setZona(zonasCli)
  }

  useEffect(() => {
    setTimeout(() => {
      loadZonasClientes()
      setLoading(false)
    }, 2000);
  }, []);

  return (
    <>
      <Layout>

        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  <span className="loader-documentos"></span>
                  <h4 className="text-center text-belmeny">Cargando información, espere un momento...</h4>
                  {
                    (timer) ?
                      <>
                        <h5 className="text-center text-belmeny">Tardando mucho? <button className='text-decoration-none' onClick={(e) => window.reload.location()}>Prueba con otra búsqueda</button></h5>
                      </> : <></>
                  }
                </div>
              </> :
              <>
                {/* Dashboard for web version */}
                <div className='d-none d-md-block d-lg-block'>
                  <div className="container-fluid rounded">
                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Consulta de Productos</h4>
                    </div>

                    <h5 className="text-center mt-2 mb-4 text-belmeny"><i>Usuario - {user.Nombre} - {user.CodVendedor}</i></h5>

                    <div className="mt-5">
                      <div className='p-5 my-3 rounded border-belmeny'>


                        {/* Search Button */}
                        <div className="row mt-3">
                          <div className="col-10">
                            <div className="my-3">
                              <DropdownButton
                                key={'end'}
                                id={`dropdown-button-drop-${'end'}`}
                                drop={'end'}
                                variant="primary"
                                title={` Búscar Productos `}
                              >
                                <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Codigo')}>Por Código</Dropdown.Item>
                                <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Nombre')}>Por Nombre</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar</Dropdown.Item>
                              </DropdownButton>
                              <div className="mt-3">
                                <div className={searchbarCodigo}>
                                  {
                                    (user.CodGerente === 'MARKETINGBELMENY') ?
                                      <h5 className='my-2 ms-2 text-belmeny'>Zona seleccionada: {zonaTerm}</h5>
                                      : <></>
                                  }
                                  <h5 className='my-2 ms-2 text-belmeny'>Búsqueda por Código</h5>
                                  <Searchbar onSearch={onSearch} />
                                </div>
                                <div className={searchbarNombre}>
                                  {
                                    (user.CodGerente === 'MARKETINGBELMENY') ?
                                      <h5 className='my-2 ms-2 text-belmeny'>Zona seleccionada: {zonaTerm}</h5>
                                      : <></>
                                  }
                                  <h5 className='my-2 ms-2 text-belmeny'>Búsqueda por Nombre</h5>
                                  <Searchbar onSearch={onSearch} />
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            (user.CodGerente === 'MARKETINGBELMENY') ?
                              <>
                                <div className="col-2">
                                  <div className="my-3 text-end">
                                    <DropdownButton
                                      key={'start'}
                                      id={`dropdown-button-drop-${'start'}`}
                                      drop={'start'}
                                      variant="primary"
                                      title={` Zona `}
                                    >
                                      {
                                        zona.map((item) => <>
                                          <Dropdown.Item eventKey={item.Zona} className='text-decoration-none' onClick={(e) => handleZona(item.Zona)}>{item.Zona}</Dropdown.Item>
                                        </>
                                        )
                                      }
                                    </DropdownButton>
                                  </div>
                                </div>
                              </> :
                              <>
                              </>
                          }
                        </div>

                        {/* Lista de productos */}
                        {
                          (producto.length !== 0) ?
                            <>
                              <div className="rounded-0 my-2">
                                <table className='table table-bordered table-hover border-belmeny '>
                                  <thead className='bg-belmeny text-light'>
                                    <tr className='text-center'>
                                      {
                                        (user.CodGerente === 'MARKETINGBELMENY') ?
                                          <>
                                            <th>RIF</th>
                                            <th>Aliado</th>
                                            <th style={{ width: '10%' }}>Zona</th>
                                            <th>Dirección</th>
                                            <th>Imagén</th>
                                            <th>Código</th>
                                            <th>Producto</th>
                                            <th>Existencia</th>
                                            <th>Cantidad Comprada</th>
                                            <th>Última Compra</th>
                                          </> :
                                          <>
                                            <th>Imagén</th>
                                            <th>Código</th>
                                            <th>Producto</th>
                                            <th>Existencia</th>
                                            <th>Venta Minima</th>
                                          </>
                                      }

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {producto.map((item) => <DetalleConsultaProductos item={item} key={item.Codigo} />)}
                                  </tbody>
                                </table>
                              </div>
                              <Pagination
                                total={totalPages}
                                current={currentPage}
                                onPageChange={page => handlePageChange(page)}
                              />
                            </> :
                            <>
                              <h4 className="text-center text-belmeny">¡Para empezar a búscar productos haz click en el filtro y escoge por código o nombre!</h4>
                            </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }

        </div>
      </Layout>
    </>
  )
}
