import React, { useState } from 'react'

import { getCobranzaVendedor } from '../../../api/requestCobranzas';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import { Modal } from 'react-bootstrap'
import { RiCloseCircleFill } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";

export const DetalleMasterCobranzas = (props) => {

  var CodigoVendedor = props.vendedor;
  var totalFacturado = props.totalFacturado
  var totalPendiente = props.totalPendiente
  var totalPagado = props.totalPagado
  var btnShow = props.show

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // State del loading
  const [loading, setLoading] = useState(true);

  // States de datos
  const [vendedor, setVendedor] = useState([]);
  const [chartData, setChartData] = useState({});

  function handleShow(CodigoVendedor) {
    setFullscreen(true);
    setShow(true);
    loadReporte(CodigoVendedor);
  }

  const loadReporte = async (Codigo) => {
    setLoading(true)
    const res = await getCobranzaVendedor(Codigo)
    // console.log(res)
    setVendedor(res[0])

    setChartData({
      labels: [res[0].Nombre],
      datasets: [
        {
          data: [res[0].Facturado],
          label: `Facturado`,
          backgroundColor: '#2980b9',
          stack: 'Stack 0',
        },
        {
          data: [res[0].Pendiente],
          label: `Pendiente`,
          backgroundColor: '#e74c3c',
          stack: 'Stack 1',
        },
        {
          data: [res[0].Pagado],
          label: `Pagado`,
          backgroundColor: '#2ecc71',
          stack: 'Stack 2',
        },
      ]
    })

    setLoading(false)
  }

  return (
    <>
      {/* onClick={(e) => handleShow(item.CodigoVendedor)} */}
      <SlOptionsVertical className='text-belmeny fs-5 float-end d-sm-block d-md-none' style={{ cursor: 'pointer' }} onClick={(e) => handleShow(CodigoVendedor)} />
      <button className="btn btn-sm btn-primary w-100 my-2 d-none d-md-block" onClick={(e) => handleShow(CodigoVendedor)}>Ver Detalles</button>

      {/* Reporte de ventas vs cobrado por vendedor */}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>Reporte Ventas vs Pagado: {vendedor.Nombre}</Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-4 bg-belmeny"></div>
        <Modal.Body>

          <div className="container-fluid">
            {/* Div para escritorio */}
            <div className='d-none d-md-block d-lg-block'>
              <div className="row">
                <div className="col">
                  <div className="text-center">
                    <h4 className='badge-vendedor rounded-pill text-light m-auto' style={{ width: "20%", position: "absolute", left: "40%", top: "5%" }}>VENDEDOR</h4>
                    <h3 className='bg-belmeny rounded-pill text-light fw-light m-auto mt-5 pt-3' style={{ width: "60%" }}>{vendedor.Nombre}</h3>
                    <h3 className='text-center text-belmeny mt-3'>Ventas vs Pagado</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {
                    (loading) ?
                      <>
                        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                          <span className="loader-cobranzas"></span>
                        </div>
                      </> :
                      <>
                        <div className="w-50 m-auto">
                          <Chart type='bar' data={chartData} className='mt-4' />
                        </div>
                        <div className="row w-50 mx-auto">
                          <div className='list-container'>
                            <ol className="gradient-list">
                              <li><h6>Facturado:</h6> <span className="text-belmeny fw-bold">${vendedor.Facturado} ~ {((vendedor.Facturado * 100) / totalFacturado).toFixed(2)}%</span></li>
                              <li><h6>Pendiente:</h6> <span className="text-danger fw-bold">${vendedor.Pendiente} ~ {((vendedor.Pendiente * 100) / totalPendiente).toFixed(2)}%</span></li>
                              <li><h6>Pagado:</h6> <span className="text-success fw-bold">${vendedor.Pagado} ~ {((vendedor.Pagado * 100) / totalPagado).toFixed(2)}%</span></li>
                            </ol>
                          </div>
                        </div>
                      </>
                  }
                </div>
              </div>
              <div className="division mt-5 bg-belmeny"></div>
              <h3 className="text-end text-belmeny fst-italic mt-2 pe-5">Belmeny Group C.A</h3>
            </div>

            {/* Div para móvil */}
            <div className='d-sm-block d-md-none'>
              <div className="row">
                <div className="col">
                  <div className="text-center">
                    <h4 className='badge-vendedor rounded-pill text-light m-auto w-75'>VENDEDOR</h4>
                    <h4 className='bg-belmeny rounded-pill text-light fw-light m-auto mt-2 p-2'>{vendedor.Nombre}</h4>
                    <h3 className='text-center text-belmeny mt-3'>Ventas vs Pagado</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {
                    (loading) ?
                      <>
                        <div className="text-center">
                          <div className="m-auto spinner">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </div>
                        </div>
                      </> :
                      <>
                        <div className="m-auto">
                          <Chart type='bar' data={chartData} className='mt-4' />
                        </div>
                        <div className="row mx-auto">
                          <div className='list-container'>
                            <ol className="gradient-list">
                              <li><h6>Facturado:</h6> <span className="text-belmeny fw-bold">${vendedor.Facturado} ~ {((vendedor.Facturado * 100) / totalFacturado).toFixed(2)}%</span></li>
                              <li><h6>Pendiente:</h6> <span className="text-danger fw-bold">${vendedor.Pendiente} ~ {((vendedor.Pendiente * 100) / totalPendiente).toFixed(2)}%</span></li>
                              <li><h6>Pagado:</h6> <span className="text-success fw-bold">${vendedor.Pagado} ~ {((vendedor.Pagado * 100) / totalPagado).toFixed(2)}%</span></li>
                            </ol>
                          </div>
                        </div>
                      </>
                  }
                </div>
              </div>
              <div className="division bg-belmeny"></div>
              <h3 className="text-end text-belmeny fst-italic mt-2 pe-5">Belmeny Group C.A</h3>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
