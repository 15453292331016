import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { AuthUser } from './AuthUser';

// import belmenyLogo from '../assets/img/logo-png.webp';
import belmenyLogo from '../assets/img/logo-belmeny-group2.png';
import { FaBars } from 'react-icons/fa';
import { MdHome } from "react-icons/md";

export const Header = (props) => {

  var bgHeaderApplied = '';
  var bgBtnApplied = '';

  switch (props.background) {
    case 'vert':
      bgHeaderApplied = 'bg-header';
      bgBtnApplied = 'btn-hover';
      break;

    case 'ingco':
      bgHeaderApplied = 'bg-headerIngco';
      bgBtnApplied = 'btn-hoverIngco';
      break;

    case 'wadfow':
      bgHeaderApplied = 'bg-headerWadfow';
      bgBtnApplied = 'btn-hoverWadfow';
      break;

    case 'corona':
      bgHeaderApplied = 'bg-headerCorona';
      bgBtnApplied = 'btn-hoverCorona';
      break;

    case 'fleximatic':
      bgHeaderApplied = 'bg-headerFleximatic';
      bgBtnApplied = 'btn-hoverFleximatic';
      break;

    case 'quilosa':
      bgHeaderApplied = 'bg-headerQuilosa';
      bgBtnApplied = 'btn-hoverQuilosa';
      break;

    case 'imou':
      bgHeaderApplied = 'bg-headerImou';
      bgBtnApplied = 'btn-hoverImou';
      break;


    default:
      bgHeaderApplied = 'bg-header';
      bgBtnApplied = 'btn-hover';
      break;
  }

  const navigate = useNavigate();
  const { getToken } = AuthUser();

  const { token, logout, user } = AuthUser();

  const logoutUser = () => {
    if (token !== undefined) {
      logout();
      navigate('/login');
    }
  }

  var authButton, dashboardButton, catalogueButton;

  if (!getToken()) {
    dashboardButton = <Link to="/" className={`text-decoration-none btn ${bgBtnApplied} border-0`}><MdHome /></Link>
    catalogueButton = <Link to="/catalogo-portada" className={`text-decoration-none btn ${bgBtnApplied} border-0`}>Catálogo Productos</Link>
    authButton = <Link to="/login" className={`text-decoration-none btn ${bgBtnApplied} border-0`}>Iniciar sesión</Link>
  } else {
    if (user.CodVendedor !== null) {
      dashboardButton = <Link to="/dashboard" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Ir al panel de trabajo</Link>
    } else if (user.CodSupervisor === 'CONSULTA') {
      dashboardButton = <Link to="/consulta-productos" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Ir al panel de trabajo</Link>
    } else if (user.CodSupervisor !== null) {
      dashboardButton = <Link to="/panel-administrativo" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Ir al panel de trabajo</Link>
    } else if (user.CodGerente === 'master') {
      dashboardButton = <Link to="/register" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Ir al panel de trabajo</Link>
    } else if (user.CodGerente === 'HABOULMOUNA') {
      dashboardButton = <Link to="/dashboard-gerente" className={`text-decoration-none btn ${bgBtnApplied} text-light border-0`}>Ir al panel de trabajo</Link>
      catalogueButton = <Link to="/catalogo-portada" className={`text-decoration-none btn ${bgBtnApplied} border-0`}>Catálogo Productos</Link>
    }
    authButton = <Link onClick={logoutUser} to="/" className={`text-decoration-none btn ${bgBtnApplied} border-0`}>Cerrar sesión</Link>
  }

  return (
    <>
      {/* Header for mobile version */}
      <div className="text-center d-sm-block d-md-none">
        <div className="navbar navbar-expand-lg bg-belmeny-gradient">
          <div className="container-fluid">
            {/* Navbar Brand + Social Buttons */}
            <div className="navbar-brand">
              <img src={belmenyLogo} alt="Belmeny Logo" style={{ width: '30%' }} className='drop-shadow-sm float-start' />
            </div>
            <button className="navbar-toggler text-light border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navBar" aria-controls="navBar" aria-expanded="false" aria-label="Toggle navigation">
              <span className="text-light">
                <FaBars />
              </span>
            </button>
            <div className="collapse navbar-collapse" id="navBar">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  {dashboardButton}
                </li>
                {/* <li className="nav-item">
                  {catalogueButton}
                </li> */}
                <li className="my-2"></li>
                <li className="nav-item">
                  {authButton}
                </li>
                <li className="my-2"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Header for desktop version */}
      <div className='d-none d-md-block d-lg-block'>
        <Navbar className={`${bgHeaderApplied} py-2`}>
          <Container fluid>
            <Nav>
              {/* <Navbar.Brand> */}
              <img src={belmenyLogo} alt="Belmeny Logo" className='text-center drop-shadow-sm align-center ms-4' />
              {/* </Navbar.Brand> */}
            </Nav>
            <Nav className="ms-5">
              {dashboardButton}
            </Nav>
            {/*
              (getToken() && user.CodGerente === 'HABOULMOUNA') ?
                <Nav className="ms-3">
                  {catalogueButton}
                </Nav>
                : null
            */}
            <Nav className="ms-3">
              {catalogueButton}
            </Nav>
            <Nav className="ms-auto">
              {authButton}
            </Nav>
          </Container>
        </Navbar>
      </div>
    </>
  );
}
