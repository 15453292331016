import React from 'react'
import { IoReturnDownBack } from "react-icons/io5";
import { Link } from 'react-router-dom'

import notFoundImg from '../../assets/img/404-not-found-2.webp';

export const PageNotFound = () => {
  return (
    <div className="vertical-center bg-lighten">
		<div className="container">
			<div id="notfound" className="text-center">
				<img src={notFoundImg} alt="Not found img" className='mx-auto d-sm-block d-md-none' style={{width: "90%"}} />
				<img src={notFoundImg} alt="Not found img" className='mx-auto d-md-block d-none' />
				<br />
				<Link to="/" className='text-decoration-none btn btn-lg btn-danger rounded-pill mt-3'><IoReturnDownBack /> Ir al inicio</Link>
			</div>
		</div>
	</div>
  )
}
