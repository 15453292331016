import React, { useState, useEffect } from 'react'

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';
import { Searchbar } from '../../components/Searchbar';
import { DetalleCliente } from './DetalleCliente';

import { RiCloseCircleFill } from "react-icons/ri";
import Pagination from 'react-responsive-pagination';
import { Dropdown, DropdownButton, Modal } from 'react-bootstrap'

import { RequestPage } from '../../api/request';
import { getClientes, getAllClientes, SearchClienteRIF, SearchClienteNombre, getClientesAtendidosByVendedor, getClientesDesatendidosByVendedor } from '../../api/requestClientes';

import { addMonths } from 'date-fns';

export const ConsultaCliente = () => {

  // State del usuario
  const { user } = AuthUser();

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // States para los datos
  const [Cliente, setCliente] = useState([]);
  const [totalClientes, setTotalClientes] = useState(0)
  const [clientesAtendidos, setClientesAtendidos] = useState(0)
  const [clientesDesatendidos, setClientesDesatendidos] = useState(0)
  const [infoCliAtendidosOrDesatendidos, setInfoCliAtendidosOrDesatendidos] = useState([])

  const [whichLoad, setWhichLoad] = useState('');

  // Paginación
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  // State del loading
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(true);

  // States de busqueda
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [found, setFound] = useState(false);
  const [foundByCliente, setFoundByCliente] = useState(false);
  const [term, setTerm] = useState('');

  // States de filtros
  const [searchbarRif, setSearchbarRif] = useState('d-none')
  const [searchbarCli, setSearchbarCli] = useState('d-none')

  // States de fechas y utils
  // Fechas para saber el rango de clientes atendidos
  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  // Se asigna el mes actual por default
  const [inicio, setInicio] = useState(firstDay.toISOString().slice(0, 10) + " 00:00:00")
  const [fin, setFin] = useState(lastDay.toISOString().slice(0, 10) + " 23:59:59")
  const [dateShown, setDateShown] = useState('Mes actual')

  function handleShow(whichModal) {
    switch (whichModal) {
      case 'atendidos':
        setWhichLoad('atendidos')
        loadAtendidosOrDesatendidos('atendidos')
        setFullscreen(true);
        setShow(true);
        break;

      case 'desatendidos':
        setWhichLoad('desatendidos')
        loadAtendidosOrDesatendidos('desatendidos')
        setFullscreen(true);
        setShow(true);
        break;

      default:
        break;
    }

  }

  var arrCliente = []
  var data = {}

  const loadAllClientes = async (fechaInicio, fechaFin) => {
    setLoading(true)
    const res = await getAllClientes(user.CodVendedor, fechaInicio, fechaFin);

    var listaCli = res.listaClientes
    var atendidos = res.atendidos

    const updatedClientes = listaCli.map((cliente) => {
      const atendidosItem = atendidos.find((item) => item.Vendedor === cliente.CodVendedor);

      return {
        ...cliente,
        Atendidos: atendidosItem ? atendidosItem.Atendidos : 0,
      };
    });

    for (let i = 0; i < updatedClientes.length; i++) {
      data = {
        Vendedor: updatedClientes[i].CodVendedor,
        Nombre: updatedClientes[i].Nombre,
        ClientesAct: updatedClientes[i].Clientes_Activos,
        Atendidos: updatedClientes[i].Atendidos
      }
      arrCliente.push(data)
    }

    setPage(1)
    setTotalPages(1)

    setCliente(arrCliente)
    setLoading(false)
  }

  const loadClientes = async () => {
    setLoading(true)

    const [resClientes, resAtendidos, resDesatendidos] = await Promise.all([getClientes(user.CodVendedor), getClientesAtendidosByVendedor(user.CodVendedor, inicio, fin), getClientesDesatendidosByVendedor(user.CodVendedor, 'General')])
    setClientesAtendidos(resAtendidos.length)
    setClientesDesatendidos(resDesatendidos.length)

    setPage(resClientes.current_page)
    setTotalPages(resClientes.last_page)
    setUrlPage(resClientes.first_page_url)

    setTotalClientes(resClientes.total)
    setCliente(resClientes.data)
    setLoading(false)
  }

  const loadPage = async (pageData) => {
    setLoading(true)

    const clienteData = await RequestPage(pageData);

    for (let i = 0; i < clienteData.data.length; i++) {
      data = {
        Codigo: clienteData.data[i].Codigo,
        Empresa: clienteData.data[i].Empresa,
        Nombre: clienteData.data[i].Nombre,
        Rif: clienteData.data[i].Rif,
        Vendedor: clienteData.data[i].Vendedor,
        DireccionFiscal: clienteData.data[i].DireccionFiscal,
        Telefono1: clienteData.data[i].Telefono1,
        Limite: clienteData.data[i].Limite,
        Descuento: clienteData.data[i].Descuento,
        Dias: clienteData.data[i].Dias,
        Ventas: clienteData.data[i].Ventas,
        Cobranzas: clienteData.data[i].Cobranzas,
        Devolucion: clienteData.data[i].Devolucion,
        Catalogo: clienteData.data[i].Catalogo,
        SaldoPendiente: clienteData.data[i].SaldoPendiente,
        FechaDocumento: clienteData.data[i].FechaDocumento,
      }

      arrCliente.push(data)
    }

    setPage(clienteData.current_page)
    setTotalPages(clienteData.last_page)
    setUrlPage(clienteData.first_page_url)

    setCliente(arrCliente)
    setLoading(false)
  }

  const loadAtendidosOrDesatendidos = async (whichLoad) => {
    setLoadingModal(true)
    switch (whichLoad) {
      case 'atendidos':
        const resAtendidos = await getClientesAtendidosByVendedor(user.CodVendedor, inicio, fin)
        setInfoCliAtendidosOrDesatendidos(resAtendidos)
        break;

      case 'desatendidos':
        const resDesatendidos = await getClientesDesatendidosByVendedor(user.CodVendedor, 'General')
        setClientesDesatendidos(resDesatendidos.length)
        setInfoCliAtendidosOrDesatendidos(resDesatendidos)
        break;

      default:
        break;
    }
    setLoadingModal(false)
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    if (foundByCliente) {
      loadPage(urlPage.replace(/.$/, page + "&Vendedor=" + user.CodVendedor + "&Nombre=" + term))
    } else if (found) {
      loadPage(urlPage.replace(/.$/, page + "&Vendedor=" + user.CodVendedor + "&Codigo=" + term))
    } else {
      loadPage(urlPage.replace(/.$/, page + "&Vendedor=" + user.CodVendedor))
    }
  }

  const onSearch = async (busqueda) => {
    if (searchbarCli === 'd-block') {
      if (!busqueda) {
        setFound(false)
        setFoundByCliente(false)
        return loadClientes();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await SearchClienteNombre(user.CodVendedor, busqueda);
      if (!result || result.data.length === 0) {
        setLoading(false);
        setFound(false)
        setFoundByCliente(false)
        setNotFound(true);
        setPage(1);
        setTotalPages(1);
        return;
      } else {
        setTerm(busqueda)
        setNotFound(false);
        setFound(false)
        setFoundByCliente(true)
        setCliente(result.data)
        setPage(result.current_page)
        setCurrentPage(result.current_page)
        setTotalPages(result.last_page)
        setUrlPage(result.first_page_url)
      }
      setLoading(false);
      setSearching(false);
    } else if (searchbarRif === 'd-block') {
      if (!busqueda) {
        setFound(false)
        return loadClientes();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await SearchClienteRIF(user.CodVendedor, busqueda.toUpperCase());
      if (!result || result.data.length === 0) {
        setLoading(false);
        setFound(false)
        setFoundByCliente(false)
        setNotFound(true);
        setPage(1);
        setTotalPages(1);
        return;
      } else {
        setNotFound(false);
        setFoundByCliente(false)
        setFound(true)
        setTerm(busqueda)
        setCliente(result.data)
        setPage(result.current_page)
        setCurrentPage(result.current_page)
        setTotalPages(result.last_page)
        setUrlPage(result.first_page_url)
      }
      setLoading(false);
      setSearching(false);
    }
  };

  const showFilter = (e, filtro) => {
    e.preventDefault();
    let newFirstDay = firstDay
    let newLastDay = lastDay
    switch (filtro) {
      case 'Rif':
        setSearchbarRif('d-block')
        setSearchbarCli('d-none')
        break;
      case 'Cliente':
        setSearchbarRif('d-none')
        setSearchbarCli('d-block')
        break;
      case '1mes':
        newFirstDay = addMonths(firstDay, 0)
        newLastDay = addMonths(lastDay, 0)
        setDateShown("Registros del mes actual")
        break;
      case '2meses':
        newFirstDay = addMonths(firstDay, -1)
        newLastDay = addMonths(lastDay, -1)
        setDateShown("Registros del mes anterior")
        break;
      case '3meses':
        newFirstDay = addMonths(firstDay, -2)
        newLastDay = addMonths(lastDay, -2)
        setDateShown("Registros de hace 2 meses")
        break;

      case 'Limpiar':
        setSearchbarRif('d-none')
        setSearchbarCli('d-none')
        onSearch('')
        setNotFound(false);
        break;

      default:
        setSearchbarRif('d-none')
        setSearchbarCli('d-none')
        break;
    }

    loadAllClientes(newFirstDay.toISOString().slice(0, 10) + " 00:00:00", newLastDay.toISOString().slice(0, 10) + " 23:59:59")
    setInicio(newFirstDay.toISOString().slice(0, 10) + " 00:00:00")
    setFin(newLastDay.toISOString().slice(0, 10) + " 23:59:59")
  }

  useEffect(() => {
    setLoading(false);
    if (user.CodVendedor !== null && user.CodSupervisor !== null && user.CodGerente === null) {
      loadClientes();
    }

    if (user.CodVendedor !== null && user.CodSupervisor === null && user.CodGerente !== null) {
      loadAllClientes(inicio, fin)
    }
  }, []);

  return (
    <>
      <Layout>
        {/* Cuerpo de la página */}
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-clientes"></span>
              </div>
            </> :
            <>
              {/* Cabecera */}
              <div className="consultas-title mt-5 pt-5">
                <h4 className='bg-belmeny text-light px-5 rounded-pill'>Consulta de Clientes</h4>
              </div>

              {
                (user.CodVendedor !== null && user.CodGerente === null) ?
                  <>
                    <h5 className="text-center mt-2 mb-4 text-belmeny"><i>Vendedor - {user.Nombre} - {user.CodVendedor}</i></h5>
                  </> :
                  (user.CodSupervisor == null) ?
                    <>
                      <h5 className="text-center mt-2 mb-4 text-belmeny"><i>Supervisor / Gerente - {user.Nombre} - {user.CodVendedor}</i></h5>
                    </> :
                    <>
                      <h5 className="text-center mt-2 mb-4 text-belmeny"><i>Gerente - {user.Nombre} - {user.CodGerente}</i></h5>
                    </>
              }

              {/* Desktop version */}
              <div className="container-fluid d-none d-md-block d-lg-block mb-3">
                <div className="row">
                  <div className="col">
                    {
                      (user.CodGerente === null) ?
                        <>
                          <div className="mb-2">
                            <DropdownButton
                              key={'end'}
                              id={`dropdown-button-drop-${'end'}`}
                              drop={'end'}
                              variant="primary"
                              title={` Filtros `}
                            >
                              <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por nombre</Dropdown.Item>
                              <Dropdown.Divider />
                              <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                            </DropdownButton>
                          </div>
                          <div className={searchbarRif}>
                            <Searchbar onSearch={onSearch} />
                          </div>
                          <div className={searchbarCli}>
                            <Searchbar onSearch={onSearch} />
                          </div>

                          <div className="col">
                            <h5 className="text-belmeny text-end">Clientes activos: {totalClientes}</h5>
                          </div>
                        </>
                        : <>
                          <div className="mb-2">
                            <div className="row">
                              <div className="col-1">
                                <DropdownButton
                                  key={'end'}
                                  id={`dropdown-button-drop-${'end'}`}
                                  drop={'bottom'}
                                  variant="primary"
                                  title={` Filtros `}
                                >
                                  <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, '1mes')}>Ver registros del mes actual</Dropdown.Item>
                                  <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, '2meses')}>Ver registros de hace 1 mes</Dropdown.Item>
                                  <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, '3meses')}>Ver registros de hace 2 meses</Dropdown.Item>
                                </DropdownButton>
                              </div>
                              <div className="col">
                                <h5 className="text-belmeny mt-2">Visualizando: {dateShown}</h5>
                              </div>
                            </div>
                          </div>
                        </>
                    }
                  </div>
                </div>
                <div className="rounded-0 my-2">
                  {
                    (notFound) ?
                      <>
                        <div className="alert alert-danger text-center" role="alert">
                          <h5>No se encontraron resultados</h5>
                        </div>
                      </> :
                      <>
                        <div className='container-fluid border-belmeny rounded d-none d-md-block d-lg-block'>
                          <div className="row bg-belmeny text-white text-center py-2 fw-bold">
                            {
                              (user.CodGerente !== null && user.CodSupervisor === null) ?
                                <>
                                  <div className="col">Vendedor</div>
                                  <div className="col">Nombre</div>
                                  <div className="col">Total Clientes</div>
                                  <div className="col">Clientes Facturados</div>
                                  <div className="col">Clientes con deuda</div>
                                  <div className="col">Opciones</div>
                                </> :
                                <>
                                  <div className="col">RIF</div>
                                  <div className="col">Nombre</div>
                                  <div className="col">Fecha Última Facturación</div>
                                  <div className="col">Saldo Pendiente</div>
                                  <div className="col">Opciones</div>
                                </>
                            }
                          </div>
                          {
                            (user.CodGerente !== null && user.CodSupervisor === null) ?
                              Cliente.map((item) => <DetalleCliente item={item} fechaInicio={inicio} fechaFin={fin} key={item.Vendedor} />)
                              :
                              Cliente.map((item) => <DetalleCliente item={item} fechaInicio={inicio} fechaFin={fin} key={item.Codigo} />)
                          }
                        </div>
                      </>
                  }
                </div>

                <Pagination
                  total={totalPages}
                  current={currentPage}
                  onPageChange={page => handlePageChange(page)}
                />
              </div>

              {/* Mobile version */}
              <div className="container-fluid d-sm-block d-md-none">
                <div className="my-3">
                  <DropdownButton
                    key={'end'}
                    id={`dropdown-button-drop-${'end'}`}
                    drop={'end'}
                    variant="primary"
                    title={` Filtros `}
                  >
                    <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Rif')}>Por RIF</Dropdown.Item>
                    <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                  </DropdownButton>
                  <div className="mt-3">
                    <div className={searchbarRif}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                  </div>
                </div>
                {
                  (notFound) ?
                    <>
                      <div className="alert alert-danger text-center" role="alert">
                        <h5>No se encontraron resultados</h5>
                      </div>
                    </> :
                    <>
                      {
                        (user.CodGerente !== null && user.CodSupervisor === null) ?
                          Cliente.map((item) => <DetalleCliente item={item} key={item.Vendedor} />)
                          :
                          Cliente.map((item) => <DetalleCliente item={item} key={item.Codigo} />)
                      }

                      <div className="mb-2">
                        <Pagination
                          total={totalPages}
                          current={currentPage}
                          onPageChange={page => handlePageChange(page)}
                        />
                      </div>
                    </>
                }
              </div>

              {/* Modal clientes atendidos o desatendidos */}
              <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header className='bg-belmeny text-light'>
                  <Modal.Title className='fst-italic'>{(whichLoad === 'atendidos') ? <>Información de clientes atendidos</> : <>Información de clientes desatendidos</>}</Modal.Title>
                  <Modal.Title>
                    <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
                  </Modal.Title>
                </Modal.Header>
                <div className="division w-100 my-4 bg-belmeny"></div>
                <Modal.Body>
                  {
                    (loadingModal) ?
                      <>
                        <div className="text-center mx-auto position-absolute top-50 start-50">
                          <span className="loader-clientes"></span>
                        </div>
                      </> :
                      <>
                        <div className="container-fluid">
                          {/* Div para escritorio */}
                          <div className='d-none d-md-block d-lg-block'>
                            <div className="row">
                              <div className="col">
                                <div className="rounded-0 my-2">
                                  <table className='table table-bordered table-hover border-belmeny '>
                                    <thead className='bg-belmeny text-light text-center'>
                                      <tr>
                                        <th>RIF</th>
                                        <th>Nombre</th>
                                        <th>Dirección</th>
                                        <th className='w-25'>Fecha Última Facturación</th>
                                      </tr>
                                    </thead>
                                    {
                                      (whichLoad === 'atendidos') ?
                                        <>
                                          <tbody>
                                            {
                                              infoCliAtendidosOrDesatendidos.map((item) => (
                                                <tr>
                                                  <td className='fw-bold text-center text-danger fs-6'>{item.codigo}</td>
                                                  <td className='fw-bold fs-6'>{item.nombre}</td>
                                                  <td className='fw-bold fs-6'>{item.DireccionFiscal}</td>
                                                  <td className='fw-bold text-center fs-6'>{new Date(item.FechaDocumento.split(" ")[0]).toLocaleDateString('es-MX')}</td>
                                                </tr>
                                              ))
                                            }
                                          </tbody>
                                        </> :
                                        <>
                                          <tbody>
                                            {
                                              infoCliAtendidosOrDesatendidos.map((item) => (
                                                <tr>
                                                  <td className='fw-bold text-center text-danger'>{item.Codigo}</td>
                                                  <td className='fw-bold'>{item.Nombre}</td>
                                                  <td className='fw-bold fs-6'>{item.DireccionFiscal}</td>
                                                  <td className='fw-bold text-center'>{new Date(item.UltimaFacturacion.split(" ")[0]).toLocaleDateString('es-MX')}</td>
                                                </tr>
                                              ))
                                            }
                                          </tbody>
                                        </>
                                    }

                                  </table>
                                </div>
                              </div>

                              <div className="division mt-5 bg-belmeny"></div>
                              <h3 className="text-end text-belmeny fst-italic mt-2 pe-5">Belmeny Group C.A</h3>
                            </div>
                          </div>

                          {/* Div para móvil */}
                          <div className='d-sm-block d-md-none'>
                          </div>
                        </div>
                      </>
                  }
                </Modal.Body>
              </Modal>
            </>
        }
      </Layout>
    </>
  )
}
