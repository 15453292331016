import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';

import userIcon from '../../assets/img/user-icon-resize.webp';
import notFoundImg from '../../assets/img/404-module-not-found-2.webp';

// SVG LOGOS
import brokenImg from '../../assets/img/broken-image-general.png';
import belmenySvg from '../../assets/img/logos/BELMENY-SVG.svg'
import ingcoSvg from '../../assets/img/logos/INGCO-SVG.svg'
import vertSvg from '../../assets/img/logos/VERT-SVG.svg'
import quilosaSvg from '../../assets/img/logos/QUILOSA-SVG.svg'
import fleximaticSvg from '../../assets/img/logos/FLEXIMATIC-SVG.svg'
import imouSvg from '../../assets/img/logos/IMOU-SVG.svg'
import wadfowSvg from '../../assets/img/logos/WADFOW-SVG.svg'

import { getZonasSupervisor, getHistoricoMetas, getCantidadVentasxGerente, getVentasZona } from '../../api/requestSupervisor';

import Chart from "react-apexcharts";

import { startOfMonth, endOfMonth, addMonths, endOfDay, startOfWeek, endOfWeek, subWeeks, format, set } from 'date-fns';
import moment from 'moment/moment';

export const Estadisticas = () => {

  useEffect(() => {
    loadZonasSupervisor()
    handleMonthLoader('1mes')
  }, []);

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [username, setUsername] = useState('')
  const [loadingText, setLoadingText] = useState('estadisticas...');
  const [whichMonthLoad, setWhichMonthLoad] = useState('1mes');
  const [totalMeta, setTotalMeta] = useState(0);
  const [montoProductos, setMontoProductos] = useState(0);
  const [totalVendidoVert, setTotalVendidoVert] = useState(0)
  const [totalVendidoIngco, setTotalVendidoIngco] = useState(0)
  const [totalVendidoImou, setTotalVendidoImou] = useState(0)
  const [totalVendidoQuilosa, setTotalVendidoQuilosa] = useState(0)
  const [totalVendidoFleximatic, setTotalVendidoFleximatic] = useState(0)
  const [totalVendidoCorona, setTotalVendidoCorona] = useState(0)
  const [totalVendidoWadfow, setTotalVendidoWadfow] = useState(0)
  const [multipleTops, setMultipleTops] = useState(false);
  const [zonasSupervisor, setZonasSupervisor] = useState([]);
  const [ventasProductos, setVentasProductos] = useState([]);
  const [barChartVentas, setBarChartVentas] = useState({})
  const [topDataMetas, setTopDataMetas] = useState({});
  const [topDataMetas2, setTopDataMetas2] = useState({});
  const [topDataMetas3, setTopDataMetas3] = useState({});

  // Nombres de los meses
  const [threeMonthsName, setThreeMonthsName] = useState(startOfMonth(addMonths(new Date(), -3)).toLocaleString("es-MX", { month: "long" }));
  const [twoMonthsName, setTwoMonthsName] = useState(startOfMonth(addMonths(new Date(), -2)).toLocaleString("es-MX", { month: "long" }));
  const [oneMonthName, setOneMonthName] = useState(startOfMonth(addMonths(new Date(), -1)).toLocaleString("es-MX", { month: "long" }));

  //Fecha inicio y fin de los meses
  const [start3Months, setStart3Months] = useState(new Date(startOfMonth(addMonths(new Date(), -3))).toISOString().slice(0, 10))
  const [end3Months, setEnd3Months] = useState(format(new Date(endOfDay(endOfMonth(addMonths(new Date(), -3))).toISOString().slice(0, 10)), 'yyyy-MM-dd'))
  // const [end3Months, setEnd3Months] = useState(new Date(endOfMonth(addMonths(new Date(), -3))).toISOString().slice(0, 10))
  const [start2Months, setStart2Months] = useState(new Date(startOfMonth(addMonths(new Date(), -2))).toISOString().slice(0, 10))
  const [end2Months, setEnd2Months] = useState(format(new Date(endOfDay(endOfMonth(addMonths(new Date(), -2))).toISOString().slice(0, 10)), 'yyyy-MM-dd'))
  // const [end2Months, setEnd2Months] = useState(new Date(endOfMonth(addMonths(new Date(), -2))).toISOString().slice(0, 10))
  const [start1Month, setStart1Month] = useState(new Date(startOfMonth(addMonths(new Date(), -1))).toISOString().slice(0, 10))
  const [end1Month, setEnd1Month] = useState(format(new Date(endOfDay(endOfMonth(addMonths(new Date(), -1))).toISOString().slice(0, 10)), 'yyyy-MM-dd'));

  // Flete
  const flete = 0.031

  function sortByMonth(arr) {
    var months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
      "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    arr.sort(function (a, b) {
      return months.indexOf(a.mes)
        - months.indexOf(b.mes);
    });
  }

  const handleImageError = (event) => {
    event.target.src = brokenImg;
  };

  const handleMonthLoader = (opt) => {
    switch (opt) {
      case '1mes':
        setWhichMonthLoad('1mes')
        loadHistorico1erMes()
        break;

      case '2mes':
        setWhichMonthLoad('2mes')
        loadHistorico2doMes()
        break;

      case '3mes':
        setWhichMonthLoad('3mes')
        loadHistorico3erMes()
        break;

      default:
        loadHistorico1erMes()
        break;
    }
  }

  const loadZonasSupervisor = async () => {
    const resZonasSup = await getZonasSupervisor(user.CodVendedor)
    setZonasSupervisor(resZonasSup)
  }

  const loadHistorico1erMes = async () => {
    setLoading(true)
    setLoadingText(`reporte de estadisticas de ${oneMonthName}...`)
    const [resVentasZona, resVentasCantidad, resHistoricoMetas, resHistoricoMetasVendedores, resZonasSup] = await Promise.all([getVentasZona(user.CodVendedor), getCantidadVentasxGerente(user.CodVendedor, start1Month, end1Month), getHistoricoMetas(user.CodVendedor, start1Month, end1Month, 'no'), getHistoricoMetas(user.CodVendedor, start1Month, end1Month, 'si'), getZonasSupervisor(user.CodVendedor)])

    const ventasxZona = await resVentasZona;
    const ventasCantidad = await resVentasCantidad;
    const histMetas = await resHistoricoMetas;
    const histMetasVendedores = await resHistoricoMetasVendedores;
    const zonasSup = await resZonasSup;

    let metasSum = 0

    for (let i = 0; i < ventasxZona.length; i++) {
      metasSum += ventasxZona[i].meta_zona;
    }

    let vendidoVert = (ventasCantidad[0].TotalVendidoVert - (ventasCantidad[0].TotalVendidoVert * flete))
    let vendidoIngco = (ventasCantidad[0].TotalVendidoIngco - (ventasCantidad[0].TotalVendidoIngco * flete))
    let vendidoImou = (ventasCantidad[0].TotalVendidoImou - (ventasCantidad[0].TotalVendidoImou * flete))
    let vendidoQuilosa = (ventasCantidad[0].TotalVendidoQuilosa - (ventasCantidad[0].TotalVendidoQuilosa * flete))
    let vendidoFleximatic = (ventasCantidad[0].TotalVendidoFleximatic - (ventasCantidad[0].TotalVendidoFleximatic * flete))
    let vendidoCorona = (ventasCantidad[0].TotalVendidoCorona - (ventasCantidad[0].TotalVendidoCorona * flete))
    let vendidoWadfow = (ventasCantidad[0].TotalVendidoWadfow - (ventasCantidad[0].TotalVendidoWadfow * flete))

    let vendidoSum = vendidoVert + vendidoIngco + vendidoImou + vendidoQuilosa + vendidoFleximatic + vendidoWadfow

    setTotalVendidoVert(vendidoVert)
    setTotalVendidoIngco(vendidoIngco)
    setTotalVendidoImou(vendidoImou)
    setTotalVendidoQuilosa(vendidoQuilosa)
    setTotalVendidoFleximatic(vendidoFleximatic)
    setTotalVendidoCorona(vendidoCorona)
    setTotalVendidoWadfow(vendidoWadfow)

    setTotalMeta(metasSum)
    setMontoProductos(vendidoSum)
    setVentasProductos(ventasCantidad)
    setBarChartVentas({
      options: {
        chart: {
          id: "chart-VentasZona",
        },
        xaxis: {
          categories: [`Meta del mes ${(whichMonthLoad === '1mes') ? oneMonthName : (whichMonthLoad === '2mes') ? twoMonthsName : threeMonthsName} - ${histMetas.totalMetas[0].metaTotalGerente}`]
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          }
        },
        colors: ['#27ae60'],
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals
            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`
            }
            return val
          },
          enabled: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ['Ventas actuales', 'Ventas esperadas'],
          markers: {
            fillColors: ['#27ae60', '#e74c3c']
          }
        }
      },
      series: [
        {
          name: 'Ventas en USD',
          data: [
            {
              x: ventasxZona[0].Sector,
              y: vendidoSum.toFixed(2),
              goals: [
                {
                  name: 'Meta en USD',
                  value: histMetas.totalMetas[0].metaTotalGerente,
                  strokeWidth: 85,
                  strokeHeight: 5,
                  strokeColor: '#e74c3c'
                }
              ]
            },
          ]
        }
      ]
    })

    const separatedData = histMetasVendedores.reduce((result, item) => {
      if (!result[item.Sector]) {
        result[item.Sector] = [];
      }
      result[item.Sector].push(item);
      return result;
    }, {});

    // S01 ANDINA, OCCIDENTE, CENTRO - OCCIDENTE - S02 CENTRO - S03 CENTRO - OCCIDENTE, LLANOS, CENTRO - S04 ORIENTE
    let topData1, topData2, topData3, topData4, topData5, topData6, topData7, topData8;

    if (user.CodVendedor === 'S01') {
      topData1 = separatedData["Andina"];
      topData2 = separatedData["Falcón"];
      topData3 = separatedData["Zulia"];
    } else if (user.CodVendedor === 'S02') {
      topData1 = separatedData["Gran Caracas"];
    } else if (user.CodVendedor === 'S03') {
      topData1 = separatedData["Centro"];
      topData2 = separatedData["Centro - Occidente"];
      topData3 = separatedData["Llanos"];
    } else if (user.CodVendedor === 'S04') {
      topData1 = separatedData["Oriente"];
    } else {
      topData1 = separatedData["Andina"];
      topData2 = separatedData["Falcón"];
      topData3 = separatedData["Zulia"];
      topData4 = separatedData["Gran Caracas"];
      topData5 = separatedData["Centro"];
      topData6 = separatedData["Centro - Occidente"];
      topData7 = separatedData["Llanos"];
      topData8 = separatedData["Oriente"];
    }

    if (zonasSup.length > 2) {
      setMultipleTops(true)
      setTopDataMetas(
        {
          options: {
            chart: {
              id: "historicoTopData1",
              type: 'bar',
            },
            xaxis: {
              categories: topData1.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            dataLabels: {
              enabled: true,
              style: {
                colors: ['#000']
              }
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData1.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )

      setTopDataMetas2(
        {
          options: {
            chart: {
              id: "historicoTopData2",
              type: 'bar',
            },
            xaxis: {
              categories: topData2.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            dataLabels: {
              enabled: true,
              style: {
                colors: ['#000']
              }
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData2.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )

      setTopDataMetas3(
        {
          options: {
            chart: {
              id: "historicoTopData3",
              type: 'bar',
            },
            xaxis: {
              categories: topData3.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            dataLabels: {
              enabled: true,
              style: {
                colors: ['#000']
              }
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData3.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )
    } else {
      setTopDataMetas(
        {
          options: {
            chart: {
              id: "historicoTopData1",
              type: 'bar',
            },
            xaxis: {
              categories: topData1.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            dataLabels: {
              enabled: true,
              style: {
                colors: ['#000']
              }
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData1.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )
    }
    setLoading(false)
  }

  const loadHistorico2doMes = async () => {
    setLoading(true)
    setLoadingText(`reporte de estadisticas de ${twoMonthsName}...`)

    const [resVentasZona, resVentasCantidad, resHistoricoMetas, resHistoricoMetasVendedores, resZonasSup] = await Promise.all([getVentasZona(user.CodVendedor), getCantidadVentasxGerente(user.CodVendedor, start2Months, end2Months), getHistoricoMetas(user.CodVendedor, start2Months, end2Months, 'no'), getHistoricoMetas(user.CodVendedor, start2Months, end2Months, 'si'), getZonasSupervisor(user.CodVendedor)]);

    const ventasxZona = await resVentasZona;
    const ventasCantidad = await resVentasCantidad;
    const histMetas = await resHistoricoMetas;
    const histMetasVendedores = await resHistoricoMetasVendedores;
    const zonasSup = await resZonasSup;

    let metasSum = 0

    for (let i = 0; i < ventasxZona.length; i++) {
      metasSum += ventasxZona[i].meta_zona;
    }

    let vendidoVert = (ventasCantidad[0].TotalVendidoVert - (ventasCantidad[0].TotalVendidoVert * flete))
    let vendidoIngco = (ventasCantidad[0].TotalVendidoIngco - (ventasCantidad[0].TotalVendidoIngco * flete))
    let vendidoImou = (ventasCantidad[0].TotalVendidoImou - (ventasCantidad[0].TotalVendidoImou * flete))
    let vendidoQuilosa = (ventasCantidad[0].TotalVendidoQuilosa - (ventasCantidad[0].TotalVendidoQuilosa * flete))
    let vendidoFleximatic = (ventasCantidad[0].TotalVendidoFleximatic - (ventasCantidad[0].TotalVendidoFleximatic * flete))
    let vendidoCorona = (ventasCantidad[0].TotalVendidoCorona - (ventasCantidad[0].TotalVendidoCorona * flete))
    let vendidoWadfow = (ventasCantidad[0].TotalVendidoWadfow - (ventasCantidad[0].TotalVendidoWadfow * flete))

    let vendidoSum = vendidoVert + vendidoIngco + vendidoImou + vendidoQuilosa + vendidoFleximatic + vendidoWadfow

    setTotalVendidoVert(vendidoVert)
    setTotalVendidoIngco(vendidoIngco)
    setTotalVendidoImou(vendidoImou)
    setTotalVendidoQuilosa(vendidoQuilosa)
    setTotalVendidoFleximatic(vendidoFleximatic)
    setTotalVendidoCorona(vendidoCorona)
    setTotalVendidoWadfow(vendidoWadfow)

    setTotalMeta(metasSum)
    setMontoProductos(vendidoSum)
    setVentasProductos(ventasCantidad)

    setBarChartVentas({
      options: {
        chart: {
          id: "chart-VentasZona",
        },
        xaxis: {
          categories: [`Meta del mes ${(whichMonthLoad === '1mes') ? oneMonthName : (whichMonthLoad === '2mes') ? twoMonthsName : threeMonthsName} - ${resHistoricoMetas.totalMetas[0].metaTotalGerente}`]
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          }
        },
        colors: ['#27ae60'],
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals
            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`
            }
            return val
          },
          enabled: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ['Ventas actuales', 'Ventas esperadas'],
          markers: {
            fillColors: ['#27ae60', '#e74c3c']
          }
        }
      },
      series: [
        {
          name: 'Ventas en USD',
          data: [
            {
              x: ventasxZona[0].Sector,
              y: vendidoSum.toFixed(2),
              goals: [
                {
                  name: 'Meta en USD',
                  value: histMetas.totalMetas[0].metaTotalGerente,
                  strokeWidth: 85,
                  strokeHeight: 5,
                  strokeColor: '#e74c3c'
                }
              ]
            },
          ]
        }
      ]
    })

    const separatedData = histMetasVendedores.reduce((result, item) => {
      if (!result[item.Sector]) {
        result[item.Sector] = [];
      }
      result[item.Sector].push(item);
      return result;
    }, {});

    // S01 ANDINA, OCCIDENTE, CENTRO - OCCIDENTE - S02 CENTRO - S03 CENTRO - OCCIDENTE, LLANOS, CENTRO - S04 ORIENTE
    let topData1, topData2, topData3;

    if (user.CodVendedor === 'S01') {
      topData1 = separatedData["Andina"];
      topData2 = separatedData["Falcón"];
      topData3 = separatedData["Zulia"];
    } else if (user.CodVendedor === 'S02') {
      topData1 = separatedData["Gran Caracas"];
    } else if (user.CodVendedor === 'S03') {
      topData1 = separatedData["Centro"];
      topData2 = separatedData["Centro - Occidente"];
      topData3 = separatedData["Llanos"];
    } else if (user.CodVendedor === 'S04') {
      topData1 = separatedData["Oriente"];
    }

    if (zonasSup.length > 1) {
      setMultipleTops(true)
      setTopDataMetas(
        {
          options: {
            chart: {
              id: "historicoTopData1",
              type: 'bar',
            },
            xaxis: {
              categories: topData1.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
                dataLabels: {
                  enabled: false
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData1.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )

      setTopDataMetas2(
        {
          options: {
            chart: {
              id: "historicoTopData2",
              type: 'bar',
            },
            xaxis: {
              categories: topData2.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
                dataLabels: {
                  enabled: false
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData2.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )

      setTopDataMetas3(
        {
          options: {
            chart: {
              id: "historicoTopData3",
              type: 'bar',
            },
            xaxis: {
              categories: topData3.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
                dataLabels: {
                  enabled: false
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData3.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )
    } else {
      setTopDataMetas(
        {
          options: {
            chart: {
              id: "historicoTopData1",
              type: 'bar',
            },
            xaxis: {
              categories: topData1.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
                dataLabels: {
                  enabled: false
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData1.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )
    }
    setLoading(false)
  }

  const loadHistorico3erMes = async () => {
    setLoading(true)
    setLoadingText(`reporte de estadisticas de ${threeMonthsName}...`)

    const [resVentasZona, resVentasCantidad, resHistoricoMetas, resHistoricoMetasVendedores, resZonasSup] = await Promise.all([getVentasZona(user.CodVendedor), getCantidadVentasxGerente(user.CodVendedor, start3Months, end3Months), getHistoricoMetas(user.CodVendedor, start3Months, end3Months, 'no'), getHistoricoMetas(user.CodVendedor, start3Months, end3Months, 'si'), getZonasSupervisor(user.CodVendedor)]);

    const ventasxZona = await resVentasZona;
    const ventasCantidad = await resVentasCantidad;
    const histMetas = await resHistoricoMetas;
    const histMetasVendedores = await resHistoricoMetasVendedores;
    const zonasSup = await resZonasSup;

    let metasSum = 0

    for (let i = 0; i < ventasxZona.length; i++) {
      metasSum += ventasxZona[i].meta_zona;
    }

    let vendidoVert = (ventasCantidad[0].TotalVendidoVert - (ventasCantidad[0].TotalVendidoVert * flete))
    let vendidoIngco = (ventasCantidad[0].TotalVendidoIngco - (ventasCantidad[0].TotalVendidoIngco * flete))
    let vendidoImou = (ventasCantidad[0].TotalVendidoImou - (ventasCantidad[0].TotalVendidoImou * flete))
    let vendidoQuilosa = (ventasCantidad[0].TotalVendidoQuilosa - (ventasCantidad[0].TotalVendidoQuilosa * flete))
    let vendidoFleximatic = (ventasCantidad[0].TotalVendidoFleximatic - (ventasCantidad[0].TotalVendidoFleximatic * flete))
    let vendidoCorona = (ventasCantidad[0].TotalVendidoCorona - (ventasCantidad[0].TotalVendidoCorona * flete))
    let vendidoWadfow = (ventasCantidad[0].TotalVendidoWadfow - (ventasCantidad[0].TotalVendidoWadfow * flete))

    let vendidoSum = vendidoVert + vendidoIngco + vendidoImou + vendidoQuilosa + vendidoFleximatic + vendidoWadfow

    setTotalVendidoVert(vendidoVert)
    setTotalVendidoIngco(vendidoIngco)
    setTotalVendidoImou(vendidoImou)
    setTotalVendidoQuilosa(vendidoQuilosa)
    setTotalVendidoFleximatic(vendidoFleximatic)
    setTotalVendidoCorona(vendidoCorona)
    setTotalVendidoWadfow(vendidoWadfow)

    setTotalMeta(metasSum)
    setMontoProductos(vendidoSum)
    setVentasProductos(ventasCantidad)

    setBarChartVentas({
      options: {
        chart: {
          id: "chart-VentasZona",
        },
        xaxis: {
          categories: [`Meta del mes ${(whichMonthLoad === '1mes') ? oneMonthName : (whichMonthLoad === '2mes') ? twoMonthsName : threeMonthsName} - ${histMetas.totalMetas[0].metaTotalGerente}`]
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          }
        },
        colors: ['#27ae60'],
        dataLabels: {
          formatter: function (val, opt) {
            const goals =
              opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                .goals
            if (goals && goals.length) {
              return `${val} / ${goals[0].value}`
            }
            return val
          },
          enabled: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ['Ventas actuales', 'Ventas esperadas'],
          markers: {
            fillColors: ['#27ae60', '#e74c3c']
          }
        }
      },
      series: [
        {
          name: 'Ventas en USD',
          data: [
            {
              x: ventasxZona[0].Sector,
              y: vendidoSum.toFixed(2),
              goals: [
                {
                  name: 'Meta en USD',
                  value: histMetas.totalMetas[0].metaTotalGerente,
                  strokeWidth: 85,
                  strokeHeight: 5,
                  strokeColor: '#e74c3c'
                }
              ]
            },
          ]
        }
      ]
    })

    const separatedData = histMetasVendedores.reduce((result, item) => {
      if (!result[item.Sector]) {
        result[item.Sector] = [];
      }
      result[item.Sector].push(item);
      return result;
    }, {});

    // S01 ANDINA, OCCIDENTE, CENTRO - OCCIDENTE - S02 CENTRO - S03 CENTRO - OCCIDENTE, LLANOS, CENTRO - S04 ORIENTE
    let topData1, topData2, topData3;

    if (user.CodVendedor === 'S01') {
      topData1 = separatedData["Andina"];
      topData2 = separatedData["Falcón"];
      topData3 = separatedData["Zulia"];
    } else if (user.CodVendedor === 'S02') {
      topData1 = separatedData["Gran Caracas"];
    } else if (user.CodVendedor === 'S03') {
      topData1 = separatedData["Centro"];
      topData2 = separatedData["Centro - Occidente"];
      topData3 = separatedData["Llanos"];
    } else if (user.CodVendedor === 'S04') {
      topData1 = separatedData["Oriente"];
    }

    if (zonasSup.length > 1) {
      setMultipleTops(true)
      setTopDataMetas(
        {
          options: {
            chart: {
              id: "historicoTopData1",
              type: 'bar',
            },
            xaxis: {
              categories: topData1.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
                dataLabels: {
                  enabled: false
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData1.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )

      setTopDataMetas2(
        {
          options: {
            chart: {
              id: "historicoTopData2",
              type: 'bar',
            },
            xaxis: {
              categories: topData2.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
                dataLabels: {
                  enabled: false
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData2.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )

      setTopDataMetas3(
        {
          options: {
            chart: {
              id: "historicoTopData3",
              type: 'bar',
            },
            xaxis: {
              categories: topData3.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
                dataLabels: {
                  enabled: false
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData3.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )
    } else {
      setTopDataMetas(
        {
          options: {
            chart: {
              id: "historicoTopData1",
              type: 'bar',
            },
            xaxis: {
              categories: topData1.map((Vendedor) => Vendedor.Nombre),
            },
            legend: {
              show: true,
              position: 'bottom'
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                labels: {
                  show: true
                },
                dataLabels: {
                  enabled: false
                },
              },
            },
            colors: ['#27ae60b3'],
          },
          series: [
            {
              name: 'Ventas en USD',
              data: topData1.map((Vendedor) => ({
                x: Vendedor.Nombre,
                y: ((Vendedor.totalVendido) - (Vendedor.totalVendido * flete)).toFixed(2),
                goals: [
                  {
                    name: 'Meta en USD',
                    value: Vendedor.global,
                    strokeWidth: 10,
                    strokeHeight: 0,
                    strokeLineCap: 'round',
                    strokeColor: '#e74c3c'
                  }
                ]
              }))
            }
          ]
        }
      )
    }
    setLoading(false)
  }

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div className="text-center">
                  <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div className="loader-book">
                      <div>
                        <ul>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                          <li>
                            <svg fill="currentColor" viewBox="0 0 90 120">
                              <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                            </svg>
                          </li>
                        </ul>
                      </div>
                      <span>Cargando {loadingText}</span>
                    </div>
                  </div>
                </div>
              </> :
              <>

                {/* Div para movil */}
                <div className="text-center d-sm-block d-md-none">
                  {/* <img width={80} src={belmenyLogo} alt="Logo Belmeny Group" className='text-center mt-3 drop-shadow' /> */}
                  <br />
                  <img src={notFoundImg} className="mx-auto w-100" alt="No se ha podido cargar la imagen..." />
                </div>

                {/* Div para web */}
                <div className='d-none d-md-block'>
                  <div className="container-fluid rounded">
                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Estadisticas de ventas</h4>
                    </div>

                    <div className="row my-4">
                      <div className="col-3">
                        <button className='buttonMonths' onClick={(e) => handleMonthLoader('3mes')}><span>Cargar datos de</span> <span className="text-capitalize">{threeMonthsName}</span></button>
                      </div>
                      <div className="col-3">
                        <button className='buttonMonths' onClick={(e) => handleMonthLoader('2mes')}><span>Cargar datos de</span> <span className="text-capitalize">{twoMonthsName}</span></button>
                      </div>
                      <div className="col-3">
                        <button className='buttonMonths' onClick={(e) => handleMonthLoader('1mes')}><span>Cargar datos de</span> <span className="text-capitalize">{oneMonthName}</span></button>
                      </div>
                    </div>

                    {/* Chart de ventas x marca */}
                    <div className="row">
                      <div className="col">
                        <div className="m-auto bg-white p-3 rounded drop-shadow-card h-100">
                          <h4 className="text-belmeny mb-1">Ventas por marca del mes: {(whichMonthLoad === '1mes') ? <span className="text-uppercase">{oneMonthName}</span> : (whichMonthLoad === '2mes') ? <span className="text-uppercase">{twoMonthsName}</span> : <span className="text-uppercase">{threeMonthsName}</span>}
                          </h4>
                          <h5 className='text-belmeny mb-3'>Total generado en el mes: <span className="text-success fw-bold">${montoProductos.toFixed(2)}</span></h5>
                          <div className="container-fluid h-100">
                            <div className="row bg-lighten py-3">
                              <div className="col-3">
                                <h5 className="text-uppercase text-belmeny">Marca</h5>
                              </div>
                              <div className="col">
                                <h5 className="text-uppercase text-belmeny text-center">Productos Vendidos</h5>
                              </div>
                              <div className="col">
                                <h5 className="text-uppercase text-belmeny text-center">Ventas Generadas</h5>
                              </div>
                              <div className="col">
                                <h5 className="text-uppercase text-belmeny text-center">% Representado</h5>
                              </div>
                            </div>
                            {/* VERT */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase pt-1"><img src={vertSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal">
                                  {
                                    (ventasProductos[0].CantidadProductosVert === null || ventasProductos[0].CantidadProductosVert === 0) ? 0 : ventasProductos[0].CantidadProductosVert
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                  {
                                    (totalVendidoVert === null || totalVendidoVert === 0) ? 0 : totalVendidoVert.toFixed(2)
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                  {
                                    (totalVendidoVert === null || totalVendidoVert === 0) ? 0 : ((totalVendidoVert * 100) / totalMeta).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* INGCO */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase"><img src={ingcoSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-2 fw-normal">
                                  {
                                    (ventasProductos[0].CantidadProductosIngco === null || ventasProductos[0].CantidadProductosIngco === 0) ? 0 : ventasProductos[0].CantidadProductosIngco
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-2 fw-normal text-success">
                                  {
                                    (totalVendidoIngco === null || totalVendidoIngco === 0) ? 0 : totalVendidoIngco.toFixed(2)
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-2 fw-normal text-info">
                                  {
                                    (totalVendidoIngco === null || totalVendidoIngco === 0) ? 0 : ((totalVendidoIngco * 100) / totalMeta).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* QUILOSA */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase"><img src={quilosaSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal">
                                  {
                                    (ventasProductos[0].CantidadProductosQuilosa === null || ventasProductos[0].CantidadProductosQuilosa === 0) ? 0 : ventasProductos[0].CantidadProductosQuilosa
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                  {
                                    (totalVendidoQuilosa === null || totalVendidoQuilosa === 0) ? 0 : totalVendidoQuilosa.toFixed(2)
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                  {
                                    (totalVendidoQuilosa === null || totalVendidoQuilosa === 0) ? 0 : ((totalVendidoQuilosa * 100) / totalMeta).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* IMOU */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase pt-1"><img src={imouSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal">
                                  {
                                    (ventasProductos[0].CantidadProductosImou === null || ventasProductos[0].CantidadProductosImou === 0) ? 0 : ventasProductos[0].CantidadProductosImou
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                  {
                                    (totalVendidoImou === null || totalVendidoImou === 0) ? 0 : totalVendidoImou.toFixed(2)
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                  {
                                    (totalVendidoImou === null || totalVendidoImou === 0) ? 0 : ((totalVendidoImou * 100) / totalMeta).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* FLEXIMATIC */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase pt-1"><img src={fleximaticSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal">
                                  {
                                    (ventasProductos[0].CantidadProductosFleximatic === null || ventasProductos[0].CantidadProductosFleximatic === 0) ? 0 : ventasProductos[0].CantidadProductosFleximatic
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                  {
                                    (totalVendidoFleximatic === null || totalVendidoFleximatic === 0) ? 0 : totalVendidoFleximatic.toFixed(2)
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                  {
                                    (totalVendidoFleximatic === null || totalVendidoFleximatic === 0) ? 0 : ((totalVendidoFleximatic * 100) / totalMeta).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                            {/* WADFOW */}
                            <div className="row mt-3">
                              <div className="col-3">
                                <h5 className="text-uppercase pt-1"><img src={wadfowSvg} width={120} alt="No se ha podido cargar la imagen..." onError={handleImageError} /></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal">
                                  {
                                    (ventasProductos[0].CantidadProductosWadfow === null || ventasProductos[0].CantidadProductosWadfow === 0) ? 0 : ventasProductos[0].CantidadProductosWadfow
                                  }
                                  <span className="ps-1">Productos</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-success">
                                  {
                                    (totalVendidoWadfow === null || totalVendidoWadfow === 0) ? 0 : totalVendidoWadfow.toFixed(2)
                                  }
                                  <span className="ps-1">USD</span></h5>
                              </div>
                              <div className="col">
                                <h5 className="text-center text-uppercase pt-4 fw-normal text-info">
                                  {
                                    (totalVendidoWadfow === null || totalVendidoWadfow === 0) ? 0 : ((totalVendidoWadfow * 100) / totalMeta).toFixed(2)
                                  }%
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="h-100 bg-white p-3 rounded drop-shadow-card">
                          <h4 className='text-belmeny ps-3'>Ventas en total del mes <span className="text">{(whichMonthLoad === '1mes') ? <span className="text-uppercase">{oneMonthName}</span> : (whichMonthLoad === '2mes') ? <span className="text-uppercase">{twoMonthsName}</span> : <span className="text-uppercase">{threeMonthsName}</span>}</span></h4>
                          <Chart
                            options={barChartVentas.options}
                            series={barChartVentas.series}
                            type="bar"
                            width="95%"
                            height="100%"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="division my-5"></div>

                    <div className="row">
                      {/* Top de vendedores */}
                      <div className="px-2">
                        {
                          (multipleTops) ?
                            <>
                              <div className="row">
                                <div className="col drop-shadow-card me-2 bg-white">
                                  <h4 className="text-center text-belmeny fst-italic mt-3">Top vendedores zona: {zonasSupervisor[0].Sector}</h4>
                                  <Chart
                                    options={topDataMetas.options}
                                    series={topDataMetas.series}
                                    type="bar"
                                    width="100%"
                                  />
                                </div>
                                <div className="col drop-shadow-card ms-2 bg-white">
                                  <h4 className="text-center text-belmeny fst-italic mt-3">Top vendedores zona: {zonasSupervisor[1].Sector}</h4>
                                  <Chart
                                    options={topDataMetas2.options}
                                    series={topDataMetas2.series}
                                    type="bar"
                                    width="100%"
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col bg-white drop-shadow-card ">
                                  <h4 className="text-center text-belmeny fst-italic mt-3">Top vendedores zona: {zonasSupervisor[2].Sector}</h4>
                                  <Chart
                                    options={topDataMetas3.options}
                                    series={topDataMetas3.series}
                                    type="bar"
                                    width="75%"
                                    className="d-flex align-center justify-content-center"
                                  />
                                </div>
                              </div>
                            </> :
                            <>
                              <div className="row  d-flex align-middle justify-content-center">
                                <div className="col w-75 bg-white drop-shadow-card">
                                  <h4 className="text-center text-belmeny fst-italic mt-3">Top vendedores zona {zonasSupervisor[0].Sector}</h4>
                                  <Chart
                                    options={topDataMetas.options}
                                    series={topDataMetas.series}
                                    type="bar"
                                    width="75%"
                                    className="d-flex align-middle justify-content-center"
                                  />
                                </div>
                              </div>
                            </>
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </>
          }
        </div>
      </Layout >
    </>
  )
}
