import React from 'react'

export const ItemsCobranzasMobile = (props) => {

  var ItemsCobranzas = props.item;

  var unaFila = props.unaFila;
  var dosFilas = props.segundaFila;
  var tresFilas = props.terceraFila;

  return (
    <>
      {
        (unaFila) ?
          <>
            <div className="row text-center">
              <div className="col">
                <p className="fw-bold">{ItemsCobranzas.DocumentoFormaPago}</p>
              </div>
              <div className="col">
                <p className="fw-bold">{(ItemsCobranzas.FormaPago === 'tr') ? <>Transferencia</> : <>Efectivo</>}</p>
              </div>
              <div className="col">
                <p className="fw-bold">{ItemsCobranzas.BancoPago}</p>
              </div>
            </div>
          </> :
          <>
          </>
      }
      {
        (dosFilas) ?
          <>
            <div className="row text-center">
              <div className="col">
                <p className="fw-bold">{new Date(ItemsCobranzas.FechaPago).toLocaleDateString('es-MX')}</p>
              </div>
              <div className="col">
                <p className='text-success fw-bold'>${ItemsCobranzas.MontoParcial.toFixed(2)}</p>
              </div>
              <div className="col">
                <p className="fw-bold">{ItemsCobranzas.Recibo}</p>
              </div>
            </div>
          </> :
          <>
          </>
      }
      {
        (tresFilas) ?
          <>
            <div className="row text-center">
              <div className="col">
                <p className='text-success fw-bold'>{ItemsCobranzas.TasadelDia} VES</p>
              </div>
            </div>
          </> :
          <>
          </>
      }
    </>
  )
}
