import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'
import { useNavigate, Link } from "react-router-dom";

// HTTP
import AuthUser from '../components/AuthUser';
import { insertZonasVendedor } from '../api/request';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'
import { AiFillCloseCircle } from "react-icons/ai";

// Logo
import belmenyLogo from '../assets/img/logoBG.webp';

export const Register = () => {

  const { http, user, token, logout } = AuthUser();
  const [loading, setLoading] = useState(true);
  const [loadLogin, setLoadLogin] = useState(false);

  const [Usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [nombre, setNombre] = useState('');
  const [codVendedor, setCodVendedor] = useState('');
  const [codSupervisor, setCodSupervisor] = useState('');
  const [codGerente, setCodGerente] = useState('');
  const [correo, setCorreo] = useState('');
  const [zona, setZona] = useState('Andina');

  const [show, setShow] = useState(false);
  const [showOptionSup, setShowOptionSup] = useState('');

  const navigate = useNavigate();

  const loader = () => {
    setLoadLogin(true);
  }

  const logoutUser = () => {
    if (token !== undefined) {
      logout();
      navigate('/login');
    }
  }

  // Manejador del submit del form
  const handleSubmit = (e) => {
    loader()
    e.preventDefault();
    if (codVendedor !== '') {

      var codSupervisor = ''
      var newZona = zona;

      switch (zona) {
        case 'Andina':
          codSupervisor = 'S01';
          break;

        case 'Falcón':
          codSupervisor = 'S01';
          newZona = 'Falcón'
          break;

        case 'Zulia':
          codSupervisor = 'S01';
          newZona = 'Zulia'
          break;

        case 'Centro - Occidente':
          codSupervisor = 'S03';
          newZona = 'Centro - Occidente'
          break;

        case 'CentroJAVIER':
          codSupervisor = 'S03';
          newZona = 'Centro'
          break;

        case 'Llanos':
          codSupervisor = 'S03';
          break;

        case 'CentroLUIS':
          codSupervisor = 'S02';
          newZona = 'Gran Caracas'
          break;

        case 'Oriente':
          codSupervisor = 'S04';
          break;

        default:
          codSupervisor = 'S01';
          break;
      }

      http.post('/register', { Usuario: Usuario, password: password, Nombre: nombre, CodVendedor: codVendedor, Activo: '1', clave: password, correo: correo, zona: newZona, CodSupervisor: codSupervisor }).then(async (res) => {
        const resInsertZonas = await insertZonasVendedor(newZona, codVendedor, codSupervisor, nombre)
        navigate('/')
      }).catch((error) => {
        console.log(error)
        setLoadLogin(false);
        setShow(true)
      })
    }
    else if (codSupervisor !== '') {
      http.post('/register', { Usuario: Usuario, password: password, Nombre: nombre, CodSupervisor: codSupervisor, Activo: '1', clave: password, correo: correo }).then((res) => {
        navigate('/')
      }).catch((error) => {
        console.log(error)
        setLoadLogin(false);
        setShow(true)
      })
    }
    else if (codGerente !== '') {
      http.post('/register', { Usuario: Usuario, password: password, Nombre: nombre, CodGerente: codGerente, Activo: '1', clave: password, correo: correo }).then((res) => {
        navigate('/')
      }).catch((error) => {
        console.log(error)
        setLoadLogin(false);
        setShow(true)
      })
    }
  }

  const handleSelect = (e) => {
    e.preventDefault();
    if (e.target.value === 'supervisor') {
      setShowOptionSup('supervisor')
    }
    else if (e.target.value === 'vendedor') {
      setShowOptionSup('vendedor')
    }
    else if (e.target.value === 'gerente') {
      setShowOptionSup('gerente')
    }
    else {
      setZona(e.target.value)
    }
  }

  useEffect(() => {
    if (user.CodGerente !== 'MASTER') {
      navigate('/404')
    }
    onLoad()
  });

  const onLoad = async () => {
    // Loader en false
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }

  return (
    <>
      {
        (loading) ?
          <>
            <Container fluid className='bg-belmeny home'>
              <div className="text-center">
                <div className="m-auto spinner">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              </div>
            </Container>
          </> :
          <>
            <div className={loadLogin ? 'login-load d-flex' : 'd-none'}>
              <div className="m-auto spinner">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            </div>

            <Container fluid className='bg-belmeny home'>

              <Link onClick={logoutUser} to="/" className='text-decoration-none btn btn-danger btn-lg text-light border-0 mt-3'>Cerrar sesión</Link>

              <Alert show={show} variant="danger" className='mt-3 alert-login'>
                <Alert.Heading>
                  <Row>
                    <Col>
                      Oops, ¡Algo ha ocurrido!
                    </Col>
                    <Col className='text-end me-auto' xs={3}>
                      <AiFillCloseCircle className='fs-3 text-danger close-alert' onClick={() => setShow(false)} />
                    </Col>
                  </Row>
                </Alert.Heading>
                <hr />
                <p>
                  Verifica los datos ingresados e intente de nuevo por favor.
                </p>
              </Alert>

              <Container className='login-form bg-light'>
                <div>
                  <h2 className='text-center fw-semibold  fs-3 mt-3 mb-4'>
                    <img width={80} src={belmenyLogo} alt="Logo Belmeny Group" className='text-center mt-3 drop-shadow-sm' />
                  </h2>
                  <h5 className='text-center fw-semibold mb-4'>Ingresa los datos para crear al usuario</h5>
                  <form action="" className='mb-4 w-75 m-auto' onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label htmlFor="Usuario">Usuario</label>
                      <input type="text"
                        name="Usuario"
                        id="Usuario"
                        className="form-control"
                        value={Usuario}
                        onChange={(e) => setUsuario(e.target.value)} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password">Contraseña</label>
                      <input type="password"
                        name="password"
                        id="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }} />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="optionSup">Seleccione que tipo de usuario desea crear</label>
                      <select name="optionSup" id="optionSup" className="form-control" onChange={handleSelect}>
                        <option value="vendedor">Crear Vendedor</option>
                        <option value="supervisor">Crear Supervisor</option>
                        <option value="gerente">Crear Gerente</option>
                      </select>
                    </div>
                    {
                      (showOptionSup === 'supervisor') ?
                        <>
                          <div className="mb-3">
                            <label htmlFor="Nombre">Nombre del Supervisor</label>
                            <input type="text"
                              name="Nombre"
                              id="Nombre"
                              className="form-control"
                              value={nombre}
                              onChange={(e) => { setNombre(e.target.value) }} />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="codSupervisor">Código Supervisor</label>
                            <input type="text"
                              name="codSupervisor"
                              id="codSupervisor"
                              className="form-control"
                              value={codSupervisor}
                              onChange={(e) => { setCodSupervisor(e.target.value) }} />
                          </div>
                        </> :
                        (showOptionSup === 'gerente') ?
                          <>
                            <div className="mb-3">
                              <label htmlFor="Nombre">Nombre del Gerente</label>
                              <input type="text"
                                name="Nombre"
                                id="Nombre"
                                className="form-control"
                                value={nombre}
                                onChange={(e) => { setNombre(e.target.value) }} />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="codGerente">Código Gerente</label>
                              <input type="text"
                                name="codGerente"
                                id="codGerente"
                                className="form-control"
                                value={codGerente}
                                onChange={(e) => { setCodGerente(e.target.value) }} />
                            </div>
                          </> :
                          <>
                            <div className="mb-3">
                              <label htmlFor="Nombre">Nombre del Vendedor</label>
                              <input type="text"
                                name="Nombre"
                                id="Nombre"
                                className="form-control"
                                value={nombre}
                                onChange={(e) => { setNombre(e.target.value) }} />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="codVendedor">Código Vendedor</label>
                              <input type="text"
                                name="codVendedor"
                                id="codVendedor"
                                className="form-control"
                                value={codVendedor}
                                onChange={(e) => { setCodVendedor(e.target.value) }} />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="zona">Seleccione la zona del vendedor</label>
                              <select name="zona" id="zona" className="form-control" onChange={handleSelect}>
                                <option value="Andina">Andina</option>
                                <option value="CentroJAVIER">Centro</option>
                                <option value="CentroLUIS">Gran Caracas</option>
                                <option value="Falcón">Falcón</option>
                                <option value="Centro - Occidente">Centro Occidente</option>
                                <option value="Llanos">Llanos</option>
                                <option value="Zulia">Zulia</option>
                                <option value="Oriente">Oriente</option>
                              </select>
                            </div>
                          </>
                    }
                    <div className="mb-3">
                      <label htmlFor="correo">Correo</label>
                      <input type="email"
                        name="correo"
                        id="correo"
                        className="form-control"
                        value={correo}
                        onChange={(e) => { setCorreo(e.target.value) }} />
                    </div>
                    <button className='btn rounded-pill bg-belmeny btn-hover text-light w-100 mt-3' type='submit'>Registrar nuevo usuario <FontAwesomeIcon icon={faArrowAltCircleRight} /></button>
                  </form>
                </div>
              </Container>
            </Container>
          </>
      }
    </>
  )
}
