import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import Html from 'react-pdf-html';
import { Margin, usePDF } from "react-to-pdf";

import Pagination from 'react-responsive-pagination';

import AuthUser from '../../../components/AuthUser';
import { RiPrinterFill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import cintilloVert from '../../../assets/img/img-catalogo/cintillos/cintillo-vert-logo-productos-ON.png'

import bombillo1 from '../../../assets/img/img-catalogo/menu/bombilloA60.png'
import bombilloUso from '../../../assets/img/img-catalogo/menu/bombilloUsoSlider.png'

import panelLed1 from '../../../assets/img/img-catalogo/productos/vert/PRS-12346.png'
import panelLedUso from '../../../assets/img/img-catalogo/productos/vert/panel-led-uso.jpg'

import cintaLed1 from '../../../assets/img/img-catalogo/menu/cintaLedSlider.png'
import cintaLedUso from '../../../assets/img/img-catalogo/menu/cintaLedUsoSlider.png'

import tubos1 from '../../../assets/img/img-catalogo/menu/tubosSlider.png'
import tubosUso from '../../../assets/img/img-catalogo/menu/tubosUsoSlider.png'

import lampara1 from '../../../assets/img/img-catalogo/menu/lamparaSlider2.png'
import lamparaUso from '../../../assets/img/img-catalogo/menu/lamparaUsoSlider.webp'

import alumbrado1 from '../../../assets/img/img-catalogo/menu/alumbradoSlider.png'
import alumbradoUso from '../../../assets/img/img-catalogo/menu/alumbradoUsoSlider.png'

import reflector1 from '../../../assets/img/img-catalogo/menu/reflectorSlider.png'
import reflectorUso from '../../../assets/img/img-catalogo/menu/reflectorUsoSlider2.png'

import linterna1 from '../../../assets/img/img-catalogo/menu/linternaSlider.png'
import linternaUso from '../../../assets/img/img-catalogo/menu/linternaUsoSlider.png'

// HOGAR
import decoracion1 from '../../../assets/img/img-catalogo/menu/decoracionSlider.png'
import decoracionUso from '../../../assets/img/img-catalogo/menu/decoracionUsoSlider.png'

import espejo1 from '../../../assets/img/img-catalogo/menu/espejoSlider.png'
import espejoUso from '../../../assets/img/img-catalogo/menu/espejoUsoSlider.png'

import jardineria1 from '../../../assets/img/img-catalogo/menu/jardineriaSlider.png'
import jardineriaUso from '../../../assets/img/img-catalogo/menu/jardineriaUsoSlider.png'

import griferia1 from '../../../assets/img/img-catalogo/menu/griferiaSlider.png'
import griferiaUso from '../../../assets/img/img-catalogo/menu/griferiaUsoSlider.png'

import candado1 from '../../../assets/img/img-catalogo/menu/candadoSlider.png'
import candadoUso from '../../../assets/img/img-catalogo/menu/candadoUsoSlider.png'

import ferreGral1 from '../../../assets/img/img-catalogo/menu/ferreGralSlider.png'
import ferreGralUso from '../../../assets/img/img-catalogo/menu/ferreGralUsoSlider.png'

import construccion1 from '../../../assets/img/img-catalogo/menu/construccionSlider2.png'
import construccionUso from '../../../assets/img/img-catalogo/menu/construccionUsoSlider.png'

import automotriz1 from '../../../assets/img/img-catalogo/menu/automotrizSlider.png'
import automotrizUso from '../../../assets/img/img-catalogo/menu/automotrizUsoSlider.png'

import electricidad1 from '../../../assets/img/img-catalogo/menu/electricidadSlider.png'
import electricidadUso from '../../../assets/img/img-catalogo/menu/electricidadUsoSlider.png'

import miscelaneo1 from '../../../assets/img/img-catalogo/menu/miscelaneoSlider.png'
import miscelaneoUso from '../../../assets/img/img-catalogo/menu/miscelaneoUsoSlider.png'

import { getCatalogoVert } from '../../../api/requestCatalogo';

export const DetalladoVert = (props) => {

  var title = props.title;
  var subtitle = props.subtitle;
  var watts = props.watts;
  var intensity = props.intensity;
  var products = props.products;

  if (products === null) products = 0;

  useEffect(() => {
    handleCategory()
  }, [])

  const [loading, setLoading] = useState(true)

  const [productos, setProductos] = useState([]);
  const [photoSlider, setPhotoSlider] = useState("");
  const [photoSlider2, setPhotoSlider2] = useState("");

  const handleCategory = () => {
    switch (title) {
      case 'BOMBILLOS':
        setPhotoSlider(bombillo1)
        setPhotoSlider2(bombilloUso)
        break;

      case 'CINTAS LED':
        setPhotoSlider(cintaLed1)
        setPhotoSlider2(cintaLedUso)
        break;

      case 'PANEL LED':
        setPhotoSlider(panelLed1)
        setPhotoSlider2(panelLedUso)
        break;

      case 'TUBOS LED':
        setPhotoSlider(tubos1)
        setPhotoSlider2(tubosUso)
        break;

      case 'LAMPARAS':
        setPhotoSlider(lampara1)
        setPhotoSlider2(lamparaUso)
        break;

      case 'ALUMBRADOS':
        setPhotoSlider(alumbrado1)
        setPhotoSlider2(alumbradoUso)
        break;

      case 'REFLECTORES LED':
        setPhotoSlider(reflector1)
        setPhotoSlider2(reflectorUso)
        break;

      case 'LINTERNAS':
        setPhotoSlider(linterna1)
        setPhotoSlider2(linternaUso)
        break;

      case 'HOGAR':
        setPhotoSlider(decoracion1)
        setPhotoSlider2(decoracionUso)
        break;

      case 'BAÑOS':
        setPhotoSlider(espejo1)
        setPhotoSlider2(espejoUso)
        break;

      case 'JARDINERIA':
        setPhotoSlider(jardineria1)
        setPhotoSlider2(jardineriaUso)
        break;

      case 'GRIFERÍA Y PLOMERÍA':
        setPhotoSlider(griferia1)
        setPhotoSlider2(griferiaUso)
        break;

      case 'CERRADURAS':
        setPhotoSlider(candado1)
        setPhotoSlider2(candadoUso)
        break;

      case 'FERRETERIA EN GENERAL':
        setPhotoSlider(ferreGral1)
        setPhotoSlider2(ferreGralUso)
        break;

      case 'ELECTRICIDAD':
        setPhotoSlider(electricidad1)
        setPhotoSlider2(electricidadUso)
        break;

      case 'AUTOMOTRIZ':
        setPhotoSlider(automotriz1)
        setPhotoSlider2(automotrizUso)
        break;

      case 'CONSTRUCCIÓN':
        setPhotoSlider(construccion1)
        setPhotoSlider2(construccionUso)
        break;

      case 'MISCELÁNEOS':
        setPhotoSlider(miscelaneo1)
        setPhotoSlider2(miscelaneoUso)
        break;

      default:
        setPhotoSlider(bombillo1)
        setPhotoSlider2(bombilloUso)
        break;
    }
  }

  return (
    <>
      <div className="container-fluid w-100 px-5 pb-3 robo-font">
        <div className="rounded px-5">
          <div className="row mt-3">
            <div className="col">
              <h2 className="fw-normal mb-2 text-light" style={{ fontSize: '2.5rem' }}>Productos vërt</h2>
              <h2 className="fw-900 text-light" style={{ fontSize: '4.5rem', lineHeight: '1' }}>{title}</h2>
              <h3 className="text-muted fw-normal mt-2" style={{ fontSize: '2.5rem' }}>{subtitle}</h3>
              <h3 className="fs-4 mt-5 pt-5 text-light">DISPONIBLES EN</h3>
              <h4 className="fs-5 fw-normal text-light">{watts}</h4>
              <h3 className="fs-5 fw-normal text-light">{intensity}</h3>
            </div>
            <div className="col d-flex mx-auto my-auto align-items-center justify-content-center">
              <div className="rounded drop-shadow-sm">
                <Splide hasTrack={false} options={{
                  rewind: true,
                  autoplay: true,
                  interval: 3000,
                  resetProgress: true,
                  gap: '0rem',
                  width: '800px',
                  autoHeight: true,
                }}>
                  <SplideTrack>
                    <SplideSlide className='text-center d-flex mx-auto my-auto align-items-center justify-content-center'>
                      <img src={photoSlider} alt="Titulo" className='w-50 text-center rounded drop-shadow-card' />
                    </SplideSlide>
                    <SplideSlide className='text-center d-flex mx-auto my-auto align-items-center justify-content-center'>
                      <img src={photoSlider2} alt="Tip 1" className='w-50 text-center rounded drop-shadow-card' />
                    </SplideSlide>
                  </SplideTrack>
                  <div className="splide__arrows ">
                    <button className="splide__arrow splide__arrow--prev"><i className='fs-3 mb-1'><IoIosArrowDroprightCircle /></i></button>
                    <button className="splide__arrow splide__arrow--next me-2"><i className='fs-3 mb-1'><IoIosArrowDroprightCircle /></i></button>
                  </div>
                </Splide>
              </div>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col mx-auto">
              <div className="row mt-3 bg-items-vert text-white fw-bold py-2" style={{ borderTopLeftRadius: "3px", borderTopRightRadius: "3px" }}>
                <div className="col-1 me-3 text-center">CÓDIGO</div>
                <div className="col-4">NOMBRE</div>
                <div className="col-4 text-center">CARACTERISTICAS</div>
                <div className="col text-center">MEDIDAS</div>
                <div className="col text-center">PRECIO</div>
              </div>
            </div>
          </div>
          {
            (products.length > 0 && products !== null) ?
              <>
                {
                  products.map((product, idx) => <>
                    <div className="row">
                      <div className="col">
                        <div className={`row ${idx % 2 === 0 ? 'bg-lighten' : 'bg-light-muted'} ${idx === products.length - 1 ? 'border-bottom-left-right' : ''} fw-bold py-2`}>
                          <div className="col-1 me-3 text-center"><Link to={`/catalogo-vert/products/${product.Codigo}`}>{product.Codigo}</Link></div>
                          <div className="col-4 fw-normal">{product.Descripcion}</div>
                          <div className="col text-center">{product.ORN_Linea}</div>
                          <div className="col text-center">{product.ORN_SubLinea}</div>
                          <div className="col text-center">{product.ORN_Familia}</div>
                          {/* <div className="col text-center">{((product.Propiedad1 !== "") || (product.Propiedad1 !== null) || (product.Propiedad1 !== " ")) ? <>{product.Propiedad1}</> : <>-</>}</div> */}
                          {/* <div className="col text-center">{((product.Propiedad2 !== "") || (product.Propiedad2 !== null) || (product.Propiedad2 !== " ")) ? <>{product.Propiedad2}</> : <>-</>}</div> */}
                          {/* <div className="col text-center">{((product.Propiedad3 !== "") || (product.Propiedad3 !== null) || (product.Propiedad3 !== " ")) ? <>{product.Propiedad3}</> : <>-</>}</div> */}
                          {/* <div className="col-1 text-center">{((product.Propiedad4 !== "") || (product.Propiedad4 !== null) || (product.Propiedad4 !== " ")) ? <>{product.Propiedad4}</> : <>-</>}</div> */}
                          {/* <div className="col text-center">6500K</div> */}
                          <div className="col text-center">{product.Medidas}</div>
                          <div className="col text-center text-success">${product.Precio.toFixed(2)}</div>
                        </div>
                      </div>
                    </div>
                  </>)
                }
              </>
              :
              <>
                <div className="row">
                  <div className="col">
                    <div className="row bg-lighten fw-bold py-2">
                      <div className="col me-3 text-center">PRE-126</div>
                      <div className="col-6 fw-normal">PANEL LED REDONDO P/EMPOTRAR DE 12W * 85-265V * 1200LM/W * 6500K *MARCA VËRT ***VACIO EL ARRAY***</div>
                      <div className="col text-center">12W</div>
                      <div className="col text-center">85-265V</div>
                      <div className="col text-center">1200LM/W</div>
                      <div className="col text-center">6500K</div>
                      <div className="col text-center">12X3</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="row bg-light-muted fw-bold py-2">
                      <div className="col me-3 text-center">PRE-126</div>
                      <div className="col-6 fw-normal">PANEL LED REDONDO P/EMPOTRAR DE 12W * 85-265V * 1200LM/W * 6500K *MARCA VËRT</div>
                      <div className="col text-center">12W</div>
                      <div className="col text-center">85-265V</div>
                      <div className="col text-center">1200LM/W</div>
                      <div className="col text-center">6500K</div>
                      <div className="col text-center">12X3</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="row bg-lighten fw-bold py-2">
                      <div className="col me-3 text-center">PRE-126</div>
                      <div className="col-6 fw-normal">PANEL LED REDONDO P/EMPOTRAR DE 12W * 85-265V * 1200LM/W * 6500K *MARCA VËRT</div>
                      <div className="col text-center">12W</div>
                      <div className="col text-center">85-265V</div>
                      <div className="col text-center">1200LM/W</div>
                      <div className="col text-center">6500K</div>
                      <div className="col text-center">12X3</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="row bg-light-muted fw-bold py-2">
                      <div className="col me-3 text-center">PRE-126</div>
                      <div className="col-6 fw-normal">PANEL LED REDONDO P/EMPOTRAR DE 12W * 85-265V * 1200LM/W * 6500K *MARCA VËRT</div>
                      <div className="col text-center">12W</div>
                      <div className="col text-center">85-265V</div>
                      <div className="col text-center">1200LM/W</div>
                      <div className="col text-center">6500K</div>
                      <div className="col text-center">12X3</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="row bg-lighten fw-bold py-2">
                      <div className="col me-3 text-center">PRE-126</div>
                      <div className="col-6 fw-normal">PANEL LED REDONDO P/EMPOTRAR DE 12W * 85-265V * 1200LM/W * 6500K *MARCA VËRT</div>
                      <div className="col text-center">12W</div>
                      <div className="col text-center">85-265V</div>
                      <div className="col text-center">1200LM/W</div>
                      <div className="col text-center">6500K</div>
                      <div className="col text-center">12X3</div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col">
                    <div className="row bg-light-muted fw-bold py-2">
                      <div className="col me-3 text-center">PRE-126</div>
                      <div className="col-6 fw-normal">PANEL LED REDONDO P/EMPOTRAR DE 12W * 85-265V * 1200LM/W * 6500K *MARCA VËRT</div>
                      <div className="col text-center">12W</div>
                      <div className="col text-center">85-265V</div>
                      <div className="col text-center">1200LM/W</div>
                      <div className="col text-center">6500K</div>
                      <div className="col text-center">12X3</div>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </div >
    </>

  )
}
