import React, { useState } from 'react'

const ExcelJS = require("exceljs")

export const PedidosPorDiaXLS = ({ uniqueDates, vendorData }) => {

    const [enableBtn, setEnableBtn] = useState(true)

    const flete = 0.031

    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        return `${day}/${month}/${year}`;
    };

    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("Pedidos por Día");
        sheet.properties.defaultRowHeight = 20;
        sheet.properties.defaultColWidth = 25;

        sheet.getRow(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '0041FF' }
        };

        sheet.getRow(1).font = {
            name: "Arial",
            family: 4,
            size: 14,
            bold: true,
            color: { argb: 'FFFFFF' }
        };

        const additionalColumns = uniqueDates.map(date => ({
            header: formatDate(date),
            key: date,
            width: 17
        }));

        sheet.columns = [
            { header: 'Vendedor', key: 'vendedor' },
            { header: '# Pedidos', key: 'nroPedidos' },
            { header: 'Monto Pedido', key: 'montoPedido', width: 30 },
            { header: 'Monto Facturado', key: 'montoFacturado', width: 30 },
            ...additionalColumns
        ];

        vendorData.forEach(vendorRow => {
            const rowData = {
                vendedor: `${vendorRow[0].CodVendedor} - ${vendorRow[0].Nombre}`,
                nroPedidos: vendorRow[0].Pedidos,
                montoPedido: (vendorRow[0].TotalPedido - (vendorRow[0].TotalPedido * flete)),
                montoFacturado: (vendorRow[0].TotalFacturado - (vendorRow[0].TotalFacturado * flete)),
                ...uniqueDates.reduce((acc, date) => {
                    const dataForDate = vendorRow.find(item => item.Fecha === date);
                    return { ...acc, [date]: dataForDate ? dataForDate.Pedidos : 0 };
                }, {})
            };
            sheet.addRow(rowData);
        });

        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Pedidos por Día.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
            setEnableBtn(true)
        });
    }

    return (
        <>
            <div className='d-md-block d-none'>
                {
                    (enableBtn) ?
                        <button onClick={exportToExcel} className='btn btn-info btn-sm mb-2 w-100'>Generar Reporte de Pedidos por Día XLSX</button>
                        :
                        <button className='btn btn-info btn-sm mb-2 w-100 disabled' disabled>Generar Reporte de Pedidos por Día XLSX</button>
                }
            </div>
        </>
    )
}