import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import AuthUser from '../../components/AuthUser'
import Layout from '../../components/Layout';

import axios from 'axios'
import { getClientesVendedor, getAllClientes, getClienteRIF, getClienteVentas6UltimosMeses, getVentasClientes } from '../../api/requestClientes';
import { postPreSolicitud } from '../../api/request';
import { Typeahead } from 'react-bootstrap-typeahead';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment/moment';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { upload } from '@testing-library/user-event/dist/upload';

// Imagenes modelo
import belmenyLogo from '../../assets/img/logoBG.webp';
import fachada from '../../assets/img/fotos_tiendas_modelo/entrada.jpeg';
import extremoIzq from '../../assets/img/fotos_tiendas_modelo/extremoizq.jpeg';
import extremoDer from '../../assets/img/fotos_tiendas_modelo/extremoder.jpeg';
import exhibidor1 from '../../assets/img/fotos_tiendas_modelo/exhibidor1.jpeg';
import exhibidor2 from '../../assets/img/fotos_tiendas_modelo/exhibidor2.jpeg';
import exhibidor3 from '../../assets/img/fotos_tiendas_modelo/exhibidor3.jpeg';
import competencia1 from '../../assets/img/fotos_tiendas_modelo/competencia1.jpeg';
import competencia2 from '../../assets/img/fotos_tiendas_modelo/competencia2.jpeg';
import competencia3 from '../../assets/img/fotos_tiendas_modelo/competencia3.jpeg';

export const AnalisisCliente = () => {

	const { user } = AuthUser();
	const navigate = useNavigate();

	// State del loading
	const [loading, setLoading] = useState(true);
	const [loading2, setLoading2] = useState(true);
	const MySwal = withReactContent(Swal)

	// Datos
	const [clientes, setClientes] = useState([]);
	const [saldoPendiente, setSaldoPendiente] = useState(0)
	const [totalVendido, setTotalVendido] = useState(0)
	const [singleSelections, setSingleSelections] = useState([]);
	const [marcaExhibidor, setMarcaExhibidor] = useState('');
	const [sugerencia, setSugerencia] = useState('')
	const [cliente, setCliente] = useState('');
	const [rif, setRif] = useState('');
	const [lotsImg, setLotsImg] = useState([])

	// Archivos

	const [firstImg, setFirstImg] = useState([])
	const [secondImg, setSecondImg] = useState([])
	const [thirdImg, setThirdImg] = useState([])
	const [fourthImg, setFourthImg] = useState([])
	const [fifthImg, setFifthImg] = useState([])
	const [sixthImg, setSixthImg] = useState([])
	const [seventhImg, setSeventhImg] = useState([])
	const [eigthImg, setEigthImg] = useState([])
	const [ninthImg, setNinthImg] = useState([])


	// Preview de imagenes
	const [imgFachada, setImgFachada] = useState()
	const [imgExtremoIzq, setImgExtremoIzq] = useState()
	const [imgExtremoDer, setImgExtremoDer] = useState()
	const [imgExhibidor1, setImgExhibidor1] = useState()
	const [imgExhibidor2, setImgExhibidor2] = useState()
	const [imgExhibidor3, setImgExhibidor3] = useState()
	const [imgCompetencia1, setImgCompetencia1] = useState()
	const [imgCompetencia2, setImgCompetencia2] = useState()
	const [imgCompetencia3, setImgCompetencia3] = useState()

	const [chartData, setChartData] = useState({});
	const [options, setOptions] = useState({})

	const today = moment().format("YYYY-M-D HH:mm:ss");

	const customSwal = MySwal.mixin({
		customClass: {
			confirmButton: 'btn btn-success',
			cancelButton: 'btn btn-danger',
			denyButton: 'btn btn-danger'
		},
		buttonsStyling: false
	})

	function sortByMonth(arr) {
		var months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
			"JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
		arr.sort(function (a, b) {
			return months.indexOf(a.months)
				- months.indexOf(b.months);
		});
	}

	const getArrayDiff = (data) => {
		var datosArr = [
			{
				vendedor: clientes.Vendedor,
				months: 'ENERO',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'FEBRERO',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'MARZO',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'ABRIL',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'MAYO',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'JUNIO',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'JULIO',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'AGOSTO',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'SEPTIEMBRE',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'OCTUBRE',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'NOVIEMBRE',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			},
			{
				vendedor: clientes.Vendedor,
				months: 'DICIEMBRE',
				ventas: 0,
				year: 2022,
				codcliente: clientes.Codigo,
			}
		]

		const isSameUser = (data, datosArr) => data.months === datosArr.months

		const onlyInLeft = (left, right, compareFunction) =>
			left.filter(leftValue =>
				!right.some(rightValue =>
					compareFunction(leftValue, rightValue)));

		const onlyInA = onlyInLeft(data, datosArr, isSameUser);
		const onlyInB = onlyInLeft(datosArr, data, isSameUser);

		const result = [...onlyInA, ...onlyInB];

		const arrayDefinitivo = result.concat(data)

		return arrayDefinitivo;
	}

	const loadClientes = async () => {
		if (user.CodVendedor != null) {
			const clientRes = await getClientesVendedor(user.CodVendedor)

			var data = {}
			var arrClient = []

			for (let i = 0; i < clientRes.length; i++) {
				data = {
					name: clientRes[i].Nombre,
					id: clientRes[i].Codigo,
				}
				arrClient.push(data)
			}
			setClientes(arrClient)
		}
		else if (user.CodSupervisor != null) {
			const clientRes = await getAllClientes()

			var data = {}
			var arrClient = []

			for (let i = 0; i < clientRes.length; i++) {
				data = {
					name: clientRes[i].Nombre,
					id: clientRes[i].Codigo,
				}
				arrClient.push(data)
			}
			setClientes(arrClient)
		}
		setLoading(false)
	}

	const handleSelect = async (e) => {
		setLoading2(true)
		try {
			setCliente(e[0].name)
			setRif(e[0].id)
			const clientRes = await getClienteRIF(e[0].id)
			const ventasCliente = await getClienteVentas6UltimosMeses(user.CodVendedor, e[0].id)
			if (ventasCliente.data[0].Total_Vendido !== null) {
				setTotalVendido(ventasCliente.data[0].Total_Vendido)
			}
			setSaldoPendiente(clientRes.data[0].SaldoPendiente)
			const data2022 = await getVentasClientes('2022', clientRes.data[0].Codigo)
			const arr2022 = getArrayDiff(data2022)
			sortByMonth(arr2022)
			console.log(arr2022)

			setChartData({
				labels: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
					"JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
				datasets: [
					{
						data: arr2022.map((Ventas) => Ventas.ventas),
						label: `Ventas del año 2022 en USD`,
						borderColor: 'rgb(128, 0, 0)',
					}
				]
			})

			setLoading2(false)
		} catch (error) {

		}
	}

	const handleImgs = (files, position) => {
		switch (position) {
			case '1st':
				setImgFachada(URL.createObjectURL(files[0]));
				setFirstImg(files);
				break;

			case '2nd':
				if (!imgFachada) {
					customSwal.fire({
						title: <strong>Imagen anterior no encontrada</strong>,
						html: <i>Debe colocar la imágen anterior para colocar la siguiente.</i>,
						icon: 'warning'
					})
				}
				else {
					setImgExtremoIzq(URL.createObjectURL(files[0]));
					setSecondImg(files)
				}
				break;

			case '3rd':
				if (!imgExtremoIzq) {
					customSwal.fire({
						title: <strong>Imagen anterior no encontrada</strong>,
						html: <i>Debe colocar la imágen anterior para colocar la siguiente.</i>,
						icon: 'warning'
					})
				}
				else {
					setImgExtremoDer(URL.createObjectURL(files[0]));
					setThirdImg(files)
				}
				break;

			case '4th':
				if (!imgExtremoDer) {
					customSwal.fire({
						title: <strong>Imagen anterior no encontrada</strong>,
						html: <i>Debe colocar la imágen anterior para colocar la siguiente.</i>,
						icon: 'warning'
					})
				}
				else {
					setImgExhibidor1(URL.createObjectURL(files[0]));
					setFourthImg(files)
				}
				break;

			case '5th':
				if (!imgExhibidor1) {
					customSwal.fire({
						title: <strong>Imagen anterior no encontrada</strong>,
						html: <i>Debe colocar la imágen anterior para colocar la siguiente.</i>,
						icon: 'warning'
					})
				}
				else {
					setImgExhibidor2(URL.createObjectURL(files[0]));
					setFifthImg(files)
				}
				break;

			case '6th':
				if (!imgExhibidor2) {
					customSwal.fire({
						title: <strong>Imagen anterior no encontrada</strong>,
						html: <i>Debe colocar la imágen anterior para colocar la siguiente.</i>,
						icon: 'warning'
					})
				}
				else {
					setImgExhibidor3(URL.createObjectURL(files[0]));
					setSixthImg(files)
				}
				break;

			case '7th':
				if (!imgExhibidor3) {
					customSwal.fire({
						title: <strong>Imagen anterior no encontrada</strong>,
						html: <i>Debe colocar la imágen anterior para colocar la siguiente.</i>,
						icon: 'warning'
					})
				}
				else {
					setImgCompetencia1(URL.createObjectURL(files[0]));
					setSeventhImg(files)
				}
				break;

			case '8th':
				if (!imgCompetencia1) {
					customSwal.fire({
						title: <strong>Imagen anterior no encontrada</strong>,
						html: <i>Debe colocar la imágen anterior para colocar la siguiente.</i>,
						icon: 'warning'
					})
				}
				else {
					setImgCompetencia2(URL.createObjectURL(files[0]));
					setEigthImg(files)
				}
				break;

			case '9th':
				if (!imgCompetencia2) {
					customSwal.fire({
						title: <strong>Imagen anterior no encontrada</strong>,
						html: <i>Debe colocar la imágen anterior para colocar la siguiente.</i>,
						icon: 'warning'
					})
				}
				else {
					setImgCompetencia3(URL.createObjectURL(files[0]));
					setNinthImg(files)
				}
				break;
		}
	}

	const submitAllData = async (e) => {
		setLoading(true)
		e.preventDefault();

		const fData = new FormData();
		var imagenes = []
		var count = 1

		new Promise((resolve, reject) => {
			for (let i = 0; i < 9; i++) {
				switch (i) {
					case 0:
						fData.append('image', firstImg[0]);
						break;

					case 1:
						fData.append('image', secondImg[0]);
						break;

					case 2:
						fData.append('image', thirdImg[0]);
						break;

					case 3:
						fData.append('image', fourthImg[0]);
						break;

					case 4:
						fData.append('image', fifthImg[0]);
						break;

					case 5:
						fData.append('image', sixthImg[0]);
						break;

					case 6:
						fData.append('image', seventhImg[0]);
						break;

					case 7:
						fData.append('image', eigthImg[0]);
						break;

					case 8:
						fData.append('image', ninthImg[0]);
						break;
				}

				axios.post('http://127.0.0.1:8000/api/postImg', fData).then((res) => {
					if (res.status === 200) {
						console.log("response", res)
						imagenes.push(res.data.id)
						setLotsImg(imagenes => [...imagenes, res.data.id]);
						if (i === 8) {
							resolve();
						}
					}
				}).catch((error) => {
					console.log(error)
				})
				count++
			}
		})
			.then(() => {
				submitSolicitud()
			})
			.catch(() => {
				console.error("Do that");
			})
	}

	const submitSolicitud = async () => {
		console.log(lotsImg)
		const preSolicitud = await postPreSolicitud(cliente, rif, sugerencia, marcaExhibidor, lotsImg, today, user.Nombre)

		if (preSolicitud.status === 200 && preSolicitud.data.cliente !== '') {
			setLoading(false)
			customSwal.fire({
				title: <strong>Solicitud envíada</strong>,
				html: <i>Tu solicitud ha sido envíada de manera exitosa. Revisa tu buzón para seguir su proceso de aprobación.</i>,
				icon: 'success'
			}).then(() => {
				console.log("REDIRECT")
				// navigate('/exhibidores/buzon/presolicitudes')
			})
		}
	}



	const resetGallery = () => {
		setLotsImg([])
		setFirstImg([])
		setSecondImg([])
		setThirdImg([])
		setFourthImg([])
		setFifthImg([])
		setSixthImg([])
		setSeventhImg([])
		setEigthImg([])
		setNinthImg([])
		setImgFachada();
		setImgExtremoIzq();
		setImgExtremoDer();
		setImgExhibidor1();
		setImgExhibidor2();
		setImgExhibidor3();
		setImgCompetencia1();
		setImgCompetencia2();
		setImgCompetencia3();
		customSwal.fire({
			title: <strong>Galería reiniciada</strong>,
			html: <i>Todas las imagenes han sido eliminadas, ingreselas nuevamente.</i>,
			icon: 'success'
		})
	}

	useEffect(() => {
		loadClientes()
	}, []);

	return (
		<>
			<Layout>
				<div className="container-fluid mt-5">
					{
						(loading) ?
							<>
								<div className="text-center mx-auto position-absolute top-50 start-50">
									<span className="loader-documentos"></span>
								</div>
							</> :
							<>

								{/* Dashboard for mobile version */}
								<div className="d-sm-block d-md-none">
									<div className="my-4">
										<div className="dashboard-title mt-2 mb-3">
											<h4 className='bg-belmeny text-light px-5 rounded-pill'>Solicitud de Exhibidores</h4>
										</div>
									</div>
								</div>

								{/* Dashboard for web version */}
								<div className='d-none d-md-block d-lg-block'>
									<div className="container-fluid rounded">
										<div className="dashboard-title mt-2 mb-3">
											<h4 className='bg-belmeny text-light px-5 rounded-pill'>Presolicitud de Exhibidores</h4>
										</div>

										<h5 className="text-center mt-2 mb-4 text-belmeny"><i>Vendedor - {user.Nombre} - {user.CodVendedor}</i></h5>

										<div className="mt-5">
											<h3 className="text-center">Registro de solicitud</h3>

											<div className="p-5 my-3 rounded border-belmeny">
												<div className="row">
													<div className="col">
														<div className="row mt-3">
															<div className="col-2">
																<h4><label htmlFor="selectCliente">Cliente: </label></h4>
															</div>
															<div className="col-6">
																<div className="form-group">
																	<Typeahead
																		id="basic-typeahead-single"
																		labelKey="name"
																		onChange={(e) => {
																			setSingleSelections(e.name)
																			handleSelect(e)
																		}}
																		options={clientes}
																		placeholder="Selecciona un cliente..."
																		selected={singleSelections}
																		preventOverflow={false}
																		required
																	/>
																</div>
															</div>
														</div>

														<div className="mt-3">
															<p className="fs-6">Acumulado de ventas al cliente en los últimos 3 meses: <span className="text-success fw-bold">${totalVendido.toFixed(2)}</span></p>
															<p className="fs-6">Saldo pendiente por cobrar: <span className="text-danger fw-bold">${saldoPendiente.toFixed(2)}</span></p>
														</div>

														<div className="mt-1 fw-normal fs-6">
															<label htmlFor="marca" className='me-3'>Marca del exhibidor: </label>
															<div className="form-check form-check-inline">
																<input
																	className="form-check-input"
																	type="radio"
																	name="marca"
																	id="marcaExhibidorVert"
																	value="VERT"
																	onChange={(e) => setMarcaExhibidor(e.target.value)}
																	required
																/>
																<label className="form-check-label" htmlFor="marcaExhibidorVert">VËRT</label>
															</div>
															<div className="form-check form-check-inline">
																<input
																	className="form-check-input"
																	type="radio"
																	name="marca"
																	id="marcaExhibidorIngco"
																	value="INGCO"
																	onChange={(e) => setMarcaExhibidor(e.target.value)}
																	required
																/>
																<label className="form-check-label" htmlFor="marcaExhibidorIngco">INGCO</label>
															</div>
														</div>

														<div className="mt-1">
															<p className="fs-6"><label htmlFor="motivoSolicitud" className="form-label">Sugerencia y/u Observación para la solicitud: </label></p>
															<textarea
																className="form-control"
																id="motivoSolicitud"
																name="motivoSolicitud"
																onChange={(e) => setSugerencia(e.target.value)}
																value={sugerencia}
																rows="3"
																required
															></textarea>
														</div>
													</div>
													<div className="col">
														{
															(!loading2) ?
																<>
																	<h4 className="text-center">Ventas realizadas al cliente:</h4>
																	<Chart type='line' data={chartData} className='mt-4' />
																</> : <></>
														}
													</div>
												</div>

												<div className="gallery">
													<div className="row mt-4">
														<h6 className="text-center mb-3" data-toggle="tooltip" data-placement="top" title="Tooltip on top">Imágenes del local del cliente <br /><span className="text-danger fw-bold">*REQUISITO NECESARIO*</span></h6>
														{/* Galeria parte 1 */}
														<div className="col-4 text-center">
															<label htmlFor="images1" className='Foto1st drop-shadow'>
																<h5 className="fst-italic py-4 bg-primary text-light upload-title">Fachada exterior</h5>
																<img src={(imgFachada) ? imgFachada : fachada} alt="" style={(imgFachada) ? { width: "480px", height: "480px" } : { width: "480px", height: "480px", filter: 'brightness(30%)' }} className="" />
																<input type="file" name="images1" id="images1" className='custom-file' onChange={e => handleImgs(e.target.files, '1st')} required accept=".jpg, .png, .jpeg" />
															</label>
														</div>
														<div className="col-4 text-center">
															<label htmlFor="images2" className='Foto1st drop-shadow'>
																<h5 className="fst-italic py-4 bg-primary text-light upload-title">Vista 90° lateral izquierdo del local</h5>
																<img src={(imgExtremoIzq) ? imgExtremoIzq : extremoIzq} alt="" style={(imgExtremoIzq) ? { width: "480px", height: "480px" } : { width: "480px", height: "480px", filter: 'brightness(30%)' }} className="" />
																<input type="file" name="images2" id="images2" className='custom-file' onChange={e => handleImgs(e.target.files, '2nd')} required accept=".jpg, .png, .jpeg" />
															</label>
														</div>
														<div className="col-4 text-center">
															<label htmlFor="images3" className='Foto1st drop-shadow'>
																<h5 className="fst-italic py-4 bg-primary text-light upload-title">Vista 90° lateral derecho del local</h5>
																<img src={(imgExtremoDer) ? imgExtremoDer : extremoDer} alt="" style={(imgExtremoDer) ? { width: "480px", height: "480px" } : { width: "480px", height: "480px", filter: 'brightness(30%)' }} className="" />
																<input type="file" name="images3" id="images3" className='custom-file' onChange={e => handleImgs(e.target.files, '3rd')} required accept=".jpg, .png, .jpeg" />
															</label>
														</div>
													</div>
													{/* Galeria parte 2 */}
													<div className="row mt-4">
														<div className="col-4 text-center">
															<label htmlFor="images4" className='Foto1st drop-shadow'>
																<h5 className="fst-italic py-4 bg-primary text-light upload-title">Lugar deseado para el exhibidor 1</h5>
																<img src={(imgExhibidor1) ? imgExhibidor1 : exhibidor1} alt="" style={(imgExhibidor1) ? { width: "480px", height: "480px" } : { width: "480px", height: "480px", filter: 'brightness(30%)' }} className="" />
																<input type="file" name="images4" id="images4" className='custom-file' onChange={e => handleImgs(e.target.files, '4th')} required accept=".jpg, .png, .jpeg" />
															</label>
														</div>
														<div className="col-4 text-center">
															<label htmlFor="images5" className='Foto1st drop-shadow'>
																<h5 className="fst-italic py-4 bg-primary text-light upload-title">Lugar deseado para el exhibidor 2</h5>
																<img src={(imgExhibidor2) ? imgExhibidor2 : exhibidor2} alt="" style={(imgExhibidor2) ? { width: "480px", height: "480px" } : { width: "480px", height: "480px", filter: 'brightness(30%)' }} className="" />
																<input type="file" name="images5" id="images5" className='custom-file' onChange={e => handleImgs(e.target.files, '5th')} required accept=".jpg, .png, .jpeg" />
															</label>
														</div>
														<div className="col-4 text-center">
															<label htmlFor="images6" className='Foto1st drop-shadow'>
																<h5 className="fst-italic py-4 bg-primary text-light upload-title">Lugar deseado para el exhibidor 3</h5>
																<img src={(imgExhibidor3) ? imgExhibidor3 : exhibidor3} alt="" style={(imgExhibidor3) ? { width: "480px", height: "480px" } : { width: "480px", height: "480px", filter: 'brightness(30%)' }} className="" />
																<input type="file" name="images6" id="images6" className='custom-file' onChange={e => handleImgs(e.target.files, '6th')} required accept=".jpg, .png, .jpeg" />
															</label>
														</div>
													</div>
													{/* Galeria parte 3 */}
													<div className="row mt-4">
														<div className="col-4 text-center">
															<label htmlFor="images7" className='Foto1st drop-shadow'>
																<h5 className="fst-italic py-4 bg-primary text-light upload-title">Exhibidor de potencial competencia 1</h5>
																<img src={(imgCompetencia1) ? imgCompetencia1 : competencia1} alt="" style={(imgCompetencia1) ? { width: "480px", height: "480px" } : { width: "480px", height: "480px", filter: 'brightness(30%)' }} className="" />
																<input type="file" name="images7" id="images7" className='custom-file' onChange={e => handleImgs(e.target.files, '7th')} required accept=".jpg, .png, .jpeg" />
															</label>
														</div>
														<div className="col-4 text-center">
															<label htmlFor="images8" className='Foto1st drop-shadow'>
																<h5 className="fst-italic py-4 bg-primary text-light upload-title">Exhibidor de potencial competencia 2</h5>
																<img src={(imgCompetencia2) ? imgCompetencia2 : competencia2} alt="" style={(imgCompetencia2) ? { width: "480px", height: "480px" } : { width: "480px", height: "480px", filter: 'brightness(30%)' }} className="" />
																<input type="file" name="images8" id="images8" className='custom-file' onChange={e => handleImgs(e.target.files, '8th')} required accept=".jpg, .png, .jpeg" />
															</label>
														</div>
														<div className="col-4 text-center">
															<label htmlFor="images9" className='Foto1st drop-shadow'>
																<h5 className="fst-italic py-4 bg-primary text-light upload-title">Exhibidor de potencial competencia 3</h5>
																<img src={(imgCompetencia3) ? imgCompetencia3 : competencia3} alt="" style={(imgCompetencia3) ? { width: "480px", height: "480px" } : { width: "480px", height: "480px", filter: 'brightness(30%)' }} className="" />
																<input type="file" name="images9" id="images9" className='custom-file' onChange={e => handleImgs(e.target.files, '9th')} required accept=".jpg, .png, .jpeg" />
															</label>
														</div>
													</div>
												</div>
												<div className="mt-3">
													<button className="btn btn-danger w-100 mb-3" onClick={e => resetGallery()}>Reiniciar Galería</button>
													<br />
													<button className="btn btn-success w-100" onClick={e => submitAllData(e)}>Registrar presolicitud de exhibidor</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
					}
				</div>
			</Layout>
		</>
	)
}
