import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/Layout';
import AuthUser from '../../components/AuthUser';

import { AnalisisCliente } from './AnalisisCliente';
import { SolicitudInfo } from './SolicitudInfo';
import { ArmadoInfo } from './ArmadoInfo';

import { postSolicitud } from '../../api/request';

import moment from 'moment/moment';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const FormularioExhibidor = () => {

  const { user } = AuthUser();
  const navigate = useNavigate();

  // State del loading
  const [loading, setLoading] = useState(true);
  const MySwal = withReactContent(Swal)

  // Paginacion del formulario
  const [page, setPage] = useState(0)
  const Pasos = ['Paso 1/3', 'Paso 2/3', 'Paso 3/3']
  const FormTitles = ['Información de Solicitud', 'Arma tu Exhibidor']

  var aprobacionSup = (user.CodVendedor != null ? 'En espera' : 'Aprobado')

  const today = moment().format("YYYY-M-D HH:mm:ss");

  // Datos
  const [formData, setFormData] = useState({
    nro_pedido: "",
    usuario: user.Nombre,
    cliente: "",
    fecha_solicitud: today,
    marca: "",
    motivoSolicitud: "",
    tipoExhibidor: "",
    anchoExhibidor: 0,
    altoExhibidor: 0,
    monto_exhibidor: 1000,
    material_exhibidor: 'N/A',
    aprobacion_supervisor: aprobacionSup,
    aprobacion_gerencia: 'En espera'
  });

  const PageDisplay = () => {
    if (page === 0) {
      return <SolicitudInfo formData={formData} setFormData={setFormData} />;
    } else {
      return <ArmadoInfo formData={formData} setFormData={setFormData} />;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, []);

  return (
    <>
      <Layout>
        <div className="container-fluid mt-5">
          {
            (loading) ?
              <>
                <div className="text-center mx-auto position-absolute top-50 start-50">
                  <span className="loader-documentos"></span>
                </div>
              </> :
              <>

                {/* Dashboard for mobile version */}
                <div className="d-sm-block d-md-none">
                  <div className="my-4">
                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Solicitud de Exhibidores</h4>
                    </div>
                  </div>
                </div>

                {/* Dashboard for web version */}
                <div className='d-none d-md-block d-lg-block'>
                  <div className="container-fluid rounded">
                    <div className="dashboard-title mt-2 mb-3">
                      <h4 className='bg-belmeny text-light px-5 rounded-pill'>Solicitud de Exhibidores</h4>
                    </div>
                  </div>

                  <div className="mt-5">
                    <h5 className="text-center">{Pasos[page]}</h5>
                    <h3 className="text-center">{FormTitles[page]}</h3>
                  </div>
                  <div className="form">
                    <div className="progress">
                      <div
                        className="progress-bar" role="progressbar"
                        style={{ width: page === 0 ? "50%" : "100%" }}
                      ></div>
                    </div>
                    <div className="form-container">
                      <div className="header">

                      </div>
                      <div className="body">{PageDisplay()}</div>
                      <div className="footer text-center">
                        <button
                          className='btn btn-danger mx-3'
                          disabled={page == 0}
                          onClick={() => {
                            setPage((currPage) => currPage - 1);
                          }}
                        >
                          Anterior
                        </button>
                        <button
                          className='btn btn-success'
                          onClick={async () => {
                            if (page === FormTitles.length - 1) {
                              console.log(formData);
                              try {
                                const res = await postSolicitud(formData.nro_pedido, formData.usuario, formData.cliente, formData.marca, formData.motivoSolicitud, formData.tipoExhibidor, formData.anchoExhibidor, formData.altoExhibidor, formData.material_exhibidor, formData.monto_exhibidor, formData.aprobacion_supervisor, formData.aprobacion_gerencia, formData.fecha_solicitud)
                                console.log(res)
                                if (res.status === 200) {
                                  MySwal.fire({
                                    title: <strong>Solicitud envíada</strong>,
                                    html: <i>Tu solicitud ha sido envíada de manera exitosa. Revisa tu buzón para seguir su proceso de aprobación.</i>,
                                    icon: 'success'
                                  }).then(() => {
                                    navigate('/dashboard/buzon')
                                  })
                                }
                              } catch (error) {
                                MySwal.fire({
                                  title: <strong>Ups! Algo ha salido mal...</strong>,
                                  html: <i>Ha ocurrido un error al enviar tu solicitud. Intentalo de nuevo por favor.</i>,
                                  icon: 'error'
                                }).then(() => {
                                  window.location.reload(false);
                                })
                              }
                            } else {
                              setPage((currPage) => currPage + 1);
                            }
                          }}
                        >
                          {page === FormTitles.length - 1 ? "Enviar Solicitud" : "Siguiente"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }
        </div>
      </Layout>
    </>
  )
}
