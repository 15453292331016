import React from 'react'

import Chart from "react-apexcharts";

export const CobranzasZona = ({ fpapData }) => {
  return (
    <>
      <div className="row">
        <div className="col bg-white rounded drop-shadow-card">
          <div className="container-fluid mx-auto row bg-belmeny3 text-light mt-3 py-3 rounded-top">
            <div className="col-2">
              <h5 className="text-uppercase">ZONA</h5>
            </div>
            <div className="col">
              <h5 className="text-uppercase text-center">FACTURADO</h5>
            </div>
            <div className="col">
              <h5 className="text-uppercase text-center">CANCELADO</h5>
            </div>
            <div className="col">
              <h5 className="text-uppercase text-center">PENDIENTE</h5>
            </div>
            <div className="col">
              <h5 className="text-uppercase text-center">GRÁFICA</h5>
            </div>
          </div>
          {
            fpapData.map((item) =>
              <div className="row mt-3 container-fluid mx-auto pb-4">
                <div className="col-2 my-auto">
                  <h5 className="text-belmeny text-uppercase fw-normal">{item.Sector}</h5>
                </div>
                <div className="col my-auto">
                  <h6 className="text-center">
                    <span className="text-primary">
                      ${((item.Facturado) - (item.Facturado * 0.031)).toFixed(2)}
                    </span>
                  </h6>
                </div>
                <div className="col my-auto">
                  <h6 className="text-center">
                    <span className="text-success">
                      ${((item.CanceladoMasAbonado) - (item.CanceladoMasAbonado * 0.031)).toFixed(2)}
                    </span>
                  </h6>
                </div>
                <div className="col my-auto">
                  <h6 className="text-center">
                    <span className="text-danger">
                      ${((item.Pendiente) - (item.Pendiente * 0.031)).toFixed(2)}
                    </span>
                  </h6>
                </div>
                <div className="col my-auto">
                  <Chart
                    options={{
                      labels: ['Facturado', 'Cancelado', 'Pendiente'],
                      legend: {
                        show: false,
                      },
                      plotOptions: {
                        pie: {
                          donut: {
                            size: "80%",
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        style: {
                          colors: ['#000']
                        }
                      },
                      colors: ['#0d6efd', '#198754', '#dc3545']
                    }}
                    series={[((item.Facturado) - (item.Facturado * 0.031)), ((item.CanceladoMasAbonado) - (item.CanceladoMasAbonado * 0.031)), ((item.Pendiente) - (item.Pendiente * 0.031))]}
                    type="pie"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}
