import React from 'react'

import AuthUser from '../../../components/AuthUser'

import brokenImg from '../../../assets/img/broken-image-general.png'

export const DetalleConsultaProductos = (props) => {

  var Productos = props.item

  const { user } = AuthUser();

  const handleImageError = (event) => {
    event.target.src = brokenImg; // Replace with your default image URL
  };

  return (
    <>
      <tr className=''>
        {
          (user.CodGerente === 'MARKETINGBELMENY') ?
            <>
              <td className='text-danger fw-bold'>
                {Productos.Cliente}
              </td>
              <td className='' style={{ width: '10%' }}>
                {Productos.NombreCliente}
              </td>
              <td className=''>
                {Productos.SubZona}
              </td>
              <td>
                {Productos.DireccionDespacho}
              </td>
              <td className='text-center'>
                <img src={Productos.RutaImagen} onError={handleImageError} alt="" className='text-center' />
              </td>
              <td className='text-danger fw-bold'>
                {Productos.CodProducto}
              </td>
              <td>
                {Productos.NomProducto}
              </td>
              <td>
                {Productos.Existencia} {(Productos.UnidEmpaque !== '') ? Productos.UnidEmpaque : <>UND</>}
              </td>
              <td>
                {Productos.Cantidad} {(Productos.UnidEmpaque !== '') ? Productos.UnidEmpaque : <>UND</>}
              </td>
              <td>
                {new Date(Productos.fechadoc + 'T00:00:00Z').toLocaleDateString('es-MX', { timeZone: 'UTC' })}
              </td>
              {/* <td>
          {new Date(Productos.fechadoc+'T00:00:00Z').toLocaleDateString('es-MX', {timeZone: 'UTC'})}
          {/* {new Date(Productos.fechadoc).toLocaleDateString('es-MX')} */}
              {/* </td>  */}
            </> :
            <>
              <td className='text-center'>
                <img src={Productos.RutaImagen} onError={handleImageError} alt="" className='text-center' />
              </td>
              <td className='text-danger fw-bold'>
                {Productos.Codigo}
              </td>
              <td>
                {Productos.Nombre}
              </td>
              <td>
                {Productos.Existencia} {Productos.UnidEmpaque}
              </td>
              <td>
                {Productos.VentaMinima} {Productos.UnidEmpaque}
              </td>
            </>
        }

      </tr>
    </>
  )
}
