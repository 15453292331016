import React, { useState, useEffect } from 'react'

import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import Html from 'react-pdf-html';
import { Margin, usePDF } from "react-to-pdf";

import AuthUser from '../../../components/AuthUser';
import { RiPrinterFill } from "react-icons/ri";

import cintilloVert from '../../../assets/img/img-catalogo/cintillos/cintillo-vert-logo-productos-ON.png'

import { getCatalogoVert, getFamilias, getProductosByFamilia } from '../../../api/requestCatalogo';

export const Vert = (props) => {

  useEffect(() => {
    loadCatalogo()
  }, [])

  const [loading, setLoading] = useState(true)

  const [productos, setProductos] = useState([]);
  const [rows, setRows] = useState([]);

  const loadCatalogo = async () => {
    setLoading(true)
    const allProducts = [];
    const responseFamilias = await getFamilias()

    for (const familia of responseFamilias) {
      const productosFam = await getProductosByFamilia(familia.ORN_Familia)
      allProducts.push(...productosFam);
    }

    setProductos(allProducts);

    const formattedRows = [];
    let currentRow = []

    allProducts.forEach((producto, index) => {
      currentRow.push(producto)
      if (currentRow.length === 3 || index === productos.length - 1) {
        formattedRows.push(currentRow);
        currentRow = [];
      }
    })

    setRows(formattedRows);
    // const resCatalogo = await getCatalogoVert()

    // if (resCatalogo.data.length > 0) {
    //   setProductos(resCatalogo.data)
    //   setLoading(false)
    // }
    setLoading(false)
  }

  const { toPDF, targetRef } = usePDF({
    filename: "usepdfHook-example.pdf",
    page: {
      margin: Margin.MEDIUM
    }
  })

  // const styles = StyleSheet.create({
  //   page: {
  //     padding: 20,
  //   },
  //   section: {
  //     flexDirection: 'row',
  //     flexWrap: 'wrap',
  //     justifyContent: 'space-between',
  //   },
  //   rowItem: {
  //     backgroundColor: '#0049b1',
  //     // margin: 10,
  //     // padding: 10,
  //   },
  //   item: {
  //     width: '23%',
  //     marginTop: 10,
  //     marginBottom: 10,
  //     padding: 5,
  //     backgroundColor: '#0049b1',
  //     // border: '1px solid #ccc',
  //     borderRadius: '10px'
  //   },
  // });

  // const itemsPerRow = 4;
  // const itemsPerPage = 16;

  // const pages = [];
  // for (let i = 0; i < productos.length; i += itemsPerPage) {
  //   const pageItems = productos.slice(i, i + itemsPerPage);
  //   const page = (
  //     <Page key={i} size="A3" style={styles.page}>
  //       <View style={styles.section}>
  //         <Image src={cintilloVert} style={{ width: '100%' }} />
  //         {pageItems.map((item, index) => (
  //           //<View key={index} style={styles.rowItem}>
  //             <View style={styles.item}>
  //               <Text style={{ fontSize: '8px', textAlign: 'center', color: 'white', marginBottom: 3, paddingHorizontal: 7 }}>
  //                 {item.Nombre}
  //               </Text>
  //               <Image
  //                 src={item.RutaImagen}
  //                 style={{ width: '150px', marginHorizontal: 'auto', marginVertical: 2, borderRadius: '3px' }}
  //               />
  //               <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
  //                 <Text style={{ fontSize: '10px', textAlign: 'left', color: 'white', marginBottom: 3, marginTop: 3, paddingLeft: 10 }}>{item.Codigo} {item.UnidEmpaque}</Text>
  //                 <Text style={{ fontSize: '10px', textAlign: 'rigth', color: 'white', marginBottom: 3, marginTop: 3, paddingRight: 10, fontWeight: 'bold' }}>{item.Precio1.toFixed(2)} / {item.UnidEmpaque}</Text>
  //               </View>
  //               <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
  //                 <Text style={{ fontSize: '10px', textAlign: 'center', color: 'white', marginBottom: 3, paddingHorizontal: 7, fontWeight: 'bold' }}>Bulto: {item.CantBulto}</Text>
  //                 <Text style={{ fontSize: '10px', textAlign: 'center', color: 'white', marginBottom: 3, paddingHorizontal: 7 }}>Min: {item.VentaMinima} / {item.UnidEmpaque}</Text>
  //               </View>
  //             </View>
  //           //</View>
  //         ))}
  //         {Array(itemsPerRow - (pageItems.length % itemsPerRow))
  //           .fill()
  //           .map((_, index) => (
  //             <View key={pageItems.length + index} style={{ width: '23%' }} />
  //           ))}
  //       </View>
  //     </Page>
  //   );
  //   pages.push(page);
  // }

  return (
    <>
      {
        (loading) ?
          <>Cargando...</>
          :
          <>
            <button className="my-4" onClick={toPDF}>Download PDF</button>
            <div className="container" ref={targetRef}>
              <img src={cintilloVert} alt="" className='rounded w-100' />
              {/* Map the productos and group them into divs with 3 columns each */}
              {rows.map((row, rowIndex) => (
                <div className='row my-3 mx-auto' key={rowIndex}>
                  {row.map((item, colIndex) => (
                    <div className="col bg-items-vert text-white py-3 rounded drop-shadow-card">
                      <div className="row">
                        <div className="col text-center fw-bold">
                          {item.descripcion} <br />
                          <img src={`https://www.belmeny.com/catalogo/${item.codigo}.png`} alt="" className='rounded drop-shadow-card mt-2' />
                        </div>
                        <div className="row text-center mt-2">
                          <div className="col">
                            <span className='float-left'>{item.codigo}</span>
                          </div>
                          <div className="col">
                            <span className='float-right fw-bold'>${item.Precio} / {item.UnidEmpaque}</span>
                          </div>
                        </div>
                        <div className="row text-center">
                          <div className="col">
                            <span className='float-left fw-bold'>Bulto: 100</span>
                          </div>
                          <div className="col">
                            <span className='float-right fw-bold'>100 / pza</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              {/* {productos.map((item, index) => (
          index % 3 === 0 ? ( // Check if it's the start of a new group of 3
            <div className='row my-3 mx-auto' key={index}>
              <div className='col bg-items-vert text-white py-3 rounded drop-shadow-card'>
                <div className="row">
                  <div className="col text-center fw-bold">
                    {item.Nombre} <br />
                    <img src={`https://www.belmeny.com/api/${item.codigo}.png`} alt="" className='rounded drop-shadow-card mt-2' />
                  </div>
                </div>
                <div className="row text-center mt-2">
                  <div className="col">
                    <span className='float-left'>{item.Codigo}</span>
                  </div>
                  <div className="col">
                    <span className='float-right fw-bold'>${item.Precio1} / {item.UnidEmpaque}</span>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col">
                    <span className='float-left fw-bold'>Bulto: {item.CantBulto}</span>
                  </div>
                  <div className="col">
                    <span className='float-right fw-bold'>{item.Existencia} / {item.UnidEmpaque}</span>
                  </div>
                </div>
              </div>
              {productos.slice(index + 1, index + 3).map((subItem, subIndex) => (
                <div className='col bg-items-vert text-white py-3 mx-1 rounded drop-shadow-card' key={subIndex + 1}>
                  <div className="row">
                    <div className="col text-center fw-bold">
                      {subItem.Nombre} <br />
                      <img src={subItem.RutaImagen} alt="" className='rounded drop-shadow-card mt-2' />
                    </div>
                  </div>
                  <div className="row text-center mt-2">
                    <div className="col">
                      <span className='float-left'>{subItem.Codigo}</span>
                    </div>
                    <div className="col">
                      <span className='float-right fw-bold'>${subItem.Precio1} / {subItem.UnidEmpaque}</span>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col">
                      <span className='float-left fw-bold'>Bulto: {subItem.CantBulto}</span>
                    </div>
                    <div className="col">
                      <span className='float-right fw-bold'>{subItem.Existencia} / {subItem.UnidEmpaque}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null
        ))} */}
            </div>
          </>
      }
    </>
  )
}


{/* 
<div className="row text-center mt-2">
                    <div className="col">
                      <span className='float-left'>{subItem.Codigo}</span>
                    </div>
                    <div className="col">
                      <span className='float-right fw-bold'>${subItem.Precio1} / {subItem.UnidEmpaque}</span>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col">
                      <span className='float-left fw-bold'>Bulto: {subItem.CantBulto}</span>
                    </div>
                    <div className="col">
                      <span className='float-right fw-bold'>{subItem.Existencia} / {subItem.UnidEmpaque}</span>
                    </div>
                  </div>
                  <div className="row text-center mt-2">
                    <div className="col">
                      <span className='float-left'>{subItem.Codigo}</span>
                    </div>
                    <div className="col">
                      <span className='float-right fw-bold'>${subItem.Precio1} / {subItem.UnidEmpaque}</span>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col">
                      <span className='float-left fw-bold'>Bulto: {subItem.CantBulto}</span>
                    </div>
                    <div className="col">
                      <span className='float-right fw-bold'>{subItem.Existencia} / {subItem.UnidEmpaque}</span>
                    </div>
                  </div>
<div className="row text-center mt-2">
                  <div className="col">
                    <span className='fw-bold'>Codigo</span>
                  </div>
                  <div className="col-4">
                    <span className='fw-bold'>WATTS / Lumens</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>Medidas</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>Precio</span>
                  </div>
                </div>
                <div className="row text-center mt-2">
                  <div className="col">
                    <span className='fw-bold'>{item.Codigo}</span>
                  </div>
                  <div className="col-4">
                    <span className='fw-bold'>5W / 6500k</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>55x85</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>${item.Precio1}</span>
                  </div>
                </div>
                <div className="row text-center mt-2">
                  <div className="col">
                    <span className='fw-bold'>{item.Codigo}</span>
                  </div>
                  <div className="col-4">
                    <span className='fw-bold'>5W / 6500k</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>55x85</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>${item.Precio1}</span>
                  </div>
                </div>
                <div className="row text-center mt-2">
                  <div className="col">
                    <span className='fw-bold'>{item.Codigo}</span>
                  </div>
                  <div className="col-4">
                    <span className='fw-bold'>5W / 6500k</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>55x85</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>${item.Precio1}</span>
                  </div>
                </div>
                <div className="row text-center mt-2">
                  <div className="col">
                    <span className='fw-bold'>{item.Codigo}</span>
                  </div>
                  <div className="col-4">
                    <span className='fw-bold'>5W / 6500k</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>55x85</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>${item.Precio1}</span>
                  </div>
                </div>
                <div className="row text-center mt-2">
                  <div className="col">
                    <span className='fw-bold'>{item.Codigo}</span>
                  </div>
                  <div className="col-4">
                    <span className='fw-bold'>5W / 6500k</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>55x85</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>${item.Precio1}</span>
                  </div>
                </div>
                <div className="row text-center mt-2">
                  <div className="col">
                    <span className='fw-bold'>{item.Codigo}</span>
                  </div>
                  <div className="col-4">
                    <span className='fw-bold'>5W / 6500k</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>55x85</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>${item.Precio1}</span>
                  </div>
                </div>
                <div className="row text-center mt-2">
                  <div className="col">
                    <span className='fw-bold'>{item.Codigo}</span>
                  </div>
                  <div className="col-4">
                    <span className='fw-bold'>5W / 6500k</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>55x85</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>${item.Precio1}</span>
                  </div>
                </div>
                <div className="row text-center mt-2">
                  <div className="col">
                    <span className='fw-bold'>{item.Codigo}</span>
                  </div>
                  <div className="col-4">
                    <span className='fw-bold'>5W / 6500k</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>55x85</span>
                  </div>
                  <div className="col">
                    <span className='fw-bold'>${item.Precio1}</span>
                  </div>
                </div>
                {/* <div className="row text-center mt-2">
                  <div className="col">
                    <span className='float-left'>{item.Codigo}</span>
                  </div>
                  <div className="col">
                    <span className='float-right fw-bold'>${item.Precio1} / {item.UnidEmpaque}</span>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col">
                    <span className='float-left fw-bold'>Bulto: {item.CantBulto}</span>
                  </div>
                  <div className="col">
                    <span className='float-right fw-bold'>{item.Existencia} / {item.UnidEmpaque}</span>
                  </div>
                </div> */}