import React, { useState } from 'react'
import jsPDF from 'jspdf'
import AuthUser from '../../components/AuthUser'
import { getClienteRIF, getClientesByVendedorAndRif } from '../../api/requestClientes';
import { getProductosFacturaPDF } from '../../api/requestFacturas';

import { RiPrinterFill } from "react-icons/ri";
import { belmenyJpg, ingcoJpg, vertJpg, fleximaticJpg, quilosaJpg, imouJpg } from '../../assets/img'
import brokenImg from '../../assets/img/broken-image-general.png';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const FacturaPDF = (props) => {

  const [disableBtn, setDisableBtn] = useState(false)

  // Sweet alert
  const MySwal = withReactContent(Swal)
  const customSwal = MySwal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ms-2',
      denyButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  var Usuario = props.user
  var Factura = props.Factura
  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;
  var CodigoCliente, Documento, Fecha, FechaV

  CodigoCliente = Factura.codcliente
  Documento = Factura.Documento
  Fecha = new Date(Factura.FechaDocumento.split(" ")[0]).toLocaleDateString('es-MX')
  FechaV = new Date(Factura.FechaVencimiento.split(" ")[0]).toLocaleDateString('es-MX')

  const generatePDF = async () => {
    var doc = new jsPDF('portrait', 'mm', 'a3');

    // var Cliente = await getClientesByVendedorAndRif(Usuario.CodVendedor, CodigoCliente)

    var Cliente

    if (Usuario.CodSupervisor === null) {
      Cliente = await getClienteRIF(CodigoCliente)
    } else {
      Cliente = await getClientesByVendedorAndRif(Usuario.CodVendedor, CodigoCliente)
    }

    const productos = await getProductosFacturaPDF(Documento, CodigoCliente);

    var textSpacing = 90
    var imgSpacing = 80
    var sumTextSpacing = 23;
    var logo

    const imgWidth = 20;
    const imgHeight = 20;

    for (let i = 0; i < productos.data.length; i++) {
      if ((i === 0) || (i === 14) || (i === 29) || (i === 44) || (i === 59) || (i === 74) || (i === 89) || (i === 104) || (i === 119) || (i === 134) || (i === 149) || (i === 164) || (i === 179) || (i === 194) || (i === 209) || (i === 224) || (i === 239) || (i === 254) || (i === 269) || (i === 284) || (i === 299) || (i === 314) || (i === 329) || (i === 344) || (i === 359) || (i === 374) || (i === 389) || (i === 404) || (i === 419) || (i === 434) || (i === 449) || (i === 464) || (i === 479) || (i === 494) || (i === 509) || (i === 524)) {

        switch (i) {
          //2
          case 14:
            doc.addPage()
            doc.setPage(2)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //3
          case 29:
            doc.addPage()
            doc.setPage(3)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //4
          case 44:
            doc.addPage()
            doc.setPage(4)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //5
          case 59:
            doc.addPage()
            doc.setPage(5)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //6
          case 74:
            doc.addPage()
            doc.setPage(6)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //7
          case 89:
            doc.addPage()
            doc.setPage(7)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //8
          case 104:
            doc.addPage()
            doc.setPage(8)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //9
          case 119:
            doc.addPage()
            doc.setPage(9)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //10
          case 134:
            doc.addPage()
            doc.setPage(10)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //11
          case 149:
            doc.addPage()
            doc.setPage(11)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //12
          case 164:
            doc.addPage()
            doc.setPage(12)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //13
          case 179:
            doc.addPage()
            doc.setPage(13)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //14
          case 194:
            doc.addPage()
            doc.setPage(14)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //15
          case 209:
            doc.addPage()
            doc.setPage(15)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //16
          case 224:
            doc.addPage()
            doc.setPage(16)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //17
          case 239:
            doc.addPage()
            doc.setPage(17)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //18
          case 254:
            doc.addPage()
            doc.setPage(18)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //19
          case 269:
            doc.addPage()
            doc.setPage(19)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //20
          case 284:
            doc.addPage()
            doc.setPage(20)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //21
          case 299:
            doc.addPage()
            doc.setPage(21)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //22
          case 314:
            doc.addPage()
            doc.setPage(22)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //23
          case 329:
            doc.addPage()
            doc.setPage(23)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //24
          case 344:
            doc.addPage()
            doc.setPage(24)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //25
          case 359:
            doc.addPage()
            doc.setPage(25)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //26
          case 374:
            doc.addPage()
            doc.setPage(26)
            doc.line(10, 10, 200, 10);

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //27
          case 389:
            doc.addPage()
            doc.setPage(27)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //28
          case 404:
            doc.addPage()
            doc.setPage(28)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //29
          case 419:
            doc.addPage()
            doc.setPage(29)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //30
          case 434:
            doc.addPage()
            doc.setPage(30)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //31
          case 449:
            doc.addPage()
            doc.setPage(31)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //32
          case 464:
            doc.addPage()
            doc.setPage(32)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //33
          case 479:
            doc.addPage()
            doc.setPage(33)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //34
          case 494:
            doc.addPage()
            doc.setPage(34)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //35
          case 509:
            doc.addPage()
            doc.setPage(35)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          //36
          case 524:
            doc.addPage()
            doc.setPage(36)

            // Spacing reset
            textSpacing = 90
            imgSpacing = 80
            sumTextSpacing = 23;
            break;

          default:
            break;
        }

        //Cabecera
        doc.setFont('helvetica', 'normal')
        doc.setFontSize(24)
          .text("Belmeny Group, C.A", 10, 10)
          .setFontSize(12);
        doc.text("R.I.F.: J-412333739 / N.I.T.: -", 10, 15);
        doc.text("LA LIMPIA MARACAIBO, EDO - ZULIA. TLF: 0261-7593849. FAX: -", 10, 20);
        doc.addImage(belmenyJpg, "JPG", 248, 3, 40, 30);
        doc.setFontSize(14)
          .setTextColor("#002975")
          .text("Factura ", 261, 36)
          .text("" + Documento, 258.1, 41)
          .setFontSize(10)
          .setTextColor("#000");
        //Fin cabecera

        //PARTE DE PEDIDO
        doc.setLineWidth(0.5)
          .setFontSize(12)
          .setDrawColor(0)
          .setFillColor(255, 255, 255)
          .roundedRect(245, 42, 50, 25, 3, 3, "FD")
          .line(245, 49, 295, 49).line(245, 61, 295, 61)
          .line(245, 56, 295, 56).line(270, 42, 270, 67);

        doc.text("Emisión", 258, 47.5, null, null, "center")
          .text("" + Fecha, 258, 54, null, null, "center");

        doc.text("Vence", 282, 47.5, null, null, "center")
          .text("" + FechaV, 282, 54, null, null, "center");

        doc.text("Fecha", 258, 60, null, null, "center");
        doc.text("" + Fecha, 258, 65.5, null, null, "center");

        doc.text("Páginas", 282, 60, null, null, "center")
          .text("" + doc.internal.getNumberOfPages(), 282, 65.5, null, null, "center");
        //FIN PARTE DE PEDIDO

        //INFORMACION CLIENTE
        doc.setDrawColor(0)
          .setFillColor(255, 255, 255)
          .roundedRect(10, 25, 230, 41, 3, 3, "FD")
          .line(10, 35, 240, 35)
          .line(10, 54, 240, 54)
          .line(10, 60, 240, 60);

        doc.setFontSize(12)
          .text("Cliente", 12, 29)
          .text("Cliente: R.I.F.:" + CodigoCliente + " \n" + Cliente[0].Nombre, 12, 29, {
            maxWidth: 190
          });

        doc.text("Domicilio fiscal", 12, 39)
          .text("Domicilio fiscal: " + Cliente[0].DireccionFiscal, 12, 39, {
            maxWidth: 190
          })

        doc.text("Teléfono", 12, 58)
        doc.text("Teléfono: " + Cliente[0].Telefono1, 12, 58)

        doc.text("Correo", 12, 64)
        doc.text("Correo: " + Cliente[0].Correo, 12, 64)
        //FIN INFORMACION CLIENTE

        //CONTENIDO
        doc.setFontSize(14)
        doc.setLineWidth(0.5);
        doc.line(10, 70, 295, 70);
        doc.text("Imagén", 13, 76.5)
        doc.text("Código", 40, 76.5)
        doc.text("Articulo", 70, 76.5)
        doc.text("Cantidad", 180, 76.5)
        doc.text("Precio Unitario", 210, 76.5)
        doc.text("Subtotal", 260, 76.5)
        doc.line(10, 79, 295, 79);
      }

      function getDataUri(url) {
        return new Promise((resolve, reject) => {
          var image = new Image();
          image.src = url;
          image.setAttribute('crossOrigin', 'anonymous'); //getting images from external domain

          image.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = this.naturalWidth;
            canvas.height = this.naturalHeight;

            //next three lines for white background in case png has a transparent background
            var ctx = canvas.getContext('2d');
            ctx.fillStyle = '#fff';  /// set white fill style
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            canvas.getContext('2d').drawImage(this, 0, 0);
            resolve(canvas.toDataURL('image/jpeg'));
          };

          //reject on error case
          image.onerror = function () {
            image.src = brokenImg
          }
        })
      }

      logo = await getDataUri('https://www.belmeny.com/catalogo/' + productos.data[i].Codigo + '.png');

      doc.addImage(logo, 'PNG', 11, imgSpacing, imgWidth, imgHeight);
      doc.setFontSize(12).text("" + productos.data[i].Codigo, 40, textSpacing)
      doc.setFontSize(12).text("" + (productos.data[i].codalternativo !== undefined) ? productos.data[i].codalternativo : "N/A", 38, textSpacing + 5)
      doc.text("" + productos.data[i].Nombre, 70, textSpacing, {
        maxWidth: 100
      })
      doc.text("" + productos.data[i].Cantidad + " UD", 183, textSpacing)
      doc.setTextColor("#198754").text("$" + productos.data[i].PrecioUnitario.toFixed(2), 222, textSpacing)
      doc.text("$" + productos.data[i].Subtotal.toFixed(2), 263, textSpacing).setTextColor("#000")

      if (textSpacing === 136) {
        sumTextSpacing = 22
      }

      textSpacing += sumTextSpacing
      imgSpacing += 22

      // LOGOS
      doc.addImage(vertJpg, "JPG", 40, 406, 32, 13);
      doc.addImage(ingcoJpg, "JPG", 90, 406, 35, 12);
      doc.addImage(imouJpg, "JPG", 140, 404, 35, 15);
      doc.addImage(quilosaJpg, "JPG", 190, 405, 32, 14);
      doc.addImage(fleximaticJpg, "JPG", 240, 406, 30, 10);
    }

    doc.setLineWidth(0.5)
    doc.setFontSize(14)
    doc.rect(10, 393, 280, 10);

    if (Factura.Descuento !== 0) {
      doc.text("Subtotal:  $" + Factura.BaseImponible.toFixed(2), 15, 400)
      doc.text("Flete: $" + Factura.Flete.toFixed(2), 65, 400)
      doc.text("Tasa IVA: $" + Factura.TasaIVA.toFixed(2), 105, 400)
      doc.text("Descuento: $" + Factura.Descuento.toFixed(2), 150, 400)
      doc.text("Total Final: $" + Factura.TotalFinal, 240, 400)
    }
    else {
      doc.text("Subtotal:  $" + Factura.BaseImponible.toFixed(2), 15, 400)
      doc.text("Flete: $" + Factura.Flete.toFixed(2), 65, 400)
      doc.text("Tasa IVA: $" + Factura.TasaIVA.toFixed(2), 105, 400)
      doc.text("Descuento: $" + Factura.Descuento.toFixed(2), 150, 400)
      doc.text("Total Final: $" + Factura.TotalFinal, 240, 400)
    }

    var fileName = 'Factura ' + Documento + '.pdf'
    doc.save(fileName)
    setDisableBtn(false)
  }

  const loadGeneratePDF = () => {
    setDisableBtn(true)
    generatePDF()
    customSwal.fire({
      title: 'Generando archivo PDF',
      text: 'Espere un momento mientras se genera el PDF (puede tardar unos minutos por la longitud de la factura).',
      icon: 'info',
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true
    });
  }


  return (
    <>
      <div className='d-md-block d-none'>
        {
          (disableBtn) ?
            <button className='btn btn-primary btn-sm mb-2 w-100 disabled' disabled>Generar Reporte</button>
            :
            <button onClick={loadGeneratePDF} className='btn btn-primary btn-sm mb-2 w-100'>Generar Reporte</button>
        }
      </div>

      <div className="d-sm-block d-md-none">
        {
          (disableBtn) ?
            <RiPrinterFill className="text-muted fs-2" />
            :
            <RiPrinterFill className="text-belmeny fs-2" onClick={loadGeneratePDF} />
        }
      </div>
    </>
  )
}