import React from 'react'

import AuthUser from '../../components/AuthUser';
import { getProductosPresupuestosPDF } from '../../api/requestPresupuestos';

const ExcelJS = require("exceljs")

export const PresupuestosXLS = ({ Presupuesto, fileName }) => {

  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;
  const agencias = ['001', '003', '004', 'CCS'];
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Presupuesto");
    sheet.properties.defaultRowHeight = 20;
    sheet.properties.defaultColWidth = 30;

    const productos = await getProductosPresupuestosPDF(Presupuesto.Documento)

    sheet.getColumn(1).header = ["Presupuesto", "Cliente", "RIF", "Vendedor"]
    sheet.getColumn(2).header = [Presupuesto.Documento, Presupuesto.NombreCliente, Presupuesto.Codcliente, `${user.CodVendedor} - ${user.Nombre}`]

    for (let i = 0; i < 4; i++) {
      sheet.getCell(`A${i + 1}`).font = {
        name: "Arial",
        family: 4,
        size: 14,
        bold: true,
      }

      sheet.getCell(`B${i + 1}`).font = {
        name: "Arial",
        family: 4,
        size: 14,
        bold: true,
      }
    }

    sheet.getRow(5).addPageBreak()

    sheet.getRow(6).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0041FF' }
    };

    sheet.getRow(6).font = {
      name: "Arial",
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFF' }
    };

    //  Valida la zona del vendedor e imprime si es de Caracas con la Zona, de no ser imprime sin Zona
    //en la validacion de agencias se usa el include que hace comparacion entre el arreglo de string y el dato de la Agencias
    if(ZonasVenta === '001,003,004,CCS'){
      sheet.getRow(6).values = ['Codigo', 'Producto', 'Precio', 'Cantidad', 'Subtotal', 'Zona'];
    sheet.getRow(6).addPageBreak()

    productos.data.forEach(producto => {
      const rowData = [producto.Codigo, producto.Nombre, producto.PrecioUnit, producto.Cantidad, producto.Subtotal, producto.Agencia === 'CCS' ? 'CARACAS CCS' : agencias.includes(producto.Agencia) ? 'MARACAIBO' : '']
      sheet.addRow(rowData);
    });
    }else{
    sheet.getRow(6).values = ['Codigo', 'Producto', 'Precio', 'Cantidad', 'Subtotal'];
    sheet.getRow(6).addPageBreak()

    productos.data.forEach(producto => {
      const rowData = [producto.Codigo, producto.Nombre, producto.PrecioUnit, producto.Cantidad, producto.Subtotal]
      sheet.addRow(rowData);
    });
    }
    

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Presupuesto.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }

  return (
    <>
      <div className='d-md-block d-none'>
        <button onClick={exportToExcel} className='btn btn-primary btn-sm mb-2 w-100'>Generar Reporte XLSX</button>
      </div>
    </>
  )
}
