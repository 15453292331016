import React, { useState } from 'react'

import Chart from "react-apexcharts";

export const TopVendedores = ({ zonasSupervisor, multipleTops, topData, topData2, topData3 }) => {
  return (
    <>
      <div className="container-fluid px-2">
        {
          (multipleTops) ?
            <>
              <div className="row">
                <div className="col bg-white drop-shadow-card rounded">
                  <h4 className="text-center text-belmeny fst-italic mt-3">Top vendedores zona: {zonasSupervisor[0].Sector}</h4>
                  <Chart
                    options={topData.options}
                    series={topData.series}
                    type="bar"
                    width="75%"
                    className="d-flex align-center justify-content-center"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col bg-white drop-shadow-card rounded">
                  <h4 className="text-center text-belmeny fst-italic mt-3">Top vendedores zona: {zonasSupervisor[1].Sector}</h4>
                  <Chart
                    options={topData2.options}
                    series={topData2.series}
                    type="bar"
                    width="75%"
                    className="d-flex align-center justify-content-center"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col bg-white drop-shadow-card rounded">
                  <h4 className="text-center text-belmeny fst-italic mt-3">Top vendedores zona: {zonasSupervisor[2].Sector}</h4>
                  <Chart
                    options={topData3.options}
                    series={topData3.series}
                    type="bar"
                    width="75%"
                    className="d-flex align-center justify-content-center"
                  />
                </div>
              </div>
            </> :
            <>
              <div className="row  d-flex align-middle justify-content-center">
                <div className="col w-75 bg-white drop-shadow-card">
                  <h4 className="text-center text-belmeny fst-italic mt-3">Top vendedores zona {zonasSupervisor[0].Sector}</h4>
                  <Chart
                    options={topData.options}
                    series={topData.series}
                    type="bar"
                    width="75%"
                    className="d-flex align-middle justify-content-center"
                  />
                </div>
              </div>
            </>
        }
      </div>
    </>
  )
}
