import axios from "axios";
import { baseURL } from "./ApiURL"

export async function getCatalogoVert() {
  try {
    const res = await axios.get(baseURL + `/getCatalogoVert`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getCatalogoByGrupo(seccion) {
  try {
    const res = await axios.get(baseURL + `/getCatalogoByGrupo?seccion=${seccion}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getCatalogoByGrupos(seccion) {
  try {
    const res = await axios.get(baseURL + `/getCatalogoByGrupos?seccion=${seccion}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getFamilias() {
  try {
    const res = await axios.get(baseURL + `/getFamilias`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getProductosByFamilia(familia) {
  try {
    const res = await axios.get(baseURL + `/getProductosByFamilia?familia=${familia}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}