
import React, { useState, useEffect } from 'react'

import AuthUser from '../../../../components/AuthUser';
import { PedidoPDF } from '../../../PDF/PedidoPDF';
import { ProductosPedido } from './ProductosPedido';
import { ProductosPedidoMobile } from './ProductosPedidoMobile';

import { getProductos, getMontoProductosPedido } from '../../../../api/requestPedidos'
import { RequestPage, getVendedor } from '../../../../api/request';

import Pagination from 'react-responsive-pagination';
import { Dropdown, ButtonToolbar } from 'rsuite';
import { Modal } from 'react-bootstrap';
import { RiCloseCircleFill } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";
import { AiFillEye } from "react-icons/ai";

export const DetallePedido = (props) => {

  var Pedidos = props.item
  var Vendedor = props.vendedor

  // State del usuario
  const { user } = AuthUser();
  const ZonasVenta = user.ZonasVenta;

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [vendedor, setVendedor] = useState('')
  const [codigoVendedor, setCodigoVendedor] = useState('')
  const [productos, setProductos] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [cantProds, setCantProds] = useState(0);

  // States de paginas
  // eslint-disable-next-line
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  function handleShow() {
    setFullscreen(true);
    setShow(true);
    loadProductos();
  }

  const loadProductos = async () => {
    setLoading(true)

    const [vendedor, productos, subtotal] = await Promise.all([getVendedor(Pedidos.Vendedor), getProductos(Pedidos.Documento), getMontoProductosPedido(Pedidos.Documento)])
    const vend = await vendedor;
    const res = await productos;
    const montoSubt = await subtotal;

    if (vend[0].CodVendedor === 'V1') {
      setVendedor('OFICINA')
    } else {
      setVendedor(vend[0].Nombre)
    }

    setCodigoVendedor(vend[0].CodVendedor)

    setSubTotal(montoSubt[0].Subtotal)
    setCantProds(montoSubt[0].CantProductos)

    setPage(res.meta.current_page)
    setTotalPages(res.meta.last_page)
    setUrlPage(res.links.first)

    setProductos(res.data)
    setLoading(false)
  }

  const loadPage = async (pageData) => {
    setLoading(true)

    const productoData = await RequestPage(pageData);

    setPage(productoData.meta.current_page)
    setTotalPages(productoData.meta.last_page)
    setUrlPage(productoData.links.first)

    setProductos(productoData.data)
    setLoading(false)
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    loadPage(urlPage.replace(/.$/, page))
  }

  return (
    <>
      <tr className='d-none-table'>
        <td className='text-danger fw-bold text-center'>
          {Pedidos.Documento}
        </td>
        <td>
          {Pedidos.NombreCliente}
        </td>
        {(user.CodGerente !== null) ? <td className='d-md-block-table d-none-table fw-bold text-belmeny text-center'>{Vendedor}</td> : null}
        <td className='d-md-block-table d-none-table fw-bold text-success text-center'>
          ${Pedidos.Monto}
        </td>
        <td className='text-center'>{new Date(Pedidos.fechayhora).toLocaleDateString('es-MX')}</td>
        <td>
          <p className="btn btn-success btn-sm mt-2 mb-2 w-100" onClick={() => handleShow()}>Ver detalles</p>
          <PedidoPDF user={user} Pedido={Pedidos} />
        </td>
      </tr>

      <div className="container-fluid d-block d-sm-block d-md-none">
        <div className="card my-3 drop-shadow border-belmeny">
          <div className="card-body">
            <div className="row">
              <div className="col-9">
                <h5 className="card-title text-belmeny">{Pedidos.NombreCliente.toUpperCase()}</h5>
              </div>
              <div className="col-3">
                <ButtonToolbar className=''>
                  <Dropdown title={<SlOptionsVertical />} activeKey="e-2" className="border-belmeny">
                    <Dropdown.Item eventKey="details"><AiFillEye className='text-belmeny fs-2' onClick={() => handleShow()} /></Dropdown.Item>
                    <Dropdown.Item eventKey="report"><PedidoPDF user={user} Pedido={Pedidos} /></Dropdown.Item>
                  </Dropdown>
                </ButtonToolbar>
              </div>
            </div>
            <h6 className="card-subtitle mb-2 text-muted fst-italic">{Pedidos.Documento}</h6>
            {(user.CodGerente !== null) ? <h6 className="card-subtitle mb-2 text-belmeny fst-italic">{Pedidos.Nombre}</h6> : null}
            <h6>Monto: <span className="text-success fw-bold">${Pedidos.Monto.toFixed(2)}</span></h6>
            <h6 className='my-1'>Fecha: {new Date(Pedidos.fechayhora).toLocaleDateString('es-MX')}</h6>
          </div>
        </div>
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>Información del pedido: {Pedidos.Documento}</Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-4"></div>
        <Modal.Body>
          <div className="container-fluid">

            {/* Body for desktop version */}
            <div className='d-none d-md-block'>
              <h3 className="bg-belmeny text-light rounded-pill text-center w-50 m-auto py-1 mb-3">Vendedor Encargado: {vendedor}</h3>
              <div className="row badge-vendedor rounded-pill">
                <div className="col">
                  <h3 className='text-start ms-3'>RIF: {Pedidos.Codcliente}</h3>
                </div>
                <div className="col">
                  <h4 className='text-end me-4'>{Pedidos.NombreCliente}</h4>
                </div>
              </div>

              <div className="container mt-3">
                <div className="row text-center">
                  <div className="col">
                    <h4><b>Fecha del pedido:</b> {new Date(Pedidos.fechayhora).toLocaleDateString('es-MX')}</h4>
                    <h4><b>Tipo de pedido:</b> {Pedidos.TipoPedido}</h4>
                    <h4><b>Forma de pago:</b> {Pedidos.FormaPago}</h4>
                  </div>
                  <div className="col">
                    <h4><b>Responsable:</b> {Pedidos.Responsable}</h4>
                    <h4><b>Sincronizado:</b> {(Pedidos.Descargado === 1) ? 'Si' : 'En proceso'}</h4>
                    {
                      (Pedidos.FormaPago === 'PREPAGO') ?
                        <>
                          <h4><b>¿Aplica descuento?</b> Si</h4>
                          <h4><b>Monto del descuento: <span className='text-success'>${(subTotal * 0.10).toFixed(2)}</span></b></h4>
                        </> :
                        <>
                        </>
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <h4>
                      <b>Total de productos:</b> {cantProds}
                    </h4>
                  </div>
                </div>
              </div>

              <h3 className='bg-belmeny m-auto w-50 rounded-pill py-2 text-center text-light mt-3'>Lista de productos</h3>
              {
                (loading) ?
                  <>
                    <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                      <span className="loader-documentos"></span>
                    </div>
                  </> :
                  <>
                    <div className="my-3">
                      <table className='table table-responsive table-bordered table-hover border-belmeny'>
                        <thead className='bg-belmeny text-light text-center'>
                          <tr>
                            <th style={{ width: '10%' }}>Imagén</th>
                            <th>Código</th>
                            <th>Producto</th>
                            <th>Precio Unitario</th>
                            <th>Cantidad</th>
                            <th>Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productos.map((item) => <ProductosPedido item={item} codVendedor={codigoVendedor} key={item.Codigo} ZonasVenta={ZonasVenta} />)}
                        </tbody>
                      </table>
                    </div>

                    <Pagination
                      total={totalPages}
                      current={currentPage}
                      onPageChange={page => handlePageChange(page)}
                    />

                    <div className="row mt-3">
                      <div className="col">
                        <h4>Comentarios: </h4>
                        <h4 className="border-belmeny p-3 w-75">
                          {
                            (Pedidos.Comentarios !== '') ?
                              <>
                                <span>{Pedidos.Comentarios}</span>
                              </> :
                              <>
                                <span className='fs-6'>No se agregaron comentarios...</span>
                              </>
                          }
                        </h4>
                      </div>
                      {
                        (Pedidos.FormaPago === 'PREPAGO') ?
                          <>
                            <div className="col">
                              <h4>Subtotal: </h4>
                              <h2 className='text-end pe-5 fs-1 text-success'>${subTotal.toFixed(2)}</h2>
                            </div>
                            <div className="col">
                              <h4>Descuento: </h4>
                              <h2 className='text-end pe-5 fs-1 text-success'>${(subTotal * 0.10).toFixed(2)}</h2>
                            </div>
                            <div className="col">
                              <h4>Total Final: </h4>
                              <h2 className='text-end pe-5 fs-1 text-success'>${Pedidos.Monto.toFixed(2)}</h2>
                            </div>
                          </> :
                          <>
                            <div className="col">
                              <h4>Subtotal: </h4>
                              <h2 className='text-end pe-5 fs-1 text-success'>${subTotal.toFixed(2)}</h2>
                            </div>
                            <div className="col">
                              <h4>Descuento: </h4>
                              <h2 className='text-end pe-5 fs-1'>$0.00</h2>
                            </div>
                            <div className="col">
                              <h4>Total Final: </h4>
                              <h2 className='text-end pe-5 fs-1 text-success'>${Pedidos.Monto.toFixed(2)}</h2>
                            </div>
                          </>
                      }
                    </div>
                  </>
              }
            </div>

            {/* Body for mobile version */}
            <div className='d-block d-sm-none'>

              <h5 className="bg-belmeny text-light rounded-pill text-center m-auto py-1 mb-3">Vendedor Encargado: {vendedor}</h5>
              <div className="badge-vendedor rounded-pill py-2">
                <div className="row">
                  <h5 className='text-center ms-3'>RIF: {Pedidos.Codcliente}</h5>
                </div>
                <div className="row">
                  <h5 className='text-center me-4'>{Pedidos.NombreCliente}</h5>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col text-center">
                  <h6><b>Fecha del pedido:</b> {new Date(Pedidos.fechayhora).toLocaleDateString('es-MX')}</h6>
                  <h6><b>Tipo de pedido:</b> {Pedidos.TipoPedido}</h6>
                  <h6><b>Forma de pago:</b> {Pedidos.FormaPago}</h6>
                  <h6><b>Responsable:</b> {Pedidos.Responsable}</h6>
                  <h6><b>Sincronizado:</b> {(Pedidos.Descargado === 1) ? 'Si' : 'En proceso'}</h6>
                  {
                    (Pedidos.FormaPago === 'PREPAGO') ?
                      <>
                        <h6><b>¿Aplica descuento?</b> Si</h6>
                        <h6><b>Monto del descuento: ${(subTotal * 0.10).toFixed(2)}</b></h6>
                      </> :
                      <>
                      </>
                  }
                  <h6><b>Total de Productos:</b> {cantProds}</h6>
                </div>
              </div>
              <h5 className='bg-belmeny m-auto w-100 rounded-pill py-2 text-center text-light mt-3'>Lista de productos</h5>

              {
                (loading) ?
                  <>
                    <div className="text-center mx-auto position-loader-consultas-mobile w-75">
                      <span className="loader-documentos"></span>
                    </div>
                  </> :
                  <>
                    <div className="my-3">
                      {productos.map((item) => <ProductosPedidoMobile item={item} key={item.Codigo} ZonasVenta={ZonasVenta}/>)}
                    </div>

                    <Pagination
                      total={totalPages}
                      current={currentPage}
                      onPageChange={page => handlePageChange(page)}
                    />

                    <div className="row mt-3">
                      <div className="col">
                        <h4>Comentarios: </h4>
                        <h4 className="border-belmeny p-3 w-100">
                          {
                            (Pedidos.Comentarios !== '') ?
                              <>
                                <span>{Pedidos.Comentarios}</span>
                              </> :
                              <>
                                <span className='fs-6'>No se agregaron comentarios...</span>
                              </>
                          }
                        </h4>
                      </div>
                    </div>
                    <div className="row mt-4">
                      {
                        (Pedidos.FormaPago === 'PREPAGO') ?
                          <>
                            <h4>Subtotal: <span className="text-success">${subTotal}</span></h4>
                            <h4>Descuento: <span className="text-success">${(subTotal * 0.10).toFixed(2)}</span></h4>
                            <h4>Total Final: <span className="text-success">${Pedidos.Monto.toFixed(2)}</span></h4>
                          </> :
                          <>
                            <h4>Subtotal: <span className="text-success">${Pedidos.Monto.toFixed(2)}</span></h4>
                            <h4>Descuento: $0</h4>
                            <h4>Total Final:  <span className="text-success">${Pedidos.Monto.toFixed(2)}</span></h4>
                          </>
                      }
                    </div>
                  </>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
