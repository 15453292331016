import React from 'react'

import paginaWebCerrada from '../assets/img/PAGINA-WEB-EN-REMODELACION.jpg'

export const PageClosed = () => {

    return (
        <div className="vertical-center bg-belmeny text-center">
            <div className="container-fluid">
                <img src={paginaWebCerrada} alt="" style={{backgroundSize: 'cover', textAlign: 'center', width: '1800px', margin: '0 auto'}} className='rounded drop-shadow-card'/>
            </div>
        </div>
    )
}
