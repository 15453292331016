import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { AuthUser } from './AuthUser'

import { PedidosPorDiaXLS } from '../views/XLS/PedidosPorDiaXLS'

import { formatNumber } from '../utils/formats';

export const PedidosPorDiaTable = ({ data, resumenFacturado, resumenPedido }) => {

  const { user } = AuthUser()

  const navigate = useNavigate();

  const [finalUniqueDates, setFinalUniqueDates] = useState([]);
  const [finalSortedVendors, setFinalSortedVendors] = useState([]);
  const [finalVendorData, setFinalVendorData] = useState([]);

  const flete = 0.031

  // Function to determine if a date is a weekend
  const isWeekend = dateStr => {
    const date = new Date(dateStr);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 5 || dayOfWeek === 6; // Sunday (0) or Saturday (6)
  };

  // State for sorting Monto Pedido and Monto Facturado
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('TotalPedido'); // Default sorting by Monto Pedido

  // Get unique dates
  useEffect(() => {
    if (data && data.length > 0) {
      const uniqueDates = [...new Set(data.map(item => item.Fecha))];
      setFinalUniqueDates(uniqueDates);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0) {
      const uniqueVendors = [...new Set(data.map(item => item.CodVendedor))];
      const sortedVendors = [...uniqueVendors];
      sortedVendors.sort((a, b) => {
        const valueA = data.find(item => item.CodVendedor === a)[sortColumn] || 0;
        const valueB = data.find(item => item.CodVendedor === b)[sortColumn] || 0;
        return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
      });
      setFinalSortedVendors(sortedVendors);
    }
  }, [data, sortColumn, sortOrder]); // Run this effect whenever `data`, `sortColumn`, or `sortOrder` changes

  useEffect(() => {
    if (data && data.length > 0) {
      const newVendorData = finalSortedVendors.map(vendor => {
        return data.filter(item => item.CodVendedor === vendor);
      });
      setFinalVendorData(newVendorData);
    }
  }, [data, finalSortedVendors]);

  // Function to toggle the sorting order and column
  const toggleSortOrder = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
      setSortColumn(column);
    }
  };

  // Render the table header with dates
  const renderTableHeader = () => {
    const formatDate = (dateStr) => {
      const [year, month, day] = dateStr.split('-');
      return `${day}/${month}/${year}`;
    };

    return (
      <tr className='bg-belmeny text-light text-center pb-3'>
        <th>Vendedor</th>
        {(user.CodGerente === 'HABOULMOUNA') ? <th>Zona</th> : null}
        <th
          onClick={() => toggleSortOrder('nroPedidos')}
          style={{ cursor: 'pointer' }}
        >
          # Pedidos
          {sortColumn === 'nroPedidos' ? ` ${sortOrder === 'asc' ? '▲' : '▼'}` : ''}
        </th>
        <th
          onClick={() => toggleSortOrder('TotalPedido')}
          style={{ cursor: 'pointer' }}
        >
          Monto Pedido
          {sortColumn === 'TotalPedido' ? ` ${sortOrder === 'asc' ? '▲' : '▼'}` : ''}
        </th>
        <th
          onClick={() => toggleSortOrder('TotalFacturado')}
          style={{ cursor: 'pointer' }}
        >
          Monto Facturado
          {sortColumn === 'TotalFacturado' ? ` ${sortOrder === 'asc' ? '▲' : '▼'}` : ''}
        </th>
        {finalUniqueDates.map(date => (
          <th key={date}>
            {formatDate(date)}
          </th>
        ))}
      </tr>
    );
  };

  // Render the table rows for each vendor
  const renderTableRows = () => {
    return finalSortedVendors.map(vendor => {
      const vendorData = data.filter(item => item.CodVendedor === vendor);
      const totalPedidoSinFlete = (vendorData[0].TotalPedido.toFixed(2) - (vendorData[0].TotalPedido.toFixed(2) * flete))
      const totalFacturadoSinFlete = (vendorData[0].TotalFacturado.toFixed(2) - (vendorData[0].TotalFacturado.toFixed(2) * flete))
      // console.log(vendorData)

      return (
        <tr key={vendor}>
          <td style={{ width: '150px', height: '50px', display: 'block', margin: '0 auto' }}><span className="pt-3"><span className="text-danger fw-bold">{vendorData.length > 0 ? vendorData[0].CodVendedor : 'N/A'}</span> - {vendorData.length > 0 ? vendorData[0].Nombre : 'N/A'}</span></td>
          {(user.CodGerente === 'HABOULMOUNA') ? <td className='text-center'>{vendorData[0].sectores}</td> : null}
          <td className='text-center'>{vendorData[0].nroPedidos}</td>
          <td className='text-belmeny text-center' onClick={() => navigate(`/orders/${vendorData[0].CodVendedor}`)}><button className='btn btn-hover-details rounded-pill w-100'>${formatNumber(totalPedidoSinFlete.toFixed(2))}</button></td>
          <td className='text-belmeny text-center' onClick={() => navigate(`/invoices/${vendorData[0].CodVendedor}`)}><button className='btn btn-hover-details rounded-pill w-100'>${formatNumber(totalFacturadoSinFlete.toFixed(2))}</button></td>
          {vendorData.map(item => (
            <td
              key={item.Fecha}
              className={`${isWeekend(item.Fecha) ? 'bg-weekend text-center' : item.Pedidos === 0 ? 'bg-no-orders text-center' : 'text-center'}`}
            >
              {
                (isWeekend(item.Fecha)) ? <span className='text-white fw-bold'>{item.Pedidos}</span> : <span>{item.Pedidos}</span>
              }
            </td>
          ))}
        </tr>
      );
    });
  };

  return (
    <div style={{ overflowX: 'auto' }} className='table-responsive'>
      <div className="row mb-1">
        <h6 className="text-belmeny">Resumen monto pedido: {formatNumber(resumenPedido.toFixed(2))} USD</h6>
      </div>
      <div className="row mb-1">
        <h6 className="text-belmeny">Resumen monto facturado: {formatNumber(resumenFacturado.toFixed(2))} USD</h6>
      </div>
      <PedidosPorDiaXLS uniqueDates={finalUniqueDates} vendorData={finalVendorData} />
      <table className='table table-bordered table-hover border-belmeny'>
        <thead>{renderTableHeader()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </table>
    </div>
  )
}
