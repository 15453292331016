import React, { useState, useEffect } from 'react'

import AuthUser from '../../../components/AuthUser';

import { ManifiestoPDF } from '../../PDF/ManifiestoPDF';
import { getDetalleManifiesto } from '../../../api/requestManifiesto';

import { Dropdown, ButtonToolbar } from 'rsuite';
import { Modal } from 'react-bootstrap';
import { RiCloseCircleFill, RiPrinterFill } from "react-icons/ri";
import { SlOptionsVertical, SlOptions } from "react-icons/sl";
import { AiFillEye } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";

export const DetalleManifiesto = (props) => {

  var Manifiesto = props.item

  // State del usuario
  const { user } = AuthUser();

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [detalle, setDetalle] = useState([]);

  function handleShow() {
    setFullscreen(true);
    setShow(true);
    loadDetalle()
  }

  const loadDetalle = async () => {
    setLoading(true)
    const resDetalle = await getDetalleManifiesto(Manifiesto.Documento, user.CodVendedor)
    // console.log(resDetalle)

    var arrManifiesto = []
    var data = {}

    for (let i = 0; i < resDetalle.data.length; i++) {
      data = {
        DocPedido: resDetalle.data[i].DocPedido,
        DocFactura: resDetalle.data[i].DocFactura,
        NombreCliente: resDetalle.data[i].NombreCliente,
        FFacturacion: resDetalle.data[i].FFacturacion,
        Zona: resDetalle.data[i].Zona,
        SubZona: resDetalle.data[i].SubZona,
        Cajas: resDetalle.data[i].Cajas,
        Bolsas: resDetalle.data[i].Bolsas,
        BaseImponible: resDetalle.data[i].BaseImponible,
        DireccionDespacho: resDetalle.data[i].DireccionDespacho
      }
      arrManifiesto.push(data)
    }

    setDetalle(arrManifiesto)
    setLoading(false)
  }

  useEffect(() => {
  }, []);

  return (
    <>
      <tr className='d-none-table'>
        <td className='text-danger fw-bold text-center'>{Manifiesto.Documento}</td>
        <td className='text-center'>{Manifiesto.Estado}</td>
        <td className='text-center'>{new Date(Manifiesto.FechaSalida).toLocaleDateString('es-MX')}</td>
        <td className='d-md-block-table d-none-table text-center w-50'>{Manifiesto.EmpresaTransporte.toUpperCase()}</td>
        <td>
          <p className="btn btn-success btn-sm mt-2 mb-2 w-100" onClick={() => handleShow()}>Ver detalles</p>
          <ManifiestoPDF user={user} Manifiesto={Manifiesto} />
        </td>
      </tr>

      <div className="container-fluid d-sm-block d-md-none">
        <div className="card my-3 drop-shadow border-belmeny">
          <div className="card-body">
            <div className="row">
              <div className="col-9">
                <h5 className="card-title text-belmeny">{Manifiesto.EmpresaTransporte.toUpperCase()}</h5>
              </div>
              <div className="col-3">
                <ButtonToolbar >
                  <Dropdown title={<SlOptionsVertical />} activeKey="e-2" className='border-belmeny'>
                    <Dropdown.Item eventKey="details"><AiFillEye className='text-belmeny fs-2' onClick={() => handleShow()} /></Dropdown.Item>
                  </Dropdown>
                </ButtonToolbar>
              </div>
            </div>
            <h6 className="card-subtitle mb-2 text-muted fst-italic">{Manifiesto.Documento}</h6>
            <h6>Estado: <span className="text-success fw-bold">{Manifiesto.Estado}</span></h6>
            <h6 className='my-1'>Fecha: {new Date(Manifiesto.FechaSalida).toLocaleDateString('es-MX')}</h6>
          </div>
        </div>
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>Información del Manifiesto: {Manifiesto.Documento}</Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 my-4"></div>
        <Modal.Body>
          <div className="container-fluid">
            {/* Body for desktop version */}
            <div className='d-none d-md-block'>
              <h3 className="bg-belmeny text-light rounded-pill text-center w-50 m-auto py-1 mb-3">Vendedor Encargado: {user.Nombre}</h3>
              <div className="row badge-vendedor rounded-pill m-auto">
                <div className="col">
                  <h3 className='text-center py-2'>Detalle del Manifiesto</h3>
                </div>
              </div>

              <div className="container-fluid px-1 mx-auto mt-5">

                <div className="row">
                  <div className="col">
                    {
                      (loading) ?
                        <>
                          <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                            <span className="loader-documentos"></span>
                          </div>
                        </> :
                        <>
                          <table className='table table-responsive table-bordered table-hover border-belmeny text-center'>
                            <thead className='bg-belmeny text-light'>
                              <tr>
                                <th>Pedido</th>
                                <th>Factura</th>
                                <th>Clientes</th>
                                <th>Fecha de Facturacion</th>
                                <th>Zona</th>
                                <th>Sub Zona</th>
                                <th>Cajas</th>
                                <th>Bolsas</th>
                                <th>Base Imponible</th>
                                <th>Direccion de Despacho</th>
                              </tr>
                            </thead>
                            <tbody>
                              {detalle.map((item) => (
                                <>
                                  <tr>
                                    <td className="text-danger fw-bold">{item.DocPedido.toUpperCase()}</td>
                                    <td className="text-danger fw-bold">{item.DocFactura.toUpperCase()}</td>
                                    <td style={{ textAlign: 'justify' }}>{item.NombreCliente.toUpperCase()}</td>
                                    <td>{new Date(item.FFacturacion).toLocaleDateString('es-MX')}</td>
                                    <td>{item.Zona}</td>
                                    <td>{item.SubZona}</td>
                                    <td>{item.Cajas}</td>
                                    <td>{item.Bolsas}</td>
                                    <td className="text-success fw-bold">${item.BaseImponible}</td>
                                    <td style={{ textAlign: 'justify' }} className='w-25'>{(item.DireccionDespacho === null) ? <>N/A</> : <>{item.DireccionDespacho.toUpperCase()}</>}</td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                        </>
                    }

                  </div>
                </div>
                <div className="division bg-belmeny"></div>
              </div>
            </div>

            {/* Body for mobile version */}
            <div className='d-block d-sm-block d-md-none'>
              <h3 className="bg-belmeny text-light rounded-pill text-center m-auto py-1 mb-3">Vendedor Encargado: {user.Nombre}</h3>
              <div className="row badge-vendedor rounded-pill m-auto">
                <div className="col">
                  <h3 className='text-center py-3 fs-2'>Detalle manifiesto</h3>
                </div>
              </div>

              <div className="container-fluid px-1 mx-auto my-3">

                <div className="text-center rounded p-1 border">
                  {/* 1st header */}
                  <div className="row">
                    <div className="col">
                      <h6>Pedido</h6>
                    </div>
                    <div className="col">
                      <h6>Factura</h6>
                    </div>
                    <div className="col">
                      <h6>Fecha de Facturación</h6>
                    </div>
                  </div>
                  {/* 1st details */}
                  <div className="row mt-2">
                    {detalle.map((item) => (
                      <>
                        <div className="col">
                          <p className="fw-bold">{item.DocPedido.toUpperCase()}</p>
                        </div>
                        <div className="col">
                          <p className="fw-bold">{item.DocFactura.toUpperCase()}</p>
                        </div>
                        <div className="col">
                          <p className="fw-bold">{new Date(item.FFacturacion).toLocaleDateString('es-MX')}</p>
                        </div>
                      </>
                    ))}
                  </div>
                  {/* 2nd header */}
                  <div className="row mt-4 mb-2">
                    <div className="col">
                      <h6>Cajas</h6>
                    </div>
                    <div className="col">
                      <h6>Bolsas</h6>
                    </div>
                    <div className="col">
                      <h6>Base Imponible</h6>
                    </div>
                  </div>
                  {/* 2nd details */}
                  {detalle.map((item) => (
                    <>
                      <div className="row">
                        <div className="col">
                          <p className="fw-bold">{item.Cajas}</p>
                        </div>
                        <div className="col">
                          <p className="fw-bold">{item.Bolsas}</p>
                        </div>
                        <div className="col">
                          <p className="fw-bold text-success">${item.BaseImponible}</p>
                        </div>
                      </div>
                    </>
                  ))}
                  {/* 3rd header */}
                  <div className="row mt-4 mb-2">
                    <div className="col">
                      <h6>Zona</h6>
                    </div>
                    <div className="col">
                      <h6>Sub Zona</h6>
                    </div>
                  </div>
                  {/* 3rd details */}
                  {detalle.map((item) => (
                    <>
                      <div className="row">
                        <div className="col">
                          <p className="fw-bold">{item.Zona}</p>
                        </div>
                        <div className="col">
                          <p className="fw-bold">{item.SubZona}</p>
                        </div>
                      </div>
                    </>
                  ))}
                  {/* 4th header */}
                  <div className="row mt-4 mb-2">
                    <div className="col">
                      <h6>Dirección de Despacho</h6>
                    </div>
                  </div>
                  {/* 4th details */}
                  <table className='table table-bordered border-belmeny'>
                    <thead className='bg-belmeny text-light'>
                      <tr>
                        <th>Cliente</th>
                        <th>Dirección Despacho</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detalle.map((item) => (
                        <>
                          <tr className='fw-bold'>
                            <td>{item.NombreCliente}</td>
                            <td>{item.DireccionDespacho}</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="division bg-belmeny"></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>

  )
}
