import React, { useState } from 'react';

import { getListaDocVencidos, getDellateDocVencido } from '../../../api/requestCobranzas';

import { Modal } from 'react-bootstrap'
import { RiCloseCircleFill } from "react-icons/ri";
import { SlOptionsVertical } from "react-icons/sl";

export const ConsultaDocVencidos = () => {

  // State del loading
  const [loading, setLoading] = useState(true);

  // State de datos
  const [lista, setLista] = useState([]);
  const [detalleDocs, setDetalleDocs] = useState([]);

  // States del modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [showDetalle, setShowDetalle] = useState(false);

  const now = new Date();
  const firstDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth(), 1)).slice(1, 11);
  const lastDay = JSON.stringify(new Date(now.getFullYear(), now.getMonth() + 1, 0)).slice(1, 11);

  const loadLista = async () => {
    setLoading(true)
    const res = await getListaDocVencidos(firstDay, lastDay)
    setLista(res)
    setLoading(false)
  }

  const loadDetalles = async (CodigoVendedor) => {
    setLoading(true)
    const res = await getDellateDocVencido(CodigoVendedor, firstDay, lastDay)
    setDetalleDocs(res)
    setLoading(false)
  }

  function handleShow() {
    setFullscreen(true);
    setShow(true);
    loadLista();
  }

  const handleshowDetalle = (CodigoVendedor) => {
    setFullscreen(true);
    setShowDetalle(true);
    loadDetalles(CodigoVendedor)
  }

  return (
    <>
      <div className="mb-2">
        <button className="btn btn-primary float-end" onClick={(e) => handleShow()}>Consultar Documentos Vencidos</button>
      </div>

      {/* Lista de documentos vencidos */}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>Consulta de documentos vencidos
          </Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShow(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 mt-3 bg-belmeny"></div>
        <Modal.Body>

          <div className="container-fluid">
            {/* Div para escritorio */}
            <div className='d-none d-md-block d-lg-block'>
              <div className="row">
                <div className="col">
                  <div className="text-center">
                    <h3 className='bg-belmeny rounded-pill text-light fw-light m-auto mt-5 pt-3' style={{ width: "60%" }}>Lista de documentos vencidos x vendedor</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {
                    (loading) ?
                      <>
                        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                          <span className="loader-cobranzas"></span>
                        </div>
                      </> :
                      <>
                        <table className="table table-bordered border-belmeny rounded table-responsive table-hover text-center mt-5 w-75 m-auto">
                          <thead className="bg-belmeny text-light">
                            <tr>
                              <th className='py-2'>Código</th>
                              <th className='py-2'>Nombre</th>
                              <th className='py-2 w-25'>Documentos Vencidos</th>
                            </tr>
                          </thead>
                          <tbody className='fs-5'>
                            {
                              (lista.map((Docs) =>
                                <>
                                  <tr style={{ cursor: 'pointer' }} onClick={(e) => handleshowDetalle(Docs.CodigoVendedor)}>
                                    <td className='fw-bold text-danger py-2'>{Docs.CodigoVendedor}</td>
                                    <td className='py-2'>{Docs.Nombre}</td>
                                    <td className='py-2'>{Docs.Nro_documentos}</td>
                                  </tr>
                                </>
                              ))
                            }
                          </tbody>
                        </table>
                      </>
                  }
                </div>
              </div>
              <div className="division mt-5 bg-belmeny"></div>
              <h3 className="text-end text-belmeny fst-italic mt-2 pe-5">Belmeny Group C.A</h3>
            </div>

            {/* Div para móvil */}
            <div className="d-sm-block d-md-none">
              <div className="row">
                <div className="col">
                  <div className="text-center">
                    <h4 className='bg-belmeny rounded-pill text-light fw-light m-auto mt-5 p-2 px-4'>Lista de Documentos Vencidos x Vendedor</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {
                    (loading) ?
                      <>
                        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                          <span className="loader-cobranzas"></span>
                        </div>
                      </> :
                      <>
                        <div className="">
                          {lista.map((Docs) => (
                            <>
                              <div className="card my-3 drop-shadow border-belmeny">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-10">
                                      <h5 className="card-title text-belmeny">{Docs.Nombre.toUpperCase()}</h5>
                                    </div>
                                    <div className="col-2">
                                      <SlOptionsVertical className='text-belmeny fs-5 float-end' style={{ cursor: 'pointer' }} onClick={(e) => handleshowDetalle(Docs.CodigoVendedor)} />
                                    </div>
                                  </div>
                                  <h6 className="card-subtitle mb-2 text-muted fst-italic">{Docs.CodigoVendedor}</h6>
                                  <h6>Documentos Vencidos: <span className="fw-bold text-belmeny">{Docs.Nro_documentos}</span></h6>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                  }
                </div>
              </div>
              <div className="division mt-5 bg-belmeny"></div>
              <h3 className="text-end text-belmeny fst-italic mt-2 pe-5">Belmeny Group C.A</h3>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Detalle de documentos vencidos */}
      <Modal show={showDetalle} fullscreen={fullscreen} onHide={() => setShowDetalle(false)}>
        <Modal.Header className='bg-belmeny text-light'>
          <Modal.Title className='fst-italic'>Información del documento:
          </Modal.Title>
          <Modal.Title>
            <RiCloseCircleFill className='text-danger fs-1 modal-close bg-light rounded-circle' onClick={() => setShowDetalle(false)} />
          </Modal.Title>
        </Modal.Header>
        <div className="division w-100 mt-3 bg-belmeny"></div>
        <Modal.Body>

          <div className="container-fluid">
            {/* Div para escritorio */}
            <div className='d-none d-md-block d-lg-block'>
              <div className="row">
                <div className="col">
                  <div className="text-center">
                    <h3 className='bg-belmeny rounded-pill text-light fw-light m-auto mt-5 pt-3' style={{ width: "60%" }}>Lista de Documentos Vencidos</h3>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {
                    (loading) ?
                      <>
                        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                          <span className="loader-cobranzas"></span>
                        </div>
                      </> :
                      <>
                        <table className="table table-bordered border-belmeny rounded table-responsive table-hover text-center mt-5 w-75 m-auto">
                          <thead className="bg-belmeny text-light">
                            <tr>
                              <th className='py-2 w-25'>Cliente</th>
                              <th className='py-2'>Documento</th>
                              <th className='py-2'>Fecha de Vencimiento</th>
                              <th className='py-2'>Días Vencido</th>
                            </tr>
                          </thead>
                          <tbody className='fs-6'>
                            {
                              (detalleDocs.map((Docs) =>
                                <>
                                  <tr>
                                    <td className='fw-bold text-danger py-2'>{Docs.nombrecli}</td>
                                    <td className='py-2'>{Docs.Documento}</td>
                                    <td className='py-2'>{new Date(Docs.FechaVencimiento.split(" ")[0]).toLocaleDateString('es-MX')}</td>
                                    <td className='py-2 text-danger fw-bold'>{Docs.DiasVencido}</td>
                                  </tr>
                                </>
                              ))
                            }
                          </tbody>
                        </table>
                      </>
                  }
                </div>
              </div>
              <div className="division mt-5 bg-belmeny"></div>
              <h3 className="text-end text-belmeny fst-italic mt-2 pe-5">Belmeny Group C.A</h3>
            </div>

            {/* Div para móvil */}
            <div className='d-sm-block d-md-none'>
              <div className="row">
                <div className="col">
                  <div className="text-center">
                    <h4 className='bg-belmeny rounded-pill text-light fw-light m-auto mt-5 p-2'>Lista de Documentos Vencidos</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {
                    (loading) ?
                      <>
                        <div className="text-center">
                          <div className="m-auto spinner">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </div>
                        </div>
                      </> :
                      <>
                        <div className="">
                          {detalleDocs.map((Docs) => (
                            <>
                              <div className="card my-3 drop-shadow border-belmeny">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col">
                                      <h5 className="card-title text-belmeny">{Docs.nombrecli.toUpperCase()}</h5>
                                    </div>
                                  </div>
                                  <h6 className="card-subtitle mb-2 text-muted fst-italic">{Docs.Documento}</h6>
                                  <h6>Fecha de Vencimiento: <span className="">{new Date(Docs.FechaVencimiento.split(" ")[0]).toLocaleDateString('es-MX')}</span></h6>
                                  <h6 className='my-1'>Días Vencido: <span className='fw-bold text-danger'>{Docs.DiasVencido}</span></h6>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                  }
                </div>
              </div>
              <div className="division mt-5 bg-belmeny"></div>
              <h3 className="text-end text-belmeny fst-italic mt-2 pe-5">Belmeny Group C.A</h3>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </>
  )
}
