import React, { useState } from 'react'
import { Container, Header, Content } from 'rsuite';

import { NavbarComponent } from './NavbarComponent'

const Layout = ({ children }) => {

    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = (checked) => {
        setCollapsed(checked);
    };

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    return (
        <Container>
            <Header>
                <NavbarComponent/>
            </Header>
            <Content className='bg-dashboard satoshi-font'>{children}</Content>
        </Container>
    )
}

export default Layout;