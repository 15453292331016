import React, { useState } from 'react'

import Layout from '../../components/Layout'
import AuthUser from '../../components/AuthUser'

import { Modal, Form, Toast, ToastContainer, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { ListaPrecioVertXLS } from './ListaPrecioVertXLS'
import { ListaPrecioIngcoXLS } from './ListaPrecioIngcoXLS'
import { ListaPrecioWadfowXLS } from './ListaPrecioWadfowXLS'

import userIcon from '../../assets/img/user-icon-resize.webp';

export const PriceListView = () => {

  // State del usuario
  const { user, getToken } = AuthUser();

  // Toast
  const [showToast, setShowToast] = useState(true)

  return (
    <Layout>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col">
            <div className="text-belmeny">
              <h2 className='fs-1'><strong>Bienvenido</strong></h2>
              <h3>{user.Nombre}</h3>
              <h5><i>{user.Usuario}</i></h5>
            </div>
          </div>
          <div className="col">
            <img src={userIcon} alt="Logo Belmeny Group" className='float-end me-5 drop-shadow-sm' />
          </div>
        </div>
      </div>

      <div className="dashboard-title mt-2 mb-3">
        <h4 className='bg-belmeny text-light px-5 rounded-pill'>Listas de Precios CADENAS</h4>
      </div>

      {/* Toast */}
      <ToastContainer position="top-end" className="p-3 mt-5">
        <Toast onClose={() => setShowToast(false)} show={showToast}>
          <Toast.Header>
            <strong className="me-auto text-danger">⚠️ AVISO ⚠️</strong>
            <small>Hace un momento</small>
          </Toast.Header>
          <Toast.Body>La lista de precios tarda en generarse de 2 a 5 minutos, por favor esperar hasta que se genere...</Toast.Body>
        </Toast>
      </ToastContainer>
      {/* End Toast */}

      <div className="container mt-5 mb-5 drop-shadow-card bg-white rounded">
        <div className="row mt-4">
          <div className="col">
            <h2 className="text-center text-belmeny pt-3 mb-3">VËRT</h2>
            <div className="w-50 mx-auto">
              <ListaPrecioVertXLS />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <h2 className="text-center text-ingco mb-3">INGCO</h2>
            <div className="w-50 mx-auto mb-4">
              <ListaPrecioIngcoXLS />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <h2 className="text-center text-wadfow mb-3">WADFOW</h2>
            <div className="w-50 mx-auto mb-4">
              <ListaPrecioWadfowXLS />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
