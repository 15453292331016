import axios from "axios";
import { baseURL } from "./ApiURL"


/*
==================================================================================
PEDIDOS
==================================================================================
*/

export async function Pedidos(Vendedor, Gerente) {
  if (Vendedor === 'none') {
    try {
      const res = await axios.get(baseURL + `/getPedidosxGerente?CodSupervisor=${Gerente}`);
      return res.data;
    } catch (error) {
      console.log(error)
    }
  } else {
    try {
      const res = await axios.get(baseURL + `/getPedidos?Vendedor[eq]=${Vendedor}`);
      return res.data;
    } catch (error) {
      console.log(error)
    }
  }
}

export async function SearchPedidos(CodUsuario, Filtro, Busqueda, fechaInicio, fechaFin) {
  try {
    let res;
    if (Filtro === 'Fecha') {
      res = await axios.get(baseURL + `/getPedidosPorBusqueda?CodUsuario=${CodUsuario}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&whatToSearch=${Filtro}`);
    } else {
      res = await axios.get(baseURL + `/getPedidosPorBusqueda?CodUsuario=${CodUsuario}&Busqueda=${Busqueda}&whatToSearch=${Filtro}`)
    }
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getMontoProductosPedido(Documento) {
  try {
    const res = await axios.get(baseURL + `/getMontoProductosPedido?Documento=${Documento}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getProductos(Documento) {
  try {
    const res = await axios.get(baseURL + `/getProductosPedidos?Documento[eq]=${Documento}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

export async function getProductosPDF(Documento) {
  try {
    const res = await axios.get(baseURL + `/getProductosPedidosPDF?Documento[eq]=${Documento}`);
    return res.data;
  } catch (error) {
    console.log(error)
  }
}

