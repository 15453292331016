import React, { useState } from 'react'

import { TrendingUp, TrendingDown } from 'lucide-react';
import { startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek, subWeeks, format, setYear } from 'date-fns';

export const CorteSemanal = ({ zonasSupervisor, ventasSemanales, ventasGralZona }) => {

  console.log(ventasSemanales)
  const zonas = zonasSupervisor.filter(item => item.Sector !== "Principal Caracas" && item.Sector !== "Principal Zulia");

  console.log(zonas);

  //  Calculate the start and end of the last week before
  const beforeLastWeekStart = startOfWeek(subWeeks(new Date(), 2), { weekStartsOn: 1 });
  const beforeLastWeekEnd = endOfWeek(subWeeks(new Date(), 2), { weekStartsOn: 1 });

  //  Calculate the start and end of the last week
  const lastWeekStart = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
  const lastWeekEnd = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });

  //  Calculate the start and end of the current week
  const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
  const currentWeekEnd = endOfWeek(new Date(), { weekStartsOn: 1 });

  //  Format the dates as required (dd / mm / yyyy)
  const formattedBeforeLastWeekStart = format(beforeLastWeekStart, 'dd/MM');
  const formattedBeforeLastWeekEnd = format(beforeLastWeekEnd, 'dd/MM');
  const formattedLastWeekStart = format(lastWeekStart, 'dd/MM');
  const formattedLastWeekEnd = format(lastWeekEnd, 'dd/MM');
  const formattedCurrentWeekStart = format(currentWeekStart, 'dd/MM');
  const formattedCurrentWeekEnd = format(currentWeekEnd, 'dd/MM');

  //  Use the values in your state or wherever you need them
  const [startBeforeLastWeek, setStartBeforeLastWeek] = useState(formattedBeforeLastWeekStart);
  const [endBeforeLastWeek, setEndBeforeLastWeek] = useState(formattedBeforeLastWeekEnd);
  const [startLastWeek, setStartLastWeek] = useState(formattedLastWeekStart);
  const [endLastWeek, setEndLastWeek] = useState(formattedLastWeekEnd);
  const [startCurrentWeek, setStartCurrentWeek] = useState(formattedCurrentWeekStart);
  const [endCurrentWeek, setEndCurrentWeek] = useState(formattedCurrentWeekEnd);
  const [startActualWeek, setStartActualWeek] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }).toLocaleDateString('en-GB'))
  const [endActualWeek, setEndActualWeek] = useState(endOfWeek(new Date(), { weekStartsOn: 1 }).toLocaleDateString('en-GB'))

  return (
    <>
      <div className="row">
        <div className="col bg-white rounded drop-shadow-card">
          <div className="container-fluid mx-auto row bg-belmeny3 text-light rounded-top mt-3 py-3">
            <div className="col-2">
              <h5 className="text-uppercase">ZONA</h5>
            </div>
            <div className="col">
              <h5 className="text-uppercase text-center">Semana del {startBeforeLastWeek} al {endBeforeLastWeek}</h5>
            </div>
            <div className="col-1">
              <h5 className="text-uppercase text-center">vs</h5>
            </div>
            <div className="col">
              <h5 className="text-uppercase text-center">Semana del {startLastWeek} al {endLastWeek}</h5>
            </div>
            <div className="col-1">
              <h5 className="text-uppercase text-center">vs</h5>
            </div>
            <div className="col">
              <h5 className="text-uppercase text-center">Semana del {startCurrentWeek} al {endCurrentWeek}</h5>
            </div>
            <div className="col">
              <h5 className="text-uppercase text-center">Tendencia</h5>
            </div>
          </div>

          {
            zonas.map((Zona) =>
              <div className="row mt-3 container-fluid mx-auto pb-4">
                <div className="col-2">
                  <h5 className="text-belmeny text-uppercase fw-normal">{Zona.Sector}</h5>
                </div>
                <div className="col">
                  <h6 className="text-center">
                    {((ventasSemanales.semanaAntepasada.find((item) => item.Sector === Zona.Sector).ventasSemanal) - (ventasSemanales.semanaAntepasada.find((item) => item.Sector === Zona.Sector).ventasSemanal * 0.031)).toFixed(2)} USD -
                    <span className="text-success ms-1">
                      {((((ventasSemanales.semanaAntepasada.find((item) => item.Sector === Zona.Sector).ventasSemanal) - (ventasSemanales.semanaAntepasada.find((item) => item.Sector === Zona.Sector).ventasSemanal * 0.031)) * 100) / ventasGralZona.find((item) => item.Sector === Zona.Sector).meta_zona).toFixed(2)}%
                    </span>
                  </h6>
                </div>
                <div className="col-1">
                  <h6 className="text-center">-</h6>
                </div>
                <div className="col">
                  <h6 className="text-center">
                    {((ventasSemanales.semanaPasada.find((item) => item.Sector === Zona.Sector).ventasSemanal) - (ventasSemanales.semanaPasada.find((item) => item.Sector === Zona.Sector).ventasSemanal * 0.031)).toFixed(2)} USD -
                    <span className="text-success ms-1">
                      {((((ventasSemanales.semanaPasada.find((item) => item.Sector === Zona.Sector).ventasSemanal) - (ventasSemanales.semanaPasada.find((item) => item.Sector === Zona.Sector).ventasSemanal * 0.031)) * 100) / ventasGralZona.find((item) => item.Sector === Zona.Sector).meta_zona).toFixed(2)}%
                    </span>
                  </h6>
                </div>
                <div className="col-1">
                  <h6 className="text-center">-</h6>
                </div>
                <div className="col">
                  <h6 className="text-center">
                    {((ventasSemanales.semanaActual.find((item) => item.Sector === Zona.Sector).ventasSemanal) - ((ventasSemanales.semanaActual.find((item) => item.Sector === Zona.Sector).ventasSemanal * 0.031))).toFixed(2)} USD -
                    <span className="text-success ms-1">
                      {((((ventasSemanales.semanaActual.find((item) => item.Sector === Zona.Sector).ventasSemanal) - ((ventasSemanales.semanaActual.find((item) => item.Sector === Zona.Sector).ventasSemanal * 0.031))) * 100) / ventasGralZona.find((item) => item.Sector === Zona.Sector).meta_zona).toFixed(2)}%
                    </span>
                  </h6>
                </div>
                <div className="col">
                  <h6 className="text-center">
                    {
                      (
                        (
                          (ventasSemanales.semanaActual.find((item) => item.Sector === Zona.Sector).ventasSemanal) - ((ventasSemanales.semanaActual.find((item) => item.Sector === Zona.Sector).ventasSemanal * 0.031))
                        ).toFixed(2)
                        >
                        (
                          (ventasSemanales.semanaPasada.find((item) => item.Sector === Zona.Sector).ventasSemanal) - (ventasSemanales.semanaPasada.find((item) => item.Sector === Zona.Sector).ventasSemanal * 0.031)
                        ).toFixed(2)
                      ) ? <span className="text-success fw-bold">Alza <TrendingUp/></span> : <span className="text-danger fw-bold">Baja <TrendingDown/></span>
                    }
                  </h6>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}
