import axios from "axios";
import { baseURL } from "./ApiURL"


/*
==================================================================================
MANIFIESTO
==================================================================================
*/
//Request para traer los manifiestos
export async function getManifiesto(Vendedor, Gerente) {
  try {
    var res;
    if (Vendedor === 'none') {
      res = await axios.get(baseURL + `/getManifiestoVendedor?Vendedor=${Vendedor}`);
    } else {
      res = await axios.get(baseURL + `/getManifiestoVendedor?Vendedor=${Vendedor}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}
//Request para traer los manifiestos supervisor
export async function getManifiestoxSupervisor(Vendedor) {
  try {
    var res;
    if (Vendedor === 'none') {
      res = await axios.get(baseURL + `/getManifiestoxSupervisor?Supervisor=${Vendedor}`);
    } else {
      res = await axios.get(baseURL + `/getManifiestoxSupervisor?Supervisor=${Vendedor}`);
    }
    return res.data;
  } catch (error) {
    console.error(error)
  }
}
//Request para el detalle 
export async function getDetalleManifiesto(DocumentoDetalle, Vendedor) {
  try {
    const res = await axios.get(baseURL + `/getDetalleManifiesto?DocumentoDetalle[eq]=${DocumentoDetalle}&Vendedor[eq]=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}
//Request para el detalle supervisor
export async function getDetalleManifiestoxSupervisor(DocumentoDetalle, Vendedor) {
  try {
    const res = await axios.get(baseURL + `/getDetalleManifiestoxSupervisor?DocumentoDetalle=${DocumentoDetalle}&Supervisor=${Vendedor}`);
    if (Array.isArray(res.data)) {
      return { data: res.data };
    } else {
      return res.data;
    }
  } catch (error) {
    console.error(error)
  }
}


//Request para la busqueda por documento
export async function searchManifiestoDoc(Documento, Vendedor) {
  try {
    console.error(Documento)
    const res = await axios.get(baseURL + `/searchManifiesto?Documento=${Documento}&Vendedor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}
//Request para la busqueda por documento supervisor
export async function searchManifiestoDocxSupervisor(Documento, Vendedor) {
  try {
    console.error(Documento)
    const res = await axios.get(baseURL + `/searchManifiestoxSupervisor?Documento=${Documento}&Supervisor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}
//Request para la busqueda por cliente
export async function searchManifiestoCli(Cliente, Vendedor) {
  try {
    const res = await axios.get(baseURL + `/searchManifiesto?Cliente=${Cliente}&Vendedor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}
//Request para la busqueda por cliente supervisor 
export async function searchManifiestoClixSupervisor(Cliente, Vendedor) {
  try {
    const res = await axios.get(baseURL + `/searchManifiestoxSupervisor?Cliente=${Cliente}&Supervisor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}

//Request para la busqueda por fecha 
export async function searchManifiestoDates(fechaInicio, fechaFin, Vendedor) {
  try {
    const res = await axios.get(baseURL + `/searchManifiesto?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&Vendedor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}
//Request para la busqueda por fecha supervisor
export async function searchManifiestoDatesxSupervisor(fechaInicio, fechaFin, Vendedor) {
  try {
    const res = await axios.get(baseURL + `/searchManifiestoxSupervisor?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&Supervisor=${Vendedor}`);
    return res.data;
  } catch (error) {
    console.error(error)
  }
}