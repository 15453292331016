import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { Header } from '../../components/Header'

import { Vert } from '../PDF/CatalogoPDF/Vert'
import { DetalladoVert } from './Detallado/DetalladoVert'

import { getCatalogoByGrupo } from '../../api/requestCatalogo';

import { FaListUl } from "react-icons/fa";
import { IoArrowBackSharp } from "react-icons/io5";

import vertLogo from '../../assets/img/img-catalogo/menu/vertLogo.png'
import vertBulbsImg from '../../assets/img/img-catalogo/menu/vertBulbs.png'
import cintasLEDImg from '../../assets/img/img-catalogo/menu/cintaLed.png'
import panelesLEDImg from '../../assets/img/img-catalogo/menu/panelLed.png'
import tubosLEDImg from '../../assets/img/img-catalogo/menu/tubosLed.png'
import lamparasImg from '../../assets/img/img-catalogo/menu/lamparas.png'
import alumbradosImg from '../../assets/img/img-catalogo/menu/alumbrados3.png'
import reflectoresImg from '../../assets/img/img-catalogo/menu/reflectores.png'
import linternasImg from '../../assets/img/img-catalogo/menu/linternas.png'
import hogarImg from '../../assets/img/img-catalogo/menu/hogar.png'
import bathsImg from '../../assets/img/img-catalogo/menu/bathroom.png'
import jardineriaImg from '../../assets/img/img-catalogo/menu/jardineria.png'
import griferiaImg from '../../assets/img/img-catalogo/menu/griferia.png'
import cerradurasImg from '../../assets/img/img-catalogo/menu/cerraduras.png'
import ferreteriaGralImg from '../../assets/img/img-catalogo/menu/ferreteriaGral.png'
import construccionImg from '../../assets/img/img-catalogo/menu/construccion.png'
import electricidadImg from '../../assets/img/img-catalogo/menu/electricidad.png'
import automotrizImg from '../../assets/img/img-catalogo/menu/automotriz.png'
import miscelaneosImg from '../../assets/img/img-catalogo/menu/miscelaneos.png'

export const CatalogoIngco = () => {

  // State del loading
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false)

  // States de datos
  const [imageSrc, setImageSrc] = useState(vertLogo);
  const [categoryTitle, setCategoryTitle] = useState('BOMBILLOS')
  const [categorySubtitle, setCategorySubtitle] = useState('')
  const [categoryWatts, setCategoryWatts] = useState('1W - 1.5W - 3W - 5W - 9W - 12W - 18W')
  const [categoryIntensity, setCategoryIntensity] = useState('3000K - 4000K - 6500K')
  const [categoryProducts, setCategoryProducts] = useState([])

  const handleCategory = async (category) => {
    setLoading(true)
    const products = await getCatalogoByGrupo(category);

    if (products !== null && products.length > 0) {
      setCategoryProducts(products)
    } else {
      setCategoryProducts(null)
    }

    switch (category) {
      case 'bombillos':
        setCategoryTitle('BOMBILLOS')
        setCategorySubtitle('')
        setCategoryWatts('1W - 1.5W - 3W - 5W - 9W - 12W - 18W')
        setCategoryIntensity('3000K - 4000K - 6500K')
        break;

      case 'cintasLED':
        setCategoryTitle('CINTAS LED')
        setCategorySubtitle('')
        setCategoryWatts('1W - 1.5W - 3W - 5W - 9W - 12W - 18W')
        setCategoryIntensity('3000K - 4000K - 6500K')
        break;

      case 'paneles':
        setCategoryTitle('PANEL LED')
        setCategorySubtitle('SOBREPONER')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'tubos':
        setCategoryTitle('TUBOS LED')
        setCategorySubtitle('')
        setCategoryWatts('1W - 1.5W - 3W - 5W - 9W - 12W - 18W')
        setCategoryIntensity('3000K - 4000K - 6500K')
        break;

      case 'lamparas':
        setCategoryTitle('LAMPARAS')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'alumbrados':
        setCategoryTitle('ALUMBRADOS')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'reflectores':
        setCategoryTitle('REFLECTORES LED')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'linternas':
        setCategoryTitle('LINTERNAS')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'hogar':
        setCategoryTitle('HOGAR')
        setCategorySubtitle('DECORACIÓN, ACCESORIOS Y ÚTILES')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'banos':
        setCategoryTitle('BAÑOS')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'jardineria':
        setCategoryTitle('JARDINERIA')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'plomeria':
        setCategoryTitle('GRIFERÍA Y PLOMERÍA')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'cerraduras':
        setCategoryTitle('CERRADURAS')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'ferreteriagral':
        setCategoryTitle('FERRETERIA EN GENERAL')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'electricidad':
        setCategoryTitle('ELECTRICIDAD')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'automotriz':
        setCategoryTitle('AUTOMOTRIZ')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'construccion':
        setCategoryTitle('CONSTRUCCIÓN')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      case 'miscelaneos':
        setCategoryTitle('MISCELÁNEOS')
        setCategorySubtitle('')
        setCategoryWatts('12W - 18W - 24W')
        setCategoryIntensity('3000K - 4500K - 6500K')
        break;

      default:
        setCategoryTitle('BOMBILLOS')
        setCategorySubtitle('')
        setCategoryWatts('1W - 1.5W - 3W - 5W - 9W - 12W - 18W')
        setCategoryIntensity('3000K - 4000K - 6500K')
        break;
    }

    setLoading(false)
  }

  const handleCategoryHover = (newImageSrc) => {
    setImageSrc(newImageSrc);
  };

  return (
    <div className="bg-ingco bg-psh">
        <Header background={'ingco'} ></Header>
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <div className="loader-book">
                  <div>
                    <ul>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                      <li>
                        <svg fill="currentColor" viewBox="0 0 90 120">
                          <path d="M90,0 L90,120 L11,120 C4.92486775,120 0,115.075132 0,109 L0,11 C0,4.92486775 4.92486775,0 11,0 L90,0 Z M71.5,81 L18.5,81 C17.1192881,81 16,82.1192881 16,83.5 C16,84.8254834 17.0315359,85.9100387 18.3356243,85.9946823 L18.5,86 L71.5,86 C72.8807119,86 74,84.8807119 74,83.5 C74,82.1745166 72.9684641,81.0899613 71.6643757,81.0053177 L71.5,81 Z M71.5,57 L18.5,57 C17.1192881,57 16,58.1192881 16,59.5 C16,60.8254834 17.0315359,61.9100387 18.3356243,61.9946823 L18.5,62 L71.5,62 C72.8807119,62 74,60.8807119 74,59.5 C74,58.1192881 72.8807119,57 71.5,57 Z M71.5,33 L18.5,33 C17.1192881,33 16,34.1192881 16,35.5 C16,36.8254834 17.0315359,37.9100387 18.3356243,37.9946823 L18.5,38 L71.5,38 C72.8807119,38 74,36.8807119 74,35.5 C74,34.1192881 72.8807119,33 71.5,33 Z"></path>
                        </svg>
                      </li>
                    </ul>
                  </div>
                  <span className="text-light">Cargando articulos...</span>
                </div>
              </div>
            </> :
            <>
              {/* Desktop */}
              <div className='container-fluid d-none d-md-block d-lg-block'>
                <div className="container-fluid px-5">
                  <div className="row pt-4">
                    <div className="col-1">
                      <Link className='buttonBack rounded-full w-100' to='/catalogo-portada'>
                        <span className="text-white fs-6"> <IoArrowBackSharp /> Atrás</span> <i className="fa fa-angle-down"></i>
                      </Link>
                    </div>
                    <div className="col-3">
                      <div className="header">
                        <div className="container-fluid">
                          <div className="row v-center">
                            <div className="header-item item-center">
                              <div className="menu-overlay">
                              </div>
                              <nav className="menu">
                                <ul className="menu-main">
                                  <li className="menu-item-has-children">
                                    <button className='buttonCategoryIngco buttonSettings rounded-full w-100'><span className="text-white"> <FaListUl /> Categorias</span> <i className="fa fa-angle-down"></i></button>
                                    <div className="sub-menu mega-menu mega-menu-column-4 drop-shadow-card rounded row">
                                      <div className="list-item col-4">
                                        <h4 className="title-ingco fw-900 fs-3">Iluminación</h4>
                                        <ul>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={(e) => handleCategory('bombillos')}
                                              onMouseEnter={() => handleCategoryHover(vertBulbsImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Bombillos
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('cintasLED')}
                                              onMouseEnter={() => handleCategoryHover(cintasLEDImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Cintas LED y Accesorios
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('paneles')}
                                              onMouseEnter={() => handleCategoryHover(panelesLEDImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Paneles LED
                                            </button></li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('tubos')}
                                              onMouseEnter={() => handleCategoryHover(tubosLEDImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Tubos LED y Accesorios
                                            </button></li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('lamparas')}
                                              onMouseEnter={() => handleCategoryHover(lamparasImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Lampáras y Bombillos Vintage
                                            </button></li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('alumbrados')}
                                              onMouseEnter={() => handleCategoryHover(alumbradosImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Alumbrados
                                            </button></li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('reflectores')}
                                              onMouseEnter={() => handleCategoryHover(reflectoresImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Reflectores
                                            </button></li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('linternas')}
                                              onMouseEnter={() => handleCategoryHover(linternasImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Linternas
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="list-item col-3">
                                        <h4 className="title-ingco fw-900 fs-3">Hogar</h4>
                                        <ul>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('hogar')}
                                              onMouseEnter={() => handleCategoryHover(hogarImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Decoración, Accesorios y Útiles
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('banos')}
                                              onMouseEnter={() => handleCategoryHover(bathsImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Baños
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('jardineria')}
                                              onMouseEnter={() => handleCategoryHover(jardineriaImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Jardineria
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('plomeria')}
                                              onMouseEnter={() => handleCategoryHover(griferiaImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Plomeria y Griferia
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('cerraduras')}
                                              onMouseEnter={() => handleCategoryHover(cerradurasImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Cerraduras
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="list-item col-3">
                                        <h4 className="title-ingco fw-900 fs-3">Ferreteria</h4>
                                        <ul>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('ferreteriagral')}
                                              onMouseEnter={() => handleCategoryHover(ferreteriaGralImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Ferreteria en General
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('construccion')}
                                              onMouseEnter={() => handleCategoryHover(construccionImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Construcción
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('automotriz')}
                                              onMouseEnter={() => handleCategoryHover(automotrizImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Automotriz
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('electricidad')}
                                              onMouseEnter={() => handleCategoryHover(electricidadImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Electricidad
                                            </button>
                                          </li>
                                          <li className='wrap'>
                                            <button
                                              className='fs-5'
                                              onClick={() => handleCategory('miscelaneos')}
                                              onMouseEnter={() => handleCategoryHover(miscelaneosImg)}
                                              onMouseLeave={() => handleCategoryHover(vertLogo)}>
                                              Miscelaneos
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="list-item col-2 text-center">
                                        <img src={imageSrc} style={{ transition: '0.5s all ease-in-out' }} className='mx-auto' alt="Imagenes" />
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="w-100"> */}
                {/* <Vert /> */}
                <DetalladoVert title={categoryTitle} subtitle={categorySubtitle} watts={categoryWatts} intensity={categoryIntensity} products={categoryProducts} />
                {/* </div> */}
              </div>
            </>
        }
      </div>
  )
}
