import React from 'react'

export const ItemsCobranzas = (props) => {

  var ItemsCobranzas = props.item;

  return (
    <>
      <tr className=''>
        <td className='text-danger fw-bold'>{ItemsCobranzas.DocumentoFormaPago}</td>
        <td>{(ItemsCobranzas.FormaPago === 'tr') ? <>Transferencia</> : <>Efectivo</>}</td>
        <td>{ItemsCobranzas.BancoPago}</td>
        <td>{new Date(ItemsCobranzas.FechaPago).toLocaleDateString('es-MX')}</td>
        <td className='text-success fw-bold'>{(ItemsCobranzas.TasadelDia > 0) ? 'Bs. ' : '$'}{ItemsCobranzas.MontoParcial.toFixed(2)}</td>
        <td className='text-danger fw-bold'>{ItemsCobranzas.Recibo}</td>
        <td className='text-success fw-bold'>Bs. {ItemsCobranzas.TasadelDia}</td>
      </tr>
    </>
  )
}
