import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import Layout from '../../../components/Layout';
import { AuthUser } from '../../../components/AuthUser';
import { Searchbar } from '../../../components/Searchbar';
import { DetallePresupuesto } from './DetallePresupuesto';

import { RequestPage } from '../../../api/request';
import { getPresupuestos, SearchPresupuesto, SearchPresupuestobyFechas, SearchPresupuestoCli } from '../../../api/requestPresupuestos';

import Pagination from 'react-responsive-pagination';
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { DateRangePicker, Stack } from 'rsuite';

import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment/moment';

export const ConsultaPresupuesto = () => {

  // State del usuario
  const { user, getToken } = AuthUser();
  const navigate = useNavigate();

  // States para los datos
  const [Presupuesto, setPresupuesto] = useState([]);

  // States de paginas
  const [page, setPage] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [urlPage, setUrlPage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  // State del loading
  const [loading, setLoading] = useState(true);

  // States de busqueda
  const [term, setTerm] = useState('');
  const [notFound, setNotFound] = useState(false);
  const [searching, setSearching] = useState(false);
  const [found, setFound] = useState(false);
  const [foundByFecha, setFoundByFecha] = useState(false);
  const [foundByCliente, setFoundByCliente] = useState(false);
  const [inicio, setInicio] = useState('');
  const [fin, setFin] = useState('');

  // States de filtros
  const [searchbarDoc, setSearchbarDoc] = useState('d-none')
  const [searchbarCli, setSearchbarCli] = useState('d-none')
  const [searchDate, setSearchDate] = useState('d-none')

  // Rango de fechas
  const predefinedRanges = [
    {
      label: 'Últimos 3 meses',
      value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Últimos 6 meses',
      value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'bottom'
    },
    {
      label: 'Último año',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'bottom'
    }
  ];

  const { afterToday } = DateRangePicker;

  var arrPresupuesto = []
  var data = {}

  const loadPresupuestos = async () => {
    setLoading(true)
    const res = await getPresupuestos(user.CodVendedor);

    for (let i = 0; i < res.data.length; i++) {
      data = {
        Documento: res.data[i].Documento,
        Codcliente: res.data[i].Codcliente,
        NombreCliente: res.data[i].NombreCliente,
        Vendedor: res.data[i].Vendedor,
        FormaPago: res.data[i].FormaPago,
        FechaPresupuesto: res.data[i].FechaPresupuesto,
        Monto: res.data[i].Monto.toFixed(2),
        Convertido: res.data[i].Convertido,
        Descuento: res.data[i].Descuento,
        DiasPromocion: res.data[i].DiasPromocion,
        TipoPromocion: res.data[i].TipoPromocion,
        MontoPromocion: res.data[i].MontoPromocion,
      }
      arrPresupuesto.push(data)
    }

    setPage(res.meta.current_page)
    setTotalPages(res.meta.last_page)
    setUrlPage(res.links.first)

    setPresupuesto(arrPresupuesto)
    setLoading(false)
  }

  const loadPage = async (pageData) => {
    setLoading(true)

    const presupuestoData = await RequestPage(pageData);

    for (let i = 0; i < presupuestoData.data.length; i++) {
      data = {
        Documento: presupuestoData.data[i].Documento,
        Codcliente: presupuestoData.data[i].Codcliente,
        NombreCliente: presupuestoData.data[i].NombreCliente,
        Vendedor: presupuestoData.data[i].Vendedor,
        FormaPago: presupuestoData.data[i].FormaPago,
        FechaPresupuesto: presupuestoData.data[i].FechaPresupuesto,
        Monto: presupuestoData.data[i].Monto,
        Convertido: presupuestoData.data[i].Convertido,
        Descuento: presupuestoData.data[i].Descuento,
        DiasPromocion: presupuestoData.data[i].DiasPromocion,
        TipoPromocion: presupuestoData.data[i].TipoPromocion,
        MontoPromocion: presupuestoData.data[i].MontoPromocion,
      }
      arrPresupuesto.push(data)
    }

    if (found || foundByCliente || foundByFecha) {
      setPage(presupuestoData.current_page)
      setTotalPages(presupuestoData.last_page)
      setUrlPage(presupuestoData.first_page_url)
    } else {
      setPage(presupuestoData.meta.current_page)
      setTotalPages(presupuestoData.meta.last_page)
      setUrlPage(presupuestoData.links.first)
    }

    setPresupuesto(arrPresupuesto)
    setLoading(false)
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page)
    if (foundByCliente) {
      loadPage(urlPage.replace(/.$/, page + "&Vendedor=" + user.CodVendedor + "&NombreCliente=" + term))
    } else if (found) {
      loadPage(urlPage.replace(/.$/, page + "&Vendedor=" + user.CodVendedor + "&Documento=" + term))
    } else if (foundByFecha) {
      loadPage(urlPage.replace(/.$/, page + "&Vendedor=" + user.CodVendedor + "&fechaInicio=" + inicio + "&fechaFin=" + fin))
      // console.log("loadpage de fechas")
    } else {
      loadPage(urlPage.replace(/.$/, page))
    }
  }

  const onSearch = async (busqueda) => {
    if (searchbarCli === 'd-block') {
      if (!busqueda) {
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return loadPresupuestos();
      }
      setLoading(true)
      setNotFound(false)
      setSearching(true)
      const result = await SearchPresupuestoCli(user.CodVendedor, busqueda)
      if (!result || result.data.length === 0) {
        setLoading(false)
        setNotFound(true)
        setFound(false)
        setPage(1)
        setTotalPages(1)
        return;
      } else {
        setNotFound(false)
        setFound(false)
        setFoundByFecha(false)
        setFoundByCliente(true)
        setTerm(busqueda)
        setPresupuesto(result.data)
        setPage(result.current_page)
        setCurrentPage(result.current_page)
        setTotalPages(result.last_page)
        setUrlPage(result.first_page_url)
      }
      setLoading(false);
      setSearching(false);
    } else if (searchbarDoc === 'd-block') {
      if (!busqueda) {
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        return loadPresupuestos();
      }
      setLoading(true);
      setNotFound(false);
      setSearching(true);
      const result = await SearchPresupuesto(busqueda.toUpperCase(), user.CodVendedor);
      if (!result || result.data.length === 0) {
        setLoading(false);
        setFound(false)
        setFoundByCliente(false)
        setFoundByFecha(false)
        setNotFound(true);
        setPage(1);
        setTotalPages(1);
        return;
      } else {
        setNotFound(false);
        setFoundByFecha(false)
        setFoundByCliente(false)
        setFound(true)
        setTerm(busqueda)
        setPresupuesto(result.data)
        setPage(result.current_page)
        setCurrentPage(result.current_page)
        setTotalPages(result.last_page)
        setUrlPage(result.first_page_url)
      }
      setLoading(false);
      setSearching(false);
    }
    else if (searchDate != 'd-block') {
      setFoundByFecha(false)
    }
  };

  const changeDate = async (e) => {
    let date = JSON.stringify(e)
    let start = date.slice(2, 12) + " " + date.slice(14, 21)
    let end = date.slice(29, 39) + " " + date.slice(41, 48)

    const res = await SearchPresupuestobyFechas(user.CodVendedor, start, end)
    setInicio(start)
    setFin(end)

    if (!res || res.data.length === 0) {
      setNotFound(true)
      setFound(false)
      setFoundByFecha(false)
      setFoundByCliente(false)
    }
    else {
      setNotFound(false)
      setFound(false)
      setFoundByFecha(true)
      setFoundByCliente(false)
      setPresupuesto(res.data)
    }
  }

  const showFilter = async (e, filtro) => {
    e.preventDefault();
    switch (filtro) {
      case 'Doc':
        setSearchbarDoc('d-block')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
      case 'Cliente':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-block')
        setSearchDate('d-none')
        break;
      case 'Fecha':
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-block')
        break;
      case 'Limpiar':
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        setFound(false)
        setFoundByFecha(false)
        setFoundByCliente(false)
        setNotFound(false);
        onSearch('')
        break;

      default:
        onSearch('')
        setSearchbarDoc('d-none')
        setSearchbarCli('d-none')
        setSearchDate('d-none')
        break;
    }
  }

  useEffect(() => {
    loadPresupuestos();
  }, []);

  return (
    <>
      <Layout>
        {/* Cuerpo de la página */}
        {
          (loading) ?
            <>
              <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                <span className="loader-documentos"></span>
              </div>
            </> :
            <>
              {/* Cabecera */}
              <div className="consultas-title mt-5 pt-5">
                <h4 className='bg-belmeny text-light px-5 rounded-pill'>Consulta de Presupuestos</h4>
              </div>

              <h5 className="text-center mt-2 mb-4 text-belmeny"><i>Vendedor - {user.Nombre} - {user.CodVendedor}</i></h5>

              <div className="container-fluid d-md-block d-none mb-3">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        showOneCalendar
                        disabledDate={afterToday()}
                      />
                    </Stack>
                  </div>
                </div>
                <div className="rounded-0 my-2">
                  {
                    (notFound) ?
                      <>
                        <div className="alert alert-danger text-center" role="alert">
                          <h5>No se encontraron resultados</h5>
                        </div>
                      </> :
                      <>
                        <table className='table table-bordered table-hover border-belmeny'>
                          <thead className='bg-belmeny text-light text-center'>
                            <tr>
                              <th>Presupuesto</th>
                              <th className='w-50'>Cliente</th>
                              <th>Monto</th>
                              <th>Fecha</th>
                              <th style={{ width: "15%" }}>Opciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Presupuesto.map((item) => <DetallePresupuesto item={item} key={item.Documento} />)}
                          </tbody>
                        </table>

                        <Pagination
                          total={totalPages}
                          current={currentPage}
                          onPageChange={page => handlePageChange(page)}
                        />
                      </>
                  }
                </div>
              </div>

              {/* Mobile version */}
              <div className="container-fluid  d-block d-sm-block d-md-none">
                <div className="row">
                  <div className="col">
                    <div className="mb-2">
                      <DropdownButton
                        key={'end'}
                        id={`dropdown-button-drop-${'end'}`}
                        drop={'end'}
                        variant="primary"
                        title={` Filtros `}
                      >
                        <Dropdown.Item eventKey="1" className='text-decoration-none' onClick={e => showFilter(e, 'Doc')}>Por documento</Dropdown.Item>
                        <Dropdown.Item eventKey="2" className='text-decoration-none' onClick={e => showFilter(e, 'Cliente')}>Por cliente</Dropdown.Item>
                        <Dropdown.Item eventKey="3" className='text-decoration-none' onClick={e => showFilter(e, 'Fecha')}>Por fecha</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4" className='bg-danger text-light text-decoration-none' onClick={e => showFilter(e, 'Limpiar')}>Limpiar filtros</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    <div className={searchbarDoc}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <div className={searchbarCli}>
                      <Searchbar onSearch={onSearch} />
                    </div>
                    <Stack direction="row" spacing={10} alignItems="flex-start" className={searchDate}>
                      <DateRangePicker
                        appearance={"subtle"}
                        ranges={predefinedRanges}
                        placeholder="Buscar por fecha"
                        style={{ width: 300 }}
                        onChange={e => changeDate(e)}
                        className="border rounded-pill mb-2"
                        disabledDate={afterToday()}
                      />
                    </Stack>
                  </div>
                </div>
                {
                  (notFound) ?
                    <>
                      <div className="alert alert-danger text-center" role="alert">
                        <h5>No se encontraron resultados</h5>
                      </div>
                    </> :
                    <>
                      {Presupuesto.map((item) => <DetallePresupuesto item={item} key={item.Documento} />)}

                      <Pagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={page => handlePageChange(page)}
                      />
                    </>
                }
                <div className="division bg-belmeny my-3"></div>
              </div>
            </>
        }
      </Layout>
    </>
  )
}
